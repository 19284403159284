import React from 'react';
import { Channel, ChannelList, Chat, LoadingIndicator } from 'stream-chat-react';
import { makeStyles } from '@material-ui/core/styles';
import { useStreamChatClient } from './hooks/useStreamChatClient';
import groupBy from 'lodash/groupBy';
import 'stream-chat-react/dist/css/v2/index.css';
import './streamChatLayout.css';
import { useStreamChatChannelListOptions } from './hooks/useStreamChatChannelListOptions';
import { StreamChannelType } from '@7chairs/types';
import { ChannelInner } from './components/ChannelInner';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  chat: {
    display: 'flex',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  infoWrapper: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
  },
}));

interface StreamChatProps {
  groupId: string;
}

export const StreamChat = ({ groupId }: StreamChatProps) => {
  const classes = useStyles();

  const { chatClient } = useStreamChatClient();
  const { filters } = useStreamChatChannelListOptions({ groupId });

  if (!chatClient) {
    return <LoadingIndicator />;
  }

  // function used to keep group channels always on top of the private ones
  const renderChannels = (loadedChannels, channelPreviewFn): React.ReactNode => {
    const channelsGroupedByType = groupBy(loadedChannels, 'type');
    return (
      <>
        {[StreamChannelType.Group, StreamChannelType.Private].map((type) => {
          const channels = channelsGroupedByType[type];
          if (channels?.length) {
            return channels.map((channel) => channelPreviewFn(channel));
          }
          return null;
        })}
      </>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.chat}>
        <Chat client={chatClient} theme='str-chat__theme-light'>
          <ChannelList
            filters={filters}
            renderChannels={renderChannels}
            allowNewMessagesFromUnfilteredChannels={false}
          />
          <Channel>
            <ChannelInner />
          </Channel>
        </Chat>
      </div>
      <div className={classes.infoWrapper}>
        <p>Download the app to chat</p>
      </div>
    </div>
  );
};
