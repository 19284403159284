export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch {
    // ignore write errors
  }
};

const handler = {
  get: function (_, key) {
    const valueStr = localStorage.getItem(key);
    if (valueStr === null) {
      return undefined;
    }
    try {
      return JSON.parse(valueStr);
    } catch (err) {
      return valueStr;
    }
  },
  set: function (_, key, value) {
    if (value === null) {
      localStorage.removeItem(key)
      return true
    }
    // no exception handling here, must be handled by user's code
    const valueStr = typeof value === 'string' ? value : JSON.stringify(value);
    localStorage.setItem(key, valueStr);
    return true;
  },
};

export const storage = new Proxy({}, handler);

export default { loadState, saveState, storage };
