import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { updateAccount } from '../../../../../store/ducks/account';
import { NavigationContext } from '../../../../../components/navigator';
import { CtaButton } from '../../../../../components/buttons';
import { Header, Label1 } from '../../../../../components/Text';
import ImageChoose from '../imageChoose/ImageChoose';
import { trackEvent } from '../../../../../utils/analyticsEvent';
import { styles } from './styles';
import { AccountStore } from '../../../../../store/ducks/account';

type PickedAccountStore = Pick<AccountStore, 'picture' | 'isFirstLogin'>;

interface ImageUploadingProps extends PickedAccountStore {}

const ImageUploading = ({ isFirstLogin, picture }: ImageUploadingProps) => {
  const classes = styles();
  const dispatch = useDispatch();
  const navCtx = useContext(NavigationContext);

  const isMobileWebview = useMemo(() => window.navigator.userAgent.indexOf('circles-mobile-app') > -1, []);

  const [socialAvatar, setSocialAvatar] = useState('');

  useEffect(() => {
    trackEvent('view profile image upload');
  }, []);

  const handleAvatar = (avatar) => {
    setSocialAvatar(avatar);
  };

  const savePhoto = () => {
    trackEvent('click profile image save');

    dispatch(updateAccount({ picture: socialAvatar }));

    navCtx.popScreen();
  };

  const onSkipClick = () => {
    trackEvent('click profile image skip');

    navCtx.popScreen();
  };

  return (
    <div className={classes.root}>
      <Header className={classes.title}>Upload profile picture</Header>

      <ImageChoose handleAvatar={handleAvatar} picture={picture} isMobileWebview={isMobileWebview} />

      <Label1 className={classes.description}>
        Your picture is shown to other members. You can create an avatar instead and can always change it in your
        profile settings
      </Label1>

      <Label1 className={classes.divider}>or</Label1>

      <Label1
        onClick={() => {
          trackEvent('click profile image create avatar');
          navCtx.pushScreen('/profile/avatar-picking');
        }}
        className={classes.createAvatar}
      >
        <u>Create an avatar</u>
      </Label1>

      {socialAvatar && (
        <CtaButton onClick={savePhoto} color="primary">
          Done
        </CtaButton>
      )}

      <Label1 onClick={onSkipClick} className={classes.saveLater}>
        <u>Skip for later</u>
      </Label1>
    </div>
  );
};

export default ImageUploading;
