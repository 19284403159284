import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { trackEvent } from '../../utils/analyticsEvent';
import TableOfContents from './TableOfContents';
import { Label1 } from '../Text';
import { CtaButton } from '../buttons';
import { OLD_COLORS, OLD_SIZES } from '../../styles/appConsts';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    position: 'relative',
    overflowY: 'auto',
    padding: OLD_SIZES.xl,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
  },
  bkgImage: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  content: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  betaDiv: {
    marginTop: OLD_SIZES.s,
    backgroundColor: 'rgba(255, 255, 255, 0.61)',
    borderRadius: 4,
    padding: `${OLD_SIZES.xs}px ${OLD_SIZES.s}px`,
  },
  betaText: {
    fontSize: 12,
  },
  stepNum: {
    marginTop: OLD_SIZES.xxxxl,
  },
  description: {
    marginTop: OLD_SIZES.m,
    whiteSpace: 'pre-wrap',
  },
  bottomButton: {
    marginTop: OLD_SIZES.l,
    flex: 'none',
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    justifyContent: 'center',
    paddingBottom: OLD_SIZES.l,
  },
  ctaButton: {
    width: '100%',
    backgroundColor: OLD_COLORS.WHITE,
    color: OLD_COLORS.BRAND_PURPLE_2,
    '&:hover': {
      backgroundColor: OLD_COLORS.WHITE,
      color: OLD_COLORS.BRAND_PURPLE_2,
    },
  },
}));

export default function PartIntro({ parts, currentPart, reviewMode, onSubmit }) {
  const classes = useStyles();

  React.useEffect(() => {
    trackEvent('view exercise part intro', { part: currentPart + 1, reviewMode });
  }, []);

  const { description, color = OLD_COLORS.DULL_TURQUOISE } = parts[currentPart];

  return (
    <div className={classes.root} style={{ backgroundColor: color }}>
      <img src={`${ASSETS_BASE_DIR}/qa-background-2.svg`} className={classes.bkgImage} />
      <div className={classes.content}>
        <Label1 color={OLD_COLORS.WHITE} align='center' className={classes.stepNum}>{`Step #${currentPart + 1}`}</Label1>
        {description && (
          <Label1 color={OLD_COLORS.WHITE} align='center' className={classes.description}>
            {description}
          </Label1>
        )}
        <div style={{ marginTop: description ? OLD_SIZES.m : OLD_SIZES.xxxxl }}>
          <TableOfContents parts={parts} activePart={currentPart + 1} />
        </div>
      </div>
      <div className={classes.bottomButton}>
        <CtaButton
          disableElevation
          className={classes.ctaButton}
          onClick={() => {
            trackEvent('submit exercise part intro', { part: currentPart + 1, reviewMode });
            onSubmit();
          }}
        >
          {currentPart === 0 ? `Let's start` : 'Next step'}
        </CtaButton>
      </div>
    </div>
  );
}
