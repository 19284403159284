export const extractAnalyticsCookies = () => {
  try {
    const aprops =
      document.cookie
        .split(';')
        .find((c) => c.trim().split('=')[0] === 'aprops')
        ?.split('=')[1]
        .trim() || {};
    // console.log('|', decodeURIComponent(aprops), '|');
    return JSON.parse(decodeURIComponent(aprops));
  } catch (err) {
    console.debug('extractAnalyticsCookies', err);
    return {};
  }
};

const sendEvent = (type, id, params = {}) => {
  if (ALLOW_ANALYTICS) {
    analytics[type](id, params);
  }
};

export const identifyEvent = ({ id, params = {} }) => {
  sendEvent('identify', id, { ...params, ...extractAnalyticsCookies() });
};

export const trackEvent = (name, params = {}) => {
  const defaultEventProps = JSON.parse(localStorage.getItem('defaultEventProps') || '{}');
  sendEvent('track', name, { ...params, ...defaultEventProps, ...extractAnalyticsCookies() });
};

export const pageEvent = (name, params = {}) => {
  const defaultEventProps = JSON.parse(localStorage.getItem('defaultEventProps') || '{}');
  sendEvent('page', name, { ...params, ...defaultEventProps });
};

export const groupEvent = (name, params = {}) => {
  sendEvent('group', name, params);
};

export const sendGTMEvent = (eventObject) => {
  if (window.dataLayer) {
    const objectToPush = { event: 'GTM event to GA', ...eventObject };
    const segment = extractAnalyticsCookies().segment;
    if (segment) {
      objectToPush.segment = segment;
    }
    window.dataLayer.push(objectToPush);
  }
};
