import React, { useState, useEffect, useContext, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { makeStyles } from '@material-ui/core/styles';
import { getColor, Icon, IconButton, Text } from '@7chairs/frontend';
import { StylesProps as IconButtonStylesProps } from '@7chairs/frontend/dist/components/IconButton';
import { AccountRole } from '@7chairs/types';

import { getAccountsTags } from '../../../store/ducks/profile';
import { trackEvent } from '../../../utils/analyticsEvent';
import { navigateToPaymentPage } from '../../../utils/payment';
import { useSlider } from './hooks/useSlider';

import { GroupSpaceMember } from '../../../screens/groupSpace/GroupSpace';
import { NavigationContext } from '../../navigator';
import { MemberCard } from './MemberCard';
import { MemberProfilePopup } from './MemberProfilePopup';
import { NoIntroPopup } from './NoIntroPopup';

interface StyleProps {
  areSliderArrowsVisible: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    width: 'unset',
    paddingLeft: '0px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '0px 24px',
    },
  },
  title: (props: StyleProps) => ({
    display: 'flex',
    gap: 8,
    marginLeft: props.areSliderArrowsVisible ? '80px' : '0px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: props.areSliderArrowsVisible ? '50px' : '0px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
    },
  }),
  sliderWrapper: {
    display: 'flex',
    gap: '40px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      gap: '10px',
    },
  },
  sliderArrow: (props: StyleProps) => ({
    display: props.areSliderArrowsVisible ? 'flex' : 'none',
    marginBottom: '40px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  }),
  slider: {
    width: '560px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .slick-dots': {
      display: 'flex',
      alignItems: 'center',
      gap: '25px',
      bottom: -25,
      paddingBottom: '5px',
      '& li div.dot': {
        color: getColor('BLACK_20'),
      },
      '& li.slick-active div.dot': {
        color: getColor('PURPLE_100'),
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '320px',
    },
  },
}));

const SLIDER_ARROW_STYLES_PROPS: IconButtonStylesProps = {
  backgroundColor: 'WHITE_100',
  color: 'PURPLE_100',
  disabledStyles: {
    color: 'BLACK_20',
  },
  size: 40,
  withShadow: false,
};

const MIN_MEMBERS_TO_SHOW_SLIDER_ARROWS = 7;
const DEFAULT_SLIDES_TO_SHOW_ON_DESKTOP = 7;
const DEFAULT_SLIDES_TO_SHOW_ON_MOBILE = 4;

interface MembersProps {
  members: GroupSpaceMember[];
  key: string;
}

export const Members: FC<MembersProps> = ({ members }) => {
  const classes = useStyles({
    areSliderArrowsVisible: members.length > MIN_MEMBERS_TO_SHOW_SLIDER_ARROWS,
  });

  const dispatch = useDispatch();
  const navCtx = useContext(NavigationContext);

  const {
    slider,
    sliderSettings,
    currentSlideIndex,
    showPrevSlide,
    showNextSlide,
    isPreviousButtonDisabled,
    isNextButtonDisabled,
  } = useSlider({
    slidesCount: members.length,
    initialSlideIndex: 0,
    sliderSpeed: 300,
    // it's limitation of used react-slick lib, is slidesToShowOnDesktop will be higher than slidesCount then it will break
    slidesToShowOnDesktop:
      members.length > DEFAULT_SLIDES_TO_SHOW_ON_DESKTOP ? DEFAULT_SLIDES_TO_SHOW_ON_DESKTOP : members.length,
    slidesToScrollOnDesktop: 1,
    slidesToShowOnMobile:
      members.length > DEFAULT_SLIDES_TO_SHOW_ON_MOBILE ? DEFAULT_SLIDES_TO_SHOW_ON_MOBILE : members.length,
    slidesToScrollOnMobile: 2,
  });

  // @ts-ignore
  const accountId: string = useSelector((state) => state.account.id);
  // @ts-ignore
  const role: AccountRole = useSelector((state) => state.account.role);
  // @ts-ignore
  const isPayment: boolean = useSelector((state) => state.account.isPayment);

  const [shouldOpenNoProfileInfoPopup, setShouldOpenNoProfileInfoPopup] = useState(false);
  const [clickedMember, setClickedMember] = useState<GroupSpaceMember | null>(null);
  const [openNoProfileInfoPopup, setOpenNoProfileInfoPopup] = useState(false);
  const [isMemberProfilePopupOpen, setMemberProfilePopupOpen] = useState(false);
  const [membersCardsClicks, setMembersCardsClicks] = useState(0);

  useEffect(() => {
    dispatch(getAccountsTags());
  }, []);

  useEffect(() => {
    if (role !== AccountRole.Leader) {
      const { intro, passions, descriptionTags, whatBringsMe } = members.find(({ id }) => id === accountId);
      const infoPopupState =
        intro?.length > 0 && whatBringsMe?.length > 0 && passions?.length > 0 && descriptionTags?.length > 0;
      setShouldOpenNoProfileInfoPopup(!infoPopupState);
    }
  }, [role, members, accountId]);

  const onMemberClick = (member: GroupSpaceMember) => {
    const { id: viewedAccountId, isFacilitator } = member;
    trackEvent('click member card', {
      groupRole: isFacilitator ? 'Facilitator' : 'Member',
      isMyself: accountId === viewedAccountId,
    });

    if (!isFacilitator) {
      if (role === AccountRole.Member && !isPayment) {
        navigateToPaymentPage();
        return;
      } else if (shouldOpenNoProfileInfoPopup) {
        setOpenNoProfileInfoPopup(true);
        return;
      }
    }

    setMembersCardsClicks(membersCardsClicks + 1);
    setClickedMember(member);
    setMemberProfilePopupOpen(true);
  };

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {role === AccountRole.Member && !isPayment && <Icon.Outline.Lock size={24} color="PURPLE_100" />}
        <Text variant="subtitle" color="BLACK_80">
          Your group members
        </Text>
      </div>

      <div className={classes.sliderWrapper}>
        <IconButton
          icon={<Icon.Outline.ChevronLeft />}
          onClick={showPrevSlide}
          disabled={isPreviousButtonDisabled}
          stylesProps={SLIDER_ARROW_STYLES_PROPS}
          className={classes.sliderArrow}
        />
        <Slider ref={(c) => (slider.current = c)} {...sliderSettings} className={classes.slider}>
          {members.map((member) => {
            const { id, isFacilitator, picture, name } = member;
            return (
              <MemberCard
                key={id}
                isFacilitator={isFacilitator}
                isFacilitatorVisible={currentSlideIndex === 0}
                picture={picture}
                name={name}
                onClick={() => onMemberClick(member)}
              />
            );
          })}
        </Slider>
        <IconButton
          icon={<Icon.Outline.ChevronRight />}
          onClick={showNextSlide}
          disabled={isNextButtonDisabled}
          stylesProps={SLIDER_ARROW_STYLES_PROPS}
          className={classes.sliderArrow}
        />
      </div>
      <NoIntroPopup
        open={openNoProfileInfoPopup}
        onClose={() => setOpenNoProfileInfoPopup(false)}
        onPrimaryButtonClick={() => navCtx.pushScreen('/profile/items?scrollToStory=true')}
        onSecondaryButtonClick={() => setOpenNoProfileInfoPopup(false)}
      />
      {clickedMember ? (
        <MemberProfilePopup
          isOpen={isMemberProfilePopupOpen}
          member={clickedMember}
          isUser={clickedMember && clickedMember.id === accountId}
          onClose={() => {
            setMemberProfilePopupOpen(false);
            setClickedMember(null);
          }}
        />
      ) : null}
    </div>
  );
};
