import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { OLD_COLORS, OLD_SIZES } from '../../styles/appConsts';
import CircledNumber from './CircledNumber';

const useStyles = makeStyles(() => ({
  sections: {
    display: 'flex',
    flexDirection: 'column',
    '& $section:first-of-type:after': {
      content: 'none',
      marginTop: 0,
    },
    '& currentSection:first-of-type:after': {
      content: 'none',
      marginTop: 0,
    },
  },
  section: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    opacity: '0.7',
    marginTop: 16,
    '&:after': {
      top: -20,
      left: 17,
      width: 2,
      height: 20,
      content: "''",
      position: 'absolute',
      backgroundColor: OLD_COLORS.WHITE,
    },
  },
  sectionName: {
    fontFamily: 'Kalam',
    fontSize: 22,
    lineHeight: '35px',
    color: OLD_COLORS.WHITE,
    marginLeft: OLD_SIZES.s,
  },
  activeSection: {
    opacity: '1',
  },
  currentSectionFirst: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    opacity: '1',
    marginTop: 16,
    marginLeft: '-2px',
  },
  currentSection: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    opacity: '1',
    marginTop: 16,
    marginLeft: '-2px',
    '&:after': {
      top: -20,
      left: 19,
      width: 2,
      height: 20,
      content: "''",
      position: 'absolute',
      backgroundColor: OLD_COLORS.WHITE,
    },
  }
}));

const TableOfContents = ({ activePart, parts }) => {
  const classes = useStyles();
  return (
    <div className={classes.sections}>
      {parts.map((part, index) => (
          <div key={index} className={`${activePart === index + 1 ? index === 0 ? classes.currentSectionFirst : classes.currentSection : classes.section} ${activePart >= index + 1 ? classes.activeSection : ''}`}>
            <CircledNumber isCurrent={activePart === index + 1} number={index + 1} />
            <div className={classes.sectionName}>{part.title}</div>
          </div>
      ))}
    </div>
  );
};

export default TableOfContents;
