import { makeStyles } from '@material-ui/core/styles';
import { Event as EventInterface } from '@7chairs/types';
import React from 'react';
import { Badge, ColorType, getColor, Text } from '@7chairs/frontend';
import {sendGTMEvent} from "../../../utils/analyticsEvent";

const BACKGROUND_COLORS: ColorType[] = ['BLUE_30', 'GREEN_30', 'YELLOW_30', 'PINK_30'];

interface StylesProps {
  backgroundColor: ColorType;
}

const useStyles = makeStyles(() => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '173px',
    gap: '16px',
    padding: '24px',
  },
  contentText: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 3,
    overflow: 'hidden',
  },
  hostContainer: {
    alignItems: 'center',
    backgroundColor: getColor('WHITE_100'),
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    display: 'flex',
    gap: '8px',
    height: '73px',
    padding: '16px 24px',
  },
  hostTexts: {
    display: 'flex',
    flexDirection: 'column',
  },
  root: (props: StylesProps) => ({
    backgroundColor: getColor(props.backgroundColor),
    borderRadius: '12px',
    boxShadow: '0px 8px 30px rgba(80, 85, 136, 0.06)',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    height: '246px',
    justifyContent: 'space-between',
    marginRight: '16px',
    minWidth: '248px',
  }),
}));

interface EventProps extends EventInterface {
  index: number;
}

export const Event = ({ externalUrl, index, meta, title }: EventProps) => {
  const classes = useStyles({ backgroundColor: BACKGROUND_COLORS[index % 4] });

  return (
    <div className={classes.root} onClick={() => {
      sendGTMEvent({
        GA_event_category: "platform",
        GA_event_action: "events and webinars clicked",
        GA_event_label: title,
        GA_event_detail: "NA",
      });
      window.open(externalUrl)
    }}>
      <div className={classes.contentContainer}>
        {meta.tag as string === 'Live' ? <Badge.Live /> : null}
        <Text className={classes.contentText} variant={'subtitleSemibold'}>{title}</Text>
      </div>
      <div className={classes.hostContainer}>
        <div className={classes.hostTexts}>
          <Text color={'BLACK_60'} variant={'label'}>Hosted by</Text>
          <Text variant={'small'}>{meta.host as string}</Text>
        </div>
      </div>
    </div>
  );
};
