import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Header } from '../../components/Text';
import { OLD_SIZES } from '../../styles/appConsts';
import DesktopHeader from '../../components/svgImages/groupSpace/HeaderDesktop';
import DesktopSmallHeader from '../../components/svgImages/groupSpace/HeaderDesktopSmall';
import MobileHeader from '../../components/svgImages/groupSpace/HeaderMobile';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    paddingBottom: 60,
    height: 260,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 32,
      height: 120,
    },
  },
  headerImage: {
    position: 'absolute',
    height: '100%',
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: OLD_SIZES.l,
    [theme.breakpoints.down('sm')]: {
      paddingTop: OLD_SIZES.m,
    },
  },
  logo: {
    width: 98,
    marginBottom: OLD_SIZES.xxxxxxl,
    [theme.breakpoints.down('sm')]: {
      marginBottom: OLD_SIZES.l,
      width: 60,
    },
  },
}));

interface AuthContainerHeaderProps {
  title: string;
}

export const AuthContainerHeader = ({ title }: AuthContainerHeaderProps) => {
  const classes = useStyles();

  const getScreenState = (width) => {
    if (width < 600) {
      return 'mobile';
    }
    if (width < 1500) {
      return 'desktop_small';
    }
    return 'desktop';
  };

  const [screenState, setScreenState] = useState(getScreenState(window.innerWidth));

  useEffect(() => {
    function handleResize() {
      const newState = getScreenState(window.innerWidth);
      if (newState !== screenState) {
        setScreenState(newState);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screenState]);

  return (
    <div className={classes.root}>
      {screenState === 'mobile' && <MobileHeader className={classes.headerImage} />}
      {screenState === 'desktop_small' && <DesktopSmallHeader className={classes.headerImage} />}
      {screenState === 'desktop' && <DesktopHeader className={classes.headerImage} />}
      <div className={classes.content}>
        <img className={classes.logo} src={`${ASSETS_BASE_DIR}/logo_white.png`} alt='' />
        <Header>{title}</Header>
      </div>
    </div>
  );
}
