export const NO_AVATAR = `${ASSETS_BASE_DIR}/avatar/no-avatar.svg`;

export const ORIGIN_EXERCISE_SIGNUP = 'exercise-signup';
export const ORIGIN_WORKSHOP = 'workshop';
export const ORIGIN_WORKSHOP_STRICT = 'workshop-s';
export const ORIGIN_GROUP_DISCOVERY_STRICT = 'group-discovery-s';

export const PAYMENT_STATUS = {
  PAID: 'paid',
  UNPAID: 'unpaid',
  TRIAL: 'trial',
  ACTIVE: 'active',
};

export const GROUP_UNDEFINED = 'undefined';

export const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.sevenchairs';
export const APP_STORE_URL = 'https://apps.apple.com/us/app/circles-support/id1540905564';
export const MOBILE_APP_URL_SCHEME = 'circlesup://';

export const SUPPORT_ACCOUNT_ID = 'a5b5fc6c-0721-4853-9e67-74083f441e30';

export const SUPPORT_ACCOUNT_CALENDLY_LINK = 'https://calendly.com/7chairsplanning/15min';

export const LIVE_CHAT_SUPPORT = 'a5b5fc6c-0721-4853-9e67-74083f441e30';
export const SESSION_GAP = 30 * 60 * 1000; // 30min, gap from group session time

export const STRAPI_PATH = {
  CALENDLY: {
    key: 'calendly',
    url: '/calendly-step',
  },
  CALENDLY_GROUPS_LAB: {
    key: 'calendlyGroupsLab',
    url: '/calendly-groups-lab',
  },
  PAYMENT_POPUP: {
    key: 'paymentPopup',
    url: '/payment-popups',
  },
};

export const STRIPE_SUBSCRIPTION_STATUS = {
  ACTIVE: 'active',
  CANCELLED: 'canceled',
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  PAST_DUE: 'past_due',
  TRAILING: 'trialing',
  UNPAID: 'unpaid',
};

export const PAYMENT_PROVIDERS = {
  STRIPE: 'stripe',
  IAP_HUB: 'iab_hub',
};

export const MOBILE_APP_DEEP_LINK = 'https://circlesv.page.link/mobile-mgs';
