import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { OLD_COLORS } from '../../../styles/appConsts';
import { Label1 } from '../../Text';

const SCALE = {
  MAX: 10,
  MIN: 5,
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  item: {
    alignItems: 'center',
    backgroundColor: OLD_COLORS.WHITE,
    border: `${theme.typography.pxToRem(1)} solid ${OLD_COLORS.VIBRANT_BLUE_3}`,
    borderRadius: theme.typography.pxToRem(4),
    cursor: 'pointer',
    display: 'flex',
    height: theme.typography.pxToRem(40),
    justifyContent: 'center',
    marginRight: theme.typography.pxToRem(8),
    marginTop: theme.typography.pxToRem(8),
    width: theme.typography.pxToRem(40),
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  selected: {
    backgroundColor: `${OLD_COLORS.VIBRANT_BLUE_2}4D`, // 4D means 30% alpha
    borderColor: OLD_COLORS.VIBRANT_BLUE_2,
  },
}));

interface RatingProps {
  scale: number;
  score: number;
  setScore: (arg: number) => void;
}

export const Rating = ({ scale, score, setScore }: RatingProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      {scale > SCALE.MAX || scale < SCALE.MIN
        ? null
        : [...Array(scale).keys()].map((element) => (
            <Box
              className={`${classes.item} ${score >= element + 1 ? classes.selected : ''}`}
              key={element + 1}
              onClick={() => setScore(element + 1)}
            >
              <Label1 color={OLD_COLORS.VIBRANT_BLUE_2}>{element + 1}</Label1>
            </Box>
          ))}
    </Box>
  );
};
