import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Avatar, Badge, Box } from '@material-ui/core';
import { OLD_COLORS, OLD_SIZES } from '../../../../styles/appConsts';
import { DESKTOP_WIDTH, MOBILE_WIDTH } from '../../utils';
import { trackEvent } from '../../../../utils/analyticsEvent';
import { updateAccount } from '../../../../store/ducks/account';
import { CTA, InputText, Label2 } from '../../../../components/Text';
import { AccountRole } from '@7chairs/types';

interface ItemPersonalDetailsProps {
  name: string;
  phoneNumber: string;
  email: string;
  role: AccountRole;
}

type UpdatedField = {
  value?: string;
  name?: 'name' | 'email' | 'phone';
  error?: boolean;
};

const useStyles = makeStyles((theme) => ({
  item: {
    marginBottom: OLD_SIZES.xl,
    display: 'inline-block',
  },
  smAvatar: {
    height: 48,
    width: 48,
  },
  field: {
    maxHeight: 315,
    [theme.breakpoints.down('sm')]: {
      width: 315,
    },
    width: 345,
  },
  fieldTitle: {
    paddingLeft: 18,
    color: OLD_COLORS.GRAY_400,
  },
  fieldTitleOnError: {
    paddingLeft: 18,
    color: OLD_COLORS.RED_500,
  },
  inputDiv: {
    height: 50,
    width: '100%',
    border: '2px solid transparent',
    borderRadius: 8,
  },
  inputField: {
    fontSize: 14,
    paddingLeft: OLD_SIZES.s,
    width: '100%',
  },
  errorDiv: {
    height: 24,
  },
  fieldError: {
    color: OLD_COLORS.RED_500,
    opacity: 0.5,
    paddingLeft: 18,
  },
  badgeAvatarTopRight: {
    top: 3,
    right: 27,
  },
  box: {
    borderRadius: 8,
    paddingTop: OLD_SIZES.m,
    paddingRight: OLD_SIZES.m,
    paddingBottom: OLD_SIZES.m,
    paddingLeft: OLD_SIZES.xl,
    textAlign: 'left',
    width: DESKTOP_WIDTH,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: OLD_SIZES.m,
      width: MOBILE_WIDTH,
    },
  },
  padding24desktop: {
    paddingLeft: OLD_SIZES.l,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
}));

const saveInfoHandler = (event) => {
  if (event.keyCode == 13) {
    event.preventDefault();
    document.getElementById('enter-my-group').focus();
  }
};

export const validNameRegex = RegExp('^[a-zA-Z.-]*[a-zA-Z]+[a-zA-Z.-]*$');
const validPhoneRegex = RegExp('^\\+?[\\s-]?(\\d{1,3}[\\s-]?)?\\(?\\d{2,4}\\)?[\\d\\s-]{3,}$');
const validEmailRegex = RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$');

function ItemPersonalDetails({ name, phoneNumber, email, role }: ItemPersonalDetailsProps) {
  const dispatch = useDispatch();

  const [localName, setLocalName] = React.useState<UpdatedField>({});
  const [localPhone, setLocalPhone] = React.useState<UpdatedField>({});
  const [localEmail, setLocalEmail] = React.useState<UpdatedField>({});

  const [editMode, setEditMode] = React.useState(false);

  if (localName.value === undefined && name !== null) {
    setLocalName({ name: 'name', value: name, error: false });
  }

  React.useEffect(() => {
    if ((localPhone.value === undefined || localPhone.value !== phoneNumber) && phoneNumber != null)
      setLocalPhone({ name: 'phone', value: phoneNumber, error: false });
    if ((localEmail.value === undefined || localEmail.value !== email) && email != null)
      setLocalEmail({ name: 'email', value: email, error: false });
  }, [phoneNumber, email]);

  const onEmailChange = (e) => {
    const newValue = e.target.value;
    setLocalEmail({
      name: 'email',
      value: newValue,
      error: !validEmailRegex.test(newValue),
    });
  };

  const onPhoneChange = (e) => {
    const newValue = e.target.value;
    setLocalPhone({
      name: 'phone',
      value: newValue,
      error: !validPhoneRegex.test(newValue) || newValue.length > 20,
    });
  };

  const onNameChange = (e) => {
    const newValue = e.target.value;
    setLocalName({
      name: 'name',
      value: newValue,
      error: !validNameRegex.test(newValue) || newValue.length > 40,
    });
  };

  const updatePersonalDetails = () => {
    trackEvent(editMode ? 'click save personal details' : 'click edit personal details');

    if (editMode) {
      if (localName.error || localPhone.error || localEmail.error) {
        return;
      } else {
        dispatch(updateAccount({ name: localName.value, email: localEmail.value, phone_number: localPhone.value }));
      }
    }
    setEditMode(!editMode);
  };

  const classes = useStyles();
  return (
    <Grid item className={classes.item}>
      <Badge
        overlap='rectangular'
        classes={{ badge: classes.badgeAvatarTopRight }}
        badgeContent={
          role !== AccountRole.Leader ? (
            <Avatar
              alt={name}
              src={`${ASSETS_BASE_DIR}/${editMode ? 'approve_avatar' : 'edit_white'}.svg`}
              className={classes.smAvatar}
              onClick={updatePersonalDetails}
            />
          ) : null
        }
      >
        <Box boxShadow={'0px 4px 20px rgba(0, 0, 0, 0.08)'} className={classes.box}>
          <div>
            <CTA style={{ paddingLeft: 18, paddingBottom: 12, fontWeight: '500' }}>Personal Details</CTA>
            <Grid container justifyContent='flex-start'>
              <div className={classes.field}>
                <Label2 className={localName.error ? classes.fieldTitleOnError : classes.fieldTitle}>Nick Name</Label2>
                <div
                  className={classes.inputDiv}
                  style={{ borderColor: localName.error ? OLD_COLORS.RED_500 : 'transparent' }}
                >
                  <InputText
                    className={classes.inputField}
                    value={localName.value || ''}
                    multiline={false}
                    inputProps={{ min: 3, max: 10 }}
                    onChange={onNameChange}
                    onKeyDown={saveInfoHandler}
                    disabled={!editMode}
                  />
                </div>
                <div className={classes.errorDiv}>
                  {localName.error && (
                    <Label2 className={classes.fieldError}>
                      {localName.value.indexOf(' ') !== -1 ? 'Name cannot include spaces' : 'Name not valid'}
                    </Label2>
                  )}
                </div>
              </div>

              <div className={`${classes.field} ${classes.padding24desktop}`}>
                <Label2 className={localEmail.error ? classes.fieldTitleOnError : classes.fieldTitle}>Email</Label2>
                <div
                  className={classes.inputDiv}
                  style={{ borderColor: localEmail.error ? OLD_COLORS.RED_500 : 'transparent' }}
                >
                  <InputText
                    className={classes.inputField}
                    value={localEmail.value || ''}
                    multiline={false}
                    inputProps={{ min: 3, max: 10 }}
                    onChange={onEmailChange}
                    onKeyDown={saveInfoHandler}
                    disabled={!editMode}
                  />
                </div>
                <div className={classes.errorDiv}>
                  {localEmail.error && <Label2 className={classes.fieldError}>Email not valid</Label2>}
                </div>
              </div>
            </Grid>
            <div className={classes.field}>
              <Label2 className={localPhone.error ? classes.fieldTitleOnError : classes.fieldTitle}>
                Phone Number
              </Label2>
              <div
                className={classes.inputDiv}
                style={{ borderColor: localPhone.error ? OLD_COLORS.RED_500 : 'transparent' }}
              >
                <InputText
                  className={classes.inputField}
                  value={localPhone.value || ''}
                  multiline={false}
                  inputProps={{ min: 3, max: 10 }}
                  onChange={onPhoneChange}
                  onKeyDown={saveInfoHandler}
                  disabled={true} // {!editMode}
                />
              </div>
              <div className={classes.errorDiv}>
                {localPhone.error && <Label2 className={classes.fieldError}>Phone not valid</Label2>}
              </div>
            </div>
          </div>
        </Box>
      </Badge>
    </Grid>
  );
}

export default ItemPersonalDetails;
