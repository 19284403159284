import React, { useState } from 'react';
import { connect } from 'react-redux';
import { logout } from '../store/ducks/auth';
import Header from './Header';
import { GhostGroupMemberPopup } from './popups/GhostGroupMemberPopup';
import Logo from './icons/Logo';
import { BackButton, Title, Settings } from './topBar';

function TopBarContainer(props) {
  const { isMobileWebview, mainLogo, withSettings, withBackNav, optBackNav, title, role, logout } = props;

  const [displayGhostMemberPopup, setDisplayGhostMemberPopup] = useState(false);

  let left = null;
  let center = null;
  const right = [];

  if (withBackNav) {
    left = <BackButton />;
  }

  if (optBackNav && optBackNav(props)) {
    left = <BackButton />;
  }

  if (mainLogo) {
    center = Logo;
  }

  if (title) {
    center = <Title text={title} />;
  }

  if (withSettings) {
    right.push(<Settings edge='end' logout={logout} role={role} />);
  }

  return (
    <>
      <Header
        center={center}
        left={isMobileWebview ? null : left}
        right={isMobileWebview ? [] : right}
        displayNewMessageAlert={false}
      />
      <GhostGroupMemberPopup open={displayGhostMemberPopup} onClose={() => setDisplayGhostMemberPopup(false)} />
    </>
  );
}

function mapState({ account }) {
  return {
    role: account.role,
  };
}

export default connect(mapState, { logout })(TopBarContainer);
