/* eslint-disable import/order */
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import apiMiddleware from './configureApiMiddleware';
import rootReducer from './ducks';

// Enable devTools Chrome extension
const composer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunkMiddleware, apiMiddleware];
const enhancer = composer(applyMiddleware(...middlewares));

export default function (initialState = {}) {
  const store = createStore(rootReducer, initialState, enhancer);
  return store;
}
