import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { OLD_COLORS } from '../../../../styles/appConsts';
import Avatar from '../../../../components/Avatar';
import { Label1, Label2 } from '../../../../components/Text';
import { CtaButton } from '../../../../components/buttons';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '50px',
    height: '90%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '50px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    color: OLD_COLORS.BLACK,
    textAlign: 'center',
  },
  image: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  button: {
    width: '220px',
    background: OLD_COLORS.VIBRANT_BLUE_2,
    '&:hover': {
      background: OLD_COLORS.VIBRANT_BLUE_2,
    },
  },
  buttonLabel: {
    color: OLD_COLORS.WHITE,
  },
}));

interface ThankYouProps {
  groupLeaderPicture: string;
  title: string;
  buttonLabel: string;
  navigateToGroupChatOnWebOrCloseOnMobileWebview: () => void;
}

export const ThankYou = ({
  groupLeaderPicture,
  title,
  buttonLabel,
  navigateToGroupChatOnWebOrCloseOnMobileWebview,
}: ThankYouProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.content}>
        <Avatar img={groupLeaderPicture} />
        <Label2 className={classes.title}>{title}</Label2>
        <img
          className={classes.image}
          src={`${ASSETS_BASE_DIR}/groupSpace/connect_with_others.svg`}
          alt='connected people'
        />
      </Box>
      <CtaButton className={classes.button} onClick={navigateToGroupChatOnWebOrCloseOnMobileWebview}>
        <Label1 className={classes.buttonLabel}>{buttonLabel}</Label1>
      </CtaButton>
    </Box>
  );
};
