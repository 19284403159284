import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { OLD_COLORS, OLD_SIZES } from '../../../styles/appConsts';
import { Label1 } from '../../Text';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '40vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  thankYouContainer: {
    height: 100,
    width: 210,
    borderRadius: 8,
    backgroundColor: OLD_COLORS.GREEN_50,
    padding: theme.typography.pxToRem(OLD_SIZES.m),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const Confirmation = () => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.thankYouContainer}>
        <span>🙏</span>
        <Label1 color={OLD_COLORS.GREEN_500} align='center' mt={OLD_SIZES.s}>
          Thanks so much for <br /> your feedback!
        </Label1>
      </Box>
    </Box>
  );
};
