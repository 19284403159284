import React from 'react';
import { Label1 } from '../../../components/Text';
import { OLD_COLORS, OLD_SIZES } from '../../../styles/appConsts';
import { CtaButton } from '../../../components/buttons';
import { makeStyles } from '@material-ui/core/styles';
import DigitsCode from '../../../components/form/DigitsCode';

const useStyles = makeStyles(() => ({
  title: {
    textAlign: 'center',
    color: OLD_COLORS.GRAY_500,
    whiteSpace: 'pre-line',
  },
  inputLabelWrapper: {
    paddingTop: OLD_SIZES.l,
    paddingLeft: OLD_SIZES.m,
  },
  inputWrapper: {
    paddingTop: OLD_SIZES.s,
  },
  ctaBtn: {
    '&:disabled': {
      backgroundColor: OLD_COLORS.BRAND_PURPLE_2,
      color: OLD_COLORS.WHITE,
    },
  },
}));

interface CodeProps {
  phoneNumber: string;
  code: string;
  isError: boolean;
  handleCodeChange: (code: string) => void;
  handleErrorChange: (isError: boolean) => void;
  onSubmit: (code: string) => void;
}

export const Code = ({ phoneNumber, code, isError, handleCodeChange, handleErrorChange, onSubmit }: CodeProps) => {
  const classes = useStyles();
  return (
    <>
      <div>
        <Label1 className={classes.title}>
          {`Please type here verification code \n sent to ${phoneNumber}`}
        </Label1>
        <div className={classes.inputLabelWrapper}>
          <Label1 color={isError ? OLD_COLORS.RED_500 : OLD_COLORS.BLACK}>6 digit code</Label1>
        </div>
        <div className={classes.inputWrapper}>
          <DigitsCode
            onChange={(code) => {
              handleCodeChange(code);
              handleErrorChange(false);
            }}
            onSubmit={onSubmit}
            error={isError}
          />
        </div>
      </div>
      <CtaButton className={classes.ctaBtn} disabled={code.length !== 6} onClick={onSubmit}>
        Continue
      </CtaButton>
    </>
  );
};
