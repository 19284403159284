import React from 'react';
import { connect } from 'react-redux';
import { isAndroid, isIOS } from 'react-device-detect';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { SubHeader, Label1, Label2 } from './Text';
import { OLD_COLORS, OLD_SIZES } from '../styles/appConsts';
import { APP_STORE_URL, GOOGLE_PLAY_URL, MOBILE_APP_URL_SCHEME } from '../utils/consts';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    borderTop: `1px solid ${OLD_COLORS.GRAY_200}`,
    zIndex: 99,
  },
  container: {
    position: 'relative',
    width: '100%',
    padding: OLD_SIZES.m,
    backgroundColor: OLD_COLORS.WHITE,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: OLD_COLORS.BLACK,
  },
  appContent: {
    display: 'flex',
    marginBottom: OLD_SIZES.m,
  },
  mobileAppIcon: {
    width: '19vw',
    height: '19vw',
    borderRadius: 16,
  },
  appDetails: {
    padding: OLD_SIZES.s,
  },
  appDetailsHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: OLD_SIZES.xs,
  },
  starsWrapper: {
    display: 'flex',
    marginLeft: OLD_SIZES.s,
  },
  starIcon: {
    width: 14,
    height: 14,
    marginRight: OLD_SIZES.xs,
  },
  buttonsWrapper: {
    display: 'flex',
  },
  actionButton: {
    width: '44vw',
    borderRadius: 2,
    padding: OLD_SIZES.xs,
    display: 'flex',
    justifyContent: 'center',
  },
  viewButton: {
    backgroundColor: OLD_COLORS.GRAY_100,
  },
  storeButton: {
    marginLeft: OLD_SIZES.m,
    backgroundColor: OLD_COLORS.GREEN_600,
  },
}));

function MobileAppBanner({ isMobileWebview, isPayment }) {
  const classes = useStyles();
  const [dismissBanner, setDismissBanner] = React.useState(false);
  if (isMobileWebview || dismissBanner || !isPayment) {
    return null;
  }

  const storeUrl = isAndroid ? GOOGLE_PLAY_URL : isIOS ? APP_STORE_URL : null;

  if (!storeUrl) {
    return null;
  }

  const onViewClick = () => {
    window.location.replace(MOBILE_APP_URL_SCHEME);
    setTimeout(() => {
      // means app is not installed
      onStoreClick();
    }, 500);
  };

  const onStoreClick = () => {
    window.open(storeUrl, '_blank');
  };

  const stars = [1, 2, 3, 4, 5];

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <IconButton aria-label='close' className={classes.closeButton} onClick={() => setDismissBanner(true)}>
          <CloseIcon />
        </IconButton>
        <div className={classes.appContent}>
          <img src={`${ASSETS_BASE_DIR}/favicon/apple-icon.png`} className={classes.mobileAppIcon} />
          <div className={classes.appDetails}>
            <div className={classes.appDetailsHeader}>
              <SubHeader>Circles Support</SubHeader>
              <div className={classes.starsWrapper}>
                {stars.map((num) => {
                  const imgSrc = num === 5 ? 'half-star.png' : 'full-star.png';
                  return (
                    <img
                      key={`star-${num}`}
                      src={`${ASSETS_BASE_DIR}/mobileAppBanner/${imgSrc}`}
                      className={classes.starIcon}
                    />
                  );
                })}
              </div>
            </div>
            <Label2>{`Available on ${isIOS ? 'the App Store' : 'Google play'}`}</Label2>
          </div>
        </div>
        <div className={classes.buttonsWrapper}>
          <div className={clsx(classes.actionButton, classes.viewButton)} onClick={onViewClick}>
            <Label1>View</Label1>
          </div>
          <div className={clsx(classes.actionButton, classes.storeButton)} onClick={onStoreClick}>
            <Label1 color={OLD_COLORS.WHITE}>Get App</Label1>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapState = ({ account }) => ({ isPayment: account.isPayment });

export default connect(mapState, null)(MobileAppBanner);
