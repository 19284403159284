import { createAction, createApiAction, createReducer } from '../../utils';
import { nextSession } from '../../utils/sessionTime';
import { GroupItem } from '@7chairs/types';

export type GroupsStore = {
  myGroups: GroupItem[];
  myGroupsLastUpdated: number;
};

// Types
export const types = {
  GET_PERSONAL: 'groups/GET_PERSONAL',
  GET_PERSONAL_SUCCESS: 'groups/GET_PERSONAL_SUCCESS',
  GET_PERSONAL_ERROR: 'groups/GET_PERSONAL_ERROR',
  UPDATE_NEXT_SESSION_TIME: 'groups/UPDATE_NEXT_SESSION_TIME',
};

// Actions
export function getPersonalGroups() {
  return createApiAction(
    [types.GET_PERSONAL, types.GET_PERSONAL_SUCCESS, types.GET_PERSONAL_ERROR],
    // when going back after selecting a team and not paying the group should not be cached (added the updated flag)
    '/groups?updated=' + Date.now(),
    'get',
    undefined
  );
}

export function updateNextSessionTime() {
  return createAction(types.UPDATE_NEXT_SESSION_TIME);
}

// Reducer
const initialState: GroupsStore = {
  myGroups: [],
  myGroupsLastUpdated: 0,
};

const handlers = {
  [types.GET_PERSONAL_SUCCESS]: (state, { data }) => ({
    ...state,
    myGroups: [...data],
    myGroupsLastUpdated: Date.now(),
  }),
  [types.UPDATE_NEXT_SESSION_TIME]: (state) => ({
    ...state,
    myGroups: state.myGroups.map((g) => {
      const _g = { ...g };
      [_g.nextStart, _g.nextEnd] = nextSession(_g.schedule);
      return _g;
    }),
  }),
};

export default createReducer(initialState, handlers);
