import React, { useState } from 'react';
import { FirstMeetingQuestionnaireSingleQuestionAnswer, SecondQuestionConfiguration } from '../../types';
import { makeStyles } from '@material-ui/core/styles';
import { OLD_COLORS } from '../../../../styles/appConsts';
import { Label1, Label2 } from '../../../../components/Text';
import { Box, TextField } from '@material-ui/core';
import { CtaButton } from '../../../../components/buttons';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '50px',
    height: '90%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '50px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    color: OLD_COLORS.BLACK,
    textAlign: 'center',
  },
  textField: {
    width: '350px',
    borderColor: OLD_COLORS.BLACK,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  button: {
    width: '220px',
    background: OLD_COLORS.VIBRANT_BLUE_2,
    '&:hover': {
      background: OLD_COLORS.VIBRANT_BLUE_2,
    },
  },
  buttonLabel: {
    color: OLD_COLORS.WHITE,
  },
}));

interface SecondQuestionProps {
  configuration: SecondQuestionConfiguration;
  addAnswer: (answer: FirstMeetingQuestionnaireSingleQuestionAnswer) => void;
  moveToNextQuestion: () => void;
}

export const SecondQuestion = ({
  configuration: { type, title, placeholder, buttonLabel },
  addAnswer,
  moveToNextQuestion,
}: SecondQuestionProps) => {
  const classes = useStyles();

  const [answer, setAnswer] = useState('');

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.content}>
        <img src={`${ASSETS_BASE_DIR}/creativeIcons/hands_pink.svg`} alt='handshake icon' />
        <Label2 className={classes.title}>{title}</Label2>
        <TextField
          variant='outlined'
          className={classes.textField}
          placeholder={placeholder}
          multiline
          maxRows={5}
          minRows={5}
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
        />
      </Box>
      <CtaButton
        className={classes.button}
        disabled={!answer}
        onClick={() => {
          addAnswer({
            type,
            value: answer,
          });
          moveToNextQuestion();
        }}
      >
        <Label1 className={classes.buttonLabel}>{buttonLabel}</Label1>
      </CtaButton>
    </Box>
  );
};
