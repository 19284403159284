import { createAction, createReducer, createApiAction } from '../../utils';
import { HubspotSourceType, CancellationReason } from '@7chairs/types';

// Types
export const types = {
  GET_PAYMENT_DETAILS: 'payment/GET_PAYMENT_DETAILS',
  PAYMENT_DETAILS_SUCCESS: 'payment/PAYMENT_DETAILS_SUCCESS',
  PAYMENT_DETAILS_ERROR: 'payment/PAYMENT_DETAILS_ERROR',
  GET_CLIENT_SECRET: 'payment/GET_CLIENT_SECRET',
  CLIENT_SECRET_RECEIVE: 'payment/CLIENT_SECRET_RECEIVE',
  CLIENT_SECRET_ERROR: 'payment/CLIENT_SECRET_ERROR',
  UPDATE_PAYMENT_METHOD: 'payment/UPDATE_PAYMENT_METHOD',
  PAYMENT_METHOD_SUCCESS: 'payment/PAYMENT_METHOD_SUCCESS',
  PAYMENT_METHOD_ERROR: 'payment/PAYMENT_METHOD_ERROR',
  CANCEL_SUBSCRIPTION_REQUEST: 'payment/CANCEL_SUBSCRIPTION_REQUEST',
  CANCEL_SUBSCRIPTION_REQUEST_SUCCESS: 'payment/CANCEL_SUBSCRIPTION_REQUEST_SUCCESS',
  CANCEL_SUBSCRIPTION_REQUEST_ERROR: 'payment/CANCEL_SUBSCRIPTION_REQUEST_ERROR',
  CANCEL_SUBSCRIPTION_CONTACT_USER_REQUEST: 'payment/CANCEL_SUBSCRIPTION_CONTACT_USER_REQUEST',
  CANCEL_SUBSCRIPTION_CONTACT_USER_REQUEST_SUCCESS: 'payment/CANCEL_SUBSCRIPTION_CONTACT_USER_REQUEST_SUCCESS',
  CANCEL_SUBSCRIPTION_CONTACT_USER_REQUEST_ERROR: 'payment/CANCEL_SUBSCRIPTION_CONTACT_USER_REQUEST_ERROR',
  CAN_CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE: 'payment/CAN_CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE',
  CAN_CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE_SUCCESS: 'payment/CAN_CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE_SUCCESS',
  CAN_CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE_ERROR: 'payment/CAN_CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE_ERROR',
  CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE: 'payment/CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE',
  CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE_SUCCESS: 'payment/CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE_SUCCESS',
  CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE_ERROR: 'payment/CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE_ERROR',
  REENABLE_SUBSCRIPTION: 'payment/REENABLE_SUBSCRIPTION',
  REENABLE_SUBSCRIPTION_SUCCESS: 'payment/REENABLE_SUBSCRIPTION_SUCCESS',
  REENABLE_SUBSCRIPTION_ERROR: 'payment/REENABLE_SUBSCRIPTION_ERROR',
  UNDISPLAY_SUCCESS_POPUP: 'payment/UNDISPLAY_SUCCESS_POPUP',
  UNDISPLAY_SUCCESS_EDIT: 'payment/UNDISPLAY_SUCCESS_EDIT',
};

// Actions
export function getPaymentDetails() {
  return createApiAction(
    [types.GET_PAYMENT_DETAILS, types.PAYMENT_DETAILS_SUCCESS, types.PAYMENT_DETAILS_ERROR],
    '/payment',
    'get',
    undefined
  );
}

export function getClientSecret() {
  return createApiAction(
    [types.GET_CLIENT_SECRET, types.CLIENT_SECRET_RECEIVE, types.CLIENT_SECRET_ERROR],
    '/payment/stripe/secret',
    'get',
    undefined
  );
}

export function updatePaymentMethod(data) {
  return createApiAction(
    [types.UPDATE_PAYMENT_METHOD, types.PAYMENT_METHOD_SUCCESS, types.PAYMENT_METHOD_ERROR],
    '/payment/stripe/edit',
    'post',
    data
  );
}

export function cancelSubscriptionRequest(reasons) {
  return createApiAction(
    [
      types.CANCEL_SUBSCRIPTION_REQUEST,
      types.CANCEL_SUBSCRIPTION_REQUEST_SUCCESS,
      types.CANCEL_SUBSCRIPTION_REQUEST_ERROR,
    ],
    '/payment/cancel',
    'post',
    { reasons }
  );
}
export function cancelSubscriptionContactUserRequest(option: CancellationReason, comment?: string) {
  const params: { option; sourceType: HubspotSourceType; comment: string | undefined } = {
    option,
    sourceType: 'RETENTION_FLOW',
    comment,
  };

  return createApiAction(
    [
      types.CANCEL_SUBSCRIPTION_CONTACT_USER_REQUEST,
      types.CANCEL_SUBSCRIPTION_CONTACT_USER_REQUEST_SUCCESS,
      types.CANCEL_SUBSCRIPTION_CONTACT_USER_REQUEST_ERROR,
    ],
    '/payment/cancel/contact_user',
    'post',
    params
  );
}

export function canCancelSubscriptionNextMonthFree() {
  return createApiAction(
    [
      types.CAN_CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE,
      types.CAN_CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE_SUCCESS,
      types.CAN_CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE_ERROR,
    ],
    '/payment/cancel/can_next_month_free/stripe',
    'get',
    undefined
  );
}

export function cancelSubscriptionNextMonthFree() {
  return createApiAction(
    [
      types.CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE,
      types.CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE_SUCCESS,
      types.CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE_ERROR,
    ],
    '/payment/cancel/next_month_free/stripe',
    'post',
    {}
  );
}

export function reenableSubscription() {
  return createApiAction(
    [types.REENABLE_SUBSCRIPTION, types.REENABLE_SUBSCRIPTION_SUCCESS, types.REENABLE_SUBSCRIPTION_ERROR],
    '/payment/cancel',
    'delete',
    {}
  );
}

export const closeSuccessPopup = () => createAction(types.UNDISPLAY_SUCCESS_POPUP);
export const closeSuccessEdit = () => createAction(types.UNDISPLAY_SUCCESS_EDIT);

// Reducer
const initialState = {
  plans: {},
  currentPlan: null,
  clientSecret: null,
  cardDetails: null,
  billing: null,
  displaySuccessPopup: false,
  displaySuccessEdit: false,
  nextMonthFreeAvailable: true,
  discount: {},
  subscriptionId: null,
};

const handlers = {
  [types.CLIENT_SECRET_RECEIVE]: (state, { data }) => ({
    ...state,
    ...data,
  }),
  [types.PAYMENT_METHOD_SUCCESS]: (state, action) => {
    const {
      data,
      config: { url },
    } = action;
    const isEdit = url === '/payment/stripe/edit';
    return {
      ...state,
      cardDetails: data.cardDetails,
      billing: data.billing,
      subscriptionId: data.subscriptionId,
      displaySuccessPopup: !isEdit,
      displaySuccessEdit: isEdit,
    };
  },
  [types.PAYMENT_DETAILS_SUCCESS]: (state, { data }) => ({
    ...state,
    cardDetails: data.cardDetails,
    billing: data.billing,
  }),
  [types.UNDISPLAY_SUCCESS_POPUP]: (state) => ({
    ...state,
    displaySuccessPopup: false,
  }),
  [types.UNDISPLAY_SUCCESS_EDIT]: (state) => ({
    ...state,
    displaySuccessEdit: false,
  }),
  [types.CAN_CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE_SUCCESS]: (state, { data }) => ({
    ...state,
    nextMonthFreeAvailable: data.nextMonthFreeAvailable || false,
  }),
  [types.CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE_SUCCESS]: (state) => ({
    ...state,
    nextMonthFreeAvailable: false,
  }),
};

export default createReducer(initialState, handlers);
