import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextAnswer from './TextAnswerVertical';
import { Label1 } from '../../Text';
import { OLD_SIZES, OLD_COLORS } from '../../../styles/appConsts';

const styles = () => ({
  textAnswersDiv: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: OLD_SIZES.m,
    marginBottom: OLD_SIZES.s,
    overflowY: 'auto',
  },
  textAnswerWrapper: {},
  label: {
    marginTop: OLD_SIZES.s,
    color: OLD_COLORS.BLACK,
    fontSize: 16,
    fontWeight: '500',
    textAlign: 'center',
  },
});

export default withStyles(styles)((props) => {
  const { classes, answers, totalAnswers, watchAnswersMode, onEditClick, toggleLike } = props;

  if (!watchAnswersMode) {
    return null;
  }

  return (
    <div className={classes.textAnswersDiv}>
      <Label1 className={classes.label}>Let’s see what other member thought...</Label1>
      {answers.map((ans, idx) => {
        return (
          <div key={`ans-${idx}`}>
            <TextAnswer
              name={ans.meta.name}
              picture={ans.meta.picture}
              answer={ans.answer.value}
              me={ans.myself}
              liked={ans.reactions?.type === 'like'}
              onEdit={() => onEditClick(ans)}
              toggleLike={() => toggleLike(ans)}
            />
          </div>
        );
      })}
    </div>
  );
});
