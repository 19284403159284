import React from 'react';
import { connect } from 'react-redux';
import NotificationsSettings from './NotificationsSettings';
import { getSettings, updateSettings } from '../../store/ducks/notificationsSettings';

function NotificationsSettingsContainer(props) {
  return (<NotificationsSettings {...props} />);
}

function mapState({ notificationsSettings }) {
  return {
    subscriptionGroups: notificationsSettings.groups,
    additionalSubscriptions: notificationsSettings.patch,
  };
}

export default connect(
  mapState,
  { getSettings, updateSettings }
)(NotificationsSettingsContainer);
