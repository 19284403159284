import { createApiAction, createReducer } from '../../utils';

import { AccountsTag } from '@7chairs/types';

export interface ProfileStore {
  passions: AccountsTag[];
  descriptions: AccountsTag[];
}

// Types
export const types = {
  GET_ACCOUNTS_TAGS: 'profile/GET_ACCOUNTS_TAGS',
  GET_ACCOUNTS_TAGS_SUCCESS: 'profile/GET_ACCOUNTS_TAGS_SUCCESS',
  GET_ACCOUNTS_TAGS_ERROR: 'profile/GET_ACCOUNTS_TAGS_ERROR',
};

// Actions
export function getAccountsTags() {
  return createApiAction(
    [types.GET_ACCOUNTS_TAGS, types.GET_ACCOUNTS_TAGS_SUCCESS, types.GET_ACCOUNTS_TAGS_ERROR],
    '/generalContent/accounts-tags?types=passion,description',
    'get',
    null
  );
}

// Reducer
const initialState: ProfileStore = {
  passions: [],
  descriptions: [],
};

const handlers = {
  [types.GET_ACCOUNTS_TAGS_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      passions: data.filter((obj) => obj.type === 'passion'),
      descriptions: data.filter((obj) => obj.type === 'description'),
    };
  },
};

export default createReducer(initialState, handlers);
