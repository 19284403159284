export const toggleItemInArray = <T>(item: T, array: T[]): T[] => {
  const arrayCopy = [...array];
  if (arrayCopy.includes(item)) {
    const itemIndex = arrayCopy.indexOf(item);
    arrayCopy.splice(itemIndex, 1);
  } else {
    arrayCopy.push(item);
  }
  return arrayCopy;
}
