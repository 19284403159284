import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Text, Icon, Button } from '@7chairs/frontend';
import { updateNextSessionTime } from '../../../store/ducks/groupSpace';
import { useDispatch, useSelector } from 'react-redux';
import { isSessionTime } from '../../../utils/sessionTime';
import moment from 'moment-timezone';
import { navigateToPaymentPage } from '../../../utils/payment';
import { NavigationContext } from '../../navigator';
import { AddToCalendar } from './AddToCalendar';
import { sendGTMEvent, trackEvent } from '../../../utils/analyticsEvent';
import { useStyles } from './sharedStyles';
import clsx from 'clsx';
import { NoMeetingPopup } from './NoMeetingPopup';
import { AccountRole } from '@7chairs/types';

interface MeetingCardProps {
  groupInstanceId: string;
}

export const MeetingCard = ({ groupInstanceId }: MeetingCardProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const navCtx = useContext(NavigationContext);

  // @ts-ignore
  const account = useSelector((state) => state.account);
  const { isPayment, role } = account;
  // @ts-ignore
  const openGroupSessionGap = useSelector((state) => state.config.openGroupSessionGap);
  // @ts-ignore
  const groupTitle = useSelector((state) => state.groupSpace.groupInfo.title);
  // @ts-ignore
  const groupSchedule = useSelector((state) => state.groupSpace.groupInfo.schedule);
  // @ts-ignore
  const groupVideo = useSelector((state) => state.groupSpace.groupInfo.video);
  // @ts-ignore
  const nextSession = useSelector((state) => state.groupSpace.nextSession);

  const [isSessionAvailable, setSessionAvailable] = useState(false);
  const [showNoMeetingPopup, setShowNoMeetingPopup] = useState(false);

  const missingPayment = useMemo(() => {
    return role === AccountRole.Member && !isPayment;
  }, [isPayment, role]);

  const meetingTimeAsText = useMemo(() => {
    if (nextSession && groupSchedule) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return `${moment(nextSession).tz(timezone).format('MMM D ddd • h:mma')} - ${moment(nextSession)
        .add(groupSchedule[0].duration, 'm')
        .tz(timezone)
        .format('h:mma')}`;
    }
    return '';
  }, [nextSession, groupSchedule]);

  useEffect(() => {
    const updateNextSessionTimeIntervalId = setInterval(() => {
      dispatch(updateNextSessionTime());
    }, 15000);
    return () => {
      clearInterval(updateNextSessionTimeIntervalId);
    };
  }, []);

  useEffect(() => {
    if (nextSession && openGroupSessionGap) {
      setSessionAvailable(isSessionTime(nextSession, openGroupSessionGap));
    } else {
      setSessionAvailable(false);
    }
  }, [nextSession, openGroupSessionGap]);

  const onJoinMeetingClick = () => {
    const isGroupVideoActive = groupVideo?.active;
    const eventName = isGroupVideoActive ? 'click enter video' : 'click enter chat';
    trackEvent(eventName, {
      groupId: groupInstanceId,
      groupTitle,
      sessionActive: isSessionAvailable,
    });
    sendGTMEvent({
      GA_event_category: 'platform',
      GA_event_action: 'join meeting clicked',
      GA_event_label: 'join meeting clicked',
      GA_event_detail: 'NA',
    });

    if (isGroupVideoActive) {
      trackEvent('open video session', { groupInstanceId, groupTitle });
      window.open(groupVideo.url, '_blank', 'noreferrer');
      return;
    }

    navCtx.pushScreen(`/conversations/${groupInstanceId}`);
  };

  const openNoMeetingPopup = () => {
    sendGTMEvent({
      GA_event_category: 'platform',
      GA_event_action: 'join meeting clicked',
      GA_event_label: 'join meeting clicked',
      GA_event_detail: 'NA',
    });
    setShowNoMeetingPopup(true);
  };

  const closeNoMeetingPopup = () => {
    setShowNoMeetingPopup(false);
  };

  return (
    <div className={classes.root}>
      <NoMeetingPopup onClose={closeNoMeetingPopup} open={showNoMeetingPopup} />
      <div className={classes.titleWrapper}>
        <div className={classes.title}>
          <Text variant={'titleH3'} color={'BLACK_100'}>
            {!missingPayment ? 'Your next group meeting' : 'Upcoming group meeting'}
          </Text>
        </div>
        <Icon.Creative.Video size={64} />
      </div>
      <div className={classes.body}>
        <Text variant={'body'} color={'BLACK_80'}>
          {meetingTimeAsText}
        </Text>
        <Text variant={'small'} color={'BLACK_80'}>
          {!missingPayment
            ? `The room will open ${openGroupSessionGap / 1000 / 60} minutes before the meeting starts`
            : 'Start your free trial now to meet your group and find support'}
        </Text>
      </div>
      <div className={classes.buttonWrapper}>
        {missingPayment ? (
          <Button
            className={classes.button}
            iconLeft={Icon.Outline.Lock}
            onClick={() => {
              trackEvent('click join meeting without payment', { groupInstanceId });
              sendGTMEvent({
                GA_event_category: 'platform',
                GA_event_action: 'join meeting clicked',
                GA_event_label: 'join meeting clicked',
                GA_event_detail: 'NA',
              });
              navigateToPaymentPage();
            }}
            size='big'
            variant='primary'
          >
            Start free trial
          </Button>
        ) : (
          <>
            <Button
              className={clsx(classes.button, !isSessionAvailable ? classes.buttonSessionUnavailable : '')}
              iconRight={Icon.Outline.ArrowRight}
              onClick={isSessionAvailable ? onJoinMeetingClick : openNoMeetingPopup}
              size='big'
              variant='primary'
            >
              Join meeting
            </Button>
            <AddToCalendar groupInstanceId={groupInstanceId} />
          </>
        )}
      </div>
    </div>
  );
};
