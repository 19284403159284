import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getColor } from '@7chairs/frontend';
import { trackEvent } from '../../utils/analyticsEvent';
import { NavigationContext } from '../../components/navigator';
import { CtaButton } from '../../components/buttons';
import { Header, Paragraph } from '../../components/Text';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '32px auto',
    gap: '32px',
    [theme.breakpoints.down('sm')]: {
      gap: '16px',
      margin: '32px 16px',
    },
  },
  section: {
    textAlign: 'center',
  },
  ctaBtn: {
    marginTop: '16px',
    cursor: 'pointer',
    backgroundColor: getColor('RED_100'),
    '&:disabled': {
      backgroundColor: getColor('BLACK_40'),
      color: getColor('WHITE_100'),
      cursor: 'default',
    },
  },
}));

function CancelSubscription({ cancelSubscriptionRequest }) {
  const navCtx = useContext(NavigationContext);
  const classes = useStyles();

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

  return (
    <div className={classes.wrapper}>
      <Header className={classes.section}>We're Sad to See You Go 💔</Header>
      <Paragraph className={classes.section}>
        We know that sometimes plans change, and that's okay. If you change your mind, we're here whenever you're ready
        to come back to your healing journey.
      </Paragraph>
      <Paragraph className={classes.section}>You'll always have a place with us.</Paragraph>
      <CtaButton
        className={classes.ctaBtn}
        disabled={isSubmitButtonDisabled}
        onClick={() => {
          trackEvent('click cancel subscription');
          setIsSubmitButtonDisabled(true);
          cancelSubscriptionRequest()
            .then(() => {
              navCtx.replaceScreen('/payment/cancel-confirmation');
            })
            .catch((err) => {
              console.log('cancelSubscriptionRequest', err);
              setIsSubmitButtonDisabled(false);
            });
        }}
      >
        Cancel my subscription
      </CtaButton>
    </div>
  );
}

export default CancelSubscription;
