import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  sectionNumber: {
    height: isCurrent => isCurrent ? 44 : 40,
  },
}));

const CircledNumber = ({ isCurrent, number }) => {
  const classes = useStyles(isCurrent)
  return (
    <div className={classes.sectionNumber}>
      <svg width={isCurrent ? 40 : 36} height={isCurrent ? 40 : 36} viewBox={isCurrent ? "0 0 40 40" : "0 0 36 36"}  fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id={'myMask' + number}>
          <rect x="0" y="0" width={isCurrent ? 40 : 36} height={isCurrent ? 40 : 36} fill="white"/>
          <text fontFamily="Noto Sans" fontWeight="500" strokeWidth="1px" stroke="black" fontSize="14px" x="50%"
                y={isCurrent ? 25 : 23} textAnchor="middle" fill="black">{number}</text>
        </mask>
        <circle cx={isCurrent ? 20 : 18} cy={isCurrent ? 20 : 18} r={isCurrent ? 20 : 18} fill="white" mask={`url(#myMask${number})`}/>
      </svg>
    </div>
  );
};

export default CircledNumber;
