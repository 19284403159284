import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Badge, Box } from '@material-ui/core';
import { NavigationContext } from '../../../../components/navigator';
import { CTA } from '../../../../components/Text';
import { sendGTMEvent, trackEvent } from '../../../../utils/analyticsEvent';
import { OLD_SIZES } from '../../../../styles/appConsts';
import { MOBILE_WIDTH, DESKTOP_WIDTH } from '../../utils';
import { navigateToPaymentPage } from '../../../../utils/payment';

interface ItemPaymentProps {
  name: string;
  isPayment: boolean;
  isPurchase: boolean;
  voicesPurchaseProvider: string;
}

const useStyles = makeStyles((theme) => ({
  item: {
    marginBottom: OLD_SIZES.xl,
    display: 'inline-block',
  },
  smAvatar: {
    height: 48,
    width: 48,
  },
  xsAvatar: {
    height: 12,
    width: 12,
  },
  badgeAvatarTopRight: {
    top: 3,
    right: 27,
  },
  box: {
    borderRadius: 8,
    paddingTop: OLD_SIZES.m,
    paddingRight: OLD_SIZES.m,
    paddingBottom: OLD_SIZES.m,
    paddingLeft: OLD_SIZES.xl,
    textAlign: 'left',
    width: DESKTOP_WIDTH,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: OLD_SIZES.m,
      width: MOBILE_WIDTH,
    },
  },
  text: {
    paddingLeft: OLD_SIZES.s,
  },
}));

function ItemPayment({ name, isPayment, isPurchase, voicesPurchaseProvider }: ItemPaymentProps) {
  const navCtx = React.useContext(NavigationContext);
  const classes = useStyles();

  if (!isPayment && !isPurchase) {
    return (
      <Grid
        item
        className={classes.item}
        onClick={() => {
          trackEvent('click open payment add');
          sendGTMEvent({
            GA_event_category: 'personal profile',
            GA_event_action: 'add payment details clicked',
            GA_event_label: 'add payment details clicked',
            GA_event_detail: 'NA',
          });
          navigateToPaymentPage();
        }}
      >
        <Badge
          overlap="rectangle"
          classes={{ badge: classes.badgeAvatarTopRight }}
          badgeContent={<img alt={name} src={`${ASSETS_BASE_DIR}/info.svg`} className={classes.smAvatar} />}
        >
          <Box boxShadow={'0px 4px 20px rgba(0, 0, 0, 0.08)'} className={classes.box} style={{ height: '80px' }}>
            <Grid container alignItems="center">
              <img alt={name} src={`${ASSETS_BASE_DIR}/payment_icon.svg`} className={classes.smAvatar} />
              <CTA fontWeight="500" className={classes.text}>
                Add payment details
              </CTA>
            </Grid>
          </Box>
        </Badge>
      </Grid>
    );
  }

  // legacy payments
  if (isPayment) {
    return (
      <Grid
        item
        style={{ order: 10 }}
        className={classes.item}
        onClick={() => {
          trackEvent('click open payment details');
          sendGTMEvent({
            GA_event_category: 'personal profile',
            GA_event_action: 'plan and payments clicked',
            GA_event_label: 'plan and payments clicked',
            GA_event_detail: 'NA',
          });
          navCtx.pushScreen('/payment');
        }}
      >
        <Box boxShadow={'0px 4px 20px rgba(0, 0, 0, 0.08)'} className={classes.box}>
          <Grid container alignItems="center" justifyContent="flex-start">
            <img alt={name} src={`${ASSETS_BASE_DIR}/payment_icon.svg`} className={classes.smAvatar} />
            <CTA fontWeight="500" className={classes.text}>
              Plan and Payments
            </CTA>
            <img
              alt={name}
              src={`${ASSETS_BASE_DIR}/rectangle_arrow.svg`}
              className={classes.xsAvatar}
              style={{ marginLeft: 'auto', marginRight: '15px' }}
            />
          </Grid>
        </Box>
      </Grid>
    );
  }

  // new purchases
  if (isPurchase && voicesPurchaseProvider === 'stripe') {
    return (
      <Grid
        item
        style={{ order: 10 }}
        className={classes.item}
        onClick={() => {
          trackEvent('click open payment details');
          sendGTMEvent({
            GA_event_category: 'personal profile',
            GA_event_action: 'plan and payments clicked',
            GA_event_label: 'plan and payments clicked',
            GA_event_detail: 'NA',
          });
          navCtx.pushScreen('/purchase');
        }}
      >
        <Box boxShadow={'0px 4px 20px rgba(0, 0, 0, 0.08)'} className={classes.box}>
          <Grid container alignItems="center" justifyContent="flex-start">
            <img alt={name} src={`${ASSETS_BASE_DIR}/payment_icon.svg`} className={classes.smAvatar} />
            <CTA fontWeight="500" className={classes.text}>
              Plan and Payments (purchase)
            </CTA>
            <img
              alt={name}
              src={`${ASSETS_BASE_DIR}/rectangle_arrow.svg`}
              className={classes.xsAvatar}
              style={{ marginLeft: 'auto', marginRight: '15px' }}
            />
          </Grid>
        </Box>
      </Grid>
    );
  }

  return null;
}

export default ItemPayment;
