import { ClickAwayListener } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, getColor, Icon, IconButton, Text } from '@7chairs/frontend';
import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { baseStyles, HeaderProps } from './shared';
import { NavigationContext } from '../../navigator';
import { updateStorageAndReplaceScreen } from '../../../screens/groupSpace/GroupSpaceContainer';
import { trackEvent } from '../../../utils/analyticsEvent';
import { NO_AVATAR } from '../../../utils/consts';

const FIRST_ROW_ELEMENT_SIZE = 32;

const useStyles = makeStyles((theme) => ({
  ...baseStyles(theme),
  avatar: {
    cursor: 'pointer',
    marginLeft: '16px',
  },
  container: {
    backgroundColor: getColor('DARK_PURPLE_100'),
    height: '176px',
    padding: '20px 0 24px',

    [theme.breakpoints.down('xs')]: {
      height: '212px',
    },
  },
  firstRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  item: {
    flexDirection: 'column',
    width: '100%',
  },
  secondRow: {
    alignSelf: 'center',
    display: 'flex',
    width: '560px',

    [theme.breakpoints.down('xs')]: {
      width: '100vw',
    },
  },
  select: {
    backgroundColor: getColor('WHITE_100'),
    borderRadius: '12px',
    boxShadow: '0px 1px 4px 3px rgba(0, 0, 0, 0.06)',
    marginTop: '12px',
    maxHeight: '50vh',
    overflowY: 'auto',
    padding: '8px',
    position: 'absolute',
    right: `calc(-1 * (16px + ${FIRST_ROW_ELEMENT_SIZE}px))`, // 16px is gap between icons in menu
    width: '350px',
    zIndex: 10,

    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)', // 32px is double 16px margin from left and right
    },
  },
  selectItem: {
    backgroundColor: getColor('WHITE_100'),
    border: 'none',
    borderRadius: '12px',
    cursor: 'pointer',
    padding: '14px 16px',
    textAlign: 'start',
    width: '100%',

    '&:disabled': {
      backgroundColor: getColor('VELVET_30'),
      color: 'inherit',
      cursor: 'not-allowed',
    },
  },
  selectText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  selectWrapper: {
    position: 'relative',
  },
  titleContainer: {
    textAlign: 'start',
    marginTop: '28px',
    width: '460px',

    [theme.breakpoints.down('xs')]: {
      marginLeft: '24px',
      width: '327px',
    },
  },
}));

type UserData = {
  groups: Array<{ groupInstanceId: string; title: string }>;
  picture: string;
};

interface MGSProps extends HeaderProps {
  className?: string;
  userData: UserData;
}

export const MGS = ({ className, text, userData }: MGSProps) => {
  const classes = useStyles();

  const navCtx = useContext(NavigationContext);

  const { groupInstanceId: groupInstanceIdFromParams } = useParams();

  const [showSelect, setShowSelect] = useState(false);

  const onGroupChange = (groupInstanceId: string) => {
    updateStorageAndReplaceScreen(navCtx, groupInstanceId, groupInstanceId);
    setShowSelect(false);
  };

  return (
    <div className={clsx(classes.root, classes.container, className)}>
      <div className={clsx(classes.content, classes.item)}>
        <div className={classes.firstRow}>
          {userData.groups.length > 1 ? (
            <ClickAwayListener onClickAway={() => setShowSelect(false)}>
              <div className={classes.selectWrapper}>
                <IconButton
                  icon={<Icon.Fill.Menu />}
                  onClick={() => setShowSelect((prevState) => !prevState)}
                  stylesProps={{
                    activeStyles: {
                      backgroundColor: 'WHITE_100',
                      color: 'PURPLE_100',
                    },
                    backgroundColor: showSelect ? 'WHITE_100' : 'PURPLE_100',
                    color: showSelect ? 'PURPLE_100' : 'WHITE_100',
                    size: FIRST_ROW_ELEMENT_SIZE,
                    withShadow: false,
                  }}
                />
                {showSelect ? (
                  <div className={classes.select}>
                    {userData.groups.map(({ groupInstanceId, title }) => (
                      <button
                        className={classes.selectItem}
                        disabled={groupInstanceId === groupInstanceIdFromParams}
                        key={groupInstanceId}
                        onClick={() => onGroupChange(groupInstanceId)}
                        type='button'
                      >
                        <Text className={classes.selectText} variant={'small'}>
                          {title}
                        </Text>
                      </button>
                    ))}
                  </div>
                ) : null}
              </div>
            </ClickAwayListener>
          ) : null}
          <Avatar
            borderColor={'WHITE_100'}
            className={classes.avatar}
            onClick={() => {
              trackEvent('click header avatar');
              navCtx.pushScreen('/profile');
            }}
            size={FIRST_ROW_ELEMENT_SIZE}
            src={userData.picture || NO_AVATAR}
          />
        </div>
        <div className={classes.secondRow}>
          <div className={classes.titleContainer}>
            <Text variant={'titleH3'} color={'WHITE_100'}>
              {text}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
