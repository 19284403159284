import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar, SnackbarContent, Grow, IconButton, Box } from '@material-ui/core';
import {
  Close,
  ErrorOutlineOutlined,
  InfoOutlined,
  ReportProblemOutlined,
  CheckCircleOutlineOutlined,
} from '@material-ui/icons';
import { SubHeader, Paragraph } from './Text';
import { NoticeSeverity, Notice as NoticeType, closeNotice } from '../store/ducks/notice';
import { OLD_COLORS, OLD_SIZES } from '../styles/appConsts';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > .MuiSnackbar-root': {
      minWidth: '40%',
      [theme.breakpoints.down('sm')]: {
        minWidth: '90%',
      },
    },
  },
  snackbarRoot: {
    '& >  .MuiSnackbarContent-root': {
      flex: 1,
      padding: 2,
    },
  },
  contentRoot: {
    '& > .MuiSnackbarContent-message': {
      width: '100%',
      padding: 0,
    },
  },
  header: {
    marginBottom: OLD_SIZES.xs,
    padding: `0px ${OLD_SIZES.s}px`,
  },
  boxIcon: {
    alignSelf: 'flex-start',
    paddingTop: OLD_SIZES.xs,
  },
  closeIconWrapper: {
    padding: 0,
  },
  closeIcon: {
    color: 'white',
  },
  titleWrapper: {
    padding: `0px ${OLD_SIZES.s}px`,
    flexGrow: 1,
  },
  title: {
    color: 'white',
    textAlign: 'center',
  },
  innerContent: {
    padding: OLD_SIZES.xs,
    backgroundColor: OLD_COLORS.GRAY_400,
  },
  subtitle: {
    textAlign: 'center',
    marginTop: OLD_SIZES.xs,
  },
  text: {
    textAlign: 'left',
    marginTop: OLD_SIZES.xs,
  },
}));

interface NoticeProps extends NoticeType {
  closeNotice: () => void;
}

function Notice({ isOpen, title, subtitle, text, severity, closeNotice }: NoticeProps) {
  const classes = useStyles();

  const severityMap = {
    [NoticeSeverity.Success]: { color: OLD_COLORS.SUCCESS_GREEN, icon: <CheckCircleOutlineOutlined /> },
    [NoticeSeverity.Info]: { color: OLD_COLORS.BRAND_PURPLE_2, icon: <InfoOutlined /> },
    [NoticeSeverity.Warning]: { color: OLD_COLORS.SALMON, icon: <ReportProblemOutlined /> },
    [NoticeSeverity.Error]: { color: OLD_COLORS.RED_500, icon: <ErrorOutlineOutlined /> },
  };

  const { color, icon } = severityMap[severity];

  const hasInnerText = !!subtitle || text.length > 0;

  return (
    <div className={classes.root}>
      <Snackbar className={classes.snackbarRoot} open={isOpen} TransitionComponent={Grow} onClose={closeNotice}>
        <SnackbarContent
          className={classes.contentRoot}
          style={{ backgroundColor: color }}
          message={
            <div>
              <Box className={classes.header} display='flex' alignItems='flex-start'>
                <Box className={classes.boxIcon} style={{ display: 'flex' }}>
                  {icon}
                </Box>
                <Box className={classes.titleWrapper}>
                  {title && <SubHeader className={classes.title}>{title}</SubHeader>}
                </Box>
                <Box className={classes.boxIcon}>
                  <IconButton className={classes.closeIconWrapper} onClick={closeNotice}>
                    <Close className={classes.closeIcon} fontSize='small' />
                  </IconButton>
                </Box>
              </Box>
              {hasInnerText && (
                <Box className={classes.innerContent}>
                  {subtitle && <Paragraph className={classes.subtitle}>{subtitle}</Paragraph>}
                  {text.map((t, i) => (
                    <Paragraph key={i} className={classes.text}>
                      {t}
                    </Paragraph>
                  ))}
                </Box>
              )}
            </div>
          }
        />
      </Snackbar>
    </div>
  );
}

const mapState = ({ notice }) => ({ ...notice });

export default connect(mapState, { closeNotice })(Notice);
