import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { AccountStore } from '../../store/ducks/account';
import { SubHeader } from '../../components/Text';
import ItemPayment from './profileItems/components/ItemPayment';
import ItemPersonalDetails from './profileItems/components/ItemPersonalDetails';
import ItemAvatar from './profileItems/components/ItemAvatar';
import ItemNotifications from './profileItems/components/ItemNotifications';
import { OLD_SIZES } from '../../styles/appConsts';
import { AccountRole } from '@7chairs/types';

interface ProfileProps {
  baseUrl: string;
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    flexWrap: 'nowrap',
    paddingLeft: `${OLD_SIZES.m}px`,
    paddingRight: `${OLD_SIZES.m}px`,
    overflowY: 'auto',
    paddingTop: '5%',
    textAlign: 'center',
  },
  item: {
    marginBottom: `${OLD_SIZES.xl}px`,
    display: 'inline-block',
  },
}));

const Profile = ({ baseUrl }: ProfileProps) => {
  const classes = useStyles();
  // TODO: enrich DefaultRootState with account: AccountStore and groups
  // @ts-ignore
  const account: AccountStore = useSelector((state) => state.account);
  const { name, picture, phoneNumber, email, role, isPayment, isVoicesPurchase, voicesPurchaseProvider } = account;

  const isMobileWebview = useMemo(() => window.navigator.userAgent.indexOf('circles-mobile-app') > -1, []);
  const isMember = role === AccountRole.Member;

  return (
    <Grid className={classes.root} container direction="column" alignItems="center">
      <ItemAvatar baseUrl={baseUrl} name={name} picture={picture} />

      {/* Hello */}
      <Grid container item justifyContent="center" className={classes.item} style={{ marginBottom: '48px' }}>
        <SubHeader>{`Hello ${name}!`}</SubHeader>
      </Grid>

      {/* Payment */}
      {!isMobileWebview && isMember && (
        <ItemPayment
          name={name}
          isPayment={isPayment}
          isPurchase={isVoicesPurchase}
          voicesPurchaseProvider={voicesPurchaseProvider}
        />
      )}

      {/* personal details */}
      <ItemPersonalDetails email={email} name={name} phoneNumber={phoneNumber} role={role} />

      {/* notification settings* */}
      <ItemNotifications />
    </Grid>
  );
};

export default Profile;
