import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { SubHeader } from '../Text';

const styles = (theme) => ({
  title: {
    flexGrow: 1,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
});

export default withStyles(styles)((props) => {
  const { classes, text } = props;
  return <SubHeader className={classes.title}>{text}</SubHeader>;
});
