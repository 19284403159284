import axios from 'axios';
import { dateToUTCOffset } from './utils/datetime';

export const apiClient = axios.create({
  baseURL: API_BASE_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'UTC-Offset': dateToUTCOffset(),
  },
});