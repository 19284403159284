import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { OLD_COLORS } from '../../../../styles/appConsts';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: -20,
    minHeight: 500,
    width: '100%',
  },
  iframe: {
    display: (props) => `${props.loading ? 'none' : 'block'}`,
    height: '100%',
    width: '100%',
    border: 'none',
    minHeight: 600,
  },
  spinnerWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    color: OLD_COLORS.VIBRANT_BLUE_2,
  },
}));

const IFrame = ({ url, queryParams = {} }) => {
  const [loading, setLoading] = useState(true);
  const classes = useStyles({ loading });
  const urlParams =
    Object.keys(queryParams).length > 0
      ? `?${Object.keys(queryParams)
          .map((k) => `${k}=${queryParams[k]}`)
          .join('&')}`
      : '';
  const hideSpinner = () => {
    setLoading(false);
  };
  return (
    <div className={classes.root}>
      {loading && (
        <div className={classes.spinnerWrapper}>
          <CircularProgress className={classes.spinner} />
        </div>
      )}
      <iframe className={classes.iframe} onLoad={() => hideSpinner()} src={`${url}${urlParams}`} />
    </div>
  );
};

export default IFrame;
