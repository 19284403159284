import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { OLD_SIZES } from '../../../../styles/appConsts';
import { LinkButton } from '../../../buttons';

const styles = () => ({
  linkButton: {
    padding: OLD_SIZES.xs,
  },
});

export default withStyles(styles)((props) => {
  const { classes, content, onClick, style = {} } = props;
  return (
    <LinkButton className={classes.linkButton} style={style} onClick={onClick}>
      {content}
    </LinkButton>
  );
});
