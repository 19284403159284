import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { NavigationContext } from '../navigator';
import { OLD_COLORS } from '../../styles/appConsts';

const styles = () => ({
  backButton: {
    color: OLD_COLORS.BLACK,
  },
});

export default withStyles(styles)((props) => {
  const { classes } = props;
  const navCtx = React.useContext(NavigationContext);

  if (!navCtx.canGoBack()) {
    return null;
  }

  return (
    <IconButton edge='start' onClick={() => navCtx.popScreen()} className={classes.backButton}>
      <ArrowBack />
    </IconButton>
  );
});
