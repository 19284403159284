import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PhoneInput from 'material-ui-phone-number';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { OLD_COLORS, OLD_SIZES } from '../../styles/appConsts';

const styles = () => ({
  root: {
    position: 'relative',
  },
  selectDiv: {
    position: 'absolute',
    top: 20,
    left: 8,
    zIndex: 1000,
  },
  selectIcon: {
    color: OLD_COLORS.BLACK,
  },
  phoneInput: {
    width: '100%',
    maxWidth: 340,
    textAlign: 'center',
    backgroundColor: OLD_COLORS.GRAY_50,
    border: `1px solid ${OLD_COLORS.BLACK}`,
    borderRadius: '100px',
    '& .MuiInputAdornment-positionStart': {
      marginRight: 0,
    },
    '& input': {
      height: 30,
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 'calc((80% - 20ch)/2)',
      margin: OLD_SIZES.s,
      borderRadius: 0,
      borderLeft: `1px solid ${OLD_COLORS.BLACK}`,
    },
    '& .MuiPhoneNumber-flagButton': {
      width: 'inherit',
      marginLeft: OLD_SIZES.l,
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '& .MuiButton-root:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export default withStyles(styles)((props) => {
  const {
    classes,
    onChange,
    onSubmit,
    defaultCountry = 'us',
    autoFocus = true,
    borderColor = OLD_COLORS.BLACK,
    value = '',
  } = props;

  const onKeyDown = (e) => (e.key === 'Enter' ? onSubmit() : null);

  return (
    <div className={classes.root}>
      <div className={classes.selectDiv}>
        <ArrowDropDownIcon className={classes.selectIcon} fontSize='small' />
      </div>
      <PhoneInput
        value={value}
        className={classes.phoneInput}
        style={{ borderColor }}
        inputProps={{ autoFocus }}
        defaultCountry={defaultCountry}
        onKeyDown={onKeyDown}
        disableAreaCodes={true}
        countryCodeEditable={false}
        onChange={onChange}
      />
    </div>
  );
});
