import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { flattenDeep, groupBy } from 'lodash';
import clsx from 'clsx';
import { Link, Label1 } from '../../Text';
import { OLD_COLORS, OLD_SIZES } from '../../../styles/appConsts';

const styles = () => ({
  root: {
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  title: {
    fontSize: 16,
    fontWeight: '500',
    textAlign: 'center',
    color: OLD_COLORS.BLACK,
  },
  answerDiv: {
    borderRadius: 4,
    marginTop: OLD_SIZES.l,
  },
  answerContent: {
    padding: `${OLD_SIZES.xs}px ${OLD_SIZES.s}px`,
  },
  selectedAnswer: {
    borderRadius: 2,
  },
  unselectedAnswer: {
    borderRadius: 4,
  },
  answerText: {
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'center',
    color: OLD_COLORS.WHITE,
  },
  bottom: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  editCtaDiv: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  editCtaText: {
    fontSize: 12,
    color: OLD_COLORS.BLACK,
    textDecoration: 'underline',
    textAlign: 'center',
  },
});

export default withStyles(styles)((props) => {
  const { classes, optionalAnswers, answers, watchAnswersMode, color, onEditClick } = props;

  if (!watchAnswersMode) {
    return null;
  }

  const [sortableAnswers, setSortableAnswers] = React.useState([]);
  const [myAnswer, setMyAnswer] = React.useState(null);

  React.useEffect(() => {
    const groupedAnswer = groupBy(
      flattenDeep(
        answers.map((item) => {
          if (item.myself) {
            setMyAnswer(item);
          }
          return item.answer.value;
        })
      )
    );
    // flatten and grouped answers to look like this {key1: [val1, val1], key2: [val2, val2]}
    let sortable = []; // array of sorted answers in order by count of duplications ex: [[key1, 5], [key2, 4]]
    for (const [key, value] of Object.entries(groupedAnswer)) {
      sortable.push([key, value.length]);
    }
    sortable = sortable
      .sort((a, b) => b[1] - a[1])
      .map((item) => item[0])
      .map((item) => optionalAnswers.find((answer) => answer.value === item)); //array of sorted optionalAnswers in order count of their duplications

    setSortableAnswers(sortable);
  }, []);


  return (
    <div className={classes.root}>
      <Label1 className={classes.title}>Here are the most popular answers</Label1>
      <div>
        {sortableAnswers.slice(0, 4).map((item) => {
          const selected = myAnswer.answer.value.includes(item.value);
          return (
            <div
              key={item.value}
              className={classes.answerDiv}
              style={{ border: selected ? `3px solid ${color}80` : '3px solid transparent' }}
            >
              <div
                className={clsx(classes.answerContent, selected ? classes.selectedAnswer : classes.unselectedAnswer)}
                style={{ backgroundColor: color }}
              >
                <Label1 className={classes.answerText}>{item.label}</Label1>
              </div>
            </div>
          );
        })}
      </div>
      <div className={classes.bottom}>
        <div className={classes.editCtaDiv} onClick={() => onEditClick(myAnswer)}>
          <Link className={classes.editCtaText}>Edit your answer</Link>
        </div>
      </div>
    </div>
  );
});
