export const OLD_COLORS = {
  // Brand colors
  BRAND_PURPLE_1: '#625FA6',
  BRAND_PURPLE_2: '#6B65E7',
  BRAND_PURPLE_3: '#BAB7F4',
  WHITE: '#FFFFFF',
  BLACK: '#1a1a1a',
  LIGHT_GRAY: '#F0F0F2',
  VIBRANT_BLUE_1: '#246BB3',
  VIBRANT_BLUE_2: '#3399ff',
  VIBRANT_BLUE_3: '#99CCFF',
  DULL_TURQUOISE: '#a1cccf',
  VIVID_PURPLE: '#C39DFF',
  LIGHT_ORANGE: '#fcc787',
  SALMON: '#ff8c78',
  BLUSH_PINK: '#f5bac4',
  BRAND_BLACK_3: '#ABABAB',
  FLORAL_WHITE: '#FFFAF1',
  PALE_ORANGE: '#FEF4E7',
  PALE_PINK: '#F5EDF8',
  PALE_PURPLE: '#ECEDFE',
  LIGHT_PURPLE: '#F3EBFF',
  DARK_GRAY: '#2B2B2B',

  SKIN_TONE_1: '#FFEDDE',
  SKIN_TONE_2: '#E8BCA3',
  SKIN_TONE_3: '#9B663D',
  SKIN_TONE_4: '#544535',

  // System Colors
  GRAY_900: '#212121',
  GRAY_800: '#424242',
  GRAY_700: '#616161',
  GRAY_600: '#757575',
  GRAY_500: '#9E9E9E',
  GRAY_400: '#BDBDBD',
  GRAY_300: '#E0E0E0',
  GRAY_200: '#EEEEEE',
  GRAY_100: '#F5F5F5',
  GRAY_50: '#FAFAFA',

  GREEN_900: '#1B5E20',
  GREEN_800: '#2E7D32',
  GREEN_700: '#388E3C',
  GREEN_600: '#43A047',
  GREEN_500: '#4CAF50',
  GREEN_400: '#66BB6A',
  GREEN_300: '#81C784',
  GREEN_200: '#A5D6A7',
  GREEN_100: '#C8E6C9',
  GREEN_50: '#E8F5E9',

  RED_900: '#B71C1C',
  RED_800: '#C62828',
  RED_700: '#D32F2F',
  RED_600: '#E53935',
  RED_500: '#F44336',
  RED_400: '#EF5350',
  RED_300: '#E57373',
  RED_200: '#EF9A9A',
  RED_100: '#FFCDD2',
  RED_50: '#FFEBEE',
};

export const OLD_SIZES = {
  xs: 4,
  s: 8,
  m: 16,
  l: 24,
  xl: 32,
  xxl: 40,
  xxxl: 48,
  xxxxl: 56,
  xxxxxl: 64,
  xxxxxxl: 72,
  xxxxxxxl: 80,
  xxxxxxxxl: 88,
  xxxxxxxxxl: 96,
};
