import { makeStyles } from '@material-ui/core/styles';
import { getColor, Icon, IconButton, Text } from '@7chairs/frontend';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { baseStyles, HeaderProps } from './shared';
import { NavigationContext } from '../../navigator';

interface StylesProps {
  size: number;
}

export const HEADER_INTERNAL_HEIGHT = 56;

const useStyles = makeStyles((theme) => ({
  ...baseStyles(theme),
  container: {
    backgroundColor: getColor('WHITE_100'),
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)',
    height: `${HEADER_INTERNAL_HEIGHT}px`,
    position: 'relative',
  },
  item: {
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  placeholder: (props: StylesProps) => ({
    height: `${props.size}px`,
    width: `${props.size}px`,
  }),
  textContainer: {
    maxWidth: '356px',

    [theme.breakpoints.down('xs')]: {
      maxWidth: '240px',
    },
  },
}));

const ICON_BUTTON_SIZE = 40;

interface InternalProps extends HeaderProps {
  className?: string;
}

export const Internal = ({ className, text }: InternalProps) => {
  const classes = useStyles({ size: ICON_BUTTON_SIZE });
  const navCtx = useContext(NavigationContext);

  return (
    <div className={clsx(classes.root, classes.container, className)}>
      <div className={clsx(classes.content, classes.item)}>
        <IconButton
          icon={<Icon.Outline.ArrowLeft />}
          onClick={() => navCtx.popScreen()}
          stylesProps={{
            backgroundColor: 'WHITE_100',
            color: 'BLACK_80',
            size: ICON_BUTTON_SIZE,
            withShadow: false,
          }}
        />
        <div className={classes.textContainer}>
          <Text variant="small">{(text ?? "").toUpperCase()}</Text>
        </div>
        <div className={classes.placeholder} />
      </div>
    </div>
  );
};
