import { createApiAction, createReducer } from '../../utils';

// Types
export const types = {
  GET_STREAM_CHAT_TOKEN: 'streamChat/GET_STREAM_CHAT_TOKEN',
  GET_STREAM_CHAT_TOKEN_SUCCESS: 'streamChat/GET_STREAM_CHAT_TOKEN_SUCCESS',
  GET_STREAM_CHAT_TOKEN_ERROR: 'streamChat/GET_STREAM_CHAT_TOKEN_ERROR',
};

// Actions
export function getStreamChatToken() {
  return createApiAction(
    [types.GET_STREAM_CHAT_TOKEN, types.GET_STREAM_CHAT_TOKEN_SUCCESS, types.GET_STREAM_CHAT_TOKEN_ERROR],
    '/stream/token',
    'get',
    undefined
  );
}

// Reducer
const initialState = {
  token: '',
};

const handlers = {
  // stream chat
  [types.GET_STREAM_CHAT_TOKEN_SUCCESS]: (state, { data: { token } }) => {
    return {
      ...state,
      token,
    };
  },
};

export default createReducer(initialState, handlers);
