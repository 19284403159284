import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import VerticalBarChart from '../../charts/VerticalBarChart';
import { Link } from '../../Text';
import { OLD_COLORS } from '../../../styles/appConsts';

const styles = () => ({
  root: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  bottom: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  editCtaDiv: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  editCtaText: {
    fontSize: 12,
    color: OLD_COLORS.BLACK,
    textDecoration: 'underline',
    textAlign: 'center',
  },
});

export default withStyles(styles)((props) => {
  const { classes, answers, watchAnswersMode, color, onEditClick } = props;

  if (!watchAnswersMode) {
    return null;
  }

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const totalAnswers = answers.length;
    const d = {};
    const maxItem = { value: '', amount: 0 };
    // preparing data
    props.optionalAnswers.forEach((ans) => {
      d[ans.value] = { label: ans.label, amount: 0, unit: '%', width: 0, picture: ans.picture };
    });
    // adding amount
    answers.forEach((ans) => {
      const key = ans.answer.value;
      if (!d[key]) {
        // value was changed, skip
        console.log('no key', key);
        return;
      }

      d[key].amount = (d[key].amount || 0) + 1;
      if (d[key].amount > maxItem.amount) {
        maxItem.value = key;
        maxItem.amount = d[key].amount;
      }
    });
    // setting width and amount as percentage of every item
    props.optionalAnswers.forEach((ans) => {
      const key = ans.value;
      d[key].width = key === maxItem.value ? 100 : Math.round((d[key].amount / maxItem.amount) * 100);
      d[key].amount = Math.round((d[key].amount / totalAnswers) * 100) || 0;
    });
    setData(Object.values(d));
  }, [answers]);

  const editAnswer = () => {
    const myAnswer = answers.find((ans) => ans.myself);
    onEditClick(myAnswer);
  };

  const barTextStyle = { color, fontSize: 14, fontWeight: '500' };

  return (
    <div className={classes.root}>
      <VerticalBarChart data={data} labelStyle={barTextStyle} amountStyle={barTextStyle} barColor={color} />
      <div className={classes.bottom}>
        <div className={classes.editCtaDiv} onClick={() => editAnswer()}>
          <Link className={classes.editCtaText}>Edit your answer</Link>
        </div>
      </div>
    </div>
  );
});
