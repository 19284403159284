import React, { useState, useMemo, ChangeEvent, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { getColor, Text, Input } from '@7chairs/frontend';
import { trackEvent } from '../../utils/analyticsEvent';
import { NavigationContext } from '../../components/navigator';
import { CtaButton } from '../../components/buttons';
import { Header } from '../../components/Text';
import { OLD_SIZES } from '../../styles/appConsts';
import { CancelPaymentRequestReason } from '@7chairs/types';

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 80,
  },
  container: {
    maxWidth: 622,
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      margin: OLD_SIZES.m,
    },
  },
  title: {
    whiteSpace: 'pre-line',
  },
  sectionWrapper: {
    paddingTop: OLD_SIZES.l,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  sectionTitle: {
    marginBottom: OLD_SIZES.m,
  },
  reasonSelect: {
    width: '100%',
    border: 'none',
    borderBottom: 1,
    borderBottomColor: getColor('BLACK_60'),
    borderBottomStyle: 'solid',
    fontSize: 16,
    fontFamily: 'Poppins',
    marginBottom: OLD_SIZES.xxxl,
    [theme.breakpoints.down('sm')]: {
      marginBottom: OLD_SIZES.xxxxxl,
    },
  },
  bottom: {
    width: 378,
    margin: '40px auto 0 auto',
    textAlign: 'center',
  },
  cancelationText: {
    maxWidth: 380,
    textAlign: 'center',
    color: getColor('BLACK_20'),
    margin: '10px auto',
  },
  ctaBtn: {
    marginTop: OLD_SIZES.l + OLD_SIZES.l,
    [theme.breakpoints.down('sm')]: {
      marginTop: OLD_SIZES.l,
    },
    cursor: 'pointer',
    backgroundColor: getColor('RED_100'),
    '&:disabled': {
      backgroundColor: getColor('BLACK_40'),
      color: getColor('WHITE_100'),
      cursor: 'default',
    },
  },
}));

function CancelSubscription({ cancelSubscriptionRequest }) {
  const navCtx = useContext(NavigationContext);
  const classes = useStyles();

  const [mainReason, setMainReason] = useState(CancelPaymentRequestReason.Default);
  const [otherReason, setOtherReason] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const isDisabled = useMemo(() => {
    return mainReason.length === 0 || otherReason.length < 10;
  }, [mainReason, otherReason]);

  return (
    <div>
      {submitted && (
        <div className={classes.loaderContainer}>
          <CircularProgress color="primary" />
        </div>
      )}
      <div className={classes.container}>
        <div>
          <Header align="center" className={classes.title}>
            {'We’re so sorry to see you go'}
          </Header>
        </div>
        <div className={classes.sectionWrapper}>
          <div className={classes.sectionTitle}>
            <Text variant="subtitle">What is the main reason for your cancelation?</Text>
          </div>
          <select
            className={classes.reasonSelect}
            style={{ color: mainReason === '' ? getColor('BLACK_20') : getColor('BLACK_100') }}
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              setMainReason(e.target.value as CancelPaymentRequestReason)
            }
            value={mainReason}
          >
            {Object.keys(CancelPaymentRequestReason).map((reason, i) => (
              <option key={i} value={CancelPaymentRequestReason[reason]} disabled={reason === 'Default'}>
                {reason === 'Default' ? 'Choose a reason' : CancelPaymentRequestReason[reason]}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.sectionWrapper}>
          <div className={classes.sectionTitle}>
            <Text variant="subtitle">Let us know what else we can do better</Text>
          </div>

          <Input.Text placeholder="Please write a minimum of 10 characters" onChange={(e) => setOtherReason(e)} />

          <div className={classes.cancelationText}>
            <Text variant="small">Your cancellation will be confirmed within 48H</Text>
          </div>
        </div>
      </div>
      <div className={classes.bottom}>
        <CtaButton
          className={classes.ctaBtn}
          disabled={isDisabled}
          onClick={() => {
            trackEvent('click cancel subscription');
            setSubmitted(true);
            cancelSubscriptionRequest([mainReason, otherReason])
              .then(() => {
                navCtx.replaceScreen('/purchase');
              })
              .catch((err) => {
                console.log('cancelSubscriptionRequest', err);
                setSubmitted(false);
              });
          }}
        >
          Cancel Subscription
        </CtaButton>
      </div>
    </div>
  );
}

export default CancelSubscription;
