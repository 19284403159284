import makeStyles from '@material-ui/core/styles/makeStyles';
import { OLD_COLORS } from '../../../../../styles/appConsts';

export const styles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    paddingTop: '75px',
    height: '220px',
    width: '220px',
    background: OLD_COLORS.GRAY_50,
    border: `3px dashed ${OLD_COLORS.GRAY_100}`,
    cursor: 'pointer',
  },
  rootImage: {
    height: '220px',
    width: '220px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  plus: {
    fontSize: '57px',
    color: OLD_COLORS.GRAY_300,
  },
  uploadPicture: {
    fontSize: '18px',
    lineHeight: '27px',
    fontWeight: '600',
    color: OLD_COLORS.GRAY_500,
    paddingTop: '35px',
  },
  dialogBoxSize: {
    width: '280px',
  },
  closeButton: {
    display: 'inline-block',
    position: 'absolute',
    right: 0,
    color: OLD_COLORS.BLACK,
  },
  titleRow: {
    marginTop: '30px',
    paddingLeft: '16px',
    marginBottom: '16px',
    fontSize: '12px',
    lineHeight: '18px',
    color: OLD_COLORS.GRAY_500,
    paddingTop: '10px',
  },
  socialItem: {
    padding: '16px 20px',
    background: OLD_COLORS.GRAY_50,
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  socialIcon: {
    width: '24px',
    height: '24px',
    marginRight: '18px',
  },
  socialTitle: {
    fontSize: '16px',
    lineHeight: '24px',
    color: OLD_COLORS.GRAY_600,
  },
  uploadIcon: {
    width: '17px',
    height: '17px',
    marginRight: '10px',
  },
  uploadPhoto: {
    fontSize: '14px',
    lineHeight: '21px',
    textDecorationLine: 'underline',
    color: OLD_COLORS.VIBRANT_BLUE_2,
    fontWeight: 500,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
  },
}));
