import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Paragraph, Label1 } from '../../components/Text';
import { OLD_SIZES, OLD_COLORS } from '../../styles/appConsts';
import Brand from './Brand';

const useStyles = makeStyles(() => ({
  root: {
    padding: OLD_SIZES.m,
    flexGrow: 1,
  },
  boxTitle: {
    marginLeft: OLD_SIZES.m,
    marginBottom: OLD_SIZES.xs,
  },
  boxWrapper: {
    border: `1px solid ${OLD_COLORS.GRAY_400}`,
    borderRadius: 8,
    padding: OLD_SIZES.m,
  },
  mainNumberWrapper: {
    flex: 1,
    paddingLeft: 8,
  },
  centerWrapper: {
    display: 'flex',
  },
}));

function PurchaseDetails({ card }) {
  const classes = useStyles();

  const { brand = 'unknown', expMonth, expYear, last4 = '****' } = card;

  const expiration =
    expMonth && expYear
      ? moment()
          .month(expMonth - 1)
          .year(expYear)
          .format('MM/YY')
      : '**/**';

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Label1 color={OLD_COLORS.GRAY_400} className={classes.boxTitle}>
            Credit card number
          </Label1>
          <div className={classes.boxWrapper}>
            <Grid container direction='row' alignItems='center'>
              <div className={classes.centerWrapper}>
                <Brand brand={brand} />
              </div>
              <div className={classes.mainNumberWrapper}>
                <Paragraph color={OLD_COLORS.GRAY_400}>**** {last4}</Paragraph>
              </div>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={6}>
          <Label1 color={OLD_COLORS.GRAY_400} className={classes.boxTitle}>
            Expiration date
          </Label1>

          <div className={classes.boxWrapper}>
            <Paragraph color={OLD_COLORS.GRAY_400}>{expiration}</Paragraph>
          </div>
        </Grid>
        <Grid item xs={6}>
          <Label1 color={OLD_COLORS.GRAY_400} className={classes.boxTitle}>
            CVV
          </Label1>
          <div className={classes.boxWrapper}>
            <Paragraph color={OLD_COLORS.GRAY_400}>***</Paragraph>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

PurchaseDetails.propTypes = {
  card: PropTypes.object.isRequired,
};

export default PurchaseDetails;
