export enum FirstMeetingQuestionnaireQuestionType {
  Rate = 'rate',
  Text = 'text',
  PositiveOrNegative = 'positiveOrNegative',
}

export interface FirstQuestionConfiguration {
  type: FirstMeetingQuestionnaireQuestionType;
  title: string;
  minRate: number;
  minRateLabel: string;
  maxRate: number;
  maxRateLabel: string;
  buttonLabel: string;
}

export interface SecondQuestionConfiguration {
  type: FirstMeetingQuestionnaireQuestionType;
  title: string;
  placeholder: string;
  buttonLabel: string;
}

export interface ThirdQuestionConfiguration {
  type: FirstMeetingQuestionnaireQuestionType;
  title: string;
  subTitle: string;
  positiveAnswerLabel: string;
  positiveAnswerValue: string;
  negativeAnswerLabel: string;
  negativeAnswerValue: string;
  testimonial: {
    text: string;
    author: string;
    rank: number;
  };
  positiveAnswer: {
    title: string;
    subTitle: string;
    buttonLabel: string;
  };
  negativeAnswer: {
    title: string;
    subTitle1: string;
    subTitle2: string;
    upperButtonLabel: string;
    lowerButtonLabel: string;
  };
}

export interface FirstMeetingQuestionnaireConfiguration {
  title: string;
  questions: [FirstQuestionConfiguration, SecondQuestionConfiguration, ThirdQuestionConfiguration];
  thankYou: {
    title: string;
    buttonLabel: string;
  };
}

export interface FirstMeetingQuestionnaireSingleQuestionAnswer {
  type: FirstMeetingQuestionnaireQuestionType;
  value: number | string;
}

export type FirstMeetingQuestionnaireAnswers = Array<FirstMeetingQuestionnaireSingleQuestionAnswer>;

export interface GroupLeaderInfo {
  id: string;
  fullName: string;
  email: string;
  picture: string;
  calendarLink: string;
}
