import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import LikeToggleButton from '../../LikeToggleButton';
import { Paragraph } from '../../Text';
import { OLD_COLORS, OLD_SIZES } from '../../../styles/appConsts';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: OLD_SIZES.xxl,
    position: 'relative',
    paddingBottom: OLD_SIZES.m,
    paddingLeft: OLD_SIZES.m,
    paddingRight: OLD_SIZES.m,
  },
  avatarDiv: {
    position: 'absolute',
    top: `-${OLD_SIZES.l}px`,
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  avatar: {
    width: 48,
    height: 48,
  },
  answerBox: {
    borderRadius: 8,
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
    padding: OLD_SIZES.m,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  contentText: {
    marginTop: OLD_SIZES.s,
    fontSize: 14,
    fontWeight: '400',
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
  pointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  editCtaText: {
    marginTop: OLD_SIZES.s,
    fontSize: 14,
    fontWeight: '400',
    color: OLD_COLORS.BLACK,
    textDecoration: 'underline',
    textAlign: 'center',
  },
  reactionDiv: {
    marginTop: OLD_SIZES.s,
  },
}));

export default function ({ name, picture, answer, me, liked, onEdit, toggleLike }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.answerBox}>
        <Paragraph className={classes.contentText}>{me ? 'You' : name}</Paragraph>
        <Paragraph className={classes.contentText}>{answer}</Paragraph>
        {me && (
          <div className={classes.pointer} onClick={onEdit}>
            <Paragraph className={classes.editCtaText}>Edit</Paragraph>
          </div>
        )}
        {!me && (
          <div className={classes.reactionDiv}>
            <LikeToggleButton liked={liked} toggleLike={toggleLike} justifyContent='center' />
          </div>
        )}
      </div>
      <div className={classes.avatarDiv}>
        <Avatar src={picture} className={classes.avatar} />
      </div>
    </div>
  );
}
