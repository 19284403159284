import React, { useEffect, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import { Icon } from '@7chairs/frontend';
import { useIsWindowWidthUnderThreshold } from '../../../../hooks/useIsWindowWidthUnderThreshold';

const MOBILE_VIEW_WIDTH_THRESHOLD = 600;

interface Props {
  slidesCount: number;
  initialSlideIndex: number;
  sliderSpeed: number;
  slidesToShowOnDesktop: number;
  slidesToScrollOnDesktop: number;
  slidesToShowOnMobile: number;
  slidesToScrollOnMobile: number;
}

export const useSlider = ({
  slidesCount,
  initialSlideIndex,
  sliderSpeed,
  slidesToShowOnDesktop,
  slidesToScrollOnDesktop,
  slidesToShowOnMobile,
  slidesToScrollOnMobile,
}: Props) => {
  const { isWindowWidthUnderThreshold } = useIsWindowWidthUnderThreshold({ threshold: MOBILE_VIEW_WIDTH_THRESHOLD });

  const slider = useRef<Slider | null>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(initialSlideIndex);
  const [isPreviousButtonDisabled, setPreviousButtonDisabled] = useState(true);
  const [isNextButtonDisabled, setNextButtonDisabled] = useState(slidesCount < slidesToShowOnDesktop);

  const sliderSettings: Settings = {
    dots: false,
    infinite: false,
    draggable: false,
    swipe: false,
    speed: sliderSpeed,
    slidesToShow: slidesToShowOnDesktop,
    slidesToScroll: slidesToScrollOnDesktop,
    initialSlide: initialSlideIndex,
    afterChange: setCurrentSlideIndex,
    arrows: false,
    responsive: [
      {
        breakpoint: MOBILE_VIEW_WIDTH_THRESHOLD,
        settings: {
          dots: true,
          swipe: true,
          draggable: true,
          touchThreshold: 100,
          customPaging: () => {
            return (
              <div className='dot'>
                <Icon.Fill.Dot size={10} />
              </div>
            );
          },
          dotsClass: 'slick-dots slick-thumb',
          initialSlide: initialSlideIndex,
          slidesToShow: slidesToShowOnMobile,
          slidesToScroll: slidesToScrollOnMobile,
        },
      },
    ],
  };

  useEffect(() => {
    setNextButtonDisabled(currentSlideIndex >= slidesCount - slidesToShowOnDesktop);
    setPreviousButtonDisabled(currentSlideIndex == initialSlideIndex);
  }, [currentSlideIndex]);

  const showPrevSlide = () => {
    if (slider) {
      slider.current.slickPrev();
    }
  };

  const showNextSlide = () => {
    if (slider) {
      slider.current.slickNext();
    }
  };

  useEffect(() => {
    // we need to reset slider after crossing threshold (bug inside react-slick lib)
    slider.current.slickGoTo(initialSlideIndex);
    setPreviousButtonDisabled(true);
    setNextButtonDisabled(slidesCount < slidesToShowOnDesktop);
  }, [isWindowWidthUnderThreshold]);

  return {
    slider,
    sliderSettings,
    currentSlideIndex,
    showPrevSlide,
    showNextSlide,
    isPreviousButtonDisabled,
    isNextButtonDisabled,
  };
};
