import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tabs from '@material-ui/core/Tabs';
import { OLD_SIZES, OLD_COLORS } from '../../../../styles/appConsts';
import Tab from '@material-ui/core/Tab';
import { CtaButton } from '../../../../components/buttons';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '../../../../utils/analyticsEvent';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    padding: `${OLD_SIZES.xl}px 0 0 0`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  optionsWrapper: {
    paddingTop: OLD_SIZES.m,
    width: '100%',
    minHeight: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  optionsWrapperLast: {
    width: '100%',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  options: {
    display: 'flex',
    alignItems: 'center',
  },
  circle: {
    height: 48,
    width: 48,
    borderRadius: '50%',
    boxSizing: 'content-box',
    margin: `${OLD_SIZES.s}px ${OLD_SIZES.s + OLD_SIZES.xs}px`,
  },
  avatarImg: {
    boxSizing: 'content-box',
    borderRadius: '50%',
    margin: `${OLD_SIZES.s}px ${OLD_SIZES.s + OLD_SIZES.xs}px`,
  },
  activeCircle: {
    border: `${OLD_SIZES.xs}px solid ${OLD_COLORS.WHITE}`,
    margin: `${OLD_SIZES.xs}px ${OLD_SIZES.s}px`,
    filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.12))',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ height: '100%', width: '100%', background: OLD_COLORS.GRAY_50 }}
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const AvatarTabsStep = ({ onDone, data }) => {
  const [tab, setTab] = useState(0);
  const classes = useStyles();
  const history = useHistory();
  const [sex, setSex] = useState(data?.sex === 'female' ? 'F' : data?.sex === 'male' ? 'M' : 'O');
  const [activeSkinColor, setActiveSkinColor] = useState(data?.skinColor || 1);
  const [activeColor, setActiveColor] = useState(data?.color || 1);
  const [activeAvatar, setActiveAvatar] = useState(data?.avatar || 1);
  const [imageLink, setImageLink] = useState(
    `https://res.cloudinary.com/better-with-us/image/upload/v1611573962/new-avatars/${
      sex === 'F' ? 'female' : sex === 'M' ? 'male' : 'other'
    }-avatars/sex_${sex}_bg_${activeColor}_skin_${activeSkinColor}_v_${activeAvatar}.png`
  );

  useEffect(() => {
    setImageLink(
      `https://res.cloudinary.com/better-with-us/image/upload/v1611573962/new-avatars/${
        sex === 'F' ? 'female' : sex === 'M' ? 'male' : 'other'
      }-avatars/sex_${sex}_bg_${activeColor}_skin_${activeSkinColor}_v_${activeAvatar}.png`
    );
  }, [sex, activeColor, activeAvatar, activeSkinColor]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const selectColor = (value) => {
    setActiveColor(value);
    setTab(2);
  };

  const selectSkinColor = (value) => {
    setActiveSkinColor(value);
    setTab(1);
  };

  return (
    <div className={classes.root}>
      <div>
        <img width={120} height={120} style={{ marginBottom: OLD_SIZES.l }} src={imageLink} alt='' />
      </div>
      <Tabs
        value={tab}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: OLD_COLORS.BRAND_PURPLE_2,
            height: OLD_SIZES.xs,
            borderRadius: 2,
            color: OLD_COLORS.BLACK,
          },
        }}
        centered
      >
        <Tab label='Skin tone' />
        <Tab label='Background' />
        <Tab label='Avatar' />
      </Tabs>
      <TabPanel value={tab} index={0}>
        <div className={classes.optionsWrapper}>
          <div
            className={`${classes.circle} ${activeSkinColor === 1 ? classes.activeCircle : ''}`}
            style={{ background: OLD_COLORS.SKIN_TONE_1 }}
            onClick={() => selectSkinColor(1)}
          />
          <div
            className={`${classes.circle} ${activeSkinColor === 2 ? classes.activeCircle : ''}`}
            style={{ background: OLD_COLORS.SKIN_TONE_2 }}
            onClick={() => selectSkinColor(2)}
          />
          <div
            className={`${classes.circle} ${activeSkinColor === 3 ? classes.activeCircle : ''}`}
            style={{ background: OLD_COLORS.SKIN_TONE_3 }}
            onClick={() => selectSkinColor(3)}
          />
          <div
            className={`${classes.circle} ${activeSkinColor === 4 ? classes.activeCircle : ''}`}
            style={{ background: OLD_COLORS.SKIN_TONE_4 }}
            onClick={() => selectSkinColor(4)}
          />
        </div>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <div className={classes.optionsWrapperLast} style={{ flexDirection: 'column' }}>
          <div className={classes.options}>
            <div
              className={`${classes.circle} ${activeColor === 1 ? classes.activeCircle : ''}`}
              style={{ background: OLD_COLORS.BRAND_PURPLE_2 }}
              onClick={() => selectColor(1)}
            />
            <div
              className={`${classes.circle} ${activeColor === 2 ? classes.activeCircle : ''}`}
              style={{ background: OLD_COLORS.LIGHT_ORANGE }}
              onClick={() => selectColor(2)}
            />
            <div
              className={`${classes.circle} ${activeColor === 3 ? classes.activeCircle : ''}`}
              style={{ background: OLD_COLORS.SALMON }}
              onClick={() => selectColor(3)}
            />
          </div>
          <div className={classes.options}>
            <div
              className={`${classes.circle} ${activeColor === 4 ? classes.activeCircle : ''}`}
              style={{ background: OLD_COLORS.VIBRANT_BLUE_2 }}
              onClick={() => selectColor(4)}
            />
            <div
              className={`${classes.circle} ${activeColor === 5 ? classes.activeCircle : ''}`}
              style={{ background: OLD_COLORS.DULL_TURQUOISE }}
              onClick={() => selectColor(5)}
            />
            <div
              className={`${classes.circle} ${activeColor === 6 ? classes.activeCircle : ''}`}
              style={{ background: OLD_COLORS.BLUSH_PINK }}
              onClick={() => selectColor(6)}
            />
          </div>
        </div>
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <div className={classes.optionsWrapper} style={{ flexDirection: 'column' }}>
          {(sex === 'F' || sex === 'M') && (
            <div className={classes.options}>
              {[...Array(4)].map((item, index) => (
                <img
                  className={`${classes.avatarImg} ${activeAvatar === index + 1 ? classes.activeCircle : ''}`}
                  width={48}
                  height={48}
                  key={index}
                  onClick={() => setActiveAvatar(index + 1)}
                  src={`https://res.cloudinary.com/better-with-us/image/upload/v1611575826/new-avatars/${
                    data?.sex
                  }-avatars/sex_${
                    data?.sex === 'female' ? 'F' : data?.sex === 'male' ? 'M' : 'O'
                  }_bg_${activeColor}_skin_${activeSkinColor}_v_${index + 1}.png`}
                  alt=''
                />
              ))}
            </div>
          )}
          {sex === 'O' && (
            <div className={classes.options}>
              {[...Array(3)].map((item, index) => (
                <img
                  className={`${classes.avatarImg} ${activeAvatar === index + 1 ? classes.activeCircle : ''}`}
                  width={48}
                  height={48}
                  key={index}
                  onClick={() => setActiveAvatar(index + 1)}
                  src={`https://res.cloudinary.com/better-with-us/image/upload/v1617881937/new-avatars/${
                    data?.sex
                  }-avatars/sex_${
                    data?.sex === 'female' ? 'F' : data?.sex === 'male' ? 'M' : 'O'
                  }_bg_${activeColor}_skin_${activeSkinColor}_v_${index + 1}.png`}
                  alt=''
                />
              ))}
            </div>
          )}
          {(sex === 'F' || sex === 'M') && (
            <div className={classes.options}>
              {[...Array(4)].map((item, index) => (
                <img
                  className={`${classes.avatarImg} ${activeAvatar === index + 5 ? classes.activeCircle : ''}`}
                  width={48}
                  height={48}
                  key={index}
                  onClick={() => setActiveAvatar(index + 5)}
                  src={`https://res.cloudinary.com/better-with-us/image/upload/v1611575826/new-avatars/${
                    data?.sex
                  }-avatars/sex_${
                    data?.sex === 'female' ? 'F' : data?.sex === 'male' ? 'M' : 'O'
                  }_bg_${activeColor}_skin_${activeSkinColor}_v_${index + 5}.png`}
                  alt=''
                />
              ))}
            </div>
          )}
          {sex === 'F' && (
            <div className={classes.options}>
              {[...Array(4)].map((item, index) => (
                <img
                  className={`${classes.avatarImg} ${activeAvatar === index + 9 ? classes.activeCircle : ''}`}
                  width={48}
                  height={48}
                  key={index}
                  onClick={() => setActiveAvatar(index + 9)}
                  src={`https://res.cloudinary.com/better-with-us/image/upload/v1611575826/new-avatars/${
                    data?.sex
                  }-avatars/sex_${
                    data?.sex === 'female' ? 'F' : data?.sex === 'male' ? 'M' : 'O'
                  }_bg_${activeColor}_skin_${activeSkinColor}_v_${index + 9}.png`}
                  alt=''
                />
              ))}
            </div>
          )}
          {sex === 'M' && (
            <div className={classes.options}>
              {[...Array(2)].map((item, index) => (
                <img
                  className={`${classes.avatarImg} ${activeAvatar === index + 9 ? classes.activeCircle : ''}`}
                  width={48}
                  height={48}
                  key={index}
                  onClick={() => setActiveAvatar(index + 9)}
                  src={`https://res.cloudinary.com/better-with-us/image/upload/v1611575826/new-avatars/${
                    data?.sex
                  }-avatars/sex_${
                    data?.sex === 'female' ? 'F' : data?.sex === 'male' ? 'M' : 'O'
                  }_bg_${activeColor}_skin_${activeSkinColor}_v_${index + 9}.png`}
                  alt=''
                />
              ))}
            </div>
          )}
          <CtaButton
            style={{ marginTop: OLD_SIZES.l }}
            onClick={() => {
              const data = {
                color: activeColor,
                skinColor: activeSkinColor,
                avatar: activeAvatar,
              };
              trackEvent('avatar tabs page click');
              onDone(data, imageLink);
            }}
          >
            Done
          </CtaButton>
        </div>
      </TabPanel>
    </div>
  );
};

export default AvatarTabsStep;
