import React, { useContext, useMemo } from 'react';
import ImageChoose from '../../avatar/avatarImage/imageChoose/ImageChoose';
import { AccountStore, updateAccountProfile } from '../../../../store/ducks/account';
import { useDispatch, useSelector } from 'react-redux';
import { NavigationContext } from '../../../../components/navigator';

export const PictureBuilder = () => {
  const navCtx = useContext(NavigationContext);
  const dispatch = useDispatch();

  // TODO: enrich DefaultRootState with account: AccountStore and groups
  // @ts-ignore
  const account: AccountStore = useSelector((state) => state.account);

  const isMobileWebview = useMemo(() => window.navigator.userAgent.indexOf('circles-mobile-app') > -1, []);

  const onUpdateProfile = (imageLink:string) => {
    dispatch(updateAccountProfile(account.id, { picture: imageLink })).then(() => navCtx.popScreen());
  };

  return (
    <ImageChoose handleAvatar={onUpdateProfile} picture={account.picture} isMobileWebview={isMobileWebview} isFromPictureBuilder={true} />
  )
}
