import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import ExerciseMain from '../../components/exercise/Main';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'block',
    flexGrow: 1,
  },
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 80,
  },
}));

function Exercise({ getAllExercises, getExercise, setOpenExercise, exercise, openExercise, exerciseId }) {
  const classes = useStyles();
  const [exerciseViewed, setExerciseViewed] = React.useState(false);

  React.useEffect(() => {
    if (exerciseId) {
      getAllExercises()
        .then(() => getExercise(exerciseId))
        .then(() => setOpenExercise(true))
        .catch((error) => {
          console.error('Exercise screen', error);
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'closeExercise' }));
          }
        });
    }
  }, []);

  React.useEffect(() => {
    if (openExercise && !exerciseViewed) {
      setExerciseViewed(true);
    }
    if (!openExercise && exerciseViewed) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'closeExercise' }));
      }
    }
  }, [openExercise, exerciseViewed]);

  return (
    <div className={classes.root}>
      {openExercise && !!exercise ? (
        <ExerciseMain />
      ) : (
        <div className={classes.loaderContainer}>
          <CircularProgress color='primary' />
        </div>
      )}
    </div>
  );
}

export default Exercise;
