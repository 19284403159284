import { createAction, createReducer, createApiAction } from '../../utils';

// Types
export const types = {
  //
  GET_PURCHASE_INFO: 'account/GET_PURCHASE_INFO',
  GET_PURCHASE_INFO_SUCCESS: 'account/GET_PURCHASE_INFO_SUCCESS',
  GET_PURCHASE_INFO_ERROR: 'account/GET_PURCHASE_INFO_ERROR',
  //
  GET_PAYMENT_DETAILS: 'purchase/GET_PAYMENT_DETAILS',
  PAYMENT_DETAILS_SUCCESS: 'purchase/PAYMENT_DETAILS_SUCCESS',
  PAYMENT_DETAILS_ERROR: 'purchase/PAYMENT_DETAILS_ERROR',
  //
  GET_CLIENT_SECRET: 'purchase/GET_CLIENT_SECRET',
  CLIENT_SECRET_RECEIVE: 'purchase/CLIENT_SECRET_RECEIVE',
  CLIENT_SECRET_ERROR: 'purchase/CLIENT_SECRET_ERROR',
  //
  UPDATE_PURCHASE_PAYMENT_METHOD: 'purchase/UPDATE_PURCHASE_PAYMENT_METHOD',
  UPDATE_PURCHASE_PAYMENT_SUCCESS: 'purchase/UPDATE_PURCHASE_PAYMENT_SUCCESS',
  UPDATE_PURCHASE_PAYMENT_ERROR: 'purchase/UPDATE_PURCHASE_PAYMENT_ERROR',
  //
  CANCEL_PURCHASE_SUBSCRIPTION_REQUEST: 'purchase/CANCEL_PURCHASE_SUBSCRIPTION_REQUEST',
  CANCEL_PURCHASE_SUBSCRIPTION_REQUEST_SUCCESS: 'purchase/CANCEL_PURCHASE_SUBSCRIPTION_REQUEST_SUCCESS',
  CANCEL_PURCHASE_SUBSCRIPTION_REQUEST_ERROR: 'purchase/CANCEL_PURCHASE_SUBSCRIPTION_REQUEST_ERROR',
  //
  REENABLE_PURCHASE_SUBSCRIPTION: 'purchase/REENABLE_PURCHASE_SUBSCRIPTION',
  REENABLE_PURCHASE_SUBSCRIPTION_SUCCESS: 'purchase/REENABLE_PURCHASE_SUBSCRIPTION_SUCCESS',
  REENABLE_PURCHASE_SUBSCRIPTION_ERROR: 'purchase/REENABLE_PURCHASE_SUBSCRIPTION_ERROR',
  //
  UNDISPLAY_SUCCESS_POPUP: 'purchase/UNDISPLAY_SUCCESS_POPUP',
  UNDISPLAY_SUCCESS_EDIT: 'purchase/UNDISPLAY_SUCCESS_EDIT',
};

// Actions
export function getPurchaseInfo() {
  return createApiAction(
    [types.GET_PURCHASE_INFO, types.GET_PURCHASE_INFO_SUCCESS, types.GET_PURCHASE_INFO_ERROR],
    '/payment/voices-purchase-info',
    'get',
    undefined
  );
}

export function getPaymentDetails() {
  return createApiAction(
    [types.GET_PAYMENT_DETAILS, types.PAYMENT_DETAILS_SUCCESS, types.PAYMENT_DETAILS_ERROR],
    '/payment/voices-purchase',
    'get',
    undefined
  );
}

export function getClientSecret() {
  return createApiAction(
    [types.GET_CLIENT_SECRET, types.CLIENT_SECRET_RECEIVE, types.CLIENT_SECRET_ERROR],
    '/payment/voices-purchase/stripe/secret',
    'get',
    undefined
  );
}

export function updatePaymentMethod(data) {
  return createApiAction(
    [types.UPDATE_PURCHASE_PAYMENT_METHOD, types.UPDATE_PURCHASE_PAYMENT_SUCCESS, types.UPDATE_PURCHASE_PAYMENT_ERROR],
    '/payment/voices-purchase/stripe/edit',
    'post',
    data
  );
}

export function cancelSubscriptionRequest() {
  return createApiAction(
    [
      types.CANCEL_PURCHASE_SUBSCRIPTION_REQUEST,
      types.CANCEL_PURCHASE_SUBSCRIPTION_REQUEST_SUCCESS,
      types.CANCEL_PURCHASE_SUBSCRIPTION_REQUEST_ERROR,
    ],
    '/payment/voices-purchase/cancel',
    'post',
    {}
  );
}

export function reenableSubscription() {
  return createApiAction(
    [
      types.REENABLE_PURCHASE_SUBSCRIPTION,
      types.REENABLE_PURCHASE_SUBSCRIPTION_SUCCESS,
      types.REENABLE_PURCHASE_SUBSCRIPTION_ERROR,
    ],
    '/payment/voices-purchase/cancel',
    'delete',
    {}
  );
}

export const closeSuccessPopup = () => createAction(types.UNDISPLAY_SUCCESS_POPUP);
export const closeSuccessEdit = () => createAction(types.UNDISPLAY_SUCCESS_EDIT);

// Reducer
const initialState = {
  plans: {},
  currentPlan: null,
  clientSecret: null,
  cardDetails: null,
  billing: null,
  displaySuccessPopup: false,
  displaySuccessEdit: false,
  discount: {},
  subscriptionId: null,
};

const handlers = {
  [types.CLIENT_SECRET_RECEIVE]: (state, { data }) => ({
    ...state,
    ...data,
  }),
  [types.UPDATE_PURCHASE_PAYMENT_SUCCESS]: (state, action) => {
    const {
      data,
      config: { url },
    } = action;
    const isEdit = url === '/stripe/edit';
    return {
      ...state,
      cardDetails: data.cardDetails,
      billing: data.billing,
      subscriptionId: data.subscriptionId,
      displaySuccessPopup: !isEdit,
      displaySuccessEdit: isEdit,
    };
  },
  [types.PAYMENT_DETAILS_SUCCESS]: (state, { data }) => ({
    ...state,
    cardDetails: data.cardDetails,
    billing: data.billing,
  }),
  [types.UNDISPLAY_SUCCESS_POPUP]: (state) => ({
    ...state,
    displaySuccessPopup: false,
  }),
  [types.UNDISPLAY_SUCCESS_EDIT]: (state) => ({
    ...state,
    displaySuccessEdit: false,
  }),
};

export default createReducer(initialState, handlers);
