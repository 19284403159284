import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Header, Link, Label1 } from '../../Text';
import { OLD_SIZES, OLD_COLORS } from '../../../styles/appConsts';

const styles = () => ({
  root: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  avgRankDiv: {
    width: 82,
    height: 82,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avgRank: {
    fontSize: 22,
    color: OLD_COLORS.WHITE,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: '500',
    marginTop: OLD_SIZES.s,
    color: OLD_COLORS.BLACK,
  },
  bottom: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  editCtaDiv: {
    '&:hover': {
      curdor: 'pointer',
    },
  },
  editCtaText: {
    fontSize: 12,
    color: OLD_COLORS.BLACK,
    textDecoration: 'underline',
    textAlign: 'center',
  },
});

export default withStyles(styles)((props) => {
  const { classes, answers, additionalContent, watchAnswersMode, color, onEditClick } = props;

  if (!watchAnswersMode) {
    return null;
  }

  const [avgRank, setAvgRank] = React.useState(null);

  React.useEffect(() => {
    const total = answers.reduce((acc, cur) => acc + parseInt(cur.answer.value), 0);
    setAvgRank(parseFloat(total / answers.length).toFixed(1));
  }, [answers]);

  const editAnswer = () => {
    const myAnswer = answers.find((ans) => ans.myself);
    onEditClick(myAnswer);
  };

  return (
    <div className={classes.root}>
      {avgRank && (
        <>
          <div className={classes.avgRankDiv} style={{ backgroundColor: color }}>
            <Header className={classes.avgRank}>{avgRank}</Header>
          </div>
          <Label1 className={classes.subtitle}>Avarage rank</Label1>
        </>
      )}
      <div className={classes.bottom}>
        <div className={classes.editCtaDiv} onClick={() => editAnswer()}>
          <Link className={classes.editCtaText}>Edit your answer</Link>
        </div>
      </div>
    </div>
  );
});
