import { createReducer } from '../../utils';
import { SESSION_GAP } from '../../utils/consts';
import { types as authTypes } from './auth';

// Reducer
const initialState = {
  openGroupSessionGap: SESSION_GAP,
};

const handlers = {
  [authTypes.PING_SUCCESS]: (state, { data }) => ({
    ...state,
    ...data.config,
  }),
};

export default createReducer(initialState, handlers);
