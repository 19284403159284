import React, { useContext, useEffect, useState } from 'react';
import { Dialog, IconButton, Avatar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { trackEvent } from '../../../../../utils/analyticsEvent';
import { styles } from './styles';
import { getSocialImage } from '../../../../../utils/firebase';
import { Label1 } from '../../../../../components/Text';
import ImageCropper from '../imageCropper/ImageCropper';
import { NavigationContext } from '../../../../../components/navigator';

interface ImageChooseProps {
  handleAvatar: (url: string) => void,
  isMobileWebview: boolean,
  isFromPictureBuilder?: boolean,
  picture: string,
}

const ImageChoose = ({ handleAvatar, isFromPictureBuilder = false, isMobileWebview, picture }: ImageChooseProps) => {
  const classes = styles();
  const navCtx = useContext(NavigationContext);

  const [openModal, setOpenModal] = useState(isFromPictureBuilder || false);
  const [openCropperModal, setOpenCropperModal] = useState(false);
  const [socialAvatar, setSocialAvatar] = useState('');

  const onModalClose = (modal: 'choose' | 'cropper') => {
    switch (modal) {
      case 'choose':
        setOpenModal(false);
        break;
      case 'cropper':
        setOpenCropperModal(false);
        break;
      default:
        const error: never = modal;
        console.error(`Unhandled modal type ${error}`);
        break;
    }
    if (isFromPictureBuilder && modal === 'choose') {
      navCtx.popScreen()
    }
  };

  const onImage = (imageUrl) => {
    setSocialAvatar(imageUrl);
    handleAvatar(imageUrl);
  };

  const handlePopupClose = (imageUrl) => {
    setOpenCropperModal(false);
    if (imageUrl) {
      onImage(imageUrl);
      setOpenModal(false);
    }
  };

  const socialLogin = (type) => {
    trackEvent('click upload social profile image', { type });
    getSocialImage(type)
      .then(({ userInfo }) => {
        switch (type) {
          case 'facebook':
            // @ts-ignore
            onImage(userInfo.additionalUserInfo.profile.picture.data.url);
            break;
          case 'google':
            // @ts-ignore
            onImage(userInfo.additionalUserInfo.profile.picture);
            break;
          case 'yahoo':
            // @ts-ignore
            onImage(userInfo.additionalUserInfo.profile.picture);
            break;
        }
      })
      .catch((err) => {
        trackEvent('error upload social profile image', { type, error: err?.message });
      });

    setOpenModal(false);
    if (isFromPictureBuilder) {
      navCtx.popScreen()
    }
  };

  useEffect(() => {
    setSocialAvatar(picture);
  }, []);

  return (
    <>
      {!isFromPictureBuilder
        ? (
          !socialAvatar ? (
            <div className={classes.root} onClick={() => setOpenModal(true)}>
              <Label1 className={classes.plus}>+</Label1>
              <Label1 className={classes.uploadPicture}>Upload a picture</Label1>
            </div>
          ) : (
            <div>
              <Avatar className={classes.rootImage} src={socialAvatar} alt={''} />
              <Label1 onClick={() => setOpenModal(true)} className={classes.uploadPhoto}>
                <img className={classes.uploadIcon} src={`${ASSETS_BASE_DIR}/profile/icon-upload.svg`} alt='gallery' />
                Upload a photo
              </Label1>
            </div>
          )
        )
        : null
      }


      <Dialog onClose={() => onModalClose('choose')} open={openModal}>
        <IconButton aria-label='close' className={classes.closeButton} onClick={() => onModalClose('choose')}>
          <CloseIcon />
        </IconButton>
        <div className={classes.dialogBoxSize}>
          <Label1 className={classes.titleRow}>You can choose a photo</Label1>

          <Label1
            onClick={() => {
              trackEvent('click open gallery profile image');
              setOpenCropperModal(true);
            }}
            className={classes.socialItem}
          >
            <img className={classes.socialIcon} src={`${ASSETS_BASE_DIR}/profile/gallery.svg`} alt='gallery' />
            <span className={classes.socialTitle}>Gallery</span>
          </Label1>

          {!isMobileWebview && (
            <>
              <Label1 className={classes.titleRow}>Or, import your profile picture from</Label1>

              <Label1 onClick={() => socialLogin('facebook')} className={classes.socialItem}>
                <img className={classes.socialIcon} src={`${ASSETS_BASE_DIR}/profile/facebook.svg`} alt='facebook' />
                <span className={classes.socialTitle}>Facebook</span>
              </Label1>

              {/* <div className={classes.socialItem}> */}
              {/*  <img className={classes.socialIcon} src={`${ASSETS_BASE_DIR}/profile/instagram.svg`} alt='instagram' /> */}
              {/*  <span className={classes.socialTitle}>Instagram</span> */}
              {/* </div> */}

              <Label1 onClick={() => socialLogin('google')} className={classes.socialItem}>
                <img className={classes.socialIcon} src={`${ASSETS_BASE_DIR}/profile/google.svg`} alt='google' />
                <span className={classes.socialTitle}>Google</span>
              </Label1>

              <Label1 onClick={() => socialLogin('yahoo')} className={classes.socialItem}>
                <img className={classes.socialIcon} src={`${ASSETS_BASE_DIR}/profile/yahoo.svg`} alt='yahoo' />
                <span className={classes.socialTitle}>Yahoo</span>
              </Label1>
            </>
          )}
        </div>
      </Dialog>

      <Dialog onClose={() => onModalClose('cropper')} open={openCropperModal}>
        <IconButton aria-label='close' className={classes.closeButton} onClick={() => onModalClose('cropper')}>
          <CloseIcon />
        </IconButton>
        <ImageCropper handlePopupClose={handlePopupClose} />
      </Dialog>
    </>
  );
};

export default ImageChoose;
