import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import TagManager from 'react-gtm-module';
import themeDefinitions from './styles/themeDefinitions';
import { OLD_COLORS } from './styles/appConsts';
import { types } from './store/ducks/root';
import configureStore from './store/configure';
import { RootNavigation } from './navigation/RootNavigation';

const tagManagerArgs = {
  gtmId: 'GTM-5WMBXQH',
};

TagManager.initialize(tagManagerArgs);

const theme = themeDefinitions(
  responsiveFontSizes(
    createTheme({
      palette: {
        primary: {
          main: OLD_COLORS.BRAND_PURPLE_2,
        },
        secondary: {
          main: OLD_COLORS.BLACK,
        },
      },
    })
  )
);

const store = configureStore();

Sentry.init({
  dsn: 'https://cd44c28a6ce742ea9246e675c49ee872@o480420.ingest.sentry.io/5527699',
  environment: process.env.NODE_ENV,
  release: APP_VERSION,
});

window.addEventListener('focus', () => store.dispatch({ type: types.UPDATE_LAST_FOCUS }));

const App = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <RootNavigation />
    </Provider>
  </ThemeProvider>
);

ReactDOM.render(<App />, document.getElementById('app'));
