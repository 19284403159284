import { createAction, createApiAction, createReducer } from '../../utils';

// Types
export const types = {
  GET_ALL: 'exercises/GET_ALL',
  GET_ALL_SUCCESS: 'exercises/GET_ALL_SUCCESS',
  GET_ALL_ERROR: 'exercises/GET_ALL_ERROR',
  GET_EXERCISE: 'exercises/GET_EXERCISE',
  GET_EXERCISE_SUCCESS: 'exercises/GET_EXERCISE_SUCCESS',
  GET_EXERCISE_ERROR: 'exercises/GET_EXERCISE_ERROR',
  CREATE_EXERCISE_PROGRESS: 'exercises/CREATE_EXERCISE_PROGRESS',
  CREATE_EXERCISE_PROGRESS_SUCCESS: 'exercises/CREATE_EXERCISE_PROGRESS_SUCCESS',
  CREATE_EXERCISE_PROGRESS_ERROR: 'exercises/CREATE_EXERCISE_PROGRESS_ERROR',
  UPDATE_EXERCISE_PROGRESS: 'exercises/UPDATE_EXERCISE_PROGRESS',
  UPDATE_EXERCISE_PROGRESS_SUCCESS: 'exercises/UPDATE_EXERCISE_PROGRESS_SUCCESS',
  UPDATE_EXERCISE_PROGRESS_ERROR: 'exercises/UPDATE_EXERCISE_PROGRESS_ERROR',
  UPDATE_ANSWER_PROGRESS: 'exercises/UPDATE_ANSWER_PROGRESS',
  UPDATE_ANSWER_PROGRESS_SUCCESS: 'exercises/UPDATE_ANSWER_PROGRESS_SUCCESS',
  UPDATE_ANSWER_PROGRESS_ERROR: 'exercises/UPDATE_ANSWER_PROGRESS_ERROR',
  SUBMIT_ANSWER: 'exercises/SUBMIT_ANSWER',
  SUBMIT_ANSWER_SUCCESS: 'exercises/SUBMIT_ANSWER_SUCCESS',
  SUBMIT_ANSWER_ERROR: 'exercises/SUBMIT_ANSWER_ERROR',
  REVIEW_QUESTION: 'exercises/REVIEW_QUESTION',
  REVIEW_QUESTION_SUCCESS: 'exercises/REVIEW_QUESTION_SUCCESS',
  REVIEW_QUESTION_ERROR: 'exercises/REVIEW_QUESTION_ERROR',
  UPDATE_ANSWER: 'exercises/UPDATE_ANSWER',
  UPDATE_ANSWER_SUCCESS: 'exercises/UPDATE_ANSWER_SUCCESS',
  UPDATE_ANSWER_ERROR: 'exercises/UPDATE_ANSWER_ERROR',
  GET_SUMMARY: 'exercises/GET_SUMMARY',
  GET_SUMMARY_SUCCESS: 'exercises/GET_SUMMARY_SUCCESS',
  GET_SUMMARY_ERROR: 'exercises/GET_SUMMARY_ERROR',
  SET_EXERCISE: 'exercises/SET_EXERCISE',
  SET_OPEN_EXERCISE: 'exercises/SET_OPEN_EXERCISE',
  ADD_QUESTION_CACHE: 'exercises/ADD_QUESTION_CACHE',
};

// Actions
export function getAllExercises() {
  return createApiAction([types.GET_ALL, types.GET_ALL_SUCCESS, types.GET_ALL_ERROR], '/exercises', 'get', undefined);
}

export function getExercise(id) {
  return createApiAction(
    [types.GET_EXERCISE, types.GET_EXERCISE_SUCCESS, types.GET_EXERCISE_ERROR],
    `/exercises/${id}`,
    'get',
    undefined
  );
}

export function createProgress(id) {
  return createApiAction(
    [types.CREATE_EXERCISE_PROGRESS, types.CREATE_EXERCISE_PROGRESS_SUCCESS, types.CREATE_EXERCISE_PROGRESS_ERROR],
    `/exercises/${id}/progress`,
    'post',
    {}
  );
}

export function updateProgress(id, data = {}) {
  return createApiAction(
    [types.UPDATE_EXERCISE_PROGRESS, types.UPDATE_EXERCISE_PROGRESS_SUCCESS, types.UPDATE_EXERCISE_PROGRESS_ERROR],
    `/exercises/${id}/progress`,
    'patch',
    data
  );
}

export function submitAnswer(exerciseId, questionId, answer) {
  return createApiAction(
    [types.SUBMIT_ANSWER, types.SUBMIT_ANSWER_SUCCESS, types.SUBMIT_ANSWER_ERROR],
    `/exercises/${exerciseId}/question/${questionId}/answer`,
    'post',
    { answer }
  );
}

export function reviewQuestion(exerciseId, questionId) {
  return createApiAction(
    [types.REVIEW_QUESTION, types.REVIEW_QUESTION_SUCCESS, types.REVIEW_QUESTION_ERROR],
    `/exercises/${exerciseId}/question/${questionId}`,
    'get',
    undefined
  );
}

// data: { answerText } || { reaction }
export function updateAnswer(exerciseId, questionId, answerId, data) {
  return createApiAction(
    [types.UPDATE_ANSWER_PROGRESS, types.UPDATE_ANSWER_PROGRESS_SUCCESS, types.UPDATE_ANSWER_PROGRESS_ERROR],
    `/exercises/${exerciseId}/question/${questionId}/answer/${answerId}`,
    'patch',
    data
  );
}
export function getSummaryInfo(exerciseId) {
  return createApiAction(
    [types.GET_SUMMARY, types.GET_SUMMARY_SUCCESS, types.GET_SUMMARY_ERROR],
    `/exercises/${exerciseId}/summary`,
    'get',
    undefined
  );
}

export const setExercise = (props) => createAction(types.SET_EXERCISE, props);
export const setOpenExercise = (open) => createAction(types.SET_OPEN_EXERCISE, open);
export const addQuestionCache = (props) => createAction(types.ADD_QUESTION_CACHE, props);

// Reducer
export const initialState = {
  all: null,
  current: null,
  openExercise: false,
  questionsCache: {},
  exerciseWidgetIndex: 0,
};

const handlers = {
  [types.GET_ALL_SUCCESS]: (state, { data }) => ({
    ...state,
    all: [...data],
  }),
  [types.GET_EXERCISE_SUCCESS]: (state, { data }) => ({
    ...state,
    current: data,
    all: state.all.map((exe) => {
      if (exe.id === data.id) {
        return {
          ...exe,
          participated: data.participated,
        };
      }
      return exe;
    }),
  }),
  [types.CREATE_EXERCISE_PROGRESS_SUCCESS]: (state, { data }) => ({
    ...state,
    current: { ...state.current, progress: data.progress },
    all: state.all.map((exe) => {
      if (exe.id === state.current.id) {
        return {
          ...exe,
          progress: { ...exe.progress, status: data.progress.status },
        };
      }
      return exe;
    }),
  }),
  [types.SUBMIT_ANSWER_SUCCESS]: (state, { data }) => ({
    ...state,
    current: {
      ...state.current,
      progress: data.progress,
      nextQuestion: data.nextQuestion,
      paymentRequired: data.paymentRequired,
    },
    all: state.all.map((exe) => {
      if (exe.id === state.current.id) {
        return {
          ...exe,
          progress: data.progress,
        };
      }
      return exe;
    }),
  }),
  [types.SET_EXERCISE]: (state, props) => ({
    ...state,
    current: props,
  }),
  [types.SET_OPEN_EXERCISE]: (state, open) => ({
    ...state,
    openExercise: open,
  }),
  [types.ADD_QUESTION_CACHE]: (state, { id, question }) => ({
    ...state,
    questionsCache: { ...state.questionsCache, [id]: question },
  }),
};

export default createReducer(initialState, handlers);
