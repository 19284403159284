import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { Grid, Tooltip, CircularProgress } from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import HelpIcon from '@material-ui/icons/Help';
import { sendGTMEvent, trackEvent } from '../../utils/analyticsEvent';
import Brand from './Brand';
import { Label1 } from '../../components/Text';
import { OLD_COLORS, OLD_SIZES } from '../../styles/appConsts';
import { CtaButton } from '../../components/buttons';
import { storage } from '../../utils/localStorage';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    height: '100%',
  },
  root: {
    position: 'relative',
    padding: OLD_SIZES.l,
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      padding: OLD_SIZES.m,
      paddingTop: 0,
    },
  },
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paymentItem: {
    paddingBottom: 4,
    maxWidth: 540,
  },
  fieldTitle: {
    marginLeft: OLD_SIZES.m,
    marginBottom: OLD_SIZES.xs,
  },
  fieldWrapper: {
    border: `1px solid ${OLD_COLORS.GRAY_300}`,
    borderRadius: 8,
    padding: OLD_SIZES.m,
    backgroundColor: OLD_COLORS.WHITE,
  },
  cardNumberGrid: {
    minHeight: OLD_SIZES.l,
  },
  mainNumberWrapper: {
    flex: 1,
    paddingLeft: OLD_SIZES.s,
  },
  centerWrapper: {
    display: 'flex',
  },
  pwdStripeImg: {
    marginTop: 12,
    width: 140,
  },
  sslImg: {
    marginLeft: OLD_SIZES.s,
    width: 50,
  },
  cvcWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  helpWrapper: {
    paddingRight: OLD_SIZES.s,
  },
  helpIcon: {
    fontSize: 15,
    color: '#BFBFBF',
  },
  promoSwitchButton: {
    textDecoration: 'underline',
    color: OLD_COLORS.GRAY_500,
    width: '100%',
    margin: '12px 0',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  bottomCtaBtnWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: OLD_SIZES.xxxl,
  },
  bottomCtaBtn: {
    '&:disabled': {
      backgroundColor: OLD_COLORS.BRAND_PURPLE_2,
      color: OLD_COLORS.WHITE,
    },
  },
  buttonContainer: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
    bottom: 0,
    left: 0,
    height: 120,
    width: '100%',
    backgroundColor: OLD_COLORS.WHITE,
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
    padding: '16px 32px',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  button: {
    backgroundColor: OLD_COLORS.BRAND_PURPLE_2,
    border: `1px solid ${OLD_COLORS.BRAND_PURPLE_3}`,
    borderRadius: 100,
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
    color: OLD_COLORS.WHITE,
    height: '100%',
    width: '100%',
    maxWidth: 311,
    padding: '16px 0',
    cursor: 'pointer',
    '&:disabled': {
      opacity: 0.4,
      cursor: 'default',
    },
  },
  buttonSubtitle: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '25px',
    color: OLD_COLORS.BRAND_PURPLE_1,
  },
  totalContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${OLD_COLORS.BRAND_PURPLE_2}`,
    borderRadius: 4,
    gap: 6,
    backgroundColor: OLD_COLORS.WHITE,
    margin: '15px 0',
    padding: 16,
    width: '100%',
  },
  total: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
  },
  cost: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '21px',
  },
  costStrike: {
    textDecoration: 'line-through',
  },
  discount: {
    color: OLD_COLORS.RED_400,
  },
  disclaimer: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '18px',
    color: OLD_COLORS.GREEN_600,
    marginTop: 16,
  },
  promoButton: {
    backgroundColor: 'transparent',
    border: 'none',
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '21px',
    color: OLD_COLORS.BRAND_PURPLE_1,
    marginLeft: 16,
    marginBottom: 30,
    padding: 0,
    textDecoration: 'underline',
    alignSelf: 'start',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
    },
  },
  promoContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 90px',
    gap: 6,
    width: '100%',
    marginBottom: 30,
  },
  promoInput: {
    border: `1px solid ${OLD_COLORS.GRAY_300}`,
    borderRadius: 8,
    backgroundColor: OLD_COLORS.WHITE,
    padding: 16,
  },
  applyButton: {
    backgroundColor: OLD_COLORS.WHITE,
    border: `1px solid ${OLD_COLORS.GRAY_300}`,
    borderRadius: 8,
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '21px',
    cursor: 'pointer',
  },
  promoCodeError: {
    color: OLD_COLORS.RED_500,
  },
}));

const stateColorMap = {
  default: OLD_COLORS.GRAY_400,
  valid: OLD_COLORS.GREEN_500,
  invalid: OLD_COLORS.RED_500,
};

const useOptions = () => {
  const fontSize = 18;
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: OLD_COLORS.BLACK,
          letterSpacing: '0.05em',
          '::placeholder': {
            color: OLD_COLORS.GRAY_400,
          },
        },
        invalid: {
          color: OLD_COLORS.RED_500,
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const MONTHLY_PLAN_KEY = '1';

const StripeForm = ({
  plans,
  currentPlan: plan = {},
  setPlanKey,
  clientSecret,
  getClientSecret,
  onSetupIntent,
  openNotice,
  discount,
  edit = false,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const classes = useStyles();
  const [brand, setBrand] = useState('unknown');
  const [tooltipOpen, toggleTooltip] = useState(false);
  const [localDiscount, setLocalDiscount] = useState({});

  const [cardNumberState, setCardNumberState] = useState({ state: 'unknown', color: stateColorMap.default });
  const [cardExpState, setCardExpState] = useState({ state: 'unknown', color: stateColorMap.default });
  const [cardCvcState, setCardCvcState] = useState({ state: 'unknown', color: stateColorMap.default });
  const [submitted, setSubmitted] = useState(false);
  const [updateApplePay, setUpdateApplePay] = useState(1);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [applePay, setApplePay] = useState(null);

  const getScreenState = (width) => {
    if (width < 600) {
      return 'mobile';
    }
    return 'desktop';
  };
  const screenState = getScreenState(window.innerWidth);

  const applePayButtonStyle = {
    paymentRequestButton: {
      type: 'default',
      // One of 'default', 'book', 'buy', or 'donate'
      // Defaults to 'default'

      theme: 'light-outline',
      // One of 'dark', 'light', or 'light-outline'
      // Defaults to 'dark'

      height: screenState === 'mobile' ? '40px' : '57px',
      // Defaults to '40px'. The width is always '100%'.
    },
  };
  const [applePayOptions, setApplePayOptions] = useState({ style: applePayButtonStyle });

  const isMobileWebview = useMemo(() => window.navigator.userAgent.indexOf('circles-mobile-app') > -1, [
    window.navigator.userAgent,
  ]);

  useEffect(() => {
    if (stripe && Object.keys(plan).length !== 0) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: plan.currency,
        total: {
          label: plan.metadata.planTitle,
          amount: plan.unit_amount, 
        },
      });
      // Check the availability of the Payment Request API first.
      pr.canMakePayment().then((result) => {
        if (result) {
          setApplePay(result.applePay);
          pr.on('paymentmethod', handlePaymentMethod);
          setPaymentRequest(pr);
          setUpdateApplePay(updateApplePay + 1);
          setApplePayOptions({ ...applePayOptions, paymentRequest: pr });
        }
      });
    }
  }, [stripe, plan, plans]);

  const handlePaymentMethod = async (ev) => {
    trackEvent(`handle ${applePay ? 'apple' : 'google'} pay`);
    setSubmitted(true);
    try {
      await onSetupIntent({
        paymentMethodId: ev.paymentMethod.id,
        priceId: plan.id,
        discount: localDiscount,
      });

      storage.showNewExercisePopup = true;
      ev.complete('success');
      const eventName = edit ? 'success edit payment method' : 'success add payment method';
      trackEvent(eventName);
      trackEvent(`${applePay ? 'apple' : 'google'} pay success`);

      // @ts-ignore
      if (!edit && isMobileWebview && window.ReactNativeWebView) {
        // @ts-ignore
        window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'addedPayment' }));
      }
    } catch (err) {
      console.warn('handlePaymentMethod', err);
      setSubmitted(false);
      ev.complete('fail');
    }
  };

  useEffect(() => {
    if (!edit) {
      setLocalDiscount(discount);
      if (discount?.code) {
        setPromoCode(discount.code);
      }
    }
  }, [discount]);

  useEffect(() => {
    if (plans && Object.keys(plan).length === 0 && plans[MONTHLY_PLAN_KEY]) {
      setPlanKey(MONTHLY_PLAN_KEY);
    }
    if (paymentRequest && plans) {
      const discountPercentageLocal = localDiscount?.coupon?.duration ? localDiscount.coupon.percentOff : 0;
      const amount = plan.amount * (1 - discountPercentageLocal / 100);
      updatePaymentRequest(amount);
    }
  }, [plan, plans]);

  const updatePaymentRequest = (amount = plan?.amount) => {
    if (amount) {
      if (paymentRequest) {
        paymentRequest.update({
          currency: plan.currency,
          total: {
            label: plan.metadata.planTitle,
            amount: amount,
          },
        });
        setUpdateApplePay(updateApplePay + 1);
        setApplePayOptions({ ...applePayOptions, paymentRequest });
      }
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setSubmitted(true);

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
      },
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log(result.error.message);
      openNotice({ title: result.error.message, severity: 'error' });
      const eventName = edit ? 'error edit payment method' : 'error add payment method';
      trackEvent(eventName, { error: result.error.message });
      setSubmitted(false);
    } else {
      // The payment has been processed!
      if (result.setupIntent.status === 'succeeded') {
        storage.showNewExercisePopup = true;
        storage.showCalendlyGroupLabPopup = true;
        const eventName = edit ? 'success edit payment method' : 'success add payment method';
        trackEvent(eventName);
        const { payment_method } = result.setupIntent;
        sendGTMEvent({
          event: 'purchase',
          GA_event_category: 'subscription',
          GA_event_action: 'purchase',
          GA_event_label: 'credit card', // e.g. “credit card”, “payapl”
          ecommerce: {
            currencyCode: plan.currency, // the currency code, e.g. “USD”
            purchase: {
              actionField: {
                id: payment_method, // e.g. Transaction id
                revenue: price * (1 - discountPercentage / 100),
                coupon: localDiscount?.code, // Coupon code, if applied
              },
              products: [
                {
                  name: 'Monthly Subscription', // product description
                  id: plan.id, // The id of this product
                  price: price,
                },
              ],
            },
          },
        });
        onSetupIntent({ paymentMethodId: payment_method, priceId: plan.id, discount: localDiscount }).catch(() => {
          getClientSecret();
          setSubmitted(false);
        });
      }
    }
  };

  const handleChange = (currentState, setMethod, complete, error) => {
    if (complete && currentState !== 'valid') {
      return setMethod({ state: 'valid', color: stateColorMap.valid });
    }
    if (error && currentState !== 'invalid') {
      return setMethod({ state: 'invalid', color: stateColorMap.invalid });
    }
    if (currentState !== 'unknown') {
      setMethod({ state: 'unknown', color: stateColorMap.default });
    }
  };

  const submitDisabled =
    !clientSecret ||
    cardNumberState.state !== 'valid' ||
    cardExpState.state !== 'valid' ||
    cardCvcState.state !== 'valid' ||
    submitted;

  const { amount } = plan;
  const discountPercentage = localDiscount?.coupon?.duration ? localDiscount.coupon.percentOff : 0;
  const price = amount ? Math.floor(amount / 100) : 0;

  return (
    <div
      className={classes.rootContainer}
      onClick={() => {
        if (tooltipOpen) {
          toggleTooltip(false);
        }
      }}
    >
      <Grid container justifyContent="center" className={classes.root}>
        {submitted && (
          <div className={classes.loaderContainer}>
            <CircularProgress color="primary" />
          </div>
        )}
        <Grid item xs={12} className={classes.paymentItem}>
          <Label1 className={classes.fieldTitle}>Credit card number</Label1>
          <div className={classes.fieldWrapper} style={{ borderColor: cardNumberState.color }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.cardNumberGrid}
            >
              <div className={classes.centerWrapper}>
                <Brand brand={brand} />
              </div>
              <div className={classes.mainNumberWrapper}>
                <CardNumberElement
                  options={options}
                  onChange={(event) => {
                    const { brand: evBrand, complete, error } = event;
                    handleChange(cardNumberState.state, setCardNumberState, !!complete, !!error);
                    if (evBrand !== brand) {
                      setBrand(evBrand);
                    }
                    if (!!complete) {
                      trackEvent('payment card number');
                    }
                  }}
                />
              </div>
              <div className={classes.centerWrapper}>
                {cardNumberState.state === 'valid' && (
                  <CheckCircleOutlineOutlinedIcon style={{ color: OLD_COLORS.GREEN_500 }} />
                )}
              </div>
            </Grid>
          </div>
          <img className={classes.pwdStripeImg} src={`${ASSETS_BASE_DIR}/payment/powered-by-stripe.jpg`} />
          <img className={classes.sslImg} src={`${ASSETS_BASE_DIR}/payment/ssl-encryption.jpg`} />
        </Grid>
        <Grid container item xs={12} className={classes.paymentItem}>
          <Grid item style={{ flex: 1 }}>
            <Label1 className={classes.fieldTitle}>Expiration date</Label1>
            <div className={classes.fieldWrapper} style={{ borderColor: cardExpState.color }}>
              <CardExpiryElement
                options={options}
                onChange={(event) => {
                  const { complete, error } = event;
                  handleChange(cardExpState.state, setCardExpState, !!complete, !!error);
                  if (!!complete) {
                    trackEvent('payment card exp');
                  }
                }}
              />
            </div>
          </Grid>
          <Grid item style={{ width: 24 }} />
          <Grid item style={{ flex: 1 }}>
            <div className={classes.cvcWrapper}>
              <Label1 className={classes.fieldTitle}>Cvc</Label1>
              <div className={classes.helpWrapper}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement="bottom-end"
                  arrow
                  open={tooltipOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="CVC code on the back of your card"
                >
                  <div onClick={() => toggleTooltip(!tooltipOpen)}>
                    <HelpIcon className={classes.helpIcon} />
                  </div>
                </Tooltip>
              </div>
            </div>
            <div className={classes.fieldWrapper} style={{ borderColor: cardCvcState.color }}>
              <CardCvcElement
                options={options}
                onChange={(event) => {
                  const { complete, error } = event;
                  handleChange(cardCvcState.state, setCardCvcState, !!complete, !!error);
                  if (!!complete) {
                    trackEvent('payment card cvc');
                  }
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      {edit && (
        <div className={classes.bottomCtaBtnWrapper}>
          <CtaButton
            className={classes.bottomCtaBtn}
            disabled={submitDisabled}
            onClick={(e) => {
              if (!submitDisabled) {
                trackEvent('click payment update');
                handleFormSubmit(e);
              }
            }}
          >
            Update Payment Method
          </CtaButton>
        </div>
      )}
    </div>
  );
};
export default StripeForm;
