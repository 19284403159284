import { createAction, createReducer } from '../../utils';
import { types as authTypes } from './auth';

// Types
export const types = {
  SET_DIALOG_PROPS: 'root/SET_DIALOG_PROPS',
  SET_OVERLAY: 'root/SET_OVERLAY',
  UPDATE_LAST_FOCUS: 'root/UPDATE_LAST_FOCUS',
  SET_PAYMENT: 'root/SET_PAYMENT',
  SET_CALENDLY: 'root/SET_CALENDLY',
  SET_LOGGED_WITH_TOKEN: 'root/SET_LOGGED_WITH_TOKEN',
  SET_IN_APP_LINK_DATA: 'root/SET_IN_APP_LINK_DATA',
};

// Actions
export const setDialogProps = (props) => createAction(types.SET_DIALOG_PROPS, { props });
export const setOverlay = (props) => createAction(types.SET_OVERLAY, props);
export const setPaymentPopUpData = (props) => createAction(types.SET_PAYMENT, props);
export const setCalendlyPopUpData = (props) => createAction(types.SET_CALENDLY, props);
export const setLoggedWithToken = (loginState) => createAction(types.SET_LOGGED_WITH_TOKEN, loginState);
export const setInAppLinkData = (inAppLinkData) => createAction(types.SET_IN_APP_LINK_DATA, { data: inAppLinkData });

// Reducer
const initialState = {
  dialogProps: { open: false },
  overlay: false,
  lastFocus: 0,
  paymentPopUpData: {
    open: false,
    skipCallback: () => null,
    onClose: () => null,
    addPaymentCallback: () => null,
  },
  calendlyPopUpData: {
    url: '',
    open: false,
    groupLab: false,
    skipCallback: () => null,
    onClose: () => null,
  },
  loggedWithToken: false,
  inAppLinkData: null,
};

const handlers = {
  [types.SET_CALENDLY]: (state, props) => {
    return {
      ...state,
      calendlyPopUpData: {
        ...initialState.calendlyPopUpData,
        ...props,
      },
    };
  },
  [types.SET_PAYMENT]: (state, props) => {
    return {
      ...state,
      paymentPopUpData: {
        ...initialState.paymentPopUpData,
        ...props,
      },
    };
  },
  [types.SET_DIALOG_PROPS]: (state, { props }) => ({
    ...state,
    dialogProps: { ...props },
  }),
  [types.SET_OVERLAY]: (state, props) => ({
    ...state,
    overlay: props,
  }),
  [types.UPDATE_LAST_FOCUS]: (state) => {
    const lastFocus = Date.now();
    return { ...state, lastFocus: lastFocus - (lastFocus % 60000) };
  },
  [types.SET_LOGGED_WITH_TOKEN]: (state, loginState) => ({
    ...state,
    loggedWithToken: loginState,
  }),
  [types.SET_IN_APP_LINK_DATA]: (state, { data }) => ({
    ...state,
    inAppLinkData: data,
  }),
  [authTypes.LOGOUT_SUCCESS]: (state) => ({
    ...state,
    loggedWithToken: false,
  }),
};

export default createReducer(initialState, handlers);
