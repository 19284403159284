import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, List, Card, CardActionArea, CardContent } from '@material-ui/core';
import { trackEvent } from '../../utils/analyticsEvent';
import { SubHeader, Label1, Paragraph } from '../../components/Text';
import { OLD_COLORS } from '../../styles/appConsts';
import { NavigationContext } from '../../components/navigator/index';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f7f8f9',
    overflow: 'hidden',
    flexWrap: 'nowrap',
    maxHeight: 'calc(100vh - 108px)',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  card: {
    overflow: 'visible',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    boxShadow: '0 10px 40px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: theme.spacing(1.5),
    '& button': {
      borderRadius: theme.spacing(1.5),
      overflow: 'hidden',
    },
  },
  description: {
    overflow: 'hidden',
    textOverflow: 'clip',
    fontWeight: '400',
    color: OLD_COLORS.BRAND_PURPLE_2,
  },
  chip: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    width: '18ch',
    textAlign: 'center',
  },
  primary: {
    color: theme.palette.primary.main,
  },
}));

function MyGroups({ myGroups, getPersonalGroups, updateNextSessionTime, openNotice, resetGroupSpace }) {
  const navCtx = React.useContext(NavigationContext);
  const classes = useStyles();

  React.useEffect(() => {
    // console.log('MyGroups updateNextSessionTime()')
    const id = setInterval(updateNextSessionTime, 15000);

    if (myGroups.length === 0) {
      getPersonalGroups().then(({ payload: { data } }) => {
        if (data.length === 0) {
          openNotice({ title: 'You have no active groups' });
        }
      });
    }

    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <Grid className={classes.root} container direction="column">
      <List className={classes.list} dense>
        {myGroups.map((g, i) => (
          <Card
            className={classes.card}
            key={i}
            raised
            onClick={() => {
              resetGroupSpace();
              trackEvent('click enter group', { groupId: g.groupInstanceId, groupTitle: g.title, source: 'mygroups' });
              navCtx.pushScreen(`group/${g.groupInstanceId}`);
            }}
          >
            <CardActionArea>
              <CardContent>
                <SubHeader gutterBottom align="center">
                  {g.title}
                </SubHeader>
                <Label1 gutterBottom align="center" style={{ fontWeight: '400' }}>
                  with {g.leaderName}
                </Label1>
                <Paragraph align="center" className={classes.description}>
                  {g.nextStart < Date.now()
                    ? `Active session until ${new Date(g.nextEnd).toLocaleString([], {
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: '2-digit',
                      })}`
                    : `Next session ${new Date(g.nextStart).toLocaleString([], {
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: '2-digit',
                      })}`}
                </Paragraph>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </List>
    </Grid>
  );
}

MyGroups.propTypes = {
  myGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  getPersonalGroups: PropTypes.func.isRequired,
  updateNextSessionTime: PropTypes.func.isRequired,
};

export default MyGroups;
