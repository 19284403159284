import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Favorite, FavoriteBorder } from '@material-ui/icons';
import { OLD_COLORS } from '../styles/appConsts';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  likeIcon: {
    color: OLD_COLORS.RED_900, // TODO: NEW COLOR
  },
  notLikeIcon: {
    color: OLD_COLORS.BLACK,
  },
}));

export default function LikeToggleButton({ liked, toggleLike, justify = 'flex-start' }) {
  const classes = useStyles();
  const [localLiked, setLocalLiked] = useState(liked);
  useEffect(() => {
    setLocalLiked(liked);
  }, [liked]);

  return (
    <div
      className={classes.root}
      style={{ justifyContent: justify }}
      onClick={() => {
        if (toggleLike) {
          setLocalLiked(!liked);
          toggleLike();
        }
      }}
    >
      {localLiked && <Favorite className={classes.likeIcon} />}
      {!localLiked && <FavoriteBorder className={classes.notLikeIcon} />}
    </div>
  );
}
