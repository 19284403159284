import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { OLD_COLORS, OLD_SIZES } from '../../styles/appConsts';
import { Header, Paragraph, Label2, Link, CTA } from '../Text';
import { CtaButton } from '../buttons';
import clsx from 'clsx';
import QuestionCard from './QuestionCard';
import TextAnswer from './questionAnswers/TextAnswerHorizontal';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    backgroundColor: OLD_COLORS.SALMON,
    position: 'relative',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
    scrollBehavior: 'smooth',
  },
  bkgImage: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  content: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: OLD_SIZES.xl,
  },
  betaDiv: {
    marginTop: OLD_SIZES.s,
    backgroundColor: 'rgba(255, 255, 255, 0.61)',
    borderRadius: 4,
    padding: `${OLD_SIZES.xs}px ${OLD_SIZES.s}px`,
  },
  betaText: {
    fontSize: 12,
  },
  mainContent: {
    zIndex: 2,
    marginTop: OLD_SIZES.l,
    textAlign: 'center',
    color: OLD_COLORS.WHITE,
    background: 'rgba(255, 255, 255, 0.3)',
    borderRadius: 8,
    padding: '13px 22px 22px',
  },
  mainContentImage: {
    marginBottom: OLD_SIZES.s,
  },
  mainContentText: {
    marginTop: OLD_SIZES.m,
  },
  subheader: {
    color: OLD_COLORS.WHITE,
    fontWeight: '600',
    textAlign: 'center',
    fontSize: 18,
    lineHeight: '25px',
  },
  tag: {
    color: OLD_COLORS.WHITE,
    fontWeight: '500',
    textAlign: 'center',
    fontSize: 14,
    lineHeight: '19px',
  },
  paragraph: {
    color: OLD_COLORS.WHITE,
    fontWeight: 'normal',
    textAlign: 'center',
    fontSize: 14,
    lineHeight: '19px',
  },
  button: {
    color: OLD_COLORS.WHITE,
    cursor: 'pointer',
    marginTop: OLD_SIZES.m,
    textDecorationLine: 'underline',
    paddingTop: OLD_SIZES.xl,
  },
  bottom: {
    position: 'relative',
    flex: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: OLD_SIZES.xl,
    minHeight: 260,
  },
  bottomText: {
    marginTop: OLD_SIZES.l,
    color: OLD_COLORS.SALMON,
    zIndex: 2,
    fontWeight: '600',
    textAlign: 'center',
    fontSize: 18,
    lineHeight: '25px',
  },
  bottomButton: {
    width: '100%',
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  ctaButton: {
    marginTop: OLD_SIZES.l,
    zIndex: 2,
    width: '100%',
    backgroundColor: OLD_COLORS.SALMON,
    color: OLD_COLORS.WHITE,
    '&:hover': {
      backgroundColor: OLD_COLORS.SALMON,
      color: OLD_COLORS.WHITE,
    },
  },
  bkgBottom: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  answersContainer: {
    paddingTop: OLD_SIZES.xxl,
    backgroundColor: OLD_COLORS.GRAY_400,
    zIndex: 1,
  },
  answersTitle: {
    textAlign: 'center',
  },
  answersTile: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    paddingRight: OLD_SIZES.l / 2,
    marginBottom: OLD_SIZES.l,
  },
  answersInnerContainer: {
    overflow: 'auto',
    display: 'flex',
    paddingLeft: OLD_SIZES.l / 2,
    paddingBottom: OLD_SIZES.l / 2,
    paddingTop: OLD_SIZES.s,
  },
}));

const colorPalette = ['#3399FF', '#FCC787', '#F5BAC4'];

export default function FinalScreen({ summaryInfo, loadSummaryInfo, onSubmit, onEditAnswer }) {
  const classes = useStyles();
  const answersRef = useRef(null);

  const onSeeAnswers = () => {
    loadSummaryInfo().then(() => {
      answersRef.current.scrollTop = answersRef.current.scrollHeight;
    });
  };

  let questionsCount = 0;
  return (
    <div ref={answersRef} className={classes.root}>
      <img src={`${ASSETS_BASE_DIR}/qa-background-2.svg`} className={classes.bkgImage} />
      <div className={classes.content}>
        <div className={classes.mainContent}>
          <img className={classes.mainContentImage} src={`${ASSETS_BASE_DIR}/exercise/final-page-icon.svg`} alt='' />
          <Header className={classes.subheader}>You did amazing!</Header>
          <Paragraph className={classes.tag}>Challenges are always easier to face together.</Paragraph>
          <Label2 className={clsx(classes.paragraph, classes.mainContentText)}>
            Remember - you can access your saved answers on your area anytime!
          </Label2>
          <Link className={clsx(classes.paragraph, classes.button)} onClick={onSeeAnswers}>
            See Answers
          </Link>
        </div>
      </div>
      <div className={classes.bottom}>
        <div className={classes.bottomButton}>
          <CtaButton disableElevation className={classes.ctaButton} onClick={onSubmit}>
            Done
          </CtaButton>
        </div>
        <img src={`${ASSETS_BASE_DIR}/exercise/summary-wave.png`} className={classes.bkgBottom} />
      </div>
      {summaryInfo.length > 0 && (
        <div className={classes.answersContainer}>
          <CTA className={classes.answersTitle}>Your insights are always with you</CTA>
          <div className={classes.answersInnerContainer}>
            {summaryInfo.map((card, i) => {
              if (card.question) {
                questionsCount++;
              }
              return (
                <div key={i} className={classes.answersTile}>
                  {card.question ? (
                    <QuestionCard {...card} color={card.partColor || colorPalette[questionsCount % 3]} />
                  ) : (
                    <TextAnswer
                      mode={'full'}
                      name={card.meta.name}
                      picture={card.meta.picture}
                      answer={card.answer.value}
                      me={card.myself}
                      liked={!card.myself}
                      onEdit={() => onEditAnswer(card.questionId)}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
