import React, { useState, MouseEvent, useMemo } from 'react';
import { Button, getColor, Icon, Link, Text } from '@7chairs/frontend';
import { makeStyles } from '@material-ui/core/styles';
import { ClickAwayListener, Fade, Popper } from '@material-ui/core';
import { buildShareUrl, isInternetExplorer, SHARE_SITES } from '../../../utils/calendar';
import {sendGTMEvent, trackEvent} from '../../../utils/analyticsEvent';
import { useSelector } from 'react-redux';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  anchor: {
    width: '50%',
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:last-of-type': {
      border: 'none',
    },
    borderRight: `1px solid ${getColor('BLACK_20')}`,
    '& a': {
      textDecoration: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  anchorsWrapper: {
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    padding: '8px',
    backgroundColor: getColor('WHITE_100'),
    opacity: '0.9',
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1), -1px 0px 2px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: -4,
      left: '80%',
      marginLeft: -5,
      borderWidth: 5,
      borderStyle: 'solid',
      borderColor: 'transparent white white transparent',
      borderRadius: 2,
      transform: 'rotate(-135deg)',
    },
  },
  link: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '28px',
    width: '295px',
  },
}));

enum Calendar {
  Apple = 'Apple',
  Google = 'Google',
}

interface CalendarEvent {
  description: string;
  duration: number;
  endDatetime: string;
  startDatetime: string;
  title: string;
  recur: string;
}

const getCalendarEvent = (
  groupTitle: string,
  groupLeaderName: string,
  meetingDuration: number,
  nextSessionTimestamp: number
): CalendarEvent => {
  const startDatetime = moment(nextSessionTimestamp).utc();
  const endDatetime = moment(nextSessionTimestamp).utc().add(meetingDuration, 'm');
  const duration = moment.duration(endDatetime.diff(startDatetime)).asHours();

  return {
    description: `${groupTitle} with ${groupLeaderName}`,
    duration,
    endDatetime: endDatetime.format('YYYYMMDDTHHmmssZ'),
    startDatetime: startDatetime.format('YYYYMMDDTHHmmssZ'),
    title: `Session with ${groupLeaderName}`,
    recur: `RRULE:FREQ=WEEKLY;BYDAY=${startDatetime.format('dd').toUpperCase()};INTERVAL=1;COUNT=24`,
  };
};

interface AddToCalendarProps {
  groupInstanceId: string;
}

export const AddToCalendar = ({ groupInstanceId }: AddToCalendarProps) => {
  const classes = useStyles();

  // @ts-ignore
  const userGroups = useSelector((state) => state.groups.myGroups);
  // @ts-ignore
  const nextSession = useSelector((state) => state.groupSpace.nextSession);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const id = open && Boolean(anchorEl) ? 'transition-popper' : undefined;

  const calendarEvent = useMemo(() => {
    const { title, leaderName, schedule } = userGroups.find(
      (userGroup) => userGroup.groupInstanceId === groupInstanceId
    );
    return getCalendarEvent(title, leaderName, schedule[0].duration, nextSession);
  }, [groupInstanceId, userGroups, nextSession]);

  const onAddToCalendarClick = (event: MouseEvent<HTMLButtonElement>) => {
    sendGTMEvent({
      GA_event_category: "platform",
      GA_event_action: "add meeting to calendar clicked",
      GA_event_label: "add meeting to calendar clicked",
      GA_event_detail: "NA",
    });
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClickAway = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCalendarButtonClick = (event: MouseEvent<HTMLAnchorElement>, calendar: Calendar) => {
    event.preventDefault();
    trackEvent('click add to calendar', { name: calendar });
    const filename = 'Circles-meeting';
    const url = event.currentTarget.getAttribute('href');
    if (url.startsWith('BEGIN')) {
      const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });
      if (isInternetExplorer()) {
        // @ts-ignore
        window.navigator.msSaveOrOpenBlob(blob, `${filename}.ics`);
      } else {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', `${filename}.ics`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      window.open(url, '_blank', 'noreferrer');
    }
  };

  return (
    <>
      <div className={classes.link}>
        <Icon.Outline.Calendar color={'DARK_PURPLE_100'}/>
        <Link onClick={onAddToCalendarClick}>Add to calendar</Link>
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        modifiers={{
          offset: {
            enabled: true,
            offset: '-20, 10',
          },
        }}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <div className={classes.anchorsWrapper}>
                <div className={classes.anchor} onClick={handleClickAway}>
                  <a
                    key={SHARE_SITES.ICAL}
                    onClick={(event) => handleCalendarButtonClick(event, Calendar.Apple)}
                    href={buildShareUrl(calendarEvent, SHARE_SITES.ICAL)}
                  >
                    <img src={`${ASSETS_BASE_DIR}/groupSpace/apple-calendar.svg`} alt='apple calendar' />
                    <Text variant='body' color='BLACK_80'>
                      {Calendar.Apple}
                    </Text>
                  </a>
                </div>
                <div className={classes.anchor} onClick={handleClickAway}>
                  <a
                    key={SHARE_SITES.GOOGLE}
                    onClick={(event) => handleCalendarButtonClick(event, Calendar.Google)}
                    href={buildShareUrl(calendarEvent, SHARE_SITES.GOOGLE)}
                    target='_blank'
                  >
                    <img src={`${ASSETS_BASE_DIR}/groupSpace/google-calendar.svg`} alt='google calendar' />
                    <Text variant='body' color='BLACK_80'>
                      {Calendar.Google}
                    </Text>
                  </a>
                </div>
              </div>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};
