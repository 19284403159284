import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { OLD_COLORS } from '../../styles/appConsts';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  timer: { position: 'relative' },
  textWrapper: {
    textAlign: 'center',
    width: 'min-content',
    whiteSpace: 'normal',
    margin: '0 auto',
    backgroundImage: `url(${ASSETS_BASE_DIR}/exercise/timer-background.svg)`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  text: {
    zIndex: 4,
    fontFamily: 'Kalam',
    fontSize: 16,
    lineHeight: '16px',
    color: OLD_COLORS.BRAND_PURPLE_2,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '20px',
    paddingTop: '50%',
    paddingBottom: '50%',
  },
  subtitle: {
    color: OLD_COLORS.WHITE,
    opacity: '0.5',
  },
}));

const TimerComponent = ({ label, description }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.timer}>
        <div className={classes.textWrapper}>
          <div className={classes.text}>{label}</div>
        </div>
      </div>
    </div>
  );
};

export default TimerComponent;
