import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Label2 } from '../Text';
import { OLD_SIZES, OLD_COLORS } from '../../styles/appConsts';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  barItem: {
    marginTop: OLD_SIZES.m,
    display: 'flex',
    alignItems: 'flex-end',
  },
  barImage: {
    width: 60,
    height: 40,
    marginRight: OLD_SIZES.m,
    borderRadius: 4,
  },
  barContent: {
    flexDirection: 'column',
    width: '100%',
  },
  barSlideDiv: {
    marginTop: OLD_SIZES.s,
    display: 'flex',
    width: '100%',
  },
  barSlide: {
    borderRadius: '100px',
    marginRight: OLD_SIZES.s,
    position: 'relative',
    overflow: 'hidden',
    '&:after': {
      transition: 'width .2s',
      content: "''",
      width: '100%',
      height: '100%',
      left: 0,
      position: 'absolute',
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.12)',
      borderRadius: 'inherit',
    },
    '&:before': {
      transition: 'background .2s',
      content: "''",
      width: '110%',
      height: '100%',
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      background: `url(${ASSETS_BASE_DIR}/horizontal-bar-stripes.png) repeat 100% center`,
    },
  },
}));

export default function ({ data, labelStyle = {}, amountStyle = {}, barColor = OLD_COLORS.BRAND_PURPLE_2 }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {data.map((bar, idx) => (
        <div key={`bar-${idx}`} className={classes.barItem}>
          {bar.picture && <img src={bar.picture} className={classes.barImage} />}
          <div className={classes.barContent}>
            {bar.label && <Label2 style={labelStyle}>{bar.label}</Label2>}
            <div className={classes.barSlideDiv}>
              <div
                className={classes.barSlide}
                style={{ width: `calc(${bar.width}% - 28px)`, backgroundColor: barColor }}
              />
              <Label2 style={amountStyle}>
                {bar.amount}
                {bar.unit || ''}
              </Label2>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
