import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, useLocation } from 'react-router-dom';
import { setCalendlyPopUpData, setDialogProps } from '../../store/ducks/root';
import { openNotice } from '../../store/ducks/notice';
import {
  getClientSecret,
  updatePaymentMethod,
  getPaymentDetails,
  cancelSubscriptionRequest,
  cancelSubscriptionContactUserRequest,
  canCancelSubscriptionNextMonthFree,
  cancelSubscriptionNextMonthFree,
  reenableSubscription,
  closeSuccessPopup,
  closeSuccessEdit,
} from '../../store/ducks/payment';
import Payment from './Payment';
import EditPayment from './EditPayment';
import CancelSubscription from './CancelSubscription';
import Confirmation from './Confirmation';
import { StayWithUs } from './ManageSubscriptionStatus';
import CancelSubscriptionConfirmation from './CancelSubscriptionConfirmation';

function PaymentContainer(props) {
  const location = useLocation();
  const baseUrl = '/payment';

  // mobile app still opens webview to /payment instead of /purchase, so need to check it here
  if (props.isVoicesPurchase) {
    // @ts-ignore
    window.location = `${WEB_APP_URL}purchase`;
    return null;
  }

  if (location.pathname === `${baseUrl}/add`) {
    const token = window.localStorage.authToken;
    // @ts-ignore
    window.location = `${PAYMENT_URL}/?accessToken=${token}`;
    return null;
  }

  return (
    <Switch>
      <Route path={`${baseUrl}/edit`}>
        <EditPayment {...props} />
      </Route>
      <Route path={`${baseUrl}/cancel/stay-with-us`}>
        <StayWithUs />
      </Route>
      <Route path={`${baseUrl}/cancel`}>
        <CancelSubscription {...props} />
      </Route>
      <Route path={`${baseUrl}/cancel-confirmation`}>
        <CancelSubscriptionConfirmation />
      </Route>
      <Route path={`${baseUrl}/confirmation`}>
        <Confirmation {...props} />
      </Route>
      <Route path={baseUrl}>
        <Payment {...props} />
      </Route>
    </Switch>
  );
}

function mapState(state) {
  return {
    plans: state.payment.plans,
    currentPlan: state.payment.currentPlan,
    discount: state.payment.discount,
    clientSecret: state.payment.clientSecret,
    cardDetails: state.payment.cardDetails,
    billing: state.payment.billing,
    displaySuccessPopup: state.payment.displaySuccessPopup,
    displaySuccessEdit: state.payment.displaySuccessEdit,
    isPayment: state.account.isPayment,
    isVoicesPurchase: state.account.isVoicesPurchase,
    cancelPaymentRequest: state.account.cancelPaymentRequest,
    myGroups: state.groups.myGroups,
    loggedWithToken: state.root.loggedWithToken,
  };
}

export default connect(mapState, {
  getClientSecret,
  updatePaymentMethod,
  getPaymentDetails,
  cancelSubscriptionRequest,
  cancelSubscriptionContactUserRequest,
  canCancelSubscriptionNextMonthFree,
  cancelSubscriptionNextMonthFree,
  reenableSubscription,
  closeSuccessPopup,
  closeSuccessEdit,
  openNotice,
  setDialogProps,
  setCalendlyPopUpData,
})(PaymentContainer);
