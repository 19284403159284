import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Icon, Text } from '@7chairs/frontend';
import { GroupSpaceTab } from './GroupSpaceContainer';
import { NavigationContext } from '../../components/navigator';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '96px',
  },
  tab: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '6px',
    cursor: 'pointer',
  },
}));

interface TabsProps {
  activeTab: GroupSpaceTab;
  groupInstanceId: string;
}

export const Tabs = ({ activeTab, groupInstanceId }: TabsProps) => {
  const classes = useStyles();
  const navCtx = useContext(NavigationContext);

  const onTabClick = (tab: GroupSpaceTab) => {
    if (tab === activeTab) {
      return;
    }
    const adjustedGroupInstanceId = groupInstanceId || 'undefined';
    switch (tab) {
      case GroupSpaceTab.GroupSpace:
        navCtx.replaceScreen(`/group/${adjustedGroupInstanceId}`);
        return;
      case GroupSpaceTab.Conversations:
        navCtx.replaceScreen(`/conversations/${adjustedGroupInstanceId}`);
        return;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.tab} onClick={() => onTabClick(GroupSpaceTab.GroupSpace)}>
        <Icon.Fill.CircleSpace
          size={28}
          color={activeTab === GroupSpaceTab.GroupSpace ? 'DARK_PURPLE_100' : 'BLACK_40'}
        />
        <Text variant='label' color={activeTab === GroupSpaceTab.GroupSpace ? 'DARK_PURPLE_100' : 'BLACK_40'}>
          Home
        </Text>
      </div>
      <div className={classes.tab} onClick={() => onTabClick(GroupSpaceTab.Conversations)}>
        <Icon.Fill.ChatSpace
          size={28}
          color={activeTab === GroupSpaceTab.Conversations ? 'DARK_PURPLE_100' : 'BLACK_40'}
        />
        <Text variant={'label'} color={activeTab === GroupSpaceTab.Conversations ? 'DARK_PURPLE_100' : 'BLACK_40'}>
          Chat
        </Text>
      </div>
    </div>
  );
};
