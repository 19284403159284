import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop, Box, Modal, TextareaAutosize } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoodIcon from '@material-ui/icons/Mood';
import moment from 'moment';
import { Rating } from './components/Rating';
import { Confirmation } from './components/Confirmation';
import { Label1, Label2, SubHeader } from '../Text';
import { CtaButton, LinkButton } from '../buttons';
import { OLD_COLORS, OLD_SIZES } from '../../styles/appConsts';
import { trackEvent } from '../../utils/analyticsEvent';
import { getSurveyData, postSurveyData } from '../../store/ducks/groupSpace';
import { omit } from 'lodash';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  wrapper: {
    outline: 'none',
    alignItems: 'center',
    background: OLD_COLORS.WHITE,
    borderRadius: theme.typography.pxToRem(OLD_SIZES.s),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: '1.5rem',
    textAlign: 'center',
    transform: 'translate(25vw, 20vh)',
    width: '50vw',
    [theme.breakpoints.down('sm')]: {
      transform: 'translate(10vw, 10vh)',
      width: '80vw',
    },
  },
  closeIconContainer: {
    cursor: 'pointer',
    margin: '-0.5rem -0.5rem 0 0',
    placeSelf: 'flex-end',
  },
  moodIconContainer: {
    color: OLD_COLORS.GRAY_200,
    fontSize: theme.typography.pxToRem(34),
    marginBottom: theme.typography.pxToRem(OLD_SIZES.s / 2),
  },
  questionsContainer: {
    height: '40vh',
    overflow: 'scroll',
    maxWidth: '45vw',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '70vw',
    },
  },
  questionContainer: {
    marginBottom: theme.typography.pxToRem(OLD_SIZES.s),
    marginTop: theme.typography.pxToRem(OLD_SIZES.xl),
  },
  scaleContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.typography.pxToRem(OLD_SIZES.s),
  },
  scaleDescriptionContainer: {
    marginBottom: theme.typography.pxToRem(OLD_SIZES.s),
  },
  submitButton: {
    background: OLD_COLORS.VIBRANT_BLUE_2,
    maxWidth: theme.typography.pxToRem(175),
    marginTop: theme.typography.pxToRem(OLD_SIZES.s),
    minHeight: theme.typography.pxToRem(OLD_SIZES.xxl),
    padding: `${theme.typography.pxToRem(OLD_SIZES.s)} ${theme.typography.pxToRem(OLD_SIZES.m)}`,
    '&:hover': {
      background: OLD_COLORS.VIBRANT_BLUE_2,
    },
  },
  closeButton: {
    marginTop: theme.typography.pxToRem(OLD_SIZES.s),
    color: OLD_COLORS.GREEN_500,
    '&:hover': {
      background: 'transparent',
    },
  },
  submitButtonContainer: {
    marginBottom: '1rem',
  },
  titleContainer: {
    marginBottom: theme.typography.pxToRem(OLD_SIZES.s),
  },
  textArea: {
    borderColor: OLD_COLORS.VIBRANT_BLUE_3,
    width: '30vw',
    [theme.breakpoints.down('md')]: {
      width: '300px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '200px',
    },
  },
}));

const MAX_GBS_VALUE = 3; // popup can be shown only 3 times within same week

enum Steps {
  Survey = 'Survey',
  Confirmation = 'Confirmation',
}

interface Question {
  maxPointsText?: string;
  minPointsText?: string;
  question: string;
  scale?: number;
  type: 'rate' | 'text';
}

export const Survey = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const groupId = useSelector(
    // @ts-ignore
    (state) => (state.groupSpace && state.groupSpace.groupInfo && state.groupSpace.groupInfo.id) || ''
  );
  // @ts-ignore
  const userRole = useSelector((state) => state.account.role);

  const [step, setStep] = useState<Steps>(Steps.Survey);
  const [answers, setAnswers] = useState<Record<number, { type: 'rate' | 'text'; value: number | string }>>({});
  const [buttonText, setButtonText] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [pieceId, setPieceId] = useState<string>('');
  const [questions, setQuestions] = useState<Question[]>([]);
  const [title, setTitle] = useState<string>('');

  const handleOpen = () => {
    trackEvent('view group belonging survey', { groupId });
    setAnswers({});
    setOpen(true);
  };

  const handleClose = ({ afterSubmit }) => {
    // eslint-disable-next-line no-unused-expressions
    !afterSubmit && trackEvent('click group belonging survey close', { groupId });
    setAnswers({});
    setOpen(false);
    setStep(Steps.Survey);
    saveClosingActionInLocalStorage({ afterSubmit });
  };

  const onClick = () => {
    if (!isSubmitEnabled) {
      return;
    }

    dispatch(postSurveyData({ groupId, pieceId, data: { answers } })).then(() => {
      trackEvent('click group belonging survey submit', { groupId });
    });
    setStep(Steps.Confirmation);
  };

  const onAnswer = ({ index, type, value }: { index: number; type: 'rate' | 'text'; value: number | string }) => {
    if (type === 'rate' && typeof value === 'number') {
      trackEvent('click group belonging survey rate', { groupId, rate: value });
      if (value === ((answers[index] && answers[index].value) || 0)) {
        return;
      }
    }

    if (type === 'text' && typeof value === 'string' && !value) {
      setAnswers((prevState) => omit(prevState, index));
      return;
    }

    setAnswers((prevState) => ({ ...prevState, [index]: { type, value } }));
  };

  const localStorageGBSKey = useMemo(() => `groupBelongingSurvey@${groupId}`, [groupId]);

  const isApiCallAllowed = useMemo(() => {
    const GBSItem = JSON.parse(localStorage.getItem(localStorageGBSKey));
    return !GBSItem || moment().isoWeek() !== moment(GBSItem.date).isoWeek() || GBSItem.value < MAX_GBS_VALUE;
  }, [localStorageGBSKey]);

  const isSubmitEnabled = useMemo(() => Boolean(Object.values(answers).find((el) => el.type === 'rate')), [answers]);

  const questionsComponents = useMemo(
    () =>
      questions.map(({ maxPointsText, minPointsText, question, scale, type }, index) => {
        const renderQuestionComponent = (type: string) => {
          switch (type) {
            case 'rate':
              return (
                <Box className={classes.scaleContainer}>
                  <Rating
                    scale={scale || 10}
                    score={
                      (answers[index] &&
                        answers[index].value &&
                        typeof answers[index].value === 'number' &&
                        Number(answers[index].value)) ||
                      0
                    }
                    setScore={(score) => onAnswer({ index, type, value: score })}
                  />
                </Box>
              );
            case 'text':
              return (
                <TextareaAutosize
                  className={classes.textArea}
                  minRows={2}
                  onChange={(e) => onAnswer({ index, type, value: e.target.value })}
                />
              );
            default:
              return null;
          }
        };

        return (
          <Box key={`${question}-${index}`}>
            <Box className={classes.questionContainer}>
              <SubHeader>{question}</SubHeader>
            </Box>
            {renderQuestionComponent(type)}
          </Box>
        );
      }),
    [answers, questions]
  );

  const saveClosingActionInLocalStorage = useCallback(
    ({ afterSubmit }) => {
      const GBSItem = JSON.parse(localStorage.getItem(localStorageGBSKey));
      if (GBSItem && moment().isoWeek() === moment(GBSItem.date).isoWeek() && GBSItem.value < MAX_GBS_VALUE) {
        localStorage.setItem(
          localStorageGBSKey,
          JSON.stringify({ value: afterSubmit ? MAX_GBS_VALUE : GBSItem.value + 1, date: new Date().toISOString() })
        );
      } else {
        localStorage.setItem(
          localStorageGBSKey,
          JSON.stringify({ value: afterSubmit ? MAX_GBS_VALUE : 1, date: new Date().toISOString() })
        );
      }
    },
    [localStorageGBSKey]
  );

  useEffect(() => {
    if (groupId && isApiCallAllowed && userRole === 'member') {
      dispatch(getSurveyData({ groupId }))
        .then(({ payload: { data } }) => {
          if (data) {
            setPieceId(data.id || '');
            setTitle(data.title || '');
            setQuestions(data.questions || []);
            setButtonText(data.buttonText || '');
            handleOpen();
          }
        })
        .catch((error) => {
          console.warn('getSurveyData', error);
        });
    }
  }, [dispatch, groupId, isApiCallAllowed]);

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <Modal
        open={open}
        onClose={() => handleClose({ afterSubmit: step === Steps.Confirmation })}
        aria-labelledby="modal-survey"
        aria-describedby="modal-survey-with-rating"
      >
        <Box className={classes.wrapper}>
          {/* <Box
            className={classes.closeIconContainer}
            onClick={() => handleClose({ afterSubmit: step === STEPS.Confirmation })}
          >
            <CloseIcon />
          </Box> */}
          <Box className={classes.moodIconContainer}>
            <MoodIcon fontSize="inherit" />
          </Box>
          <Box className={classes.titleContainer}>
            <Label2 color={OLD_COLORS.GRAY_500}>{title}</Label2>
          </Box>
          {step === Steps.Survey && <Box className={classes.questionsContainer}>{questionsComponents}</Box>}
          {step === Steps.Confirmation && <Confirmation />}
          <Box className={classes.submitButtonContainer}>
            {step === Steps.Survey ? (
              <CtaButton className={classes.submitButton} onClick={onClick} disabled={!isSubmitEnabled}>
                <Label1 color={OLD_COLORS.WHITE}>{buttonText}</Label1>
              </CtaButton>
            ) : (
              <LinkButton className={classes.closeButton} onClick={() => handleClose({ afterSubmit: true })}>
                Close
              </LinkButton>
            )}
          </Box>
        </Box>
      </Modal>
    </Backdrop>
  );
};
