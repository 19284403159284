import Cookies from 'universal-cookie';
import { getColor } from '@7chairs/frontend';
import { OLD_SIZES } from '../../../styles/appConsts';
import { trackEvent } from '../../../utils/analyticsEvent';

export const exerciseNeedABreak = () => ({
  key: 'exerciseNeedABreak',
  id: 'exerciseNeedABreak',
  contentComponents: [
    {
      type: 'Image',
      content: `${ASSETS_BASE_DIR}/exercise/hands-break.svg`,
      style: { marginBottom: OLD_SIZES.s, marginTop: OLD_SIZES.xl },
    },

    { type: 'SubHeader', content: `Need a break?`, style: { marginBottom: 0, fontWeight: 600 } },
    {
      type: 'Paragraph',
      content: "It's ok!",
      style: { marginBottom: 0 },
    },
    {
      type: 'Paragraph',
      content: "Your answers are saved, so you'll be able to pick up from where you stopped.",
      style: { width: '80%', marginBottom: OLD_SIZES.xxxxxxl },
    },
    {
      type: 'CtaButton',
      action: 'close',
      content: 'Got it',
    },
  ],
});

export const openExerciseBreakModal = (setDialogProps, { onClose }) => {
  trackEvent('open exericse take break modal');
  setDialogProps({ open: true, onClose, ...exerciseNeedABreak() });
};

const rejoinPopUp = (name, { title, leaderName, leaderPicture, schedule, id: groupInstanceId }) => ({
  key: 'rejoinPopUp',
  id: 'rejoinPopUp',
  contentComponents: [
    { type: 'Image', content: `${ASSETS_BASE_DIR}/creativeIcons/thumb_up_orange.svg`, style: { marginBottom: 40 } },
    { type: 'SubHeader', content: `Hi ${name},`, style: {} },
    {
      type: 'Header',
      content: 'Congratulations',
      style: {},
    },
    {
      type: 'SubHeader',
      content: 'We found you a new group!',
      style: { marginBottom: 50 },
    },
    {
      type: 'CtaButton',
      action: 'close',
      content: 'Got it',
    },
  ],
});

export const openRejoinPopUp = (setDialogProps, { name, group, onAcceptAsync, onDeny, onRejoin } = {}) => {
  const handleAction = (action) => {
    setDialogProps({ open: false });
    if (action === 'close') {
      setDialogProps({ open: false });
      onRejoin();
    }
    if (action === 'accept') {
      onAcceptAsync(group.id).then(() => {
        setDialogProps({
          ...rejoinPopUp(),
          handleAction,
          onClose: onRejoin,
          open: true,
        });
      });
    }
    if (action === 'deny') {
      onDeny();
    }
  };
  setDialogProps({
    ...rejoinPopUp(name, group),
    handleAction,
    open: true,
  });
};

// payment
export const cancelPaymentContactUs = (setDialogProps, { onClose, onApprove }) => {
  setDialogProps({
    key: 'cancelPaymentContactUs',
    id: 'cancelPaymentContactUs',
    contentComponents: [
      {
        type: 'Image',
        content: `${ASSETS_BASE_DIR}/creativeIcons/heart_key_pink.svg`,
        style: { marginBottom: OLD_SIZES.l },
      },
      {
        type: 'SubHeader',
        content: 'Thank you.',
        style: { fontWeight: 'bold' },
      },
      {
        type: 'Label1',
        content: 'We will be in touch soon.',
        style: { marginBottom: OLD_SIZES.s, width: '80%' },
      },
      {
        type: 'Label1',
        content: "Let's try and find a solution that fits your needs.",
        style: { marginBottom: OLD_SIZES.m, width: '90%' },
      },
      {
        type: 'CtaButton',
        content: 'OK',
        action: 'approve',
      },
    ],
    handleAction: (action) => {
      setDialogProps({ open: false });
      if (action === 'approve') {
        onApprove();
      }
    },
    onClose,
    open: true,
  });
};

export const scheduleMeetingWithCS = (setDialogProps, { onClose, onApprove, meetingUrl }) => {
  setDialogProps({
    key: 'scheduleMeetingWithCS',
    id: 'scheduleMeetingWithCS',
    contentComponents: [
      {
        type: 'iframeSrc',
        content: meetingUrl,
      },
    ],
    handleAction: (action) => {
      setDialogProps({ open: false });
      if (action === 'approve') {
        onApprove();
      }
    },
    onClose,
    open: true,
  });
};

export const nextMonthFreeConrifmation = (setDialogProps, { onClose, onConfirm }) => {
  setDialogProps({
    key: 'nextMonthFreeConrifmation',
    id: 'nextMonthFreeConrifmation',
    contentComponents: [
      {
        type: 'Image',
        content: `${ASSETS_BASE_DIR}/creativeIcons/heart_key_pink.svg`,
        style: { marginBottom: OLD_SIZES.l },
      },
      {
        type: 'SubHeader',
        content: 'Next month is on us',
        style: { marginBottom: OLD_SIZES.s, fontWeight: 'bold' },
      },
      {
        type: 'Label1',
        content:
          'Thank you and kudos for giving this another shot. We will contact you soon to ensure a better experience for you.',
        style: { marginBottom: OLD_SIZES.m, width: '90%' },
      },
      {
        type: 'CtaButton',
        content: 'Close',
        action: 'confirm',
      },
    ],
    handleAction: (action) => {
      setDialogProps({ open: false });
      if (action === 'confirm') {
        onConfirm();
      }
    },
    onClose,
    open: true,
  });
};

export const nextMonthFreeOffer = (setDialogProps, { onClose, onApprove, onUnsubscribe }) => {
  setDialogProps({
    key: 'nextMonthFreeOffer',
    id: 'nextMonthFreeOffer',
    contentComponents: [
      {
        type: 'SubHeader',
        content: 'Don’t leave just yet.',
        style: { fontWeight: 'bold' },
      },
      {
        type: 'SubHeader',
        content: 'Give healing some time',
        style: { marginBottom: OLD_SIZES.s, fontWeight: 'bold' },
      },
      {
        type: 'Label1',
        content: '86% of our members feel much better after completing their first month.',
        style: { marginBottom: OLD_SIZES.l, width: '80%' },
      },
      {
        type: 'ContentDiv',
        style: {
          borderRadius: 8,
          boxShadow: '7px 7px 25px rgba(0, 0, 0, 0.07), 0px 4px 12px rgba(0, 0, 0, 0.08)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: OLD_SIZES.l,
          paddingBottom: OLD_SIZES.l,
          marginLeft: OLD_SIZES.m,
          marginRight: OLD_SIZES.m,
        },
        components: [
          {
            type: 'Label1',
            content: 'Keep your subscription',
            style: { marginBottom: OLD_SIZES.xs },
          },
          {
            type: 'Paragraph',
            content: 'Get next month free',
            style: { color: getColor('PURPLE_100'), fontWeight: 'bold', fontSize: 20 },
          },
        ],
      },
      {
        type: 'CtaButton',
        content: 'Keep subscription',
        action: 'approve',
        style: {
          marginTop: OLD_SIZES.xl,
          backgroundColor: getColor('PURPLE_100'),
          color: getColor('WHITE_100'),
        },
      },
      {
        type: 'CtaButton',
        content: 'Unsubscribe',
        action: 'unsubscribe',
        style: {
          border: `1px solid ${getColor('DARK_PURPLE_100')}`,
          backgroundColor: getColor('WHITE_100'),
          color: getColor('BLACK_60'),
        },
      },
    ],
    handleAction: (action) => {
      setDialogProps({ open: false });
      if (action === 'approve') {
        onApprove();
      }
      if (action === 'unsubscribe') {
        onUnsubscribe();
      }
    },
    onClose,
    open: true,
  });
};
