import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isMobileOnly, isSafari } from 'react-device-detect';
import { setLoggedWithToken } from '../../store/ducks/root';
import { getAccount } from '../../store/ducks/account';
import { loginWithToken } from '../../store/ducks/auth';
import { getLoginTokenParams, useNavContext } from '../../components/navigator';
import { MOBILE_APP_URL_SCHEME } from '../../utils/consts';
import { AccountStore } from '../../store/ducks/account';
import { AccountRole } from '@7chairs/types';

type UseAccuontFetchProps = Pick<AccountStore, 'isAuth' | 'role'>;

export const useAccountFetchAndRoute = ({ isAuth, role }: UseAccuontFetchProps) => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { replaceScreen, pushScreen } = useNavContext();

  const [accountFetched, setAccountFetched] = useState(false);
  const [postLoginNav, setPostLoginNav] = useState(null);

  useEffect(() => {
    if (!accountFetched) {
      const { type, token } = getLoginTokenParams(pathname);

      if (token) {
        if (isMobileOnly && !isSafari) {
          window.location.replace(MOBILE_APP_URL_SCHEME);
        }

        dispatch(setLoggedWithToken(true));
      }

      dispatch(token ? loginWithToken(type, token) : getAccount())
        .then(({ payload: { data } }) => {
          setPostLoginNav(data.navTo);
        })
        .catch(() => {
          // if token was expired we still want to check if user has "active" session
          if (token) {
            dispatch(getAccount());
          }
        })
        .finally(() => {
          setAccountFetched(true);
        });
    }
  }, [dispatch, accountFetched, pathname]);

  useEffect(() => {
    if (accountFetched) {
      const currentUrl = window.location.href;

      if (!isAuth && !pathname.includes('/login')) {
        window.location.replace(`/app/login/?loginRedirectUrl=${currentUrl}`);
      }

      if (isAuth && (pathname.includes('/login') || pathname.includes('/i/') || pathname === '/')) {
        // (priority flow) redirect to the page that user attempted to access before authentication
        const loginRedirectUrl = new URLSearchParams(currentUrl.split('?')[1]).get('loginRedirectUrl');
        const prePickedPlanId = new URLSearchParams(currentUrl.split('?')[1]).get('plan');
        if (loginRedirectUrl) {
          // if user was sent here from onboarding, we need to redirect him there with accessToken so he can pay later
          if (loginRedirectUrl.includes('onboarding/document')) {
            window.location.replace(
              `${loginRedirectUrl}?accessToken=${window.localStorage.authToken}${
                prePickedPlanId ? `&plan=${prePickedPlanId}` : ''
              }`
            );
            return;
          }
          window.location.replace(loginRedirectUrl);
        }

        // regular flow
        replaceScreen('/profile');

        if (postLoginNav) {
          pushScreen(postLoginNav);
          setPostLoginNav(null);
        }
      }
    }
  }, [isAuth, accountFetched, role, postLoginNav]);

  return { accountFetched };
};
