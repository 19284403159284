export type Action = {
  type: string;
  payload?: any;
  meta?: any;
  error?: boolean;
};

export default function createAction(type: string, payload?: any, meta?: any): Action {
  const action: Action = { type };
  if (payload !== undefined) {
    action.payload = payload;
    if (payload instanceof Error) {
      action.error = true;
    }
  }
  if (meta !== undefined) {
    action.meta = meta;
  }
  return action;
}
