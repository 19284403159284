import { Box } from '@material-ui/core';
import React from 'react';
import { Label1, Label2 } from '../../../../components/Text';
import { CtaButton } from '../../../../components/buttons';
import { makeStyles } from '@material-ui/core/styles';
import { OLD_COLORS } from '../../../../styles/appConsts';
import Avatar from '../../../../components/Avatar';
import CalendlyPopUp from '../../../../components/popups/CalendlyPopUp';
import { useDispatch } from 'react-redux';
import { setCalendlyPopUpData } from '../../../../store/ducks/root';
import { GroupLeaderInfo } from '../../types';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '50px',
    height: '90%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    color: OLD_COLORS.BLACK,
    textAlign: 'center',
    maxWidth: '350px',
  },
  subTitle: {
    fontSize: '18px',
    fontWeight: 400,
    color: OLD_COLORS.BLACK,
    textAlign: 'center',
    maxWidth: '420px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    marginBottom: '5px',
  },
  upperButton: {
    width: '270px',
    background: OLD_COLORS.VIBRANT_BLUE_2,
    '&:hover': {
      background: OLD_COLORS.VIBRANT_BLUE_2,
    },
  },
  upperButtonLabel: {
    color: OLD_COLORS.WHITE,
  },
  lowerButton: {
    width: '270px',
    background: OLD_COLORS.GRAY_200,
    '&:hover': {
      background: OLD_COLORS.GRAY_200,
    },
  },
  lowerButtonLabel: {
    color: OLD_COLORS.VIBRANT_BLUE_2,
  },
}));

interface ThirdQuestionNegativeAnsweredProps {
  groupLeaderInfo: GroupLeaderInfo;
  title: string;
  subTitle1: string;
  subTitle2: string;
  upperButtonLabel: string;
  lowerButtonLabel: string;
  finishQuestionnaire: (showGroupChatOnWebOrCloseOnMobileWebview: boolean, showThankYou: boolean) => void;
}

export const ThirdQuestionNegativeAnswered = ({
  groupLeaderInfo: { picture, calendarLink  },
  title,
  subTitle1,
  subTitle2,
  upperButtonLabel,
  lowerButtonLabel,
  finishQuestionnaire,
}: ThirdQuestionNegativeAnsweredProps) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const openCalendlyPopUp = () => {
    dispatch(
      setCalendlyPopUpData({
        url: calendarLink,
        groupLab: false,
        open: true,
        skipCallback: () => finishQuestionnaire(false, true),
        onClose: () => finishQuestionnaire(false, true),
      })
    );
  };

  return (
    <>
      <Box className={classes.wrapper}>
        <Avatar img={picture} />
        <Label2 className={classes.title}>{title}</Label2>
        <Label2 className={classes.subTitle}>{subTitle1}</Label2>
        <Label2 className={classes.subTitle}>{subTitle2}</Label2>
        <Box className={classes.buttons}>
          <CtaButton className={classes.upperButton} onClick={() => openCalendlyPopUp()}>
            <Label1 className={classes.upperButtonLabel}>{upperButtonLabel}</Label1>
          </CtaButton>
          <CtaButton className={classes.lowerButton} onClick={() => finishQuestionnaire(false, true)}>
            <Label1 className={classes.lowerButtonLabel}>{lowerButtonLabel}</Label1>
          </CtaButton>
        </Box>
      </Box>
      <CalendlyPopUp />
    </>
  );
};
