import { createApiAction, createReducer } from '../../utils';

// Types
export const types = {
  GET_NOTIFICATIONS_SETTINGS: 'notificationsSettings/GET_NOTIFICATIONS_SETTINGS',
  GET_NOTIFICATIONS_SETTINGS_SUCCESS: 'notificationsSettings/GET_NOTIFICATIONS_SETTINGS_SUCCESS',
  GET_NOTIFICATIONS_SETTINGS_ERROR: 'notificationsSettings/GET_NOTIFICATIONS_SETTINGS_ERROR',
  SET_NOTIFICATIONS_SETTINGS: 'notificationsSettings/SET_NOTIFICATIONS_SETTINGS',
  SET_NOTIFICATIONS_SETTINGS_SUCCESS: 'notificationsSettings/SET_NOTIFICATIONS_SETTINGS_SUCCESS',
  SET_NOTIFICATIONS_SETTINGS_ERROR: 'notificationsSettings/SET_NOTIFICATIONS_SETTINGS_ERROR',
};

// Actions
export function getSettings() {
  return createApiAction(
    [
      types.GET_NOTIFICATIONS_SETTINGS,
      types.GET_NOTIFICATIONS_SETTINGS_SUCCESS,
      types.GET_NOTIFICATIONS_SETTINGS_ERROR,
    ],
    '/account/notifications',
    'get',
    undefined
  );
}

export function updateSettings(key, id, status) {
  return createApiAction(
    [
      types.SET_NOTIFICATIONS_SETTINGS,
      types.SET_NOTIFICATIONS_SETTINGS_SUCCESS,
      types.SET_NOTIFICATIONS_SETTINGS_ERROR,
    ],
    `/account/notifications/${key}/${id}/${status}`,
    'patch',
    undefined
  );
}

// Reducer
const initialState = {
  emailConfirmed: '',
  phoneConfirmed: '',
  groups: [],
  patch: {},
};

const handlers = {
  [types.GET_NOTIFICATIONS_SETTINGS_SUCCESS]: (state, { data }) => ({
    ...state,
    emailConfirmed: data.emailConfirmed,
    phoneConfirmed: data.phoneConfirmed,
    groups: data.groups,
    patch: data.patch,
  }),
  [types.SET_NOTIFICATIONS_SETTINGS_SUCCESS]: (state, { data }) => ({
    ...state,
    groups: data.groups,
    patch: data.patch,
  }),
};

export default createReducer(initialState, handlers);
