import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getConfigurations } from '../../store/ducks/configurations';

// we fetch data we want to be updated whenever lastFocus is changed
export const useLastFocus = () => {
  const dispatch = useDispatch();

  // @ts-ignore
  const lastFocus = useSelector((state) => state.root.lastFocus);
  // @ts-ignore
  const account: AccountStore = useSelector((state) => state.account);

  const { isAuth } = account;

  useEffect(() => {
    if (isAuth) {
      dispatch(getConfigurations());
    }
  }, [lastFocus, isAuth]);
};
