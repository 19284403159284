import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Menu, MenuItem, Fade } from '@material-ui/core';
import { SettingsOutlined, ExitToAppOutlined, SupervisorAccountOutlined } from '@material-ui/icons';
import { AccountRole } from '@7chairs/types';
import { Paragraph } from '../Text';
import { firebaseSignOut } from '../../utils/firebase';
import { trackEvent } from '../../utils/analyticsEvent';
import { OLD_COLORS } from '../../styles/appConsts';

const styles = () => ({
  settingIcon: {
    color: OLD_COLORS.SALMON,
  },
  menuItemWrapper: {
    width: 220,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default withStyles(styles)((props) => {
  const { classes, edge, logout } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    setAnchorEl(null);
    trackEvent('click logout');
    firebaseSignOut();
    logout();
  };

  return (
    <div>
      <IconButton edge={edge} onClick={handleClick} className={classes.settingIcon}>
        <SettingsOutlined />
      </IconButton>
      <Menu id="fade-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose} TransitionComponent={Fade}>
        {props.role === AccountRole.Admin && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              window.open('/admin/');
            }}
          >
            <div className={classes.menuItemWrapper}>
              <Paragraph>Admin panel</Paragraph>
              <SupervisorAccountOutlined style={{ color: OLD_COLORS.SALMON }} />
            </div>
          </MenuItem>
        )}
        <MenuItem onClick={onLogout}>
          <div className={classes.menuItemWrapper}>
            <Paragraph>Logout</Paragraph>
            <ExitToAppOutlined style={{ color: OLD_COLORS.SALMON }} />
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
});
