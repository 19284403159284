import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLoginTokenParams } from '../../components/navigator';
import ThreeDots from '../../components/ThreeDots';
import { PhoneNumberAuth } from './phoneNumberAuth/PhoneNumberAuth';
import { AuthContainerHeader } from './AuthContainerHeader';
import { makeStyles } from '@material-ui/core/styles';
import { ThirdPartyAuth } from './thirdPartyAuth/ThirdPartyAuth';
import { initFirebase } from '../../utils/firebase';
import { OLD_SIZES } from '../../styles/appConsts';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: OLD_SIZES.xl,
    overflowY: 'auto',
    paddingBottom: OLD_SIZES.l,
  },
}));

initFirebase();

export const AuthContainer = () => {
  const classes = useStyles();

  const { pathname } = useLocation();
  const { token } = getLoginTokenParams(pathname);

  // @ts-ignore
  const isAuth = useSelector((state) => state.account.isAuth);

  const [isThirdPartyAuthVisible, setThirdPartyAuthVisible] = useState<boolean>(true);

  const hideThirdPartyAuth = () => setThirdPartyAuthVisible(false);

  if (token && !isAuth) {
    return <ThreeDots size="64px" />;
  }

  return (
    <div className={classes.root}>
      <AuthContainerHeader title="Log In" />
      <PhoneNumberAuth hideThirdPartyAuth={hideThirdPartyAuth} />
      {isThirdPartyAuthVisible ? <ThirdPartyAuth /> : null}
    </div>
  );
};
