import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExerciseCard } from './ExerciseCard';
import { getAllExercises, setOpenExercise } from '../../../store/ducks/exercises';
import { getSettings } from '../../../store/ducks/notificationsSettings';
import { extractAnalyticsCookies, sendGTMEvent } from '../../../utils/analyticsEvent';
import { Text } from '@7chairs/frontend';
import getPaymentStatus from '../../../utils/payment';
import { setPaymentPopUpData } from '../../../store/ducks/root';

const useStyles = makeStyles(() => ({
  cardList: {
    display: 'flex',
    marginBottom: '56px',
    maxWidth: 560,
    overflowX: 'auto',
    width: '100%',
  },
  title: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '24px',
    maxWidth: 560,
    width: '100%',
  },
}));

export const ExerciseCardsList = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { shallPay } = getPaymentStatus();

  // @ts-ignore
  const allExercises = useSelector((state) => state.exercises.all);

  const [origin, setOrigin] = useState('');

  const onPaymentRequired = () => dispatch(setPaymentPopUpData({ open: true, source: 'memberCard' }));

  useEffect(() => {
    dispatch(getSettings());
    setOrigin(extractAnalyticsCookies()?.origin);
  }, []);

  useEffect(() => {
    if (!allExercises) {
      dispatch(getAllExercises());
    }
  }, [allExercises]);

  return (
    <>
      <div className={classes.title}>
        <Text variant={'subtitle'}>Reflection tools</Text>
      </div>
      <div className={classes.cardList}>
        {allExercises?.map((ex, idx) => {
          return (
            <ExerciseCard
              origin={origin}
              index={idx + 1}
              key={ex.id}
              id={ex.id}
              title={ex.title}
              mode={ex.progress.status}
              onClick={() => {
                sendGTMEvent({
                  GA_event_category: 'reflection',
                  GA_event_action: 'reflection clicked',
                  GA_event_label: 'reflection clicked',
                  GA_event_detail: 'NA',
                  reflection: ex.title,
                });
                if (shallPay && idx > 0) {
                  onPaymentRequired();
                  return;
                }
                dispatch(setOpenExercise(true));
              }}
            />
          );
        })}
      </div>
    </>
  );
};
