import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Paragraph, Label1 } from '../../components/Text';
import { OLD_COLORS, OLD_SIZES } from '../../styles/appConsts';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: OLD_COLORS.GRAY_50,
  },
  bigGrid: {
    padding: `${OLD_SIZES.l}px ${OLD_SIZES.xl}px`,
  },
  smallGrid: {
    textAlign: 'center',
    borderLeft: `1px solid ${OLD_COLORS.GRAY_200}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function PurchaseBillingCycle({ billingDetails }) {
  const classes = useStyles();

  const { amount, nextBilling } = billingDetails;

  const nextBillingStr = nextBilling ? moment(nextBilling * 1000).format('MM/DD/YYYY') : '';

  const billingSum = typeof amount === 'number' ? `${(amount / 100).toFixed(2)}$` : '';

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid container item xs={9} className={classes.bigGrid}>
          <Grid>
            <Paragraph fontWeight='500'>Next Billing Cycle</Paragraph>
          </Grid>
          <Grid>
            <Paragraph fontWeight='500' style={{ opacity: 0.5, marginLeft: OLD_SIZES.s }}>
              {nextBillingStr}
            </Paragraph>
          </Grid>
        </Grid>
        <Grid item xs={3} className={classes.smallGrid}>
          <Label1 color={OLD_COLORS.GRAY_700}>{billingSum}</Label1>
        </Grid>
      </Grid>
    </div>
  );
}

PurchaseBillingCycle.propTypes = {
  billingDetails: PropTypes.object.isRequired,
};

export default PurchaseBillingCycle;
