import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const styles = () => ({});

const useStyles = makeStyles(() => ({
  root: {
    width: 64,
    height: 64,
    borderRadius: '50%',
    position: 'relative',
  },
  outCircle: {
    width: 64,
    height: 64,
    borderRadius: '50%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    border: '3px solid rgba(255, 255, 255, 0.3)',
    zIndex: 4,
  },
  innerCircle: {
    width: 64,
    height: 64,
    borderRadius: '50%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2,
    background: `url(${ASSETS_BASE_DIR}/avatar/bg.svg) no-repeat center / cover`,
  },
  image: {
    height: 60,
    borderRadius: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50% !important',
    left: '50% !important',
    zIndex: 3,
  },
  imgWrapper: {
    width: 64,
    height: 64,
  },
}));

export default withStyles(styles)((props) => {
  const { classes, img, style } = props;
  const parentClasses = useStyles();
  return (
    <div className={parentClasses.root} style={style}>
      <div className={parentClasses.outCircle} />
      <div className={parentClasses.innerCircle} />
      <div className={parentClasses.imgWrapper}>
        <img className={parentClasses.image} style={style} src={img} />
      </div>
    </div>
  );
});
