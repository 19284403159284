import { useState, useEffect } from 'react';

const checkIfWindowWidthIsUnderThreshold = (threshold: number) => window.innerWidth <= threshold;

interface Props {
  threshold: number;
}

export const useIsWindowWidthUnderThreshold = ({ threshold }: Props) => {
  const [isWindowWidthUnderThreshold, setWindowWidthUnderThreshold] = useState(
    checkIfWindowWidthIsUnderThreshold(threshold)
  );

  useEffect(() => {
    const onResize = () => {
      setWindowWidthUnderThreshold(checkIfWindowWidthIsUnderThreshold(threshold));
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return { isWindowWidthUnderThreshold };
};
