export const dateToUTCOffset = () => {
  const offset = new Date().getTimezoneOffset();
  const sign = offset <= 0 ? '+' : '-';
  const hoursOffset = Math.floor(Math.abs(offset / 60));
  const minutesOffset = Math.abs(offset % 60);
  const h = hoursOffset < 10 ? `0${hoursOffset}` : hoursOffset;
  const m = minutesOffset < 10 ? `0${minutesOffset}` : minutesOffset;
  return `${sign}${h}${m}`;
};

const pad = (n, z) => {
  z = z || 2;
  return ('00' + n).slice(-z);
};
export const msToTime = (s) => {
  let ms = s % 1000;
  s = (s - ms) / 1000;
  let secs = s % 60;
  s = (s - secs) / 60;
  let mins = s % 60;
  let hrs = (s - mins) / 60;

  return pad(hrs) + ':' + pad(mins) + ':' + pad(secs);
};

export const timestampToDiffStr = (ts) => {
  let diff = Math.floor((Date.now() - ts) / 1000); // seconds
  if (diff < 60) {
    return 'now'; //`${diff}sec`;
  }
  diff = Math.floor(diff / 60); // minutes
  if (diff < 60) {
    return `${diff}min`;
  }
  diff = Math.floor(diff / 60); // hours
  if (diff < 24) {
    return `${diff}h`;
  }
  diff = Math.floor(diff / 24); // days
  if (diff < 7) {
    return `${diff}d`;
  }
  diff = Math.floor(diff / 7); // weeks
  if (diff < 5) {
    return `${diff}w`;
  }
  return `${Math.floor(diff / 4)}mon`;
};
