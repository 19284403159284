import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavContext } from '../../components/navigator';

export const useTokenPath = () => {
  const { pathname, search } = useLocation();
  const { replaceScreen } = useNavContext();

  // for mobile webview
  // we open webview of webapp like that:
  // "/token?at=<user_access_token>&path=<required_path>"
  useEffect(() => {
    if (pathname === '/token') {
      const queryParams = new URLSearchParams(search);

      const token = queryParams.get('at');
      const path = queryParams.get('path') || '/';

      if (token) {
        localStorage.setItem('authToken', token);
      }

      replaceScreen(path);
    }
  }, [pathname, search]);
};
