import axiosMiddleware from 'redux-axios-middleware';
import { purgeSession } from './ducks/auth';
import { openNotice } from './ducks/notice';
import { storage } from '../utils/localStorage';
import { apiClient } from '../axiosClient';

const noticeDispatch = (dispatch, data) => {
  const notice = data?._uiNotice;
  if (notice) {
    dispatch(openNotice(notice));
  }
};

const interceptRequest = ({}, request) => {
  // withCredentials: true should not take place on Strapi requests
  request.withCredentials = true;

  const authToken = storage.authToken;
  if (authToken) {
    request.headers['Authorization'] = authToken;
  }
  return request;
};

const interceptResponseSuccess = ({ dispatch }, response) => {
  const { data } = response;

  noticeDispatch(dispatch, data);

  return Promise.resolve(response);
};

const interceptResponseError = ({ dispatch }, error) => {
  const { response } = error;

  if (response) {
    if (response.status === 403 || response.status === 401) {
      dispatch(purgeSession());
    }
    noticeDispatch(dispatch, response.data);
  }

  return Promise.reject(error);
};

const config = {
  returnRejectedPromiseOnError: true,
  interceptors: {
    response: [{ success: interceptResponseSuccess, error: interceptResponseError }],
    request: [{ success: interceptRequest }],
  },
};

export default axiosMiddleware(apiClient, config);
