import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { OLD_COLORS } from '../styles/appConsts';

// color = ['default' | 'inherit' | 'primary' | 'secondary']

const styles = (theme) => ({
  linkBtn: {
    // width: '100%',
    textDecoration: 'underline',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: '24px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      lineHeight: '21px',
    },
  },
  cta: {
    '&:disabled': {
      backgroundColor: OLD_COLORS.VIBRANT_BLUE_3,
      color: OLD_COLORS.WHITE,
      cursor: 'not-allowed'
    },
  },
});

export const CtaButton = withStyles(styles)((props) => {
  const { classes, className, children } = props;
  return (
    <Button
      {...props}
      classes={{ disabled: classes.cta }}
      className={clsx(className)}
      color='primary'
      variant='contained'
    >
      {children}
    </Button>
  );
});

export const OutlinedButton = withStyles(styles)((props) => {
  const { classes, className, children } = props;
  return (
    <Button
      {...props}
      classes={undefined}
      className={clsx(classes.ctaBtn, className)}
      color='primary'
      variant='outlined'
    >
      {children}
    </Button>
  );
});

export const LinkButton = withStyles(styles)((props) => {
  const { classes, className, children } = props;
  return (
    <Button
      {...props}
      classes={undefined}
      className={clsx(classes.linkBtn, className)}
      color='secondary'
      variant='text'
    >
      {children}
    </Button>
  );
});
