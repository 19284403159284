import { makeStyles } from '@material-ui/core/styles';
import { OLD_COLORS } from '../../../../../styles/appConsts';

export const styles = makeStyles((theme) => ({
  dialogBoxSize: {
    padding: 40,
    paddingBottom: 23,
    width: 600,
    [theme.breakpoints.down('sm')]: {
      width: 280,
    },
  },
  labelForfileUpload: {
    marginTop: 410,
    [theme.breakpoints.down('sm')]: {
      marginTop: 205,
    },
  },
  mdhiAvatar: {
    height: '50px',
    width: '50px',
  },
  badgeMdhiAvatarTopRight: {
    top: '20px',
    right: '9px',
  },
  editIcon: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    left: 43,
    zIndex: 10,
  },
  input: {
    display: 'none',
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: OLD_COLORS.GRAY_200,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 5,
      alignItems: 'center',
    },
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  sliderContainer: {
    width: '100%',
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  sliderBox: {
    width: '45%',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
    },
  },
  sliderBoxRight: {
    width: '48%',
    marginLeft: '4%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: '85%',
    },
  },
  sliderLabel: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 65,
    },
  },
  sliderLabelRight: {
    marginLeft: 10,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 5,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderRight: {
    padding: '22px 0px',
    marginLeft: 16,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  innerFileIcon: {
    position: 'absolute',
    alignItems: 'center',
    top: '40%',
    left: '50%',
    [theme.breakpoints.down('sm')]: {
      top: '26%',
    },
    transform: 'translate(-50%, -50%)',
  },
  uploadLabel1: {
    border: '1px solid #ccc',
    display: 'inline-block',
    padding: '6px 12px',
    width: '100%',
    height: 400,
    [theme.breakpoints.down('sm')]: {
      height: 200,
    },
    cursor: 'pointer',
    background: OLD_COLORS.GRAY_300,
  },
  uploadLabel2: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  uploadIcon1: {
    width: '5em',
    height: '5em',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  uploadIcon2: {
    cursor: 'pointer',
  },
  uploadText2: {
    color: OLD_COLORS.VIBRANT_BLUE_2,
    fontSize: 14,
    cursor: 'pointer',
    paddingTop: 7,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: OLD_COLORS.GRAY_500,
  },
  menutItemWrapper: {
    width: 140,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
