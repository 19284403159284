import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/ducks';
import { getPickAGroupUrl } from '../../../../store/ducks/account';

export function usePickAGroupVariant() {
  const pickAGroupUrl = useSelector((state: RootState) => state.account.pickAGroupUrl) as string;

  const dispatch = useDispatch()
  useEffect(() => {
    if (pickAGroupUrl) {
      return
    }
    dispatch(getPickAGroupUrl());
  }, []);

  if (!pickAGroupUrl) { // if not found by useSelector, I get an empty string
    return null
  }
  
  return pickAGroupUrl
}