import { createAction, createApiAction, createReducer } from '../../utils';
import { storage } from '../../utils/localStorage';

// Types
export const types = {
  LOGIN_WITH_FIREBASE: 'auth/LOGIN_WITH_FIREBASE',
  LOGIN_WITH_FIREBASE_SUCCESS: 'auth/LOGIN_WITH_FIREBASE_SUCCESS',
  LOGIN_WITH_FIREBASE_ERROR: 'auth/LOGIN_WITH_FIREBASE_ERROR',
  PING: 'auth/PING',
  PING_SUCCESS: 'auth/PING_SUCCESS',
  PING_ERROR: 'auth/PING_ERROR',
  LOGOUT: 'auth/LOGOUT',
  LOGOUT_SUCCESS: 'auth/LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'auth/LOGOUT_ERROR',
  TOKEN_LOGIN: 'auth/TOKEN_LOGIN',
  TOKEN_LOGIN_SUCCESS: 'auth/TOKEN_LOGIN_SUCCESS',
  TOKEN_LOGIN_ERROR: 'auth/TOKEN_LOGIN_ERROR',
};

// Actions
export function loginWithFirebase(idToken, meta) {
  return createApiAction(
    [types.LOGIN_WITH_FIREBASE, types.LOGIN_WITH_FIREBASE_SUCCESS, types.LOGIN_WITH_FIREBASE_ERROR],
    '/auth/firebase',
    'post',
    {
      idToken,
      meta,
    }
  );
}

export function loginWithToken(type, token) {
  return createApiAction(
    [types.TOKEN_LOGIN, types.TOKEN_LOGIN_SUCCESS, types.TOKEN_LOGIN_ERROR],
    `/auth/link/${type}/${token}`,
    'get',
    undefined
  );
}

export function logout() {
  return createApiAction([types.LOGOUT, types.LOGOUT_SUCCESS, types.LOGOUT_ERROR], '/auth', 'delete', undefined);
}

export function ping({ screen }) {
  return createApiAction(
    [types.PING, types.PING_SUCCESS, types.PING_ERROR],
    `/auth/ping?screen=${screen}`,
    'get',
    undefined
  );
}

export function purgeSession() {
  return createAction(types.LOGOUT_SUCCESS);
}

// Reducer
const initialState = {
  phoneNumber: null,
  recaptchaToken: null,
  sessionInfo: null,
};

const handlers = {
  [types.LOGOUT_SUCCESS]: (state) => {
    storage.authToken = null;
    return {
      ...state,
      sessionInfo: null,
    };
  },
  [types.LOGIN_WITH_FIREBASE_SUCCESS]: (state, { headers }) => {
    storage.authToken = headers.token;
    return { ...state };
  },
  [types.TOKEN_LOGIN_SUCCESS]: (state, { headers }) => {
    storage.authToken = headers.token;
    return { ...state };
  },
};

export default createReducer(initialState, handlers);
