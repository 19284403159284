import React from 'react';
import { NavigationContext } from '../../components/navigator/index';
import { makeStyles } from '@material-ui/core/styles';
import { trackEvent } from '../../utils/analyticsEvent';
import { CtaButton } from '../../components/buttons';
import { Paragraph, Header, SubHeader } from '../../components/Text';
import { GROUP_UNDEFINED } from '../../utils/consts';
import { OLD_COLORS, OLD_SIZES } from '../../styles/appConsts';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'auto',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  container: {
    padding: OLD_SIZES.l,
    maxWidth: 882,
    [theme.breakpoints.up('sm')]: {
      margin: '0 auto',
    },
    [theme.breakpoints.down('sm')]: {
      padding: OLD_SIZES.m,
    },
    height: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleWrapper: {
    padding: `0px ${OLD_SIZES.xl + OLD_SIZES.m}px`,
    [theme.breakpoints.down('sm')]: {
      padding: `0px ${OLD_SIZES.m}px ${OLD_SIZES.m}px ${OLD_SIZES.m}px`,
    },
  },
  title: {
    whiteSpace: 'pre-line',
  },
  sectionWrapper: {
    paddingTop: OLD_SIZES.l,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  text: {
    maxWidth: 300,
    color: OLD_COLORS.GRAY_500,
    textAlign: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginBottom: OLD_SIZES.xs,
  },
  titleText: {
    textAlign: 'center',
    marginRight: 'auto',
    marginLeft: 'auto',
    fontWeight: 'bold',
  },
  bottom: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: OLD_SIZES.m,
    [theme.breakpoints.down('sm')]: {
      padding: OLD_SIZES.m,
    },
  },
  ctaBtn: {
    marginTop: OLD_SIZES.l + OLD_SIZES.l,
    [theme.breakpoints.down('sm')]: {
      marginTop: OLD_SIZES.l,
    },
    backgroundColor: OLD_COLORS.VIBRANT_BLUE_2,
    '&:hover': {
      backgroundColor: OLD_COLORS.VIBRANT_BLUE_2,
    },
  },
}));

function Confirmation() {
  const navCtx = React.useContext(NavigationContext);

  const [isMobileWebview] = React.useState(window.navigator.userAgent.indexOf('circles-mobile-app') > -1);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div>
          <div className={classes.titleWrapper}>
            <Header align='center' className={classes.title}>
              <img className={classes.img} src={`${ASSETS_BASE_DIR}/payment/confirmation-icon.svg`} />
            </Header>
            <SubHeader className={classes.titleText}>Subscription successful!</SubHeader>
          </div>
          <div className={classes.sectionWrapper}>
            <Paragraph className={classes.text}>
              Your payment method was successfully updated.
            </Paragraph>
          </div>
        </div>
      </div>
      <div className={classes.bottom}>
        <CtaButton
          className={classes.ctaBtn}
          onClick={() => {
            trackEvent('click confirmation payment page');

            if (isMobileWebview && window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'close' }));
              return;
            }

            navCtx.pushScreen(`/group/${GROUP_UNDEFINED}`);
          }}
        >
          Done
        </CtaButton>
      </div>
    </div>
  );
}

export default Confirmation;
