import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { NavigationContext } from '../../components/navigator';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { OLD_SIZES } from '../../styles/appConsts';
import StripeForm from './StripeForm';

const stripePromise = loadStripe(STRIPE_KEY);

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 80,
  },
  paymentContainer: {
    paddingTop: OLD_SIZES.l,
    height: '100%',
    maxWidth: 882,
    [theme.breakpoints.up('sm')]: {
      margin: '0 auto',
    },
  },
  paymentItems: {
    height: '100%',
    minWidth: 732,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'calc(100% - 32px)',
    },
  },
}));

function EditPurchase({ updatePaymentMethod, clientSecret, getClientSecret, openNotice, currentPlan }) {
  const classes = useStyles();

  const navCtx = useContext(NavigationContext);

  useEffect(() => {
    getClientSecret();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.paymentContainer}>
        <div className={classes.paymentItems}>
          {!currentPlan && (
            <div className={classes.loaderContainer}>
              <CircularProgress color='primary' />
            </div>
          )}
          {currentPlan && (
            <Elements stripe={stripePromise}>
              <StripeForm
                edit
                clientSecret={clientSecret}
                getClientSecret={getClientSecret}
                onSetupIntent={(data) => {
                  return updatePaymentMethod(data).then(() => navCtx.popScreen());
                }}
                openNotice={openNotice}
                currentPlan={currentPlan}
              />
            </Elements>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditPurchase;
