import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Avatar, Badge } from '@material-ui/core';
import { NavigationContext } from '../../../../components/navigator';
import { OLD_SIZES } from '../../../../styles/appConsts';
import { MOBILE_WIDTH, DESKTOP_WIDTH } from '../../utils';
import { trackEvent } from '../../../../utils/analyticsEvent';

interface ItemAvatarProps {
  baseUrl: string;
  name: string;
  picture: string;
}

const useStyles = makeStyles((theme) => ({
  topItem: {
    marginBottom: `${OLD_SIZES.m}px`,
    display: 'inline-block',
    width: DESKTOP_WIDTH,
    [theme.breakpoints.down('sm')]: {
      width: MOBILE_WIDTH,
    },
  },
  avatar: {
    cursor: 'pointer',
    marginTop: 'auto',
    height: '90px',
    width: '90px',
    '&>*': {
      shapeRendering: 'geometricPrecision',
    },
  },
  mdhiAvatar: {
    cursor: 'pointer',
    height: '50px',
    width: '50px',
  },
  badgeMdhiAvatarTopRight: {
    top: '20px',
    right: '9px',
  },
}));

function ItemAvatar({ baseUrl, name, picture }: ItemAvatarProps) {
  const classes = useStyles();
  const navCtx = useContext(NavigationContext);

  return (
    <Grid id='avatar-grid' container item direction='column' alignItems='center' className={classes.topItem}>
      <Badge
        classes={{ badge: classes.badgeMdhiAvatarTopRight }}
        badgeContent={
          <img
            src={`${ASSETS_BASE_DIR}/edit_orange.svg`}
            className={classes.mdhiAvatar}
            onClick={() => {
              trackEvent('Click update newprofile');
              navCtx.pushScreen(`${baseUrl}/items`);
            }}
          />
        }
        overlap='rectangular'
      >
        <Avatar
          alt={name}
          src={picture}
          className={classes.avatar}
          onClick={() => {
            trackEvent('Click update newprofile');
            navCtx.pushScreen(`${baseUrl}/items`);
          }}
        />
      </Badge>
    </Grid>
  );
}

export default ItemAvatar;
