import { StreamChat } from 'stream-chat';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStreamChatToken } from '../../../store/ducks/streamChat';

export const useStreamChatClient = () => {
  const dispatch = useDispatch();

  // @ts-ignore
  const accountId = useSelector((state) => state.account.id);
  // @ts-ignore
  const token = useSelector((state) => state.streamChat.token);

  const [chatClient, setChatClient] = useState(null);

  useEffect(() => {
    if (!token) {
      dispatch(getStreamChatToken());
    }
  }, []);

  useEffect(() => {
    if (accountId && token) {
      // @ts-ignore
      const client = new StreamChat(STREAM_APP_ID);
      // prevents application from setting stale client (user changed, for example)
      let didUserConnectInterrupt = false;

      const connectionPromise = client.connectUser({ id: accountId }, token).then(() => {
        if (!didUserConnectInterrupt) setChatClient(client);
      });

      return () => {
        didUserConnectInterrupt = true;
        setChatClient(null);
        // wait for connection to finish before initiating closing sequence
        connectionPromise
          .then(() => client.disconnectUser())
          .then(() => {
            console.log('stream ws connection closed');
          });
      };
    }
  }, [accountId, token]);

  return { chatClient };
};
