import React, { useEffect, useMemo, useRef, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Beforeunload } from 'react-beforeunload';
import { setDialogProps } from '../store/ducks/root';

import MixedDialog from '../components/dialogs/mixedDialog/MixedDialog';
import { PaymentPopUp } from '../components/popups/PaymentPopUp';
import CalendlyPopUp from '../components/popups/CalendlyPopUp';
import { Loader } from '../components/loader/Loader';

import { identifyEvent } from '../utils/analyticsEvent';
import { trackEvent } from '../utils/analyticsEvent';

import { useNewlySignup } from './hooks/useNewlySignup';
import { useTokenPath } from './hooks/useTokenPath';
import { usePingInterval } from './hooks/usePingInterval';
import { useAccountFetchAndRoute } from './hooks/useAccountFetchAndRoute';
import { useLastFocus } from './hooks/useLastFocus';

import { AccountStore } from '../store/ducks/account';

export const AppGateway: FC = ({ children }) => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const nextRef = useRef({ pathname });

  // @ts-ignore
  const dialogProps = useSelector((state) => state.root.dialogProps);
  // @ts-ignore
  const account: AccountStore = useSelector((state) => state.account);

  const { id: userId, isAuth, role, defaultEventProps } = account;

  // AppGateway hooks
  const { newlySignedUp, minimalLoadingAnimationTimeElapsed } = useNewlySignup();
  useTokenPath();
  usePingInterval({ userId });
  const { accountFetched } = useAccountFetchAndRoute({ isAuth, role });
  useLastFocus();

  const loaderText = useMemo(
    () =>
      newlySignedUp
        ? `We're creating your profile, after that you'll be able to choose your image and meet the rest of the group!`
        : null,
    [newlySignedUp]
  );

  useEffect(() => {
    nextRef.current = { ...nextRef.current, pathname };
  }, [pathname, nextRef.current]);

  useEffect(() => {
    if (userId) {
      identifyEvent({ id: userId });
    }
  }, [userId]);

  useEffect(() => {
    if (Object.keys(defaultEventProps).length > 0) {
      localStorage.setItem('defaultEventProps', JSON.stringify(defaultEventProps));
    }
  }, [defaultEventProps]);

  const beforeUnloadHandler = (e) => {
    trackEvent('click close browser', { currentPage: e.target.location.pathname });
  };

  return (
    <>
      <Beforeunload onBeforeunload={beforeUnloadHandler} />
      {accountFetched && minimalLoadingAnimationTimeElapsed ? (
        children
      ) : (
        <Loader>
          <p>{loaderText}</p>
        </Loader>
      )}
      <MixedDialog
        {...dialogProps}
        onClose={() => {
          if (typeof dialogProps.onClose === 'function') {
            dialogProps.onClose();
          }
          dispatch(setDialogProps({ open: false }));
        }}
      />
      <PaymentPopUp />
      <CalendlyPopUp />
    </>
  );
};
