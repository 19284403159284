// TODO the same file in app-server
// the common utilities shall be in external package

import { SESSION_GAP } from './consts';

const gapMs = 5 * 60 * 1000; // time before end of session considered as end
const weekMs = 7 * 24 * 60 * 60 * 1000;
const scheduleToMs = (s) => s.weekday * 24 * 60 * 60 * 1000 + s.hour * 60 * 60 * 1000 + s.minute * 60 * 1000;

// return next session start and end time from schedule list
// weekdays [0:sun, mon, ..., 6:sat]
// schedule ex [{"weekday":3, "hour":7, "minute":30,"duration": 15}, ... ];
// this function is a duplicate of a function with the same name in the backend repository
// we should change it to a common util
export const nextSession = (schedule, startFrom = 0) => {
  // sometimes startFrom is a stringed number so we convert it to a number
  if (startFrom && typeof startFrom === 'string') startFrom = Number(startFrom);

  const WEEK_MS = 7 * 24 * 60 * 60 * 1000;
  const n = new Date();
  const nowMs = n.getTime();

  // normalize date to 0 ms of current day
  n.setUTCHours(0);
  n.setUTCMinutes(0);
  n.setUTCSeconds(0);
  n.setUTCMilliseconds(0);
  const weekStart = n.getTime() - n.getUTCDay() * 24 * 60 * 60 * 1000;

  const weekSchedule = {};
  for (let i = 0; i < schedule.length; i++) {
    const durationMs = schedule[i].duration * 60 * 1000;
    let sessionStart = weekStart + scheduleToMs(schedule[i]);
    if (sessionStart + durationMs - gapMs < nowMs) {
      sessionStart += weekMs;
    }
    weekSchedule[sessionStart] = sessionStart + durationMs;
  }

  const nextStart = Math.min(...Object.keys(weekSchedule));

  let additionalMs = 0;
  while (typeof startFrom === 'number' && nextStart + additionalMs < startFrom) {
    additionalMs += WEEK_MS;
  }

  return [nextStart + additionalMs, weekSchedule[nextStart] + additionalMs];
};

const openChatGapMs = 2 * 60 * 60 * 1000; // 2 hours before and after chat time
export const isSessionClosed = (start, end, getNextSession) => {
  const now = Date.now();
  if (now - openChatGapMs > end) {
    getNextSession && getNextSession(); // get new next session time, update store
    // return true; // currently chat is always open
  }
  // return now + openChatGapMs < start; // currently chat is always open
  return false;
};

export const isSessionTime = (nextStart, sessionGap = SESSION_GAP) => {
  return Date.now() > nextStart - sessionGap;
};

export default {
  nextSession,
  isSessionClosed,
  isSessionTime,
};
