import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    image: {
        // width: 72,
        // height: 72,
    },
});

export default withStyles(styles)((props) => {
    const { classes, content, style } = props;
    return (<img className={classes.image} style={style} src={content} />);
});
