const { paymentPageUrl } = require('./payment');

export const navigateToPickAGroupPage = ({ targetUrl, isPayment }) => {
  if (!targetUrl) {
    return;
  }

  const token = window.localStorage.authToken;
  let pickAGroupUrl = `${WEBSITE_URL}/${targetUrl}/?accessToken=${token}`;
  if (!isPayment) {
    const paymentUrl = paymentPageUrl();
    pickAGroupUrl += `&backUrl=${encodeURIComponent(paymentUrl)}`;
  } else {
    pickAGroupUrl += `&backUrl=${encodeURIComponent(window.location.href)}`;
  }
  window.location.href = pickAGroupUrl;
};
