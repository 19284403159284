import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { OLD_SIZES } from '../../../styles/appConsts';
import { sendGTMEvent, trackEvent } from '../../../utils/analyticsEvent';
import { PhoneNumber } from './PhoneNumber';
import { Code } from './Code';
import { useDispatch } from 'react-redux';
import { loginWithFirebase } from '../../../store/ducks/auth';
import { signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import { extractFromUserCredential, firebaseSignOut, getFirebaseAuth } from '../../../utils/firebase';
import { AuthProvider } from '@7chairs/types';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  stepContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 'auto',
    paddingLeft: OLD_SIZES.xxl,
    paddingRight: OLD_SIZES.xxl,
    maxWidth: 420,
  },
}));

enum PhoneNumberAuthStep {
  PhoneNumber = 'phoneNumber',
  Code = 'code',
}

interface PhoneNumberAuthProps {
  hideThirdPartyAuth: () => void;
}

export const PhoneNumberAuth = ({ hideThirdPartyAuth }: PhoneNumberAuthProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState<PhoneNumberAuthStep>(PhoneNumberAuthStep.PhoneNumber);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [isError, setError] = useState<boolean>(false);

  const handlePhoneNumberChange = (phoneNumber: string) => setPhoneNumber(phoneNumber);
  const handleCodeChange = (code: string) => setCode(code);
  const handleErrorChange = (isError: boolean) => setError(isError);

  const onSignInSubmit = () => {
    trackEvent('click login send sms', { phoneNumber });
    // @ts-ignore
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(getFirebaseAuth(), phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // @ts-ignore
        window.confirmationResult = confirmationResult;
        hideThirdPartyAuth();
        setCurrentStep(PhoneNumberAuthStep.Code);
      })
      .catch((err) => {
        // error with that code is always thrown, even if the provided phoneNumber was correct
        // probably it's the bug on the firebase side
        if (err.code !== 'auth/internal-error') {
          handleErrorChange(true);
        }
      });
  };

  const onVerifyCodeSubmit = () => {
    const provider = AuthProvider.PhoneNumber;
    trackEvent('click login verify code');
    // @ts-ignore
    window.confirmationResult
      .confirm(code)
      .then(async (result) => {
        const { idToken, email } = await extractFromUserCredential(result);
        dispatch(
          loginWithFirebase(idToken, {
            provider,
            phoneNumber,
            email,
          })
        )
          .then(() => {
            trackEvent('login with provider success', { provider });
            sendGTMEvent({
              GA_event_category: 'login',
              GA_event_action: 'login success',
              GA_event_label: provider,
              GA_event_detail: 'NA',
            });
          })
          .catch(() => {
            firebaseSignOut();
            trackEvent('login with provider fail', { provider });
          });
      })
      .catch(() => {
        handleErrorChange(true);
      });
  };

  useEffect(() => {
    // @ts-ignore
    window.recaptchaVerifier = new RecaptchaVerifier(getFirebaseAuth(), 'recaptcha-container', {
      size: 'invisible',
      callback: onSignInSubmit,
    });

    // @ts-ignore
    window.recaptchaVerifier.render().then(function (widgetId) {
      // @ts-ignore
      window.recaptchaWidgetId = widgetId;
    });

    return () => {
      // @ts-ignore
      if (window.recaptchaVerifier) {
        // @ts-ignore
        window.recaptchaVerifier.clear();
      }
      // @ts-ignore
      delete window.recaptchaVerifier;
      // @ts-ignore
      delete window.recaptchaWidgetId;
      // @ts-ignore
      delete window.recaptcha;
      // @ts-ignore
      delete window.confirmationResult;
    };
  }, []);

  return (
    <div className={classes.root}>
      <div id="recaptcha-container" />
      <div className={classes.stepContent}>
        {currentStep === PhoneNumberAuthStep.PhoneNumber ? (
          <PhoneNumber
            phoneNumber={phoneNumber}
            isError={isError}
            handlePhoneNumberChange={handlePhoneNumberChange}
            handleErrorChange={handleErrorChange}
            onSubmit={onSignInSubmit}
          />
        ) : null}
        {currentStep === PhoneNumberAuthStep.Code ? (
          <Code
            phoneNumber={phoneNumber}
            code={code}
            isError={isError}
            handleCodeChange={handleCodeChange}
            handleErrorChange={handleErrorChange}
            onSubmit={onVerifyCodeSubmit}
          />
        ) : null}
      </div>
    </div>
  );
};
