import React from 'react';
import {
  ChannelHeader,
  MessageInput,
  MessageList,
  MessageToSend,
  Thread,
  useChannelActionContext,
  useChannelStateContext,
  Window,
} from 'stream-chat-react';
import { sendGTMEvent, trackEvent } from '../../../utils/analyticsEvent';
import { StreamChannelType } from '@7chairs/types';
import { SUPPORT_ACCOUNT_ID } from '../../../utils/consts';

const availableMessageActions = ['react', 'delete', 'reply'];

export const ChannelInner = () => {
  const { channel } = useChannelStateContext();
  const { sendMessage } = useChannelActionContext();

  const overrideSubmitHandler = async (message: MessageToSend) => {
    const {
      type,
      state: { members },
    } = channel;

    const { text, attachments, mentioned_users } = message;

    if (text) {
      const privateChat = type === StreamChannelType.Private;

      let isCustomerSupport = false;
      if (privateChat) {
        isCustomerSupport = Object.keys(members).some((accountId) => accountId === SUPPORT_ACCOUNT_ID);
      }

      let chatType = 'Group Chat';
      if (privateChat) {
        chatType = isCustomerSupport ? 'Customer Support' : 'Private Chat';
      }

      trackEvent('click send chat text message', { privateChat, cs: isCustomerSupport });
      sendGTMEvent({
        GA_event_category: 'chat',
        GA_event_action: 'chat message sent',
        GA_event_label: chatType,
        GA_event_detail: 'NA',
      });
    }

    if (attachments.length) {
      trackEvent('click send chat picture message');
    }

    if (mentioned_users.length) {
      mentioned_users.forEach(({ id, name }) => trackEvent('click mention on chat message', { id, name }));
    }

    await sendMessage(message);
  };

  return (
    <>
      <Window>
        <ChannelHeader />
        <MessageList onlySenderCanEdit={true} messageActions={availableMessageActions} />
        <MessageInput noFiles={true} overrideSubmitHandler={overrideSubmitHandler} />
      </Window>
      <Thread messageActions={availableMessageActions} />
    </>
  );
};
