import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Account, Member, AccountProfileProperties, AccountRole } from '@7chairs/types';
import { getColor } from '@7chairs/frontend';
import moment from 'moment';
import { setCalendlyPopUpData, setPaymentPopUpData } from '../../store/ducks/root';
import { RootState } from '../../store/ducks/index';
import { getHasSeenDownloadAppPopup } from '../../store/ducks/account';
import { storage } from '../../utils/localStorage';
import getPaymentStatus from '../../utils/payment';
import Exercise from '../../components/exercise/Main';
import { Survey } from '../../components/groupBelongingSurvey/Survey';
import { EventsList } from '../../components/groupSpace/webinarsAndEvents/EventsList';
import { ExerciseCardsList } from '../../components/groupSpace/exerciseCards/ExerciseCardsList';
import { Members } from '../../components/groupSpace/members/Members';
import { MeetingCard } from '../../components/groupSpace/meetingCards/MeetingCard';
import { MeetingCardNoCircle } from '../../components/groupSpace/meetingCards/MeeetingCardNoCircle';

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '920px',
    width: '100%',
    padding: '0 24px',
  },
  meetingCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '24px 0 56px',

    [theme.breakpoints.down('xs')]: {
      margin: '32px 0 56px',
    },
  },
  members: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '202px',
    backgroundColor: getColor('GRAY_BG'),

    [theme.breakpoints.down('xs')]: {
      backgroundColor: getColor('WHITE_100'),
      borderBottom: `1px solid ${getColor('VELVET_30')}`,
      height: '210px',
    },
  },
  root: {
    display: 'flex',
    width: '100vw',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '48px',
  },
}));

const FACILITATOR_FALLBACK_CALENDLY_LINK = 'https://calendly.com/7chairsplanning/15min';

export type GroupSpaceMember = Pick<Account, 'id' | 'fullName' | 'name' | 'picture'> &
  Pick<Member, 'memberId' | 'memberStatus'> &
  Pick<AccountProfileProperties, 'intro' | 'passions' | 'descriptionTags' | 'whatBringsMe'> & {
    paid: boolean;
    isFacilitator: boolean;
    calendlyLink?: string;
  };

interface GroupSpaceProps {
  groupInstanceId: string;
}

export const GroupSpace = ({ groupInstanceId }: GroupSpaceProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { shallPay } = getPaymentStatus();

  const role = useSelector((state: RootState) => state.account.role);
  const groupLeaderAccountId = useSelector((state: RootState) => state.groupSpace.groupInfo.leaderAccountId);
  const groupMembers = useSelector((state: RootState) => state.groupSpace.groupInfo.members);
  const groupLab = useSelector((state: RootState) => state.groupSpace.groupInfo.groupLab);
  const exercise = useSelector((state: RootState) => state.exercises.current);
  const openExercise = useSelector((state: RootState) => state.exercises.openExercise);
  const pickAGroupUrl = useSelector((state: RootState) => state.account.pickAGroupUrl);
  const mobileAppVersion = useSelector((state: RootState) => state.account.meta?.mobileAppVersion);
  const accountDateCreated = useSelector((state: RootState) => state.account.dateCreated);
  const [members, setMembers] = useState<GroupSpaceMember[]>([]);

  useEffect(() => {
    if (groupMembers && groupMembers.length) {
      setMembers(
        groupMembers.map((groupMember) => ({
          ...groupMember,
          isFacilitator: groupMember.id === groupLeaderAccountId,
        }))
      );
    }
  }, [groupMembers, groupLeaderAccountId]);

  useEffect(() => {
    let paymentPopupTimeout = null;

    if (shallPay && groupInstanceId) {
      paymentPopupTimeout = setTimeout(() => {
        dispatch(setPaymentPopUpData({ open: true, source: 'memberCard' }));
      }, 5000);
    }

    return () => {
      if (paymentPopupTimeout) {
        clearTimeout(paymentPopupTimeout);
      }
    };
  }, [shallPay]);

  useEffect(() => {
    const facilitator = members.find(({ isFacilitator }) => isFacilitator);

    if (facilitator && !shallPay && role === AccountRole.Member) {
      const { calendlyLink } = facilitator;
      if (storage.showCalendlyPopup && !groupLab) {
        dispatch(
          setCalendlyPopUpData({
            url: calendlyLink || FACILITATOR_FALLBACK_CALENDLY_LINK,
            open: true,
          })
        );
        storage.showCalendlyPopup = false;
      }

      if (storage.showCalendlyGroupLabPopup && groupLab) {
        dispatch(
          setCalendlyPopUpData({
            url: calendlyLink || FACILITATOR_FALLBACK_CALENDLY_LINK,
            open: true,
            groupLab: !!calendlyLink,
          })
        );
        storage.showCalendlyGroupLabPopup = false;
      }
    }
  }, [members, shallPay, groupLab, role]);

  useEffect(() => {
    if (!mobileAppVersion && registeredMoreThanHourAgo) {
      dispatch(getHasSeenDownloadAppPopup());
    }
  }, [mobileAppVersion]);

  //we don't want to show the popup on mobile webapp, as they already have a popup on onboarding
  const now = moment(new Date());
  const registeredMoreThanHourAgo = moment.duration(now.diff(accountDateCreated)).asHours() > 1;

  return (
    <div className={classes.root}>
      <Survey />
      {openExercise && !!exercise && <Exercise />}
      {groupInstanceId && members.length > 0 ? (
        <div className={classes.members}>
          <Members key={`memlen-${members.length}`} members={members} />
        </div>
      ) : null}
      <div className={classes.content}>
        <div className={classes.meetingCard}>
          {groupInstanceId ? (
            <MeetingCard groupInstanceId={groupInstanceId} />
          ) : (
            <MeetingCardNoCircle redirectUrl={pickAGroupUrl} />
          )}
        </div>
        <ExerciseCardsList />
        <EventsList />
      </div>
    </div>
  );
};
