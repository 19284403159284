import { createAction, createApiAction, createReducer } from '../../utils';

// Types
export const types = {
  VALIDATE: 'validation/VALIDATE',
  VALIDATE_SUCCESS: 'validation/VALIDATE_SUCCESS',
  VALIDATE_ERROR: 'validation/VALIDATE_ERROR',
  VERIFY: 'validation/VERIFY',
  VERIFY_SUCCESS: 'validation/VERIFY_SUCCESS',
  VERIFY_ERROR: 'validation/VERIFY_ERROR',
  SET_PHONE_OR_EMAIL: 'validation/SET_PHONE_OR_EMAIL',
  SET_VALIDATION_MODE: 'validation/SET_VALIDATION_MODE',
};

// Actions
export function validate({ phoneNumber = '', email = '' }) {
  return createApiAction(
    [types.VALIDATE, types.VALIDATE_SUCCESS, types.VALIDATE_ERROR],
    `/signup/validate?phone=${phoneNumber}&email=${email}`,
    'get',
    undefined
  );
}

export function verify({ phoneNumber = '', email = '', code }) {
  return createApiAction(
    [types.VERIFY, types.VERIFY_SUCCESS, types.VERIFY_ERROR],
    `/signup/verify?phone=${phoneNumber}&email=${email}&code=${code}`,
    'get',
    undefined
  );
}

export function setPhoneOrEmail(props) {
  return createAction(types.SET_PHONE_OR_EMAIL, { props });
}

export function setValidationMode(payload) {
  return createAction(types.SET_VALIDATION_MODE, { payload });
}

// Reducer
const initialState = {
  phoneNumber: null,
  email: null,
  validationMode: false,

};

const handlers = {
  [types.VALIDATE_SUCCESS]: (state) => ({
    ...state,
    validationMode: true
  }),
  [types.VERIFY_SUCCESS]: (state) => ({
    ...state,
  }),
  [types.SET_PHONE_OR_EMAIL]: (state, { props }) => ({
    ...state,
    ...props
  }),
  [types.SET_VALIDATION_MODE]: (state, { payload }) => ({
    ...state,
    validationMode: payload
  }),
};


export default createReducer(initialState, handlers);
