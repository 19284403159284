import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import { NavigationContext } from '../../../../components/navigator';
import { CTA } from '../../../../components/Text';
import { trackEvent } from '../../../../utils/analyticsEvent';
import { OLD_SIZES } from '../../../../styles/appConsts';
import { MOBILE_WIDTH, DESKTOP_WIDTH } from '../../utils';

const useStyles = makeStyles((theme) => ({
  item: {
    marginBottom: OLD_SIZES.xl,
    display: 'inline-block',
  },
  smAvatar: {
    height: 48,
    width: 48,
  },
  xsAvatar: {
    height: 12,
    width: 12,
  },
  box: {
    borderRadius: 8,
    paddingTop: OLD_SIZES.m,
    paddingRight: OLD_SIZES.m,
    paddingBottom: OLD_SIZES.m,
    paddingLeft: OLD_SIZES.xl,
    textAlign: 'left',
    width: DESKTOP_WIDTH,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: OLD_SIZES.m,
      width: MOBILE_WIDTH,
    },
  },
  text: {
    paddingLeft: OLD_SIZES.s,
  },
}));

function ItemNotifications() {
  const navCtx = React.useContext(NavigationContext);
  const classes = useStyles();

  return (
    <Grid
      item
      className={classes.item}
      onClick={() => {
        trackEvent('click open notifications settings');
        navCtx.pushScreen('/notifications_settings');
      }}
    >
      <Box boxShadow={'0px 4px 20px rgba(0, 0, 0, 0.08)'} className={classes.box}>
        <Grid container alignItems='center' justifyContent='flex-start'>
          <img src={`${ASSETS_BASE_DIR}/profile/notifications-badge.svg`} className={classes.smAvatar} />
          <CTA fontWeight='500' className={classes.text}>
            Notifications
          </CTA>
          <img
            src={`${ASSETS_BASE_DIR}/rectangle_arrow.svg`}
            className={classes.xsAvatar}
            style={{ marginLeft: 'auto', marginRight: '15px' }}
          />
        </Grid>
      </Box>
    </Grid>
  );
}

export default ItemNotifications;
