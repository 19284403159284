import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Label1, Paragraph, Link } from '../../../../components/Text';
import { OLD_SIZES, OLD_COLORS } from '../../../../styles/appConsts';
import { useHistory } from 'react-router-dom';
import { NavigationContext } from '../../../../components/navigator';
import { trackEvent } from '../../../../utils/analyticsEvent';

const useStyles = makeStyles(() => ({
  root: {
    padding: `${OLD_SIZES.xl}px ${OLD_SIZES.m}px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  cta: {
    cursor: 'pointer',
    marginTop: OLD_SIZES.s,
    marginBottom: OLD_SIZES.s,
    maxWidth: 344,
    minWidth: 290,
    background: OLD_COLORS.GRAY_50,
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: OLD_SIZES.s,
    padding: OLD_SIZES.l,
    fontWeight: '500',
    color: OLD_COLORS.GRAY_500,
    '@media only screen and (min-width: 600px)': {
      fontWeight: 600,
      padding: OLD_SIZES.l,
    },
    '&:focus, &:active, &:hover': {
      color: `${OLD_COLORS.GRAY_500} !important`,
      backgroundColor: `${OLD_COLORS.GRAY_50} !important`,
      opacity: `0.8 !important`,
    },
  },
}));

const AvatarSexStep = ({ next, redirect }) => {
  const classes = useStyles();
  const history = useHistory();
  const navCtx = React.useContext(NavigationContext);

  return (
    <div className={classes.root}>
      <Label1 color={OLD_COLORS.GRAY_800} mb={OLD_SIZES.xl}>
        Create an avatar and name that will
        <br /> be shared with your Circle
      </Label1>
      <img src={`${ASSETS_BASE_DIR}/avatar/default-avatar.svg`} alt='' />
      <Paragraph color={OLD_COLORS.GRAY_800} mt={OLD_SIZES.l} mb={OLD_SIZES.xl}>
        I’m a
      </Paragraph>
      <div
        className={classes.cta}
        onClick={() => {
          trackEvent('avatar sex step click');
          next({ sex: 'female' });
        }}
      >
        Female
      </div>
      <div
        className={classes.cta}
        onClick={() => {
          trackEvent('avatar sex step click');
          next({ sex: 'male' });
        }}
      >
        Male
      </div>
      <div
        className={classes.cta}
        onClick={() => {
          trackEvent('avatar sex step click');
          next({ sex: 'other' });
        }}
      >
        Other
      </div>
      <Link
        onClick={() => {
          trackEvent('avatar save for later click');
          redirect();
        }}
        mt={OLD_SIZES.xxl}
        color={OLD_COLORS.VIBRANT_BLUE_2}
      >
        Save for later
      </Link>
    </div>
  );
};

export default AvatarSexStep;
