import { createAction, createReducer } from '../../utils';

export enum NoticeSeverity {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export type Notice = {
  isOpen: boolean;
  title?: string;
  subtitle?: string;
  text: string[];
  severity?: NoticeSeverity;
};

// Types
export const types = {
  OPEN_NOTICE: 'notice/OPEN_NOTICE',
  CLOSE_NOTICE: 'notice/CLOSE_NOTICE',
};

// Actions
export const openNotice = (content: Notice) => createAction(types.OPEN_NOTICE, { content });
export const closeNotice = () => createAction(types.CLOSE_NOTICE);

// Reducer
const initialState: Notice = {
  isOpen: false,
  title: null,
  subtitle: null,
  text: [],
  severity: NoticeSeverity.Info,
};

const handlers = {
  [types.OPEN_NOTICE]: (state, { content }: { content: Notice }) => ({
    ...state,
    ...content,
    isOpen: true,
  }),
  [types.CLOSE_NOTICE]: (state) => ({
    ...initialState,
  }),
};

export default createReducer(initialState, handlers);
