import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { InputText } from '../../Text';
import { OLD_SIZES, OLD_COLORS } from '../../../styles/appConsts';

const styles = () => ({
  container: {
    width: '100%',
    height: 150,
    marginTop: OLD_SIZES.m,
  },
  input: {
    fontFamily: 'Noto Sans',
    color: OLD_COLORS.BLACK,
    width: '100%',
    fontSize: 14,
    lineHeight: '19px',
  },
});

export default withStyles(styles)((props) => {
  const { classes, value, onChange, placeholder = '' } = props;

  return (
    <div className={classes.container}>
      <InputText placeholder={placeholder} value={value || ''} onChange={onChange} className={classes.input} />
    </div>
  );
});
