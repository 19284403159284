import { trackEvent } from '../../../../utils/analyticsEvent';
import { setDialogProps } from '../../../../store/ducks/root';
import {
  cancelSubscriptionContactUserRequest,
} from '../../../../store/ducks/payment';
import React, { useContext } from 'react';

import { useDispatch } from 'react-redux';

import { cancelPaymentContactUs } from '../../../../components/dialogs/mixedDialog/mixedDialogContent';

import { CancellationReason } from '@7chairs/types'
import { closeIfWebview } from '../utils';
import { NavigationContext } from '../../../../components/navigator/index';

export const useCancelSubscriptionModal = () => {
  const navCtx = useContext(NavigationContext);
  const dispatch = useDispatch()
  const dispatchSetDialogProps = (...props: Parameters<typeof setDialogProps>) => dispatch(setDialogProps(...props))
  const dispatchCancelSubscription = (...props: Parameters<typeof cancelSubscriptionContactUserRequest>) => dispatch(cancelSubscriptionContactUserRequest(...props))
  return (option: CancellationReason, comment?: string) => {
    trackEvent('click cancel subscription option', { option });
    cancelPaymentContactUs(dispatchSetDialogProps, {
      onApprove: () => {
        dispatchCancelSubscription(option, comment);
        if (closeIfWebview()) {
          return
        }
        navCtx.replaceScreen('/profile');
      },
      onClose: () => {
        dispatchCancelSubscription(option, comment);
        if (closeIfWebview()) {
          return
        }
        navCtx.replaceScreen('/profile');
      },
    });
  }
};