import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { getColor, Text, Tag } from '@7chairs/frontend';
import { AccountRole, AccountsTag, AccountsTagType } from '@7chairs/types';

import clsx from 'clsx';

interface BoxTagFieldProps {
  tagType: AccountsTagType;
  emptyErrorText: string;
  options: AccountsTag[];
  onTagClick: () => void;
}

const useStyles = makeStyles(() => ({
  optionsContainer: {
    border: `1px solid ${getColor('BLACK_20')}`,
    backgroundColor: getColor('WHITE_100'),
    borderRadius: '8px',
    cursor: 'pointer',
    minHeight: '171px',
    padding: '20px 16px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'start',
    columnGap: 10,
    rowGap: 12,
  },
  textAreaErrorBorder: {
    border: `1px solid ${getColor('RED_100')}`,
  },
}));

export const BoxTagField = ({ tagType, onTagClick, options, emptyErrorText }: BoxTagFieldProps) => {
  const classes = useStyles();
  // @ts-ignore
  const role: AccountRole = useSelector((state) => state.account.role);
  // @ts-ignore
  const descriptionTags = useSelector((state) => state.account.descriptionTags) || [];
  // @ts-ignore
  const passions = useSelector((state) => state.account.passions) || [];
  const tags: string[] = tagType === AccountsTagType.Description ? descriptionTags : passions;

  return (
    <>
      {role && tags ? (
        <Box
          className={clsx(
            classes.optionsContainer,
            tags.length == 0 && role !== AccountRole.Leader ? classes.textAreaErrorBorder : ''
          )}
          onClick={onTagClick}
        >
          {tags.length ? (
            <Box className={classes.container}>
              {options.map((option) => {
                if (tags.includes(option.key)) {
                  return (
                    <Tag
                      key={option.key}
                      label={option.label}
                      isSelected={true}
                      onClick={() => null}
                      value={option.key}
                      variant='light'
                    />
                  );
                }
              })}
            </Box>
          ) : (
            <Text variant='body' color='BLACK_40'>
              {emptyErrorText}
            </Text>
          )}
        </Box>
      ) : null}
    </>
  );
};
