import { AccountRole, MemberStatus, StreamChannelType } from '@7chairs/types';
import { useSelector } from 'react-redux';
import { SUPPORT_ACCOUNT_ID } from '../../../utils/consts';

interface UseStreamChatChannelListOptionsProps {
  groupId: string;
}

export const useStreamChatChannelListOptions = ({ groupId }: UseStreamChatChannelListOptionsProps) => {
  // @ts-ignore
  const id = useSelector((state) => state.account.id);
  // @ts-ignore
  const privateGroups = useSelector((state) => state.account.privateGroups);
  // @ts-ignore
  const role = useSelector((state) => state.account.role);
  // @ts-ignore
  const isPayment = useSelector((state) => state.account.isPayment);
  // @ts-ignore
  const members = useSelector((state) => state.groupSpace.groupInfo.members);

  const membersAccountIds: string[] = members?.map(({ id }) => id);

  // filter private channels ONLY with users that are members of picked group (group that is open in MGS)
  const privateChannelsIds: string[] = [
    ...Object.values(privateGroups ?? {})
      .filter(({ peerId }) => peerId === SUPPORT_ACCOUNT_ID || membersAccountIds?.includes(peerId))
      .map(({ groupInstanceId }) => groupInstanceId),
  ];

  const isActiveMember: boolean = members?.find((member) => member.id === id)?.memberStatus === MemberStatus.Active;

  const shouldQueryGroupChannel: boolean = role === AccountRole.Member ? isPayment && isActiveMember : isActiveMember;

  const filters = {
    frozen: false,
    hidden: false,
    disabled: false,
    type: { $in: [StreamChannelType.Group, StreamChannelType.Private] },
    id: {
      $in: shouldQueryGroupChannel ? [groupId, ...privateChannelsIds] : [...privateChannelsIds],
    },
  };

  return {
    filters,
  };
};
