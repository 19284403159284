import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import TimerComponent from './TimerComponent';
import { Label1, Header, Paragraph, SubHeader } from '../Text';
import { CtaButton } from '../buttons';
import { OLD_COLORS, OLD_SIZES } from '../../styles/appConsts';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    position: 'relative',
    overflowY: 'auto',
    padding: OLD_SIZES.xl,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
    backgroundImage: `url(${ASSETS_BASE_DIR}/exercise/exercise-intro-bg.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
  },
  content: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: 98,
  },
  betaDiv: {
    marginBottom: OLD_SIZES.m,
    backgroundColor: 'rgba(255, 255, 255, 0.61)',
    borderRadius: 4,
    padding: `${OLD_SIZES.xs}px ${OLD_SIZES.s}px`,
  },
  betaText: {
    fontSize: 12,
  },
  title: {
    fontSize: 22,
    width: '72%',
    marginBottom: OLD_SIZES.m,
  },
  paragraph: {
    marginBottom: OLD_SIZES.m,
    fontSize: 16,
  },
  label: {
    marginTop: OLD_SIZES.m,
    fontSize: 18,
    fontWeight: 600,
  },
  subheader: {
    marginTop: OLD_SIZES.m,
    fontSize: 14,
    fontWeight: 600,
  },
  timerDiv: {
    marginTop: OLD_SIZES.xxl,
  },
  listContainer: {
    paddingBottom: OLD_SIZES.m,
  },
  listDot: {
    paddingRight: 8,
  },
  listContent: {
    color: OLD_COLORS.WHITE,
    fontSize: 14,
    fontWeight: 500,
  },
  peopleImg: {
    marginTop: OLD_SIZES.l,
    width: 210,
    [theme.breakpoints.down('sm')]: {},
  },
  bottomButton: {
    flex: 'none',
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    justifyContent: 'center',
  },
  ctaButton: {
    width: '100%',
    color: OLD_COLORS.WHITE,
    backgroundColor: OLD_COLORS.BRAND_PURPLE_2,
    '&:hover': {
      color: OLD_COLORS.WHITE,
      backgroundColor: OLD_COLORS.BRAND_PURPLE_2,
    },
  },
}));

export default function Intro({ title, pages, onSubmit }) {
  const [step, setStep] = useState(0);
  const { content, cta } = pages[step];
  const classes = useStyles();

  const ctaClick = () => {
    if (step === pages.length - 1) {
      onSubmit();
    } else {
      setStep(step + 1);
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div>
          <img className={classes.logo} src={`${ASSETS_BASE_DIR}/logo_white.png`} />
        </div>
        <Header color={OLD_COLORS.WHITE} align='center' className={classes.title}>
          {title}
        </Header>
        <Grid container direction='column' alignItems='center'>
          {content.map((p, idx) => {
            switch (p.type) {
              case 'text':
                return (
                  <Paragraph key={`p-${idx}`} color={OLD_COLORS.WHITE} align='center' className={classes.paragraph}>
                    {p.content}
                  </Paragraph>
                );
              case 'label':
                return (
                  <Label1 key={`p-${idx}`} color={OLD_COLORS.WHITE} align='center' className={classes.label}>
                    {p.content}
                  </Label1>
                );
              case 'subheader':
                return (
                  <SubHeader key={`p-${idx}`} color={OLD_COLORS.WHITE} align='center' className={classes.subheader}>
                    {p.content}
                  </SubHeader>
                );
              case 'image':
                return (
                  <img
                    key={`p-${idx}`}
                    className={classes.peopleImg}
                    src={
                      'https://res.cloudinary.com/better-with-us/image/upload/v1602502172/chat-app/exercise-intro-answers_rlmu5h.png'
                    }
                  />
                );
              case 'listItem':
                return (
                  <Grid
                    key={`p-${idx}`}
                    container
                    wrap='nowrap'
                    alignItems='baseline'
                    className={classes.listContainer}
                  >
                    <img src={`${ASSETS_BASE_DIR}/exercise/list-dot.svg`} className={classes.listDot} />
                    <Label1 className={classes.listContent}>{p.content}</Label1>
                  </Grid>
                );
              case 'timer':
                return (
                  <div key={`p-${idx}`} className={classes.timerDiv}>
                    <TimerComponent label={p.content} />
                  </div>
                );
            }
          })}
        </Grid>
      </div>
      <div className={classes.bottomButton}>
        <CtaButton disableElevation className={classes.ctaButton} onClick={ctaClick}>
          {cta}
        </CtaButton>
      </div>
    </div>
  );
}
