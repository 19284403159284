import { makeStyles } from '@material-ui/core/styles';
import { getColor } from '@7chairs/frontend';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '24px',
    borderRadius: '12px',
    boxShadow: '0px 8px 30px rgba(80, 85, 136, 0.06)',
    backgroundColor: getColor('YELLOW_30'),
    background: `url(${ASSETS_BASE_DIR}/designSystem/meeting-card-background.svg)`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right top',
    width: '100%',
    minWidth: '343px',
    maxWidth: '560px',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    width: '207px',
    height: '72px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '295px',
  },
  buttonWrapper: {
    alignItems: 'end',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  button: {
    width: '295px',
  },
  buttonFindYourCircle: {
    marginTop: '15px',
  },
  buttonSessionUnavailable: {
    backgroundColor: getColor('PURPLE_DISABLED'),
  },
}));
