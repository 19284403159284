import React from 'react';

export default (props) => (
  <svg {...props} viewBox='0 0 12 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.6187 7.26656C11.6187 6.53184 11.0212 5.93434 10.2865 5.93434H9.62036V3.93602C9.62036 2.09955 8.12628 0.605469 6.28981 0.605469C4.45335 0.605469 2.95927 2.09955 2.95927 3.93602V5.93434H2.29316C1.55844 5.93434 0.960938 6.53184 0.960938 7.26656V12.5954C0.960938 13.3302 1.55844 13.9277 2.29316 13.9277H10.2865C11.0212 13.9277 11.6187 13.3302 11.6187 12.5954V7.26656ZM4.29148 3.93602C4.29148 2.83427 5.18807 1.93769 6.28981 1.93769C7.39156 1.93769 8.28814 2.83427 8.28814 3.93602V5.93434H4.29148V3.93602Z'
      fill={props.color}
    />
  </svg>
);
