import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import MixedDialog from '../../dialogs/mixedDialog/MixedDialog';
import { Paragraph } from '../../Text';
import { Icon, IconButton, Text } from '@7chairs/frontend';
import { createProgress, getExercise } from '../../../store/ducks/exercises';
import { OLD_COLORS } from '../../../styles/appConsts';
import { trackEvent } from '../../../utils/analyticsEvent';
import { ORIGIN_EXERCISE_SIGNUP } from '../../../utils/consts';
import { storage } from '../../../utils/localStorage';

interface StylesProps {
  backgroundUrl: string;
}

const useStyles = makeStyles(() => ({
  actionContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: '16px',
    justifyContent: 'flex-end',
  },
  clickableText: {
    cursor: 'pointer',
  },
  contentText: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
    overflow: 'hidden',
  },
  root: (props: StylesProps) => ({
    background: props.backgroundUrl,
    borderRadius: '12px',
    boxShadow: '0px 8px 30px rgba(80, 85, 136, 0.06)',
    display: 'flex',
    flexDirection: 'column',
    height: '176px',
    justifyContent: 'space-between',
    marginRight: '16px',
    minWidth: '248px',
    padding: '24px',
  }),
}));

const ICON_BUTTON_SIZE = 40;

interface ExerciseCardProps {
  origin: string;
  index: number;
  id: string;
  title: string;
  mode: 'new' | 'in_progress' | 'completed';
  onClick: () => void;
}

export const ExerciseCard = ({ origin, index, id, title, mode, onClick }: ExerciseCardProps) => {
  const classes = useStyles({
    backgroundUrl: `url(${ASSETS_BASE_DIR}/designSystem/exerciseCard/background-${index % 4 || 4}.svg)`,
  });
  const cookies = new Cookies();
  const dispatch = useDispatch();

  const [modalProps, setModalProps] = useState<Record<string, unknown>>({ open: false });

  const onCtaClick = () => {
    trackEvent('click open exercise');
    dispatch(getExercise(id))
      .then(() => {
        onClick();
      })
      .catch((error) => {
        console.error(`[ExerciseCard - onCtaClick] ${JSON.stringify(error)}`);
      });
  };

  const actionItems = () => {
    switch (mode) {
      case 'new':
        return (
          <div className={classes.actionContainer}>
            <div className={classes.clickableText} onClick={onCtaClick}>
              <Text variant={'bodyMedium'}>Start</Text>
            </div>
            <IconButton
              icon={<Icon.Fill.Play />}
              onClick={onCtaClick}
              stylesProps={{
                backgroundColor: 'WHITE_100',
                color: 'PURPLE_100',
                size: ICON_BUTTON_SIZE,
                withShadow: false,
              }}
            />
          </div>
        );
      case 'in_progress':
        return (
          <div className={classes.actionContainer}>
            <div className={classes.clickableText} onClick={onCtaClick}>
              <Text variant={'bodyMedium'}>Resume</Text>
            </div>
            <IconButton
              icon={<Icon.Fill.Pause />}
              onClick={onCtaClick}
              stylesProps={{
                backgroundColor: 'WHITE_100',
                color: 'PURPLE_100',
                size: ICON_BUTTON_SIZE,
                withShadow: false,
              }}
            />
          </div>
        );
      case 'completed':
        return (
          <div className={classes.actionContainer}>
            <div className={classes.clickableText} onClick={onCtaClick}>
              <Text variant={'bodyMedium'}>Completed</Text>
            </div>
            <IconButton
              icon={<Icon.Fill.Check />}
              onClick={onCtaClick}
              stylesProps={{
                backgroundColor: 'WHITE_100',
                color: 'PURPLE_100',
                size: ICON_BUTTON_SIZE,
                withShadow: false,
              }}
            />
          </div>
        );
      default:
        const error: never = mode;
        console.error(`Wrong mode: ${error}`);
    }
  };

  useEffect(() => {
    const remoteExerciseId = cookies.get('exerciseId');
    if (remoteExerciseId === id && origin === ORIGIN_EXERCISE_SIGNUP && mode === 'new') {
      dispatch(getExercise(id)).then(() => {
        dispatch(createProgress(id)).then(onClick);
      });
    }
    if (storage.showNewExercisePopup && index === 1) {
      storage.showNewExercisePopup = false;
      setModalProps({
        key: 'exercisePromo',
        id: 'exercisePromo',
        open: true,
        contentComponents: [
          { type: 'Header', content: 'Start feeling better now' },
          {
            type: 'Paragraph',
            content: 'Completing a short group exercise and process you feelings',
          },
          {
            type: 'Image',
            content: `${ASSETS_BASE_DIR}/exercise/promoImg.svg`,
            style: { width: '100%', marginTop: -20 },
          },
          {
            type: 'CtaButton',
            action: 'submit',
            content: "Let's start!",
            style: { backgroundColor: OLD_COLORS.VIBRANT_BLUE_2 },
          },
        ],
        onSubmit: () => {
          trackEvent('click exercise promo submit');
          setModalProps({ open: false });
          dispatch(getExercise(id)).then(() => {
            dispatch(createProgress(id)).then(onClick);
          });
        },
      });
    }
  }, []);

  return (
    <>
      <MixedDialog {...modalProps} onClose={() => setModalProps({ open: false })} />
      <div className={classes.root}>
        <Text className={classes.contentText} variant={'subtitleSemibold'}>
          {title}
        </Text>
        {actionItems()}
      </div>
    </>
  );
};
