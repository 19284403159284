import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import TopBar from '../../TopBarContainer';

interface StylesProps {
  offset: number;
}

const useStyles = makeStyles(() => ({
  base: (props: StylesProps) => ({
    display: 'flex',
    height: `calc(100vh - ${props.offset}px)`,
    justifyContent: 'center',
    overflowY: 'scroll',
  }),
  content: {
    maxWidth: '920px',
    width: '100%',
  },
}));

const TOP_BAR_HEIGHT = 64;

interface WithHeaderFromPreviousDesignSystemProps {
  className?: string;
  topBarProps: Record<string, unknown>; // no need to type old component which is deprecated due to new design system
}

export const WithHeaderFromPreviousDesignSystem: FC<WithHeaderFromPreviousDesignSystemProps> = ({
  children,
  className,
  topBarProps,
}) => {
  const offset = TOP_BAR_HEIGHT;
  const classes = useStyles({ offset });

  return (
    <>
      <TopBar {...topBarProps} />
      <div className={classes.base}>
        <div className={clsx(classes.content, className)}>{children}</div>
      </div>
    </>
  );
};
