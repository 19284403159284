import React from 'react';
import { Button, Icon, Popup, Text } from '@7chairs/frontend';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  title: {
    marginTop: '24px',
    height: '72px',
  },
  body: {
    marginTop: '16px',
    height: '124px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: '48px',
  },
  button: {
    width: '287px',
    height: '56px',
  },
}));

interface NoIntroPopupProps {
  open: boolean;
  onClose: () => void;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick: () => void;
}

export const NoIntroPopup = ({ open, onClose, onPrimaryButtonClick, onSecondaryButtonClick }: NoIntroPopupProps) => {
  const classes = useStyles();
  return (
    <Popup
      onClose={onClose}
      open={open}
      stylesProps={{ background: `${ASSETS_BASE_DIR}/designSystem/default-popup-background.svg` }}
    >
      <Icon.Creative.Key size={60} />
      <div className={classes.title}>
        <Text variant={'titleH3'} color={'BLACK_100'} className={classes.title}>
          Who else is in your Circle?
        </Text>
      </div>
      <div className={classes.body}>
        <Text variant={'body'} color={'BLACK_80'}>
          Edit your story so your Circle members can view your profile and you can view theirs.
        </Text>
      </div>
      <div className={classes.buttons}>
        <Button className={classes.button} onClick={onPrimaryButtonClick} size='small' variant='primary'>
          Edit my story
        </Button>
        <Button className={classes.button} onClick={onSecondaryButtonClick} size='small' variant='secondary'>
          Maybe later
        </Button>
      </div>
    </Popup>
  );
};
