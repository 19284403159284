import React, { useState, useRef, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Input } from '@material-ui/core';
import clsx from 'clsx';
import { OLD_COLORS, OLD_SIZES } from '../../styles/appConsts';

const styles = () => ({
  root: {
    display: 'flex',
  },
  square: {
    width: 48,
    height: 48,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spacing: {
    marginLeft: OLD_SIZES.s,
  },
  empty: {
    backgroundColor: OLD_COLORS.GRAY_50,
    border: `1px solid ${OLD_COLORS.BLACK}`,
  },
  filled: {
    border: `1px solid ${OLD_COLORS.BLACK}`,
  },
  error: {
    border: `1px solid ${OLD_COLORS.RED_500}`,
  },
  input: {
    textAlign: 'center',
  },
  inputError: {
    color: OLD_COLORS.RED_500,
  },
});

export default withStyles(styles)((props) => {
  const { classes, error, numOfDigits = 6, onChange, onSubmit, onDone = () => null } = props;
  const refs = [];
  for (let i = 0; i < numOfDigits; i++) {
    refs.push(useRef(null));
  }
  const [codeArr, setCodeArr] = useState([]);
  const nextRef = React.useRef({ codeArr });

  const downHandler = ({ key }) => {
    if (nextRef.current.codeArr.length > 0 && key === 'Backspace') {
      const arr = nextRef.current.codeArr;
      arr.pop();
      setCodeArr([...arr]);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, []);

  useEffect(() => {
    nextRef.current = { ...nextRef.current, codeArr };
    onChange(codeArr.join(''));
    if (codeArr.length === numOfDigits) {
      onDone(codeArr.join(''));
    } else {
      const cur = refs[codeArr.length];
      cur.current.firstElementChild.focus();
    }
  }, [codeArr]);

  const onInputChange = (e) => {
    const dig = e.target.value
      .split('')
      .map((d) => parseInt(d))
      .filter((n) => !isNaN(n));

    setCodeArr([...codeArr, ...dig].slice(0, numOfDigits));
  };
  const onKeyDown = (e) => (e.key === 'Enter' ? onSubmit() : null);
  return (
    <div className={classes.root}>
      {refs.map((refEl, idx) => {
        const stateCls = typeof codeArr[idx] !== 'number' ? classes.empty : error ? classes.error : classes.filled;
        const spacingCls = idx > 0 ? classes.spacing : '';
        return (
          <div key={`dig-${idx}`} className={clsx(classes.square, stateCls, spacingCls)}>
            <Input
              ref={refEl}
              classes={{ input: clsx(classes.input, error ? classes.inputError : '') }}
              disableUnderline
              autoFocus={idx === 0}
              type='tel'
              value={typeof codeArr[idx] === 'number' ? codeArr[idx] : ''}
              onKeyDown={onKeyDown}
              onChange={onInputChange}
            />
          </div>
        );
      })}
    </div>
  );
});
