import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { AccountRole, AccountsTag, AccountsTagType } from '@7chairs/types';
import { Button, getColor, Input, Text } from '@7chairs/frontend';

import { AccountStore, getAccountProfile, updateAccountProfile } from '../../../store/ducks/account';
import { getAccountsTags, ProfileStore } from '../../../store/ducks/profile';

import { trackEvent } from '../../../utils/analyticsEvent';

import { NavigationContext } from '../../../components/navigator';
import { validNameRegex } from './components/ItemPersonalDetails';
import { BoxTagField } from './components/BoxTagField';
import { TagOptionsMode } from './profileTagOptions/ProfileTagOptions';

const useStyles = makeStyles((theme) => ({
  avatar: {
    alignSelf: 'center',
    border: `6px solid ${getColor('WHITE_100')}`,
    borderRadius: '50%',
    cursor: 'pointer',
    height: '120px',
    width: '120px',
  },
  avatarWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '32px',
  },
  button: {
    width: '327px',
    [theme.breakpoints.down('xs')]: {
      width: '311px',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '54px',
    width: '327px',

    [theme.breakpoints.down('xs')]: {
      marginTop: '24px',
    },
  },
  editIcon: {
    color: getColor('WHITE_100'),
    fontSize: '20px',
    left: '5px',
    position: 'relative',
    top: '6px',
  },
  editIconWrapper: {
    backgroundColor: getColor('PURPLE_100'),
    borderRadius: '50%',
    boxShadow: `0 2px 4px ${getColor('BLACK_100')}4D`,
    cursor: 'pointer',
    height: '32px',
    width: '32px',
    position: 'relative',
    right: '35px',
    top: '40px',
  },
  footer: {
    alignItems: 'center',
    backgroundColor: getColor('WHITE_100'),
    bottom: 0,
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    height: '141px',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      height: '88px',
    },
  },
  imageContainer: {
    alignItems: 'center',
    backgroundColor: `${getColor('PURPLE_100')}1A`,
    display: 'flex',
    height: '165px',
    justifyContent: 'center',
    padding: '22px 0',
    width: '100%',
  },
  propertiesContainer: {
    marginBottom: '40px',
  },
  textDescription: {
    marginBottom: '24px',
  },
  textTitle: {
    marginBottom: '8px',
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 205px)', // 205px is height of header and footer combined
    overflowY: 'scroll',

    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 144px)', // 144px is height of header and footer combined
    },
  },
}));

export const ProfileItems = () => {
  const classes = useStyles();
  const navCtx = useContext(NavigationContext);
  const dispatch = useDispatch();

  const isMobileWebview = useMemo(() => window.navigator.userAgent.indexOf('circles-mobile-app') > -1, []);

  // TODO: enrich DefaultRootState with account: AccountStore and groups
  // @ts-ignore
  const account: AccountStore = useSelector((state) => state.account);
  // @ts-ignore
  const profileStore: ProfileStore = useSelector((state) => state.profile);

  const [name, setName] = useState<string>(account.name || '');
  const [story, setStory] = useState<string>(account.intro || '');
  const [whatBringsMe, setWhatBringsMe] = useState<string>(account.whatBringsMe || '');
  const [passions, setPassions] = useState<AccountsTag[]>(profileStore.passions);
  const [descriptionTags, setDescriptionTags] = useState<AccountsTag[]>(profileStore.descriptions);
  const myStory = useRef(null);

  const invalidName = useMemo(() => !validNameRegex.test(name) || name.length > 40, [name]);

  const LETTER_LIMIT = [AccountRole.Leader, AccountRole.PeerGf].includes(account?.role) ? 1000 : 200;

  const onUpdateProfile = () => {
    if (
      invalidName ||
      (account.role !== AccountRole.Leader &&
        (story.length == 0 ||
          account.descriptionTags.length === 0 ||
          account.passions.length === 0 ||
          whatBringsMe.length == 0))
    ) {
      return;
    }

    const trackingObject = {
      myDescriptions: !!account.descriptionTags.length,
      myPassions: !!account.passions.length,
      myStory: !!story,
      whatBringsMe: !!whatBringsMe,
    };
    trackEvent('Click update newprofile', trackingObject);

    dispatch(
      updateAccountProfile(account.id, {
        name,
        intro: story,
        whatBringsMe,
      })
    ).then(() => {
      // @ts-ignore
      if (isMobileWebview && window.ReactNativeWebView) {
        // @ts-ignore
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ action: 'updateMemberProfile', update: { name, intro: story, whatBringsMe } })
        );
        // @ts-ignore
        window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'close' }));
      } else {
        navCtx.popScreen();
      }
    });
  };

  const onTagOptionsClick = (mode: TagOptionsMode) => {
    // quickfix to be removed in the future ---START---
    if (invalidName) {
      return;
    }
    dispatch(
      updateAccountProfile(account.id, {
        name,
        intro: story,
        whatBringsMe,
      })
    );
    // quickfix to be removed in the future ---END---
    navCtx.pushScreen(`/profile/items/tag-options?mode=${mode}`);
  };

  const onImageClick = () => {
    // quickfix to be removed in the future ---START---
    if (invalidName || account.role === AccountRole.Leader) {
      return;
    }
    dispatch(
      updateAccountProfile(account.id, {
        name,
        intro: story,
        whatBringsMe,
      })
    );
    // quickfix to be removed in the future ---END---
    navCtx.pushScreen('/profile/items/image-builder');
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const scrollToStory = queryParams.get('scrollToStory');
    if (scrollToStory) {
      myStory.current.scrollIntoView();
    }
    dispatch(getAccountProfile(account.id)).then((response) => {
      const responsePayloadData = response.payload.data;
      setName(responsePayloadData.name || '');
      setStory(responsePayloadData.intro || '');
      setWhatBringsMe(responsePayloadData.whatBringsMe || '');
    });
    dispatch(getAccountsTags()).then((response) => {
      const responsePayloadData = response.payload.data;
      setPassions(responsePayloadData.filter((obj) => obj.type === 'passion'));
      setDescriptionTags(responsePayloadData.filter((obj) => obj.type === 'description'));
    });
  }, []);

  return (
    <>
      <Box className={classes.wrapper}>
        {!isMobileWebview && (
          <Box className={classes.imageContainer}>
            <Box className={classes.avatarWrapper}>
              <Avatar alt='avatar' className={classes.avatar} onClick={onImageClick} src={account.picture} />
              {account.role !== AccountRole.Leader ? (
                <Box className={classes.editIconWrapper} onClick={onImageClick}>
                  <EditIcon className={classes.editIcon} />
                </Box>
              ) : null}
            </Box>
          </Box>
        )}
        <Box className={classes.contentContainer}>
          <Box className={classes.propertiesContainer}>
            <Text variant="subtitleBold" className={classes.textTitle}>
              My name
            </Text>
            <Text variant='body' className={classes.textDescription}>
              Use your real name or a nickname - whatever feels comfortable.
            </Text>
            <Input.Text
              onChange={(e) => setName(e.substring(0, LETTER_LIMIT))}
              placeholder='Type here...'
              value={name}
              errorMessage={
                invalidName ? (name.indexOf(' ') !== -1 ? 'Name cannot include spaces' : 'Name not valid') : ''
              }
              disabled={account.role === AccountRole.Leader}
            />
          </Box>
          {/* the below object with ref is a hack as material-ui Box is not typed correctly */}
          {/* https://github.com/mui-org/material-ui/issues/17010#issuecomment-615577360 */}
          <Box {...{ ref: myStory }} className={classes.propertiesContainer}>
            <Text variant='subtitleBold' className={classes.textTitle}>
              My story
            </Text>
            <Text variant='body' className={classes.textDescription}>
              Just a short bio so we can get to know you a little better.
            </Text>
            <Input.Textarea
              maxLength={LETTER_LIMIT}
              errorMessage={story.length == 0 && account.role !== AccountRole.Leader ? 'Please add a short bio' : ''}
              onChange={(e) => {
                setStory(e.substring(0, LETTER_LIMIT));
              }}
              placeholder='I’m a mother of 2 beautiful girls, and I work as an Operations Manager in a small tech company. In my free time, I love to travel, do yoga and listen to music.'
              value={story}
              disabled={account.role === AccountRole.Leader}
            />
          </Box>
          <Box className={classes.propertiesContainer}>
            <Text variant='subtitleBold' className={classes.textTitle}>
              My passions
            </Text>
            <Text variant='body' className={classes.textDescription}>
              Select up to 5 topics that matter a whole lot to you.
            </Text>
            <BoxTagField
              tagType={AccountsTagType.Passion}
              onTagClick={() => onTagOptionsClick(TagOptionsMode.Passions)}
              emptyErrorText={'Click to choose your super passions'}
              options={passions}
            />
          </Box>
          <Box className={classes.propertiesContainer}>
            <Text variant='subtitleBold' className={classes.textTitle}>
              Which of these best describes you?
            </Text>
            <Text variant='body' className={classes.textDescription}>
              Select up to 5
            </Text>
            <BoxTagField
              tagType={AccountsTagType.Description}
              onTagClick={() => onTagOptionsClick(TagOptionsMode.DescriptionTags)}
              emptyErrorText={'Click to choose your super powers'}
              options={descriptionTags}
            />
          </Box>
          <Box className={classes.propertiesContainer}>
            <Text variant='subtitleBold' className={classes.textTitle}>
              What brings me to Circles
            </Text>
            <Text variant='body' className={classes.textDescription}>
              Please share what drives you to seek support.
            </Text>
            <Input.Textarea
              maxLength={LETTER_LIMIT}
              errorMessage={
                whatBringsMe.length == 0 && account.role !== AccountRole.Leader ? 'What drives you to seek support' : ''
              }
              onChange={(e) => {
                setWhatBringsMe(e.substring(0, LETTER_LIMIT));
              }}
              placeholder='I’ve recently divorced my partner of 10 years. It’s been difficult to deal with all of the complicated emotions, so I figured sharing with people that can relate could help.'
              value={whatBringsMe}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.footer}>
        <Button variant='primary' size='big' className={classes.button} onClick={onUpdateProfile}>
          Update profile
        </Button>
      </Box>
    </>
  );
};
