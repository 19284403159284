import React from 'react';
import { Button, Icon, Popup, Text } from '@7chairs/frontend';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  title: {
    marginTop: '35px',
    maxWidth: '456px',
    textAlign: 'center',
  },
  body: {
    marginTop: '16px',
    maxWidth: '456px',
    textAlign: 'center',
  },
  button: {
    marginTop: '40px',
    width: '216px',
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

interface NoMeetingPopupProps {
  open: boolean;
  onClose: () => void;
}

export const NoMeetingPopup = ({ open, onClose }: NoMeetingPopupProps) => {
  const classes = useStyles();
  return (
    <Popup
      onClose={onClose}
      open={open}
      stylesProps={{ background: `${ASSETS_BASE_DIR}/designSystem/default-popup-background.svg` }}
    >
      <div className={classes.wrapper}>
        <div>
          <Icon.Creative.Key size={60} />
          <div className={classes.title}>
            <Text variant={'titleH3'} color={'BLACK_100'} className={classes.title}>
              This room opens 30 minutes before your meeting
            </Text>
          </div>
          <div className={classes.body}>
            <Text variant={'body'} color={'BLACK_80'}>
              In between meetings, you can connect with the members of your Circle in the chat for support that’s always
              in reach.
            </Text>
          </div>
        </div>
        <Button className={classes.button} onClick={onClose} size='big' variant='primary'>
          Got it
        </Button>
      </div>
    </Popup>
  );
};
