import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import clsx from 'clsx';
import LikeToggleButton from '../../LikeToggleButton';
import { Paragraph } from '../../Text';
import { OLD_COLORS, OLD_SIZES } from '../../../styles/appConsts';

const useStyles = makeStyles(() => ({
  root: {
    width: 210,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    width: 36,
    height: 36,
    boxShadow: '-1px 1px 4px rgba(0, 0, 0, 0.2), -1px 0px 2px rgba(0, 0, 0, 0.15)',
    border: '2px solid rgba(255, 255, 255, 0.5)',
  },
  name: {
    marginTop: OLD_SIZES.xs,
    marginBottom: OLD_SIZES.s,
  },
  answerContainer: {
    backgroundColor: OLD_COLORS.WHITE,
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    padding: OLD_SIZES.s,
    width: '100%',
  },
  compactAnswer: {
    opacity: 0.5,
    height: 100,
  },
  fullAnswer: {
    height: 226,
    display: 'flex',
    flexDirection: 'column',
  },
  compactText: {
    maxHeight: 76,
    overflow: 'hidden',
    whiteSpace: 'pre-line',
    hyphens: 'auto',
    filter: 'blur(3px)',
    color: OLD_COLORS.BLACK,
  },
  fullText: {
    maxHeight: 172,
    overflowX: 'hidden',
    overflowY: 'auto',
    hyphens: 'auto',
    color: OLD_COLORS.BLACK,
  },
  bottom: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
  },
  pointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  editIcon: {
    color: OLD_COLORS.BRAND_PURPLE_2,
  },
}));

export default function ({ mode, name, picture, answer, me, liked, onEdit, toggleLike }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar src={picture} className={classes.avatar} />
      <Paragraph className={classes.name} color={OLD_COLORS.WHITE} align='center'>
        {name}
      </Paragraph>
      <div className={clsx(classes.answerContainer, mode === 'compact' ? classes.compactAnswer : classes.fullAnswer)}>
        <Paragraph className={mode === 'compact' ? classes.compactText : classes.fullText}>{answer}</Paragraph>
        {mode === 'full' && (
          <div className={classes.bottom}>
            {me && (
              <div className={classes.pointer} onClick={onEdit}>
                <Create className={classes.editIcon} />
              </div>
            )}
            {!me && <LikeToggleButton liked={liked} toggleLike={toggleLike} />}
          </div>
        )}
      </div>
    </div>
  );
}
