import makeStyles from '@material-ui/core/styles/makeStyles';
import { OLD_COLORS } from '../../../../../styles/appConsts';

export const styles = makeStyles(() => ({
  root: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '95vh',
    overflow: 'scroll',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '500',
  },
  title: {
    color: OLD_COLORS.GRAY_500,
    lineHeight: '18px',
    fontSize: '12px',
    fontWeight: '300',
    paddingBottom: '23px',
  },
  description: {
    lineHeight: '21px',
    fontSize: '14px',
    fontWeight: '300',
    paddingTop: '24px',
    paddingBottom: '4px',
    paddingLeft: '15%',
    paddingRight: '15%',
  },
  divider: {
    fontSize: '14px',
    color: OLD_COLORS.GRAY_500,
  },
  createAvatar: {
    color: OLD_COLORS.VIBRANT_BLUE_2,
    marginTop: '8px',
    marginBottom: '15px',
    cursor: 'pointer',
    fontSize: '14px',
  },
  saveLater: {
    fontSize: '14px',
    marginTop: '10px',
    cursor: 'pointer',
  },
}));
