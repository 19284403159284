import React, { useContext, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog } from '@material-ui/core';
import { STRAPI_PATH } from '../../utils/consts';
import { setPaymentPopUpData } from '../../store/ducks/root';
import { extractAnalyticsCookies, trackEvent } from '../../utils/analyticsEvent';
import { NavigationContext } from '../navigator';
import { OLD_COLORS } from '../../styles/appConsts';
import {navigateToPaymentPage} from "../../utils/payment";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 30,
    background: `linear-gradient(${OLD_COLORS.PALE_ORANGE}, ${OLD_COLORS.PALE_ORANGE} 30%, ${OLD_COLORS.WHITE} 50%, ${OLD_COLORS.WHITE})`,
    margin: 12,
    overflowY: 'unset',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: `url("${ASSETS_BASE_DIR}/payment/blob-background.webp") no-repeat top left`,
    maxWidth: 600,
    borderRadius: 30,
    padding: '64px 32px 32px 32px',
    [theme.breakpoints.up('sm')]: {
      padding: '64px 80px 32px 80 px',
      minHeight: 550,
    },
  },
  image: {
    width: 84,
    height: 84,
  },
  header: {
    fontSize: 28,
    fontWeight: 600,
    lineHeight: '42px',
    marginTop: 16,
  },
  subheader: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    marginTop: 20,
  },
  button: {
    backgroundColor: OLD_COLORS.BRAND_PURPLE_2,
    border: `1px solid ${OLD_COLORS.BRAND_PURPLE_3}`,
    borderRadius: 100,
    fontFamily: 'Poppins',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
    color: OLD_COLORS.WHITE,
    padding: 20,
    width: '100%',
    maxWidth: 288,
    marginTop: 60,
    [theme.breakpoints.up('sm')]: {
      marginTop: 'auto',
    },
  },
}));

export const PaymentPopUp = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const navCtx = useContext(NavigationContext);

  const open = useSelector((state) => state.root.paymentPopUpData.open);
  const skipCallback = useSelector((state) => state.root.paymentPopUpData.skipCallback);
  const addPaymentCallback = useSelector((state) => state.root.paymentPopUpData.addPaymentCallback);
  const source = useSelector((state) => state.root.paymentPopUpData.source) || 'default';

  const strapiPaymentPopup = useSelector((state) => state.strapi[STRAPI_PATH.PAYMENT_POPUP.key]);

  const contentComponents = useMemo(() => {
    return (
      strapiPaymentPopup.find((item) => item.source === source)?.content ||
      strapiPaymentPopup.find((item) => item.source === 'default')?.content ||
      []
    );
  }, [source, strapiPaymentPopup]);

  const paymentHeader = useMemo(() => {
    return contentComponents.find((item) => item.type === 'Header')?.content || '';
  }, [contentComponents]);

  const paymentSubheader = useMemo(() => {
    return contentComponents.find((item) => item.type === 'SubHeader')?.content || '';
  }, [contentComponents]);

  const [ctaButtonText, ctaButtonAction] = useMemo(() => {
    return [
      contentComponents.find((item) => item.type === 'CtaButton')?.content || '',
      contentComponents.find((item) => item.type === 'CtaButton')?.action || 'skip',
    ];
  }, [contentComponents]);

  const { isPayment } = extractAnalyticsCookies();

  if (open) {
    if (isPayment || typeof isPayment === 'undefined') {
      trackEvent('view payment popup error');
      if (skipCallback) {
        skipCallback();
      }
      return null;
    }
  }

  return (
    <Dialog classes={{ paper: classes.root }} open={open && contentComponents.length > 0}>
      <div className={classes.container}>
        <img className={classes.image} src={`${ASSETS_BASE_DIR}/payment/heart-key.webp`} alt={''} />
        <span className={classes.header}>{paymentHeader}</span>
        <span className={classes.subheader}>{paymentSubheader}</span>
        <button
          className={classes.button}
          onClick={() => {
            dispatch(setPaymentPopUpData({ open: false }));
            addPaymentCallback(ctaButtonAction);
            if (ctaButtonAction === 'addPayment') {
              trackEvent('click payment popup add');
              navigateToPaymentPage()
            }
            if (ctaButtonAction === 'skip') {
              trackEvent('click payment popup skip');
              if (skipCallback) {
                skipCallback();
              }
            }
            if (ctaButtonAction === 'addPaymentFree1on1') {
              trackEvent('click payment popup add', { referrer: 'free 1:1 session' });
              navigateToPaymentPage()
            }
          }}
        >
          {ctaButtonText}
        </button>
      </div>
    </Dialog>
  );
};
