import React from 'react';
import questionTypes from '../questionTypes';
import TextAnswers from './Text';
import RankAnswers from './RankAvarage';
import MultiChoiceAnswers from './MultiChoice';
import SingleChoiceAnswers from './SingleChoice';

const components = {
  [questionTypes.TEXT]: TextAnswers,
  [questionTypes.RANK]: RankAnswers,
  [questionTypes.MULTI_CHOICE]: MultiChoiceAnswers,
  [questionTypes.SINGLE_CHOICE]: SingleChoiceAnswers,
};

export default function ({
  questionType,
  optionalAnswers,
  additionalContent,
  totalAnswers,
  answers,
  watchAnswersMode,
  color,
  onEditClick,
  toggleLike,
}) {
  const compProps = {
    [questionTypes.TEXT]: { answers, watchAnswersMode, totalAnswers, onEditClick, toggleLike },
    [questionTypes.RANK]: {
      settings: optionalAnswers?.[0],
      additionalContent,
      answers,
      watchAnswersMode,
      color,
      onEditClick,
    },
    [questionTypes.MULTI_CHOICE]: { optionalAnswers, answers, watchAnswersMode, color, onEditClick },
    [questionTypes.SINGLE_CHOICE]: { optionalAnswers, answers, watchAnswersMode, color, onEditClick },
  };

  const Comp = components[questionType];

  return <Comp {...compProps[questionType]} />;
}
