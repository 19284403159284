import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Event } from './Event';
import { getAccountGeneralContent } from '../../../store/ducks/account';
import { makeStyles } from '@material-ui/core/styles';
import { Text } from '@7chairs/frontend';

const useStyles = makeStyles(() => ({
  eventsList: {
    display: 'flex',
    marginBottom: '56px',
    maxWidth: 560,
    overflowX: 'auto',
    width: '100%',
  },
  title: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '24px',
    maxWidth: 560,
    width: '100%',
  },
}));

export const EventsList = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  // @ts-ignore
  const events = useSelector((state) => state.account.events);

  useEffect(() => {
    if (!events.length) {
      dispatch(getAccountGeneralContent());
    }
  }, []);

  return (
    <>
      <div className={classes.title}>
        <Text variant={'subtitle'}>Events & webinars</Text>
      </div>
      <div className={classes.eventsList}>
        {events.map((event, index) => (
          <Event key={event.id} index={index + 1} {...event} />
        ))}
      </div>
    </>
  );
};
