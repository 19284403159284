import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ping } from '../../store/ducks/auth';

const PING_INTERVAL = 5000;

export const usePingInterval = ({ userId }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    const id = setInterval(() => {
      const screen = pathname.split('/')?.[1];

      if (userId) {
        dispatch(ping({ screen }));
      }
    }, PING_INTERVAL);

    return () => {
      clearInterval(id);
    };
  }, [userId, pathname]);
};
