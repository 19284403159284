import React, { useContext, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Header, Paragraph } from '../../components/Text';
import { Button } from '@7chairs/frontend';
import { NavigationContext } from '../../components/navigator';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '32px auto',
    gap: '32px',
    [theme.breakpoints.down('sm')]: {
      gap: '16px',
      margin: '32px 16px',
    },
  },
  section: {
    textAlign: 'center',
  },
  ctaBtn: {
    marginTop: '16px',
    cursor: 'pointer',
    width: '160px',
  },
}));

function CancelSubscriptionConfirmation() {
  const classes = useStyles();
  const navCtx = useContext(NavigationContext);

  const isMobileWebview = useMemo(() => window.navigator.userAgent.indexOf('circles-mobile-app') > -1, [
    window.navigator.userAgent,
  ]);

  const onButtonClick = () => {
    if (isMobileWebview) {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'close' }));
    } else {
      navCtx.replaceScreen(`/purchase`);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Header className={classes.section}>Your cancellation request has been sent</Header>
      <Paragraph className={classes.section}>
        Please allow up to 3 business days to process. A confirmation will be sent to your registered email. For any
        questions, reach out to support@circlesup.com — we're here to help.
      </Paragraph>
      <Button className={classes.ctaBtn} onClick={onButtonClick} size="big" variant="primary">
        Got it
      </Button>
    </div>
  );
}

export default CancelSubscriptionConfirmation;
