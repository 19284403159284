import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCalendlyPopUpData } from '../../store/ducks/root';
import MixedDialog from '../dialogs/mixedDialog/MixedDialog';
import { trackEvent } from '../../utils/analyticsEvent';

export const GhostGroupMemberPopup = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const { name } = useSelector((state) => state.account);

  const contentComponents = [
    { type: 'SubHeader', content: 'Finding you the best Circle', style: { marginTop: 16 } },
    { type: 'Paragraph', content: `Hi ${name}, great to see you again!`, style: { marginTop: 16 } },
    {
      type: 'Paragraph',
      content: `Our wish is to match you with the best Circle for you and make sure you have everything you need to attend the meetings and connect with your group. Since the initial group you chose has already started, we want to help you find a new one.
        Our advisors will be happy to match you with a group which is starting now and help you onboard in the best way.`,
      style: { marginTop: 8, marginBottom: 24 },
    },
    {
      type: 'CtaButton',
      action: 'bookCall',
      content: 'Book a call now',
    },
  ];

  const handleAction = (action) => {
    if (action === 'bookCall') {
      trackEvent('click ghost member book call', { version: 'v1' });
      dispatch(
        setCalendlyPopUpData({ open: true, url: 'https://calendly.com/7chairsplanning/15min?utm_source=Funnel' })
      );
    }

    onClose();
  };

  return (
    <MixedDialog
      id="ghostGroupMemberPopup"
      open={open}
      onClose={onClose}
      handleAction={handleAction}
      contentComponents={contentComponents}
    />
  );
};
