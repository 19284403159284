import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { trackEvent } from '../../utils/analyticsEvent';
import InputAnswer from './inputAnswers';
import QuestionAnswers from './questionAnswers';
import questionTypes from './questionTypes';
import { Paragraph, Label1, SubHeader } from '../Text';
import { CtaButton } from '../buttons';
import Lock from '../svgImages/common/Lock';
import TopLeftCircles from '../svgImages/exercise/TopLeftCircles';
import { OLD_COLORS, OLD_SIZES } from '../../styles/appConsts';

const MIN_ANS_LENGTH = 5;
const MAX_ANS_LENGTH = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    backgroundColor: OLD_COLORS.WHITE,
    borderRadius: 4,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
  },
  topLeftCircles: {
    position: 'absolute',
    left: 0,
    top: 0,
  },
  header: {
    height: 208,
    padding: OLD_SIZES.m,
  },
  headerStrip: {
    position: 'relative',
    marginBottom: OLD_SIZES.m,
  },
  step: {
    opacity: 0.5,
    fontSize: 14,
    fontWeight: '500',
    color: OLD_COLORS.WHITE,
    textAlign: 'center',
  },
  backIconDiv: {
    position: 'absolute',
    left: 0,
    top: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  backIcon: {
    color: OLD_COLORS.WHITE,
  },
  questionText: {
    marginTop: OLD_SIZES.m,
  },
  questionContent: {
    marginBottom: -90,
    height: '80%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: OLD_SIZES.m,
  },
  questionAnswerDiv: {
    paddingBottom: 70,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
  },
  gradient: {
    position: 'absolute',
    width: '100%',
    bottom: 10,
    height: 90,
    background: 'linear-gradient(rgba(255, 255, 255, 0.2), white)',
  },
  answersCount: {
    marginTop: OLD_SIZES.s,
  },
  errorMsg: {
    height: '1em',
    color: OLD_COLORS.RED_500,
    width: '100%',
    paddingLeft: 8,
    paddingTop: 4,
    fontSize: 12,
  },
  bottomButton: {
    marginTop: OLD_SIZES.l,
    flex: 'none',
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    justifyContent: 'center',
    paddingBottom: OLD_SIZES.m,
    paddingLeft: OLD_SIZES.xl,
    paddingRight: OLD_SIZES.xl,
  },
  ctaButton: {
    width: '100%',
    color: OLD_COLORS.WHITE,
    '&:hover': {
      color: OLD_COLORS.WHITE,
    },
    '&:disabled': {
      color: OLD_COLORS.WHITE,
    },
  },
  lock: {
    width: 13,
    marginRight: 12,
  },
  spinnerDiv: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    color: OLD_COLORS.WHITE,
  },
}));

// const getMyAnswer = (answers = []) => {
//   const ans = answers?.find(item => item.myself)
//   return ans ? ans.answer.value : ''
// }

export default function Question({
  switchQuestionSpinner,
  questionId,
  prevQuestionId,
  part,
  currentPart,
  currentQuestion,
  totalQuestions,
  question,
  type,
  optionalAnswers,
  additionalContent,
  totalAnswers,
  answered,
  answers,
  paymentRequired,
  isLoading,
  onSubmit,
  onNext,
  onBack,
  onUpdate,
}) {
  // right now background-color for answers is same as for question
  const { color: partColor = OLD_COLORS.VIBRANT_BLUE_2 } = part;

  const classes = useStyles();
  const [answer, setAnswer] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);
  const [editAnswerId, setEditAnswerId] = React.useState(null);

  // const [displayPageAnim, setDisplayPageAnim] = React.useState(true);
  // const [transitionBkg, setTransitionBkg] = React.useState({
  //   property: 'background-color',
  //   duration: '0s',
  //   value: ['backgroundColor', answered ? answersColor : partColor],
  // });

  const onEditClick = (ans) => {
    trackEvent('click edit exercise answer', { part: currentPart + 1, question: currentQuestion + 1 });
    setAnswer(ans?.answer.value);
    setEditAnswerId(ans?.id);
    setEditMode(true);
  };

  const toggleLike = (ans) => {
    const reaction = ans.reactions?.type === 'like' ? 'unlike' : 'like';
    trackEvent('click exercise answer reaction', { reaction });
    onUpdate(ans.id, { reaction });
  };

  // React.useEffect(() => {
  //   const answersMode = answered && !editMode;
  //   setTransitionBkg({
  //     ...transitionBkg,
  //     duration: '2s',
  //     value: ['backgroundColor', answersMode ? answersColor : partColor],
  //   });
  //   setDisplayPageAnim(false);
  //   setTimeout(() => setDisplayPageAnim(true), 200);
  // }, [editMode, answered, questionId]);

  if (switchQuestionSpinner) {
    return (
      <div className={classes.root} style={{ backgroundColor: partColor }}>
        <div className={classes.spinnerDiv}>
          <CircularProgress className={classes.spinner} />
        </div>
      </div>
    );
  }

  return (
    // <Slide direction={'right'} in={displayPageAnim} timeout={displayPageAnim ? 500 : 200}>
    <div className={classes.root}>
      <div className={classes.topLeftCircles}>
        <TopLeftCircles color={OLD_COLORS.GRAY_400} />
      </div>
      <div className={classes.header} style={{ backgroundColor: partColor }}>
        <div className={classes.headerStrip}>
          <Label1 className={classes.step}>{`Step ${currentPart + 1} - ${part.title}`}</Label1>
          {!!prevQuestionId && (
            <div
              className={classes.backIconDiv}
              onClick={() => {
                setAnswer(null);
                setEditMode(false);
                onBack(prevQuestionId);
              }}
            >
              <ArrowBackIcon className={classes.backIcon} />
            </div>
          )}
        </div>
        <Label1 className={classes.step}>{`${currentQuestion + 1}/${totalQuestions}`}</Label1>
        <SubHeader color={OLD_COLORS.WHITE} align='center' className={classes.questionText}>
          {question}
        </SubHeader>
      </div>
      <div className={classes.questionContent}>
        {(!answered || editMode) && (
          <>
            {type === questionTypes.TEXT && (
              <Paragraph
                color={OLD_COLORS.WHITE}
                align='center'
                className={classes.answersCount}
              >{`${totalAnswers} members shared their reflections`}</Paragraph>
            )}
            <InputAnswer
              questionType={type}
              placeholder='Share your answer to see what other members shared'
              value={answer}
              optionalAnswers={optionalAnswers}
              additionalContent={additionalContent}
              color={partColor}
              setAnswer={(val) => {
                setAnswer(val);
              }}
            />
          </>
        )}
        {answers && (
          <>
            <div className={classes.questionAnswerDiv}>
              <QuestionAnswers
                questionType={type}
                totalAnswers={totalAnswers}
                optionalAnswers={optionalAnswers}
                additionalContent={additionalContent}
                watchAnswersMode={answered && !editMode}
                color={partColor}
                answers={answers}
                onEditClick={onEditClick}
                toggleLike={toggleLike}
              />
            </div>
            <div className={classes.gradient} />
          </>
        )}
      </div>
      <div className={classes.bottomButton}>
        {(!answered || editMode) && (
          <CtaButton
            disabled={!answer || answer.length === 0}
            disableElevation
            className={classes.ctaButton}
            style={{ backgroundColor: partColor }}
            onClick={() => {
              if (!isLoading) {
                if (editMode) {
                  trackEvent('click update exercise answer', {
                    part: currentPart + 1,
                    question: currentQuestion + 1,
                  });
                  onUpdate(editAnswerId, { answerText: answer });
                  setEditMode(false);
                  setEditAnswerId(null);
                } else {
                  onSubmit(answer);
                }
              }
            }}
          >
            Answer
          </CtaButton>
        )}
        {answered && !editMode && (
          <CtaButton
            disableElevation
            className={classes.ctaButton}
            style={{ backgroundColor: partColor }}
            onClick={() => {
              onNext();
              setAnswer(null);
            }}
          >
            {paymentRequired && <Lock className={classes.lock} color={OLD_COLORS.WHITE} />}
            Next
          </CtaButton>
        )}
      </div>
    </div>
    // </Slide>
  );
}
