import React from 'react';
import { connect } from 'react-redux';
import { getPersonalGroups, updateNextSessionTime } from '../../store/ducks/groups';
import { resetGroupSpace } from '../../store/ducks/groupSpace';
import { openNotice } from '../../store/ducks/notice';
import MyGroups from './MyGroups';

function MyGroupsContainer(props) {
  return <MyGroups {...props} />;
}

function mapState(state) {
  return {
    myGroups: state.groups.myGroups,
  };
}

export default connect(mapState, { getPersonalGroups, updateNextSessionTime, openNotice, resetGroupSpace })(
  MyGroupsContainer
);
