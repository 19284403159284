import React from 'react';

export default (props) => (
  <svg {...props} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns='http://www.w3.org/2000/svg'>
    <path d="M3.5748 14.7475L7.48506 11.9066C7.56607 11.8477 7.66035 11.8161 7.75563 11.8116V0C7.56324 0.00859333 7.37535 0.123584 7.30342 0.344971L5.80983 4.94176C5.7429 5.14777 5.55092 5.28725 5.33431 5.28725H0.500958C0.0165963 5.28725 -0.184791 5.90706 0.207066 6.19176L4.11733 9.03273C4.29257 9.16005 4.3659 9.38573 4.29896 9.59174L2.80538 14.1885C2.6557 14.6492 3.18294 15.0322 3.5748 14.7475Z" 
    fill={props.colorleft}/>
    <path d="M11.9364 14.7475L8.02617 11.9066C7.94516 11.8477 7.85088 11.8161 7.7556 11.8116V0C7.94799 0.00859333 8.13588 0.123584 8.20781 0.344971L9.7014 4.94176C9.76833 5.14777 9.96031 5.28725 10.1769 5.28725H15.0103C15.4946 5.28725 15.696 5.90706 15.3042 6.19176L11.3939 9.03273C11.2187 9.16005 11.1453 9.38573 11.2123 9.59174L12.7059 14.1885C12.8555 14.6492 12.3283 15.0322 11.9364 14.7475Z" 
    fill={props.colorright}/>
  </svg>
);
