import React from 'react';

// #FBFAFA

export default (props) => (
  <svg width='187' height='177' viewBox='0 0 187 177' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity='0.7'>
      <path
        opacity='0.1'
        d='M56.9893 -118.525C-6.13753 -150.088 -57.4356 -144.191 -87.3111 -84.4392C-117.187 -24.6871 -95.4228 92.8716 -32.2959 124.434C30.8309 155.997 99.2165 121.968 129.092 62.2156C158.967 2.46348 120.116 -86.9625 56.9893 -118.525Z'
        fill={props.color}
      />
      <path
        opacity='0.1'
        d='M31.2586 -119.711C-27.6999 -149.19 -55.4246 -148.215 -83.2285 -92.6057C-111.033 -36.9966 -90.5647 72.5173 -31.6062 101.996C27.3523 131.475 91.132 99.8726 118.936 44.2636C146.74 -11.3455 90.2171 -90.2327 31.2586 -119.711Z'
        fill={props.color}
      />
      <path
        opacity='0.1'
        d='M17.6859 -124.214C-38.2104 -146.097 -63.357 -142.718 -84.385 -89.0043C-105.413 -35.2901 -78.3932 63.7638 -22.4969 85.6461C33.3993 107.528 88.9964 72.7118 110.024 18.9976C131.053 -34.7165 73.5821 -102.332 17.6859 -124.214Z'
        fill={props.color}
      />
    </g>
  </svg>
);
