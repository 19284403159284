import React, { useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { Button, Slider, CircularProgress } from '@material-ui/core';
import getCroppedImg from '../createImageUtils';
import { Label1 } from '../../../../../components/Text';
import UploadFileIcon from '../../../../../components/svgImages/profileImage/UploadFile';
import { openNotice } from '../../../../../store/ducks/notice';
import axios from 'axios';
import { styles } from './styles';
import { OLD_COLORS } from '../../../../../styles/appConsts';
import { trackEvent } from '../../../../../utils/analyticsEvent';
import { connect, useSelector } from 'react-redux';
import { updateAccount } from '../../../../../store/ducks/account';
import PropTypes from 'prop-types';

const ImageCropper = ({ ...props }) => {
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [uploadInputLabel, setUploadInputLabel] = useState('Upload profile picture');
  const [croppedImage, setCroppedImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const classes = styles();

  const uploadImageApiKey = useSelector((state) => state.configurations.uploadImageApiKey);

  useEffect(() => {
    if (croppedImage) {
      const formData = new FormData();
      formData.append('profile-pic', croppedImage);
      trackEvent('click submit profile image');
      axios
        .post('https://o3n165jy3f.execute-api.us-east-2.amazonaws.com/production/upload-image', formData, {
          headers: {
            'X-Api-Key': uploadImageApiKey,
          },
        })
        .then(({ data }) => {
          const url = data.data[0].link
          if (props.handlePopupClose) {
            props.handlePopupClose(url);
          } else {
            props.updateAccount({ picture: url });
          }
        })
        .catch((error) => {
          console.log('axios error', error);
          const errorMsg =
            error?.response?.data?.message || error?.response?.status === 413
              ? 'File is too large'
              : 'Something went wrong, please try again or contact support at hello@circlesup.com';
          openNotice({ title: errorMsg });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [croppedImage]);

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const filesize = files[0] ? (files[0].size / 1024 / 1024).toFixed(4) : 0; // file size in MB

    const reader = new FileReader();
    reader.onload = () => {
      if (filesize <= 4) {
        setError(null);
        setImage(reader.result);
        setUploadInputLabel('Upload a new photo');
      } else {
        setError('The image you tried to upload is too large. Please try a smaller image.');
      }
    };
    if (files[0]) {
      reader.readAsDataURL(files[0]);
    }
  };

  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onImageCropped = async () => {
    if (croppedAreaPixels) {
      try {
        setLoading(true);
        const croppedImage = await getCroppedImg(image, croppedAreaPixels, rotation);
        const blobImage = await fetch(croppedImage).then((r) => r.blob());
        setCroppedImage(blobImage);
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <div className={classes.dialogBoxSize}>
      <div style={{ width: '100%' }}>
        {image ? (
          <div className={classes.cropContainer}>
            <Cropper
              image={image}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={3 / 3}
              cropShape='round'
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
            <label htmlFor='file-upload' className={classes.labelForfileUpload}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <UploadFileIcon color={OLD_COLORS.VIBRANT_BLUE_2} className={classes.uploadIcon2} />
                <Label1 className={classes.uploadText2}>{uploadInputLabel}</Label1>
              </div>
            </label>
            <input id='file-upload' type='file' onChange={onChange} className={classes.input} />
          </div>
        ) : (
          <>
            <label htmlFor='file-upload' className={classes.uploadLabel1}>
              <div className={classes.innerFileIcon}>
                <UploadFileIcon color={OLD_COLORS.VIBRANT_BLUE_2} className={classes.uploadIcon1} />
                <Label1 className={classes.uploadLabel2}>{uploadInputLabel}</Label1>
              </div>
            </label>
            <input id='file-upload' type='file' onChange={onChange} className={classes.input} />
          </>
        )}
      </div>
      <div className={classes.sliderContainer} style={image && { paddingTop: 40 }}>
        <div className={classes.sliderBox}>
          <Label1 className={classes.sliderLabel}> Zoom </Label1>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            disabled={!image}
            aria-labelledby='Zoom'
            className={classes.slider}
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </div>
        <div className={classes.sliderBoxRight}>
          <Label1 className={classes.sliderLabelRight}>Rotation</Label1>
          <Slider
            value={rotation}
            min={0}
            max={360}
            step={1}
            disabled={!image}
            aria-labelledby='Rotation'
            className={classes.sliderRight}
            onChange={(e, rotation) => setRotation(rotation)}
          />
        </div>
      </div>
      <div className={classes.controls}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            onClick={onImageCropped}
            disabled={!image || !croppedAreaPixels}
            variant='contained'
            color='primary'
            className={classes.cropButton}
          >
            Crop Image
          </Button>
        )}
      </div>
      {error && <div style={{ color: 'red', fontSize: 14 }}>{error}</div>}
    </div>
  );
};

ImageCropper.propTypes = {
  updateAccount: PropTypes.func.isRequired,
  handlePopupClose: PropTypes.func,
};

function mapState({ account }) {
  return {
    picture: account.picture,
  };
}

export default connect(mapState, { updateAccount })(ImageCropper);
