import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAllExercises, setOpenExercise, getExercise, createProgress } from '../../store/ducks/exercises';

import Exercise from './Exercise';

function ExerciseContainer(props) {
  const { exerciseId } = useParams();

  return <Exercise {...props} exerciseId={exerciseId} />;
}

function mapState({ exercises }) {
  return {
    exercise: exercises.current,
    openExercise: exercises.openExercise,
  };
}

export default connect(mapState, {
  getAllExercises,
  getExercise,
  createProgress,
  setOpenExercise,
})(ExerciseContainer);
