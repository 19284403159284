import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, IconButton, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { getColor } from '@7chairs/frontend';

import { trackEvent } from '../../../utils/analyticsEvent';
import { OLD_SIZES } from '../../../styles/appConsts';
import * as components from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    width: 532,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 32px)',
    },
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: getColor('BLACK_80'),
    zIndex: 100,
  },
  contentComponentDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  iframeContainer: {
    marginTop: -20,
    minHeight: 500,
    width: '100%',
  },
  iframe: {
    display: 'block',
    height: '100%',
    width: '100%',
    border: 'none',
    minHeight: 600,
  },
  spinnerContainer: {
    height: '100%',
    minHeight: 500,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    color: getColor('BLUE_100'),
  },
}));

const Content = withStyles(() => ({
  root: {
    padding: 0,
    paddingBottom: OLD_SIZES.l + OLD_SIZES.s,
    paddingTop: `${OLD_SIZES.l + OLD_SIZES.xs}px`,
    justifyContent: 'center',
  },
}))(DialogContent);

const MixedDialog = ({
  id,
  open,
  dialogStyle = {},
  onClose: parentOnClose,
  onSubmit,
  handleAction: parentActionHandler,
  contentComponents = [],
  disableClose = false,
  closeStyle = {},
}) => {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const onClose = () => {
    trackEvent('click popup close', { id });
    if (typeof parentOnClose === 'function') {
      parentOnClose(id);
    }
  };

  const handleAction = (action) => {
    trackEvent('click popup action', { id, action });

    if (action === 'submit' && onSubmit) {
      return onSubmit(data);
    }

    if (action && parentActionHandler) {
      return parentActionHandler(action);
    }

    // default action
    onClose(id);
  };

  useEffect(() => {
    if (open) {
      trackEvent('view popup', { id });
    }
  }, [open]);

  const renderComponent = (comp, i) => {
    const { type, name = `_${i}`, action, initValue, components: contComponents = [], parentStyle = {} } = comp;

    const changeHandler = (value) => {
      const d = { ...data };
      d[name] = value;
      setData(d);
    };

    const props = { ...comp };

    const isTextComponent = [
      'CTA',
      'Header',
      'Label1',
      'Label2',
      'Link',
      'Paragraph',
      'PresentType',
      'SubHeader',
    ].includes(type);

    const compKey = isTextComponent ? 'Text' : type;

    if (type === 'iframeSrc') {
      return (
        <div>
          {isLoading && (
            <div className={classes.spinnerContainer}>
              <CircularProgress className={classes.spinner} />
            </div>
          )}
          <iframe
            className={classes.iframe}
            onLoad={() => setIsLoading(false)}
            src={comp.content}
            title={'calendly dialog'}
          />
        </div>
      );
    }

    if (!components[compKey]) {
      return null;
    }

    const style = { marginTop: action ? OLD_SIZES.m : OLD_SIZES.s };
    const Child = components[compKey];

    if (initValue && typeof data[name] === 'undefined') {
      changeHandler(initValue);
    }

    if (action) {
      props.onClick = () => handleAction(action);
    }
    if (contComponents.length > 0) {
      props.renderComponent = renderComponent;
    }

    return (
      <div key={`${i}-${name}`} className={classes.contentComponentDiv} style={{ ...style, ...parentStyle }}>
        <Child {...props} value={data[name]} onChange={changeHandler} onSubmit={onSubmit} />
      </div>
    );
  };

  return (
    <>
      <Dialog
        classes={{ paper: classes.root }}
        onClose={disableClose ? null : onClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <Content style={dialogStyle}>
          {!disableClose && (
            <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
              <CloseIcon style={closeStyle} />
            </IconButton>
          )}

          {contentComponents.map((comp, i) => renderComponent(comp, i))}
        </Content>
      </Dialog>
    </>
  );
};

MixedDialog.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  closeStyle: PropTypes.object,
  onSubmit: PropTypes.func,
  handleAction: PropTypes.func,
  contentComponents: PropTypes.arrayOf(PropTypes.object),
};

export default MixedDialog;
