import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Header, SubHeader, Label1, Paragraph, Link, Label2, CTA, PresentType } from '../../../Text';
import { OLD_SIZES } from '../../../../styles/appConsts';

const styles = () => ({
  root: {
    paddingLeft: OLD_SIZES.l,
    paddingRight: OLD_SIZES.l,
  },
  header: {
    textAlign: 'center',
  },
  title: {
    textAlign: 'center',
  },
  paragraph: {
    textAlign: 'center',
  },
  label2: {
    textAlign: 'center',
  },
  label1: {
    textAlign: 'center',
    opacity: 0.7,
  },
  link: {
    textAlign: 'center',
  },
  cta: {
    textAlign: 'center',
  },
  presentType: {
    textAlign: 'center',
  },
});

export default withStyles(styles)((props) => {
  const { classes, content, type, style = {} } = props;

  const componentsMap = {
    Header: Header,
    SubHeader: SubHeader,
    Paragraph: Paragraph,
    Label1: Label1,
    Label2: Label2,
    Link: Link,
    CTA: CTA,
    PresentType: PresentType,
  };
  const classesMap = {
    Header: classes.header,
    SubHeader: classes.title,
    Paragraph: classes.paragraph,
    Label1: classes.label1,
    Label2: classes.label2,
    Link: classes.link,
    CTA: classes.cta,
    PresentType: classes.presentType,
  };

  const TextComponent = componentsMap[type] || Paragraph;
  const _class = classesMap[type] || classesMap.subtitle;
  return (
    <TextComponent className={clsx(classes.root, _class)} style={style}>
      {content}
    </TextComponent>
  );
});
