import { combineReducers } from 'redux';
import root from './root';
import config from './config';
import auth from './auth';
import account from './account';
import groups from './groups';
import streamChat from './streamChat';
import notice from './notice';
import payment from './payment';
import purchase from './purchase';
import profile from './profile';
import groupSpace from './groupSpace';
import notificationsSettings from './notificationsSettings';
import exercises from './exercises';
import validation from './validation';
import strapi from './strapi';
import configurations from './configurations';

const rootReducer = combineReducers({
  root,
  config,
  auth,
  account,
  groups,
  streamChat,
  notice,
  payment,
  purchase,
  profile,
  groupSpace,
  notificationsSettings,
  exercises,
  validation,
  strapi,
  configurations,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
