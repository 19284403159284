import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Avatar, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { OLD_COLORS } from '../../../../styles/appConsts';
import { AccountStore } from '../../../../store/ducks/account';
import { useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import { CtaButton } from '../../../../components/buttons';
import { NavigationContext } from '../../../../components/navigator';
import { AccountRole } from '@7chairs/types';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '231px',

    [theme.breakpoints.down('xs')]: {
      width: '311px',
    },
  },
  editIcon: {
    color: OLD_COLORS.WHITE,
    fontSize: '20px',
    left: '5px',
    position: 'relative',
    top: '6px',
  },
  editIconWrapper: {
    backgroundColor: OLD_COLORS.BRAND_PURPLE_2,
    borderRadius: '50%',
    boxShadow: `0 2px 4px ${OLD_COLORS.BLACK}4D`,
    cursor: 'pointer',
    height: '32px',
    width: '32px',
    position: 'relative',
    right: '40px',
    top: '50px',
  },
  image: {
    alignSelf: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    height: '132px',
    width: '132px',
  },
  imageWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '32px',
    marginBottom: '30px',
  },
  textAlternativeAction: {
    color: OLD_COLORS.BRAND_PURPLE_1,
    cursor: 'pointer',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    textDecoration: 'underline',
    marginBottom: '107px',
    maxWidth: '420px',

    [theme.breakpoints.down('xs')]: {
      marginBottom: '64px',
      maxWidth: '327px',
    },
  },
  textDescription: {
    color: OLD_COLORS.DARK_GRAY,
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '24px',
    marginBottom: '74px',
    maxWidth: '420px',

    [theme.breakpoints.down('xs')]: {
      marginBottom: '54px',
      maxWidth: '327px',
    },
  },
  textPreAlternativeAction: {
    color: OLD_COLORS.GRAY_500,
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    marginBottom: '6px',
  },
  textTitle: {
    color: OLD_COLORS.BLACK,
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '33px',
    marginBottom: '11px',
    maxWidth: '420px',

    [theme.breakpoints.down('xs')]: {
      marginBottom: '8px',
      maxWidth: '327px',
    },
  },
  wrapper: {
    alignItems: 'center',
    backgroundColor: OLD_COLORS.FLORAL_WHITE,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: '78px',

    [theme.breakpoints.down('xs')]: {
      paddingTop: '30px',
    },
  },
}));

enum ImageBuilderMode {
  Avatar = 'avatar',
  Default = 'default',
  Picture = 'picture',
}

const TEXTS_PRESETS = {
  [ImageBuilderMode.Avatar]: {
    title: 'Edit avatar',
    description: '',
    alternativeAction: 'upload image',
  },
  [ImageBuilderMode.Default]: {
    title: 'Add profile picture',
    description: '',
    alternativeAction: 'Create an avatar',
  },
  [ImageBuilderMode.Picture]: {
    title: 'Edit profile picture',
    description: 'Choose your a profile picture',
    alternativeAction: 'Create an avatar',
  },
};

export const ProfileImageBuilder = () => {
  const classes = useStyles();

  const navCtx = useContext(NavigationContext);

  // TODO: enrich DefaultRootState with account: AccountStore and groups
  // @ts-ignore
  const account: AccountStore = useSelector((state) => state.account);
  const [mode, setMode] = useState<ImageBuilderMode>(ImageBuilderMode.Default);

  const actionHandler = (type: 'alternative' | 'main') => {
    if (account.role === AccountRole.Leader) {
      return;
    }
    switch (mode) {
      case ImageBuilderMode.Avatar:
        navCtx.pushScreen(
          `/profile/items/image-builder/${type === 'main' ? ImageBuilderMode.Avatar : ImageBuilderMode.Picture}`
        );
        break;
      case ImageBuilderMode.Default:
        navCtx.pushScreen(
          `/profile/items/image-builder/${type === 'main' ? ImageBuilderMode.Picture : ImageBuilderMode.Avatar}`
        );
        break;
      case ImageBuilderMode.Picture:
        navCtx.pushScreen(
          `/profile/items/image-builder/${type === 'main' ? ImageBuilderMode.Picture : ImageBuilderMode.Avatar}`
        );
        break;
      default:
        const error: never = mode;
        console.error(`Not supported mode: ${error}`);
        break;
    }
  };

  const defaultMode = useMemo(() => {
    if (account.picture) {
      if (account.picture.includes('no-avatar')) {
        return ImageBuilderMode.Default;
      }
      if (account.picture.includes('avatars')) {
        return ImageBuilderMode.Avatar;
      }
      return ImageBuilderMode.Picture;
    }
    return ImageBuilderMode.Default;
  }, [account]);

  const onDone = () => {
    navCtx.popScreen();
  };

  useEffect(() => {
    setMode(defaultMode);
  }, [defaultMode]);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.textTitle}>{TEXTS_PRESETS[mode].title}</Box>
      <Box className={classes.textDescription}>{TEXTS_PRESETS[mode].description}</Box>
      <Box className={classes.imageWrapper}>
        <Avatar alt='avatar' className={classes.image} src={account.picture} onClick={() => actionHandler('main')} />
        {account.role !== AccountRole.Leader ? (
          <Box className={classes.editIconWrapper}>
            <EditIcon className={classes.editIcon} onClick={() => actionHandler('main')} />
          </Box>
        ) : null}
      </Box>
      <Box className={classes.textPreAlternativeAction}>or</Box>
      <Box className={classes.textAlternativeAction} onClick={() => actionHandler('alternative')}>
        {TEXTS_PRESETS[mode].alternativeAction}
      </Box>
      <CtaButton className={classes.button} onClick={onDone}>
        Done
      </CtaButton>
    </Box>
  );
};
