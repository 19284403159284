import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const MINIMAL_LOADING_ANIMATION_TIME = 3000;

export const useNewlySignup = () => {
  const history = useHistory();
  const { search } = useLocation();

  const [minimalLoadingAnimationTimeElapsed, setMinimalLoadingAnimationTimeElapsed] = useState(true);
  const [newlySignedUp, setNewlySignedUp] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);

    const newlySignedUpQueryParam = queryParams.get('newly-signed-up');

    if (newlySignedUpQueryParam) {
      setNewlySignedUp(true);

      queryParams.delete('newly-signed-up');

      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [search]);

  useEffect(() => {
    if (newlySignedUp) {
      setMinimalLoadingAnimationTimeElapsed(false);

      setTimeout(() => {
        // reason for this timeout is that for newly signed up people we want to show some text to read
        setMinimalLoadingAnimationTimeElapsed(true);
      }, MINIMAL_LOADING_ANIMATION_TIME);
    }
  }, [newlySignedUp]);

  return { newlySignedUp, minimalLoadingAnimationTimeElapsed };
};
