import React, { useContext } from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import { extractAnalyticsCookies, pageEvent } from '../../utils/analyticsEvent';

export const NavigationContext = React.createContext({
  pushScreen: (screen) => {},
  popScreen: () => {},
  replaceScreen: (screen) => {},
  canGoBack: () => false,
});

export const Navigator = withRouter(({ children, location, history }) => {
  React.useEffect(() => {
    const { pathname = '', state: { from = '' } = {} } = location;
    let page = pathname.split('/')?.[1];

    if (typeof page === 'undefined') {
      return;
    }
    if (page.length === 0) {
      page = 'home';
    }

    pageEvent(`view ${page} page`, { referrer: from });
    window.dataLayer.push({
      userID: extractAnalyticsCookies().userId || 'NA',
      isLoggedIn: localStorage.getItem('authToken') ? 'yes' : 'no',
    });
  }, [location]);

  const pushScreen = (screen) => {
    const { pathname, state = {} } = location;
    history.push({ pathname: screen, state: { ...state, from: pathname } });
  };

  const popScreen = () => {
    history.goBack();
  };

  const replaceScreen = (screen) => {
    const { pathname, state = {} } = location;
    history.replace({ pathname: screen, state: { ...state, from: pathname } });
  };

  const canGoBack = () => {
    const { action, length } = history;
    return action === 'POP' ? length > 2 : length > 1;
  };

  return (
    <NavigationContext.Provider value={{ pushScreen, popScreen, replaceScreen, canGoBack }}>
      {children}
    </NavigationContext.Provider>
  );
});

export const useNavContext = () => useContext(NavigationContext);

export const getLoginTokenParams = (pathname) => {
  const matches = [
    { path: '/login', paramName: 'longId' },
    { path: '/i', paramName: 'shortId' },
  ];
  const match = matchUrlParams(pathname, matches);
  if (match.length === 0) {
    return {};
  }
  const [type, token] = Object.entries(match[0])[0];
  return { type, token };
};

export const matchUrlParams = (pathname, matches) => {
  return matches
    .map((el) => {
      const { paramName, path } = el;
      const match = matchPath(pathname, { path: `${path}/:${paramName}`, exact: true, strict: false });
      return { [paramName]: match?.params?.[paramName] };
    })
    .filter((m) => !!Object.values(m)[0]);
};
