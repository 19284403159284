import React, { useState } from "react";
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Header } from '../../../components/Text';
import { Text, Input, Button } from '@7chairs/frontend';
import { OLD_SIZES } from '../../../styles/appConsts';
import { useCancelSubscriptionModal } from "./hooks/useCancelSubscriptionModal";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: OLD_SIZES.l,
    '& > *': {
      width: '327px',
    }
  },
  title: {
    marginBottom: OLD_SIZES.m,
    textAlign: 'center'
  },
  subtitle: {
    marginBottom: OLD_SIZES.xl,
    textAlign: 'center',
  },
  button: {
    marginTop: '106px',
  },
}));

const MAX_INPUT_LENGTH = 200

export const ExplainProblem = () => {
  const classes = useStyles();
  const [problemDescription, setProblemDescription] = useState<string>('')
  const isFormValid = problemDescription.length >= 4 && problemDescription.length <= MAX_INPUT_LENGTH
  const cancelSubscriptionModal = useCancelSubscriptionModal()
  const onSubmit = () => cancelSubscriptionModal('other', problemDescription)
  
  return (
    <Grid container direction='column' alignItems='center' className={classes.root}>
      <Header className={classes.title}>How can we help?</Header>
      <div className={classes.subtitle}>
        <Text variant="subtitle">
          Please explain the issue you’re experiencing. We’ll do our best to help you right away.
        </Text>
      </div>
      <Input.Textarea
        maxLength={MAX_INPUT_LENGTH}
        errorMessage={isFormValid ? '' : 'Please describe your problem'}
        onChange={(e) => {
          setProblemDescription(e.substring(0, MAX_INPUT_LENGTH));
        }}
        placeholder='Type here...'
        value={problemDescription}
      />
      <div className={classes.button}>
        <Button
          disabled={!isFormValid}
          variant='primary'
          size='big'
          onClick={onSubmit}
        >
          Submit
        </Button>
      </div>
    </Grid>
  )
}