import { createApiAction, createReducer } from "../../utils";

interface ConfigurationsStore {
  uploadImageApiKey: string
}

// Types
export const types = {
  GET_CONFIGURATIONS: 'account/GET_CONFIGURATIONS',
  GET_CONFIGURATIONS_SUCCESS: 'account/GET_CONFIGURATIONS_SUCCESS',
  GET_CONFIGURATIONS_ERROR: 'account/GET_CONFIGURATIONS_ERROR',
}

// Actions
export function getConfigurations() {
  return createApiAction(
    [types.GET_CONFIGURATIONS, types.GET_CONFIGURATIONS_SUCCESS, types.GET_CONFIGURATIONS_ERROR],
    '/configurations/desktop',
    'get',
    undefined,
  );
}

// Reducer
const initialState: ConfigurationsStore = {
  uploadImageApiKey: ''
}

const handlers = {
  [types.GET_CONFIGURATIONS_SUCCESS]: (state, { data }) => ({
    ...state,
    uploadImageApiKey: data.uploadImageApiKey,
  }),
}

export default createReducer(initialState, handlers);
