import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import MixedDialog from '../dialogs/mixedDialog/MixedDialog';
import { setCalendlyPopUpData } from '../../store/ducks/root';
import { trackEvent } from '../../utils/analyticsEvent';
import { OLD_COLORS, OLD_SIZES } from '../../styles/appConsts';
import { STRAPI_PATH } from '../../utils/consts';

const CalendlyPopUp = ({
  calendlyPopUpData,
  setCalendlyPopUpData,
  strapiCalendly,
  name,
  email,
  strapiCalendlyGroupLab,
}) => {
  const { open, onClose, skipCallback, url, groupLab } = calendlyPopUpData;
  const { title, text, cta, skip } = groupLab ? strapiCalendlyGroupLab : strapiCalendly;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [components, setComponents] = useState([]);

  const data = [
    {
      type: 'ContentDiv',
      style: {
        height: '70%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      components: [
        {
          type: 'ContentDiv',
          style: {
            textAlign: 'center',
            paddingTop: OLD_SIZES.m,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
          components: [
            { type: 'Image', content: `${ASSETS_BASE_DIR}/groupSpace/calendly.svg` },
            { type: 'SubHeader', content: title },
            { type: 'Paragraph', content: text },
          ],
        },
        {
          type: 'ContentDiv',
          style: {
            flexShrink: 0,
            flexGrow: 1,
            paddingTop: OLD_SIZES.m,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-end',
          },
          components: [
            {
              type: 'CtaButton',
              action: 'next',
              content: cta,
              style: { backgroundColor: OLD_COLORS.VIBRANT_BLUE_2 },
            },
            {
              type: 'LinkButton',
              action: 'skip',
              content: skip,
              style: {
                color: OLD_COLORS.GRAY_500,
                marginBottom: OLD_SIZES.l,
              },
            },
          ],
        },
      ],
    },
    { type: 'IFrame', url, queryParams: { name, email, utm_source: 'MGS' } },
  ];

  useEffect(() => {
    if (Object.keys(strapiCalendly).length > 0) {
      setComponents([data[0]]);
      setDataLoaded(true);
    }
  }, [strapiCalendly]);

  useEffect(() => {
    if (open) {
      setComponents([data[0]]);
    }
  }, [open]);

  return (
    <MixedDialog
      open={open}
      contentComponents={components}
      handleAction={(action) => {
        if (action === 'next') {
          trackEvent('click calendly popup next');
          setComponents([data[1]]);
        }
        if (action === 'skip') {
          setCalendlyPopUpData({ open: false });
          trackEvent('click calendly popup skip');
          if (skipCallback) {
            skipCallback();
          }
        }
      }}
      onClose={() => {
        trackEvent('click calendly popup close');
        onClose();
        setCalendlyPopUpData({ open: false });
      }}
    />
  );
};

const mapState = ({ root, strapi, account }) => ({
  calendlyPopUpData: root.calendlyPopUpData,
  strapiCalendly: strapi[STRAPI_PATH.CALENDLY.key],
  strapiCalendlyGroupLab: strapi[STRAPI_PATH.CALENDLY_GROUPS_LAB.key],
  name: account.name,
  email: account.email,
});

export default connect(mapState, { setCalendlyPopUpData })(CalendlyPopUp);
