import { Box } from '@material-ui/core';
import React from 'react';
import { Label1, Label2 } from '../../../../components/Text';
import { CtaButton } from '../../../../components/buttons';
import { makeStyles } from '@material-ui/core/styles';
import { OLD_COLORS } from '../../../../styles/appConsts';
import Avatar from '../../../../components/Avatar';
import { GroupLeaderInfo } from '../../types';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '50px',
    height: '90%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '50px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    color: OLD_COLORS.BLACK,
    textAlign: 'center',
    maxWidth: '350px',
  },
  subTitle: {
    fontSize: '22px',
    fontWeight: 500,
    color: OLD_COLORS.BRAND_PURPLE_2,
    textAlign: 'center',
  },
  button: {
    width: '220px',
    background: OLD_COLORS.VIBRANT_BLUE_2,
    '&:hover': {
      background: OLD_COLORS.VIBRANT_BLUE_2,
    },
  },
  buttonLabel: {
    color: OLD_COLORS.WHITE,
  },
}));

interface ThirdQuestionPositiveAnsweredProps {
  groupLeaderInfo: GroupLeaderInfo;
  title: string;
  subTitle: string;
  buttonLabel: string;
  finishQuestionnaire: (showGroupChatOnWebOrCloseOnMobileWebview: boolean, showThankYou: boolean) => void;
}

export const ThirdQuestionPositiveAnswered = ({
  groupLeaderInfo,
  title,
  subTitle,
  buttonLabel,
  finishQuestionnaire,
}: ThirdQuestionPositiveAnsweredProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.content}>
        <Avatar img={groupLeaderInfo.picture} />
        <Label2 className={classes.title}>{title}</Label2>
        <Label2 className={classes.subTitle}>{subTitle}</Label2>
      </Box>
      <CtaButton
        className={classes.button}
        onClick={() => {
          finishQuestionnaire(true, false);
        }}
      >
        <Label1 className={classes.buttonLabel}>{buttonLabel}</Label1>
      </CtaButton>
    </Box>
  );
};
