import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, getColor, Text } from '@7chairs/frontend';
import { AccountRole } from '@7chairs/types';
import { NO_AVATAR } from '../../../utils/consts';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '80px',
    height: '118px',
    cursor: 'pointer',
    margin: '1px',
  },
  rootFacilitator: {
    backgroundColor: getColor('WHITE_100'),
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '12px',
  },
  avatar: {
    margin: '10px 12px 0px 12px',
  },
  bio: {
    marginTop: '8px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  name: {
    width: '60px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: 1,
    boxOrient: 'vertical',
    whiteSpace: 'nowrap',
  },
}));

interface MemberCardProps {
  isFacilitator: boolean;
  isFacilitatorVisible: boolean;
  picture: string;
  name: string;
  onClick: () => void;
}

export const MemberCard = ({ isFacilitator, isFacilitatorVisible, picture, name, onClick }: MemberCardProps) => {
  const classes = useStyles();

  // @ts-ignore
  const isPayment: boolean = useSelector((state) => state.account.isPayment);
  // @ts-ignore
  const role: AccountRole = useSelector((state) => state.account.role);

  const shouldBlurImage = useMemo(() => {
    return role === AccountRole.Member && !isFacilitator && !isPayment ? true : false;
  }, [isPayment, role]);

  return (
    <div
      className={`${classes.root} ${isFacilitator && isFacilitatorVisible ? `${classes.rootFacilitator}` : ''}`}
      onClick={onClick}
    >
      <Avatar className={classes.avatar} blur={shouldBlurImage} src={picture || NO_AVATAR} />
      <div className={classes.bio}>
        {isFacilitator ? (
          <Text variant="labelSemibold" color='PURPLE_100'>
            Facilitator
          </Text>
        ) : null}
        <Text variant='label' color='BLACK_100' className={classes.name}>
          {name}
        </Text>
      </div>
    </div>
  );
};
