import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { Header } from '../headers';
import { HEADER_INTERNAL_HEIGHT } from '../headers/Internal';

const useStyles = makeStyles(() => ({
  base: {
    display: 'flex',
    height: `calc(100vh - ${HEADER_INTERNAL_HEIGHT}px)`,
    justifyContent: 'center',
    overflowY: 'scroll',
  },
  content: {
    maxWidth: '920px',
    width: '100%',
  },
}));

interface WithHeaderInternalProps {
  children: ReactNode;
  className?: string;
  headerText: string;
}

export const WithHeaderInternal = ({ children, className, headerText }: WithHeaderInternalProps) => {
  const classes = useStyles();

  return (
    <>
      <Header.Internal text={headerText} />
      <div className={classes.base}>
        <div className={clsx(classes.content, className)}>{children}</div>
      </div>
    </>
  );
};
