import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: '300px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  content: {
    width: '100%',
    height: '150px',
    borderRadius: '8px',
    backgroundImage:
      'linear-gradient(90deg, rgba(211,184,254,1) 0%, rgba(235,202,205,1) 100%, rgba(235,203,203,1) 100%)',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  image: {
    maxWidth: '100%',
    height: '300px',
    position: 'absolute',
    bottom: '0px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

interface FooterProps {
  imageVisible: boolean;
}

export const Footer = ({ imageVisible }: FooterProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.content} />
      {imageVisible ? (
        <img
          className={classes.image}
          src={`${ASSETS_BASE_DIR}/groupSpace/connect_with_others.svg`}
          alt='connected people'
        />
      ) : null}
    </Box>
  );
};
