import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Add, Remove } from '@material-ui/icons';
import { Header, Paragraph } from '../../Text';
import { OLD_SIZES, OLD_COLORS } from '../../../styles/appConsts';

const styles = () => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
  },
  titleDiv: {
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  title: {
    fontSize: 16,
    fontWeight: '500',
    textAlign: 'center',
    marginTop: OLD_SIZES.l,
    color: OLD_COLORS.BLACK,
  },
  contentDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  iconDiv: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  value: {
    margin: `0px ${OLD_SIZES.m}px`,
    width: 82,
    height: 82,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  valueText: {
    fontSize: 22,
    color: OLD_COLORS.WHITE,
  },
});

export default withStyles(styles)((props) => {
  const { classes, value, color, onChange, settings = {} } = props;

  const { min = 1, max = 5, default: def = 3 } = settings;

  const val = value || def;

  // we do that so answer (on parent component) will not be null on initiate
  React.useEffect(() => {
    if (!props.value) {
      onChange(props.settings.default);
    }
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.titleDiv}>
        <Paragraph className={classes.title}>Please rank</Paragraph>
      </div>
      <div className={classes.contentDiv}>
        <div
          className={classes.iconDiv}
          onClick={() => {
            if (val > min) {
              onChange(val - 1);
            }
          }}
        >
          <Remove style={{ color }} />
        </div>
        <div className={classes.value} style={{ backgroundColor: color }}>
          <Header className={classes.valueText}>{val}</Header>
        </div>
        <div
          className={classes.iconDiv}
          onClick={() => {
            if (val < max) {
              onChange(val + 1);
            }
          }}
        >
          <Add style={{ color }} />
        </div>
      </div>
    </div>
  );
});
