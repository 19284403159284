import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { getAccount } from '../../store/ducks/account';
import { getPurchaseInfo } from '../../store/ducks/purchase';
import Profile from './Profile';
import { ProfileItems } from './profileItems/ProfileItems';
import AvatarBuilder from './avatar/avatarBuilder/AvatarBuilder';
import ImageUploading from './avatar/avatarImage/imageUploading/ImageUploading';
import { AccountStore } from '../../store/ducks/account';
import { ProfileImageBuilder } from './profileItems/profileImage/ProfileImageBuilder';
import { AvatarBuilder as AvatarBuilderNew } from './profileItems/profileImage/AvatarBuilder';
import { PictureBuilder } from './profileItems/profileImage/PictureBuilder';
import { ProfileTagOptions } from './profileItems/profileTagOptions/ProfileTagOptions';
import { NeedAssistance } from './NeedAssistance';

const ProfileContainer = () => {
  const dispatch = useDispatch();

  // TODO: enrich DefaultRootState with account: AccountStore and groups
  // @ts-ignore
  const account: AccountStore = useSelector((state) => state.account);

  const { picture, email, isFirstLogin, isAuth, isVoicesPurchase } = account;

  useEffect(() => {
    if (isAuth && !email) {
      dispatch(getAccount());
    }
  }, [isAuth, getAccount]);

  useEffect(() => {
    if (isVoicesPurchase === undefined) {
      dispatch(getPurchaseInfo());
    }
  }, [isVoicesPurchase, getPurchaseInfo]);

  const baseUrl = '/profile';

  return (
    <Switch>
      <Route path={`${baseUrl}/need-assistance`}>
        <NeedAssistance />
      </Route>
      <Route path={`${baseUrl}/avatar-picking`}>
        <AvatarBuilder />
      </Route>
      <Route path={`${baseUrl}/image-upload`}>
        <ImageUploading isFirstLogin={isFirstLogin} picture={picture} />
      </Route>
      <Route path={`${baseUrl}/items/image-builder/avatar`}>
        <AvatarBuilderNew />
      </Route>
      <Route path={`${baseUrl}/items/image-builder/picture`}>
        <PictureBuilder />
      </Route>
      <Route path={`${baseUrl}/items/image-builder`}>
        <ProfileImageBuilder />
      </Route>
      <Route path={[`${baseUrl}/items/tag-options`, `${baseUrl}/items/tag-options?:tagOptionsQuery`]}>
        <ProfileTagOptions />
      </Route>
      <Route path={[`${baseUrl}/items`, `${baseUrl}/items?:itemsQuery`]}>
        <ProfileItems />
      </Route>
      <Route path={baseUrl}>
        <Profile baseUrl={baseUrl} />
      </Route>
    </Switch>
  );
};

export default ProfileContainer;
