import { initializeApp, FirebaseApp } from 'firebase/app';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  UserCredential,
} from 'firebase/auth';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { AuthProvider } from '@7chairs/types';

let firebase: FirebaseApp;

const FIREBASE_CONFIG = {
  STAGE: {
    apiKey: 'AIzaSyA8lTTQ7W9x7_EMsX1WauK8ySPTZLhCet8',
    authDomain: 'chat-app-stage.firebaseapp.com',
    databaseURL: 'https://chat-app-stage.firebaseio.com',
    projectId: 'chat-app-stage',
    storageBucket: 'chat-app-stage.appspot.com',
    messagingSenderId: '589740633903',
    appId: '1:589740633903:web:fe23fc7a4c9c008c253bda',
    measurementId: 'G-VSH3B0NGH6',
  },
  PROD: {
    apiKey: 'AIzaSyBNxyMn1NurmQexARKEhzGxDUvVz-qNmb0',
    authDomain: 'chat-app-96795.firebaseapp.com',
    databaseURL: 'https://chat-app-96795.firebaseio.com',
    projectId: 'chat-app-96795',
    storageBucket: 'chat-app-96795.appspot.com',
    messagingSenderId: '401984707342',
    appId: '1:401984707342:web:505f9fa15be687fec5955f',
    measurementId: 'G-SMWCQD8KBM',
  },
};

const FIREBASE_APP_CHECK_KEY = {
  STAGE: '6LeOAhgoAAAAAJ7dXOhpth_5dcJbkQhO1SFq_rOm',
  PROD: '6Ld8QhgoAAAAAGzmDKL0mw5kyhtGMcX5B6U8eXjJ',
};

export const THIRD_PARTY_AUTH_PROVIDERS = {
  [AuthProvider.Google]: () => new GoogleAuthProvider().setCustomParameters({ prompt: 'select_account ' }),
  [AuthProvider.Facebook]: () => new FacebookAuthProvider(),
  [AuthProvider.Yahoo]: () => new OAuthProvider('yahoo.com').setCustomParameters({ prompt: 'login ' }),
};

export const initFirebase = () => {
  // @ts-ignore
  firebase = initializeApp(FIREBASE_CONFIG[FIREBASE_PROJECT]);
  getAuth(firebase).useDeviceLanguage();

  initializeAppCheck(firebase, {
    // @ts-ignore
    provider: new ReCaptchaV3Provider(FIREBASE_APP_CHECK_KEY[FIREBASE_PROJECT]),
    isTokenAutoRefreshEnabled: true,
  });
};

export const getFirebaseAuth = () => getAuth(firebase);

export const extractFromUserCredential = async (userCredential: UserCredential) => {
  const idToken = await userCredential.user.getIdToken(true);
  return {
    idToken,
    email: userCredential.user.email,
  };
};

export const firebaseSignOut = () => {
  getFirebaseAuth()
    .signOut()
    .catch((err) => {
      console.error('an error occurred while signing out from firebase', err);
    });
};

export const getSocialImage = (provider: AuthProvider) =>
  signInWithPopup(getFirebaseAuth(), THIRD_PARTY_AUTH_PROVIDERS[provider]()).then((result) => ({ userInfo: result }));
