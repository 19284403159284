import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { OLD_COLORS, OLD_SIZES } from '../../../../styles/appConsts';
import { CtaButton } from '../../../../components/buttons';
import { trackEvent } from '../../../../utils/analyticsEvent';
import { AccountStore, updateAccountProfile } from '../../../../store/ducks/account';
import { useDispatch, useSelector } from 'react-redux';
import { NavigationContext } from '../../../../components/navigator';

const useStyles = makeStyles((theme) => ({
  avatar: {
    boxSizing: 'content-box',
    borderRadius: '50%',
    margin: `${OLD_SIZES.s}px ${OLD_SIZES.s + OLD_SIZES.xs}px`,
  },
  activeCircle: {
    border: `${OLD_SIZES.xs}px solid ${OLD_COLORS.WHITE}`,
    margin: `${OLD_SIZES.xs}px ${OLD_SIZES.s}px !important`,
    filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.12))',
  },
  button: {
    backgroundColor: OLD_COLORS.WHITE,
    border: `1px solid ${OLD_COLORS.BRAND_PURPLE_3}`,
    color: OLD_COLORS.BLACK,
    marginBottom: '24px',
    width: '231px',

    [theme.breakpoints.down('xs')]: {
      width: '311px',
    },

    [theme.breakpoints.up('xs')]: {
      '&:hover': {
        backgroundColor: OLD_COLORS.BRAND_PURPLE_2,
        color: OLD_COLORS.WHITE,
      },
    },
  },
  buttonSubmit: {
    backgroundColor: OLD_COLORS.BRAND_PURPLE_2,
    border: `1px solid ${OLD_COLORS.BRAND_PURPLE_3}`,
    color: OLD_COLORS.WHITE,
    marginTop: '50px',
    width: '231px',

    [theme.breakpoints.down('xs')]: {
      width: '311px',
    },
  },
  circle: {
    height: 48,
    width: 48,
    borderRadius: '50%',
    boxSizing: 'content-box',
    margin: `${OLD_SIZES.s}px ${OLD_SIZES.s + OLD_SIZES.xs}px`,
  },
  image: {
    alignSelf: 'center',
    borderRadius: '50%',
    height: '132px',
    width: '132px',
  },
  imageWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '30px',
  },
  tabAvatarType: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: '32px',
  },
  tabBackgroundColor: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: '110px',
  },
  tabOptions: {
    alignItems: 'center',
    display: 'flex',
  },
  tabSkinTone: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingTop: '170px',
  },
  tabWrapper: {
    backgroundColor: OLD_COLORS.WHITE,
    display: 'flex',
    height: '100vh',
    width: '100vw',
  },
  textTitle: {
    color: OLD_COLORS.BLACK,
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '33px',
    marginBottom: '82px',

    [theme.breakpoints.down('xs')]: {
      marginBottom: '71px',
    },
  },
  wrapper: {
    alignItems: 'center',
    backgroundColor: OLD_COLORS.FLORAL_WHITE,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'scroll',
    paddingTop: '78px',

    [theme.breakpoints.down('xs')]: {
      paddingTop: '30px',
    },
  },
}));

enum Steps {
  Gender = 'Gender',
  AvatarPersonalization = 'AvatarPersonalization',
}

enum Gender {
  Female = 'female',
  Male = 'male',
  Other = 'other',
}

const TEXTS_PRESETS = {
  0: 'Choose avatar background',
  1: 'Choose avatar skin tone',
  2: 'Select avatar style',
};

interface TabPanelProps {
  children?: React.ReactNode;
  currentValue: number;
  tabValue: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, currentValue, tabValue, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={currentValue !== tabValue}
      id={`tabpanel-${tabValue}`}
      aria-labelledby={`tab-${tabValue}`}
      {...other}
    >
      {currentValue === tabValue && <>{children}</>}
    </Box>
  );
};

export const AvatarBuilder = () => {
  const classes = useStyles();
  const navCtx = useContext(NavigationContext);
  const dispatch = useDispatch();

  const isMobileWebview = useMemo(() => window.navigator.userAgent.indexOf('circles-mobile-app') > -1, []);

  // TODO: enrich DefaultRootState with account: AccountStore and groups
  // @ts-ignore
  const account: AccountStore = useSelector((state) => state.account);

  const [step, setStep] = useState<Steps>(Steps.Gender);
  const [gender, setGender] = useState<Gender>(Gender.Other);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [backgroundColor, setBackgroundColor] = useState<number>(1);
  const [skinColor, setSkinColor] = useState<number>(1);
  const [avatarType, setAvatarType] = useState<number>(1);

  const sex = useMemo(() => gender[0].toUpperCase(), [gender]);

  const [imageLink, setImageLink] = useState(
    `https://res.cloudinary.com/better-with-us/image/upload/v1611573962/new-avatars/${gender}-avatars/sex_${sex}_bg_${backgroundColor}_skin_${skinColor}_v_${avatarType}.png`
  );

  const onGenderChoose = (option: Gender) => {
    setStep(Steps.AvatarPersonalization);
    setGender(option);
  };

  const onSkinChoose = (option: number) => {
    setSkinColor(option);
    setCurrentTab(1);
  };

  const onBackgroundColorChoose = (option: number) => {
    setBackgroundColor(option);
    setCurrentTab(2);
  };

  const onUpdateProfile = () => {
    dispatch(updateAccountProfile(account.id, { picture: imageLink })).then(() => {
      // @ts-ignore
      if (isMobileWebview && window.ReactNativeWebView) {
        // @ts-ignore
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ action: 'updateUserProfile', update: { picture: imageLink } })
        );
        // @ts-ignore
        window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'close' }));
      } else {
        navCtx.popScreen();
      }
    });
  };

  useEffect(() => {
    setImageLink(
      `https://res.cloudinary.com/better-with-us/image/upload/v1611573962/new-avatars/${gender}-avatars/sex_${gender[0].toUpperCase()}_bg_${backgroundColor}_skin_${skinColor}_v_${avatarType}.png`
    );
  }, [avatarType, backgroundColor, gender, skinColor]);

  return (
    <Box className={classes.wrapper}>
      {step === Steps.Gender ? (
        <>
          <Box className={classes.textTitle}>Select avatar gender</Box>
          <CtaButton className={classes.button} onClick={() => onGenderChoose(Gender.Male)}>
            Male
          </CtaButton>
          <CtaButton className={classes.button} onClick={() => onGenderChoose(Gender.Female)}>
            Female
          </CtaButton>
          <CtaButton className={classes.button} onClick={() => onGenderChoose(Gender.Other)}>
            Other
          </CtaButton>
        </>
      ) : null}
      {step === Steps.AvatarPersonalization ? (
        <>
          <Box className={classes.textTitle}>{TEXTS_PRESETS[currentTab]}</Box>
          <Box className={classes.imageWrapper}>
            <Avatar alt='avatar' className={classes.image} src={imageLink} />
          </Box>
          <Tabs
            centered
            onChange={(_, newValue) => setCurrentTab(newValue)}
            TabIndicatorProps={{
              style: {
                backgroundColor: OLD_COLORS.BRAND_PURPLE_2,
                borderRadius: 2,
                height: OLD_SIZES.xs,
                color: OLD_COLORS.BLACK,
              },
            }}
            value={currentTab}
          >
            <Tab label='Skin tone' />
            <Tab label='Background' />
            <Tab label='Avatar' />
          </Tabs>
          <TabPanel currentValue={currentTab} tabValue={0}>
            <Box className={`${classes.tabWrapper} ${classes.tabSkinTone}`}>
              <Box
                className={`${classes.circle} ${skinColor === 1 ? classes.activeCircle : ''}`}
                style={{ background: OLD_COLORS.SKIN_TONE_1 }}
                onClick={() => onSkinChoose(1)}
              />
              <Box
                className={`${classes.circle} ${skinColor === 2 ? classes.activeCircle : ''}`}
                style={{ background: OLD_COLORS.SKIN_TONE_2 }}
                onClick={() => onSkinChoose(2)}
              />
              <Box
                className={`${classes.circle} ${skinColor === 3 ? classes.activeCircle : ''}`}
                style={{ background: OLD_COLORS.SKIN_TONE_3 }}
                onClick={() => onSkinChoose(3)}
              />
              <Box
                className={`${classes.circle} ${skinColor === 4 ? classes.activeCircle : ''}`}
                style={{ background: OLD_COLORS.SKIN_TONE_4 }}
                onClick={() => onSkinChoose(4)}
              />
            </Box>
          </TabPanel>
          <TabPanel currentValue={currentTab} tabValue={1}>
            <Box className={`${classes.tabWrapper} ${classes.tabBackgroundColor}`}>
              <Box className={classes.tabOptions}>
                <Box
                  className={`${classes.circle} ${backgroundColor === 1 ? classes.activeCircle : ''}`}
                  style={{ background: OLD_COLORS.BRAND_PURPLE_2 }}
                  onClick={() => onBackgroundColorChoose(1)}
                />
                <Box
                  className={`${classes.circle} ${backgroundColor === 2 ? classes.activeCircle : ''}`}
                  style={{ background: OLD_COLORS.LIGHT_ORANGE }}
                  onClick={() => onBackgroundColorChoose(2)}
                />
                <Box
                  className={`${classes.circle} ${backgroundColor === 3 ? classes.activeCircle : ''}`}
                  style={{ background: OLD_COLORS.SALMON }}
                  onClick={() => onBackgroundColorChoose(3)}
                />
              </Box>
              <Box className={classes.tabOptions}>
                <Box
                  className={`${classes.circle} ${backgroundColor === 4 ? classes.activeCircle : ''}`}
                  style={{ background: OLD_COLORS.VIBRANT_BLUE_2 }}
                  onClick={() => onBackgroundColorChoose(4)}
                />
                <Box
                  className={`${classes.circle} ${backgroundColor === 5 ? classes.activeCircle : ''}`}
                  style={{ background: OLD_COLORS.DULL_TURQUOISE }}
                  onClick={() => onBackgroundColorChoose(5)}
                />
                <Box
                  className={`${classes.circle} ${backgroundColor === 6 ? classes.activeCircle : ''}`}
                  style={{ background: OLD_COLORS.BLUSH_PINK }}
                  onClick={() => onBackgroundColorChoose(6)}
                />
              </Box>
            </Box>
          </TabPanel>
          <TabPanel currentValue={currentTab} tabValue={2}>
            <Box className={`${classes.tabWrapper} ${classes.tabAvatarType}`}>
              {(sex === 'F' || sex === 'M') && (
                <Box className={classes.tabOptions}>
                  {[...Array(4)].map((item, index) => (
                    <img
                      className={`${classes.avatar} ${avatarType === index + 1 ? classes.activeCircle : ''}`}
                      width={48}
                      height={48}
                      key={index}
                      onClick={() => setAvatarType(index + 1)}
                      src={`https://res.cloudinary.com/better-with-us/image/upload/v1611575826/new-avatars/${gender}-avatars/sex_${sex}_bg_${backgroundColor}_skin_${skinColor}_v_${
                        index + 1
                      }.png`}
                      alt=''
                    />
                  ))}
                </Box>
              )}
              {sex === 'O' && (
                <Box className={classes.tabOptions}>
                  {[...Array(3)].map((item, index) => (
                    <img
                      className={`${classes.avatar} ${avatarType === index + 1 ? classes.activeCircle : ''}`}
                      width={48}
                      height={48}
                      key={index}
                      onClick={() => setAvatarType(index + 1)}
                      src={`https://res.cloudinary.com/better-with-us/image/upload/v1617881937/new-avatars/${gender}-avatars/sex_${sex}_bg_${backgroundColor}_skin_${skinColor}_v_${
                        index + 1
                      }.png`}
                      alt=''
                    />
                  ))}
                </Box>
              )}
              {(sex === 'F' || sex === 'M') && (
                <Box className={classes.tabOptions}>
                  {[...Array(4)].map((item, index) => (
                    <img
                      className={`${classes.avatar} ${avatarType === index + 5 ? classes.activeCircle : ''}`}
                      width={48}
                      height={48}
                      key={index}
                      onClick={() => setAvatarType(index + 5)}
                      src={`https://res.cloudinary.com/better-with-us/image/upload/v1611575826/new-avatars/${gender}-avatars/sex_${sex}_bg_${backgroundColor}_skin_${skinColor}_v_${
                        index + 5
                      }.png`}
                      alt=''
                    />
                  ))}
                </Box>
              )}
              {sex === 'F' && (
                <Box className={classes.tabOptions}>
                  {[...Array(4)].map((item, index) => (
                    <img
                      className={`${classes.avatar} ${avatarType === index + 9 ? classes.activeCircle : ''}`}
                      width={48}
                      height={48}
                      key={index}
                      onClick={() => setAvatarType(index + 9)}
                      src={`https://res.cloudinary.com/better-with-us/image/upload/v1611575826/new-avatars/${gender}-avatars/sex_${sex}_bg_${backgroundColor}_skin_${skinColor}_v_${
                        index + 9
                      }.png`}
                      alt=''
                    />
                  ))}
                </Box>
              )}
              {sex === 'M' && (
                <Box className={classes.tabOptions}>
                  {[...Array(2)].map((item, index) => (
                    <img
                      className={`${classes.avatar} ${avatarType === index + 9 ? classes.activeCircle : ''}`}
                      width={48}
                      height={48}
                      key={index}
                      onClick={() => setAvatarType(index + 9)}
                      src={`https://res.cloudinary.com/better-with-us/image/upload/v1611575826/new-avatars/${gender}-avatars/sex_${sex}_bg_${backgroundColor}_skin_${skinColor}_v_${
                        index + 9
                      }.png`}
                      alt=''
                    />
                  ))}
                </Box>
              )}
              <CtaButton
                className={classes.buttonSubmit}
                onClick={() => {
                  trackEvent('avatar tabs page click');
                  onUpdateProfile();
                }}
              >
                Done
              </CtaButton>
            </Box>
          </TabPanel>
        </>
      ) : null}
    </Box>
  );
};
