// @DEPRECATED - we don't use strapi anymore
// we hold here static values

import { STRAPI_PATH } from '../../utils/consts';
import { createReducer } from '../../utils';

const PAYMENT_POPUP_CONTENT = [
  {
    id: 1,
    source: 'default',
    created_by: { id: 1, firstname: 'Vladimir', lastname: 'Loskutov', username: 'Vladimir' },
    updated_by: { id: 4, firstname: 'Dan', lastname: 'Landa', username: null },
    created_at: '2021-04-22T16:49:02.477Z',
    updated_at: '2021-11-28T09:01:23.462Z',
    content: [
      { id: 6, type: 'Image', content: `${ASSETS_BASE_DIR}/payment/sparkling-star.svg`, action: null, style: null },
      { id: 7, type: 'SubHeader', content: 'Save your seat!', action: null, style: null },
      { id: 10, type: 'Label2', content: 'Limited offer!', action: null, style: null },
      { id: 25, type: 'Label1', content: 'Get 75% off your first month of support!', action: null, style: null },
      { id: 28, type: 'Label1', content: 'Apply code FIRST75', action: null, style: null },
      {
        id: 11,
        type: 'CtaButton',
        content: 'Join this Circle',
        action: 'addPayment',
        style: { id: 2, backgroundColor: '#3399ff' },
      },
    ],
  },
  {
    id: 2,
    source: 'memberCard',
    created_by: { id: 1, firstname: 'Vladimir', lastname: 'Loskutov', username: 'Vladimir' },
    updated_by: { id: 4, firstname: 'Dan', lastname: 'Landa', username: null },
    created_at: '2021-04-22T16:58:42.441Z',
    updated_at: '2021-11-28T09:01:36.263Z',
    content: [
      { id: 12, type: 'Image', content: `${ASSETS_BASE_DIR}/payment/sparkling-star.svg`, action: null, style: null },
      { id: 13, type: 'SubHeader', content: 'Save your seat!', action: null, style: null },
      { id: 26, type: 'Label2', content: 'Special offer!', action: null, style: null },
      { id: 15, type: 'Label1', content: 'Get 75% off your first month of support!', action: null, style: null },
      { id: 29, type: 'Label1', content: 'Apply code FIRST75', action: null, style: null },
      {
        id: 17,
        type: 'CtaButton',
        content: 'Join this Circle',
        action: 'addPayment',
        style: { id: 3, backgroundColor: '#3399ff' },
      },
    ],
  },
  {
    id: 3,
    source: 'groupChat',
    created_by: { id: 1, firstname: 'Vladimir', lastname: 'Loskutov', username: 'Vladimir' },
    updated_by: { id: 6, firstname: 'Ofri', lastname: 'Ivzori', username: null },
    created_at: '2021-04-22T17:00:48.421Z',
    updated_at: '2021-10-13T10:34:47.659Z',
    content: [
      { id: 18, type: 'Image', content: `${ASSETS_BASE_DIR}/payment/sparkling-star.svg`, action: null, style: null },
      { id: 19, type: 'SubHeader', content: 'Save your seat!', action: null, style: null },
      {
        id: 20,
        type: 'Label1',
        content: 'Join weekly live video sessions with your Circle of support',
        action: null,
        style: null,
      },
      { id: 21, type: 'Label1', content: 'Led by professional facilitator, only $20/week', action: null, style: null },
      { id: 24, type: 'Label1', content: 'First session is free of charge!', action: null, style: null },
      {
        id: 22,
        type: 'CtaButton',
        content: 'Join this Circle',
        action: 'addPayment',
        style: { id: 4, backgroundColor: '#3399ff' },
      },
    ],
  },
];

const CALENDLY_CONTENT = {
  id: 1,
  title: 'We’re here for you',
  text:
    'We invite you to a free consultation call with one of our Circles experts who will answer any question you may have and will help you smoothly connect to your group.\n',
  cta: 'Book your free consultation',
  skip: 'Continue without free consultation',
  created_by: {},
  updated_by: { id: 6, firstname: 'Ofri', lastname: 'Ivzori', username: null },
  created_at: '2021-02-28T16:21:14.460Z',
  updated_at: '2021-10-14T13:38:38.576Z',
};

const CALENDLY_GROUPS_LAB_CONTENT = {
  id: 1,
  title: 'Schedule your call today',
  text: 'Have a video call scheduled with one of our specialists to better understand about the MyCircle program.',
  cta: 'Next',
  skip: 'I prefer to skip this step',
  created_by: {},
  updated_by: {},
  created_at: '2021-05-31T14:43:13.959Z',
  updated_at: '2021-05-31T14:43:13.974Z',
};

// Reducer
const initialState = {
  [STRAPI_PATH.PAYMENT_POPUP.key]: PAYMENT_POPUP_CONTENT,
  [STRAPI_PATH.CALENDLY.key]: CALENDLY_CONTENT,
  [STRAPI_PATH.CALENDLY_GROUPS_LAB.key]: CALENDLY_GROUPS_LAB_CONTENT,
};

export default createReducer(initialState, {});
