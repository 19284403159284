import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Label1 } from '../Text';
import { OLD_SIZES } from '../../styles/appConsts';

const useStyles = makeStyles(() => ({
  root: {
    width: 210,
    height: 226,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: OLD_SIZES.s,
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    position: 'relative',
    justifyContent: 'space-around',
    padding: OLD_SIZES.m,
  },
  title: {
    position: 'absolute',
    top: OLD_SIZES.m,
  },
  question: {},
}));

export default function ({ description, title, question, part, color, className }) {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ backgroundColor: color }}>
      <Label1 className={classes.title}>{`Part ${part} - ${title}`}</Label1>
      <Label1 className={classes.question}>{question}</Label1>
    </div>
  );
}
