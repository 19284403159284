import React, { useMemo, useState } from 'react';
import {
  FirstMeetingQuestionnaireQuestionType,
  FirstMeetingQuestionnaireSingleQuestionAnswer,
  FirstQuestionConfiguration,
} from '../../types';
import { Box } from '@material-ui/core';
import { Label1, Label2 } from '../../../../components/Text';
import { OLD_COLORS } from '../../../../styles/appConsts';
import { CtaButton } from '../../../../components/buttons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '50px',
    height: '90%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    color: OLD_COLORS.BLACK,
    textAlign: 'center',
  },
  ratingWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '30px',
  },
  ratingItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px',
  },
  ratingItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: OLD_COLORS.WHITE,
    border: `1px solid ${OLD_COLORS.BLACK}`,
    borderRadius: '50px',
    height: '40px',
    width: '40px',
    margin: '8px 5px 0px 5px',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  selectedRatingItem: {
    backgroundColor: `${OLD_COLORS.VIBRANT_BLUE_2}4D`,
    border: `1px solid ${OLD_COLORS.VIBRANT_BLUE_2}`,
  },
  ratingItemLabel: {
    color: OLD_COLORS.BLACK,
  },
  edgeRatingItemSignatureBelow: {
    fontSize: '12px',
    color: OLD_COLORS.GRAY_500,
  },
  button: {
    width: '220px',
    background: OLD_COLORS.VIBRANT_BLUE_2,
    '&:hover': {
      background: OLD_COLORS.VIBRANT_BLUE_2,
    },
  },
  buttonLabel: {
    color: OLD_COLORS.WHITE,
  },
}));

interface FirstQuestionProps {
  configuration: FirstQuestionConfiguration;
  addAnswer: (answer: FirstMeetingQuestionnaireSingleQuestionAnswer) => void;
  moveToNextQuestion: () => void;
}

export const FirstQuestion = ({
  configuration: { title, minRate, minRateLabel, maxRate, maxRateLabel, buttonLabel },
  addAnswer,
  moveToNextQuestion,
}: FirstQuestionProps) => {
  const classes = useStyles();

  const [answer, setAnswer] = useState<number | null>(null);

  const rates = useMemo(() => Array.from(Array(maxRate).keys()).map((el) => el + 1), [minRate, maxRate]);

  return (
    <Box className={classes.wrapper}>
      <Box>
        <Label2 className={classes.title}>{title}</Label2>
        <Box className={classes.ratingWrapper}>
          {rates.map((rate: number) => (
            <Box className={classes.ratingItemWrapper} key={rate}>
              <Box
                className={`${classes.ratingItem} ${answer && answer === rate ? classes.selectedRatingItem : ''}`}
                onClick={() => setAnswer(rate)}
              >
                <Label1 className={classes.ratingItemLabel}>{rate}</Label1>
              </Box>
              <Box className={classes.edgeRatingItemSignatureBelow}>
                {rate === minRate ? minRateLabel : null}
                {rate === maxRate ? maxRateLabel : null}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <CtaButton
        className={classes.button}
        disabled={!answer}
        onClick={() => {
          addAnswer({
            type: FirstMeetingQuestionnaireQuestionType.Rate,
            value: answer,
          });
          moveToNextQuestion();
        }}
      >
        <Label1 className={classes.buttonLabel}>{buttonLabel}</Label1>
      </CtaButton>
    </Box>
  );
};
