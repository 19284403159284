import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { NavigationContext } from '../../navigator';
import { OLD_COLORS } from '../../../styles/appConsts';
import { ProfileStore } from '../../../store/ducks/profile';
import { trackEvent } from '../../../utils/analyticsEvent';
import { apiClient } from '../../../axiosClient';
import { GroupSpaceMember } from '../../../screens/groupSpace/GroupSpace';

interface MemberProfilePopupProps {
  isOpen: boolean;
  member: GroupSpaceMember;
  isUser: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 30,
    margin: '36px 12px 12px 12px',
    width: 'calc(100% - 24px)',
    overflowY: 'unset',
    '&.dialog-root': {
      maxWidth: 'unset',
    },
    [theme.breakpoints.up('sm')]: {
      width: 'unset',
    },
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    borderRadius: 30,
    padding: '44px 24px 24px 24px',
    overflowY: 'auto',
    [theme.breakpoints.up('sm')]: {
      padding: '44px 54px 32px 54px',
      width: 600,
    },
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: `url("${ASSETS_BASE_DIR}/profile/profile-popup-background.svg") no-repeat center`,
    width: '100%',
    height: 104,
    [theme.breakpoints.up('sm')]: {
      height: 120,
    },
  },
  closeButton: {
    position: 'absolute',
    right: 24,
    top: -24,
    color: OLD_COLORS.WHITE,
    backgroundColor: OLD_COLORS.BRAND_PURPLE_2,
    borderRadius: '50%',
    zIndex: 100,
    '&:hover': {
      backgroundColor: OLD_COLORS.BRAND_PURPLE_2,
    },
  },
  avatar: {
    border: `5px solid ${OLD_COLORS.PALE_ORANGE}`,
    borderRadius: '50%',
    alignSelf: 'center',
    width: 108,
    height: 108,
    zIndex: 10,
    [theme.breakpoints.up('sm')]: {
      width: 126,
      height: 126,
    },
  },
  name: {
    alignSelf: 'center',
    fontSize: 24,
    fontWeight: 700,
    lineHeight: '36px',
    marginTop: '10px 0 24px 0',
  },
  edit: {
    alignSelf: 'center',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '21px',
    color: OLD_COLORS.BRAND_PURPLE_1,
    border: 'none',
    backgroundColor: 'transparent',
    top: -16,
    marginBottom: 24,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  label: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '27px',
  },
  text: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    marginTop: 10,
  },
  break: {
    borderBottom: `1px solid ${OLD_COLORS.BRAND_PURPLE_3}`,
    width: 'calc(100%)',
    margin: '30px 0',
  },
  pillsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: 12,
    columnGap: 10,
    marginTop: 20,
  },
  pill: {
    backgroundColor: OLD_COLORS.FLORAL_WHITE,
    border: `1px solid ${OLD_COLORS.LIGHT_ORANGE}`,
    borderRadius: 38,
    color: OLD_COLORS.GRAY_700,
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: '24px',
    padding: '7px 16px',
  },
  noData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: `url("${ASSETS_BASE_DIR}/profile/gray-blob.svg") no-repeat center`,
    color: OLD_COLORS.GRAY_500,
    width: '100%',
    height: 180,
    marginTop: 44,
  },
}));

export const MemberProfilePopup = ({ isOpen, member, isUser, onClose }: MemberProfilePopupProps) => {
  const classes = useStyles();
  const navCtx = useContext(NavigationContext);

  // @ts-ignore
  const profileStore: ProfileStore = useSelector((state) => state.profile);

  useEffect(() => {
    trackEvent('Click view newprofile', { clickedOnOwnProfile: isUser });
  }, []);

  useEffect(() => {
    const viewedAccountId = member.id
    apiClient
     .post(`/account/profile/${viewedAccountId}/view`)
     .catch(err => console.error('[trackProfileView]', err))
  }, [])

  return (
    <Dialog
      classes={{ paper: `${classes.root} dialog-root` }}
      onClose={onClose}
      aria-labelledby='customized-dialog-title'
      open={isOpen}
      scroll={'body'}
    >
      <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <div className={classes.container}>
        <div className={classes.background} />
        <img className={classes.avatar} src={member.picture} alt={member.name} />
        <span className={classes.name}>{member.name}</span>
        {isUser && (
          <button
            className={classes.edit}
            onClick={() => {
              trackEvent('Click newprofile popup edit');
              navCtx.pushScreen('/profile/items');
            }}
          >
            Edit profile
          </button>
        )}
        {member.intro && (
          <>
            <span className={classes.label}>My story</span>
            <span className={classes.text}>{member.intro}</span>
            {((member.passions && member.passions.length > 0) ||
              (member.descriptionTags && member.descriptionTags.length > 0) ||
              member.whatBringsMe) && <div className={classes.break} />}
          </>
        )}
        {member.passions && member.passions.length > 0 && (
          <>
            <span className={classes.label}>My passions</span>
            <div className={classes.pillsContainer}>
              {member.passions.map((passion) => (
                <div className={classes.pill} key={passion}>
                  {profileStore.passions.find((obj) => obj.key === passion).label}
                </div>
              ))}
            </div>
            {((member.descriptionTags && member.descriptionTags.length > 0) || member.whatBringsMe) && (
              <div className={classes.break} />
            )}
          </>
        )}
        {member.descriptionTags && member.descriptionTags.length > 0 && (
          <>
            <span className={classes.label}>A little bit about me</span>
            <div className={classes.pillsContainer}>
              {member.descriptionTags.map((descriptionTag) => (
                <div className={classes.pill} key={descriptionTag}>
                  {profileStore.descriptions.find((obj) => obj.key === descriptionTag).label}
                </div>
              ))}
            </div>
            {member.whatBringsMe && <div className={classes.break} />}
          </>
        )}
        {member.whatBringsMe && (
          <>
            <span className={classes.label}>What brings me to Circles</span>
            <span className={classes.text}>{member.whatBringsMe}</span>
          </>
        )}
        {!member.intro && !member.whatBringsMe && !member.passions && !member.descriptionTags && (
          <div className={classes.noData}>{`${member.name} has not yet filled profile information`}</div>
        )}
      </div>
    </Dialog>
  );
};
