import { createApiAction, createReducer } from '../../utils';
import { types as authTypes } from './auth';
import { types as paymentTypes } from './payment';
import { types as purchaseTypes } from './purchase';
import { Account, AccountProfileProperties, AccountsTag, Event, AccountRole } from '@7chairs/types';

export type AccountStore = Omit<Account, 'firstAvailableMeeting' | 'timezone' | 'firebaseIds'> & {
  accountId: string;
  role: AccountRole;
  isAuth: boolean;
  isPayment: boolean;
  isFirstLogin: boolean;
  intro: string;
  whatBringsMe: string;
  events: Event[];
  oneTimeCircle: unknown;
  passions: AccountsTag[];
  descriptionTags: AccountsTag[];
  defaultEventProps: {};
  hasSeenDownloadApp: boolean;
  paymentProvider?: string;
  pickAGroupUrl?: string;
  isVoicesPurchase: boolean;
  voicesPurchaseProvider: string | null;
  voicesPurchaseCancelPaymentRequest: { status: string | null } | null;
};

// Types
export const types = {
  GET_ACCOUNT: 'account/GET_ACCOUNT',
  GET_ACCOUNT_SUCCESS: 'account/GET_ACCOUNT_SUCCESS',
  GET_ACCOUNT_ERROR: 'account/GET_ACCOUNT_ERROR',
  UPDATE_ACCOUNT: 'account/UPDATE_ACCOUNT',
  UPDATE_ACCOUNT_SUCCESS: 'account/UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_ERROR: 'account/UPDATE_ACCOUNT_ERROR',
  ASSIGN_ACCOUNT_TO_GROUP: 'account/ASSIGN_ACCOUNT_TO_GROUP',
  ASSIGN_ACCOUNT_TO_GROUP_SUCCESS: 'account/ASSIGN_ACCOUNT_TO_GROUP_SUCCESS',
  ASSIGN_ACCOUNT_TO_GROUP_ERROR: 'account/ASSIGN_ACCOUNT_TO_GROUP_ERROR',
  FIND_MATCHING_GROUPS_BY_DISCOVERY: 'account/FIND_MATCHING_GROUPS_BY_DISCOVERY',
  FIND_MATCHING_GROUPS_BY_DISCOVERY_SUCCESS: 'account/FIND_MATCHING_GROUPS_BY_DISCOVERY_SUCCESS',
  FIND_MATCHING_GROUPS_BY_DISCOVERY_ERROR: 'account/FIND_MATCHING_GROUPS_BY_DISCOVERY_ERROR',
  GET_DISCOVERY: 'account/GET_DISCOVERY',
  GET_DISCOVERY_SUCCESS: 'account/GET_DISCOVERY_SUCCESS',
  GET_DISCOVERY_ERROR: 'account/GET_DISCOVERY_ERROR',
  GET_ACCOUNT_PROFILE: 'account/GET_ACCOUNT_PROFILE',
  GET_ACCOUNT_PROFILE_SUCCESS: 'account/GET_ACCOUNT_PROFILE_SUCCESS',
  GET_ACCOUNT_PROFILE_ERROR: 'account/GET_ACCOUNT_PROFILE_ERROR',
  UPDATE_ACCOUNT_PROFILE: 'account/UPDATE_ACCOUNT_PROFILE',
  UPDATE_ACCOUNT_PROFILE_SUCCESS: 'account/UPDATE_ACCOUNT_PROFILE_SUCCESS',
  UPDATE_ACCOUNT_PROFILE_ERROR: 'account/UPDATE_ACCOUNT_PROFILE_ERROR',
  GET_ACCOUNT_GENERAL_CONTENT: 'account/GET_ACCOUNT_GENERAL_CONTENT',
  GET_ACCOUNT_GENERAL_CONTENT_SUCCESS: 'account/GET_ACCOUNT_GENERAL_CONTENT_SUCCESS',
  GET_ACCOUNT_GENERAL_CONTENT_ERROR: 'account/GET_ACCOUNT_GENERAL_CONTENT_ERROR',
  GET_PICK_A_GROUP_URL: 'account/GET_PICK_A_GROUP_URL',
  GET_PICK_A_GROUP_URL_SUCCESS: 'account/GET_PICK_A_GROUP_URL_SUCCESS',
  GET_PICK_A_GROUP_URL_ERROR: 'account/GET_PICK_A_GROUP_URL_ERROR',
  GET_HAS_SEEN_DOWNLOAD_APP_POPUP: 'account/GET_HAS_SEEN_DOWNLOAD_APP_POPUP',
  GET_HAS_SEEN_DOWNLOAD_APP_POPUP_SUCCESS: 'account/GET_HAS_SEEN_DOWNLOAD_APP_POPUP_SUCCESS',
  GET_HAS_SEEN_DOWNLOAD_APP_POPUP_ERROR: 'account/GET_HAS_SEEN_DOWNLOAD_APP_POPUP_ERROR',
  MARK_HAS_SEEN_DOWNLOAD_APP_POPUP: 'account/MARK_HAS_SEEN_DOWNLOAD_APP_POPUP',
  MARK_HAS_SEEN_DOWNLOAD_APP_POPUP_SUCCESS: 'account/MARK_HAS_SEEN_DOWNLOAD_APP_POPUP_SUCCESS',
  MARK_HAS_SEEN_DOWNLOAD_APP_POPUP_ERROR: 'account/MARK_HAS_SEEN_DOWNLOAD_APP_POPUP_ERROR',
};

// Actions
export function getAccount() {
  return createApiAction(
    [types.GET_ACCOUNT, types.GET_ACCOUNT_SUCCESS, types.GET_ACCOUNT_ERROR],
    '/account',
    'get',
    undefined
  );
}

export function getPickAGroupUrl() {
  return createApiAction(
    [types.GET_PICK_A_GROUP_URL, types.GET_PICK_A_GROUP_URL_SUCCESS, types.GET_PICK_A_GROUP_URL_ERROR],
    '/account/find-group-url',
    'get',
    undefined
  );
}

export const getAccountProfile = (accountId: string) =>
  createApiAction(
    [types.GET_ACCOUNT_PROFILE, types.GET_ACCOUNT_PROFILE_SUCCESS, types.GET_ACCOUNT_PROFILE_ERROR],
    `/account/${accountId}/profile`,
    'get',
    undefined
  );

export const getAccountGeneralContent = () =>
  createApiAction(
    [
      types.GET_ACCOUNT_GENERAL_CONTENT,
      types.GET_ACCOUNT_GENERAL_CONTENT_SUCCESS,
      types.GET_ACCOUNT_GENERAL_CONTENT_ERROR,
    ],
    `/generalContent`,
    'get',
    undefined
  );

export function updateAccount(props) {
  return createApiAction(
    [types.UPDATE_ACCOUNT, types.UPDATE_ACCOUNT_SUCCESS, types.UPDATE_ACCOUNT_ERROR],
    '/account',
    'patch',
    { ...props }
  );
}

export const updateAccountProfile = (accountId: string, accountProfileProperties: Partial<AccountProfileProperties>) =>
  createApiAction(
    [types.UPDATE_ACCOUNT_PROFILE, types.UPDATE_ACCOUNT_PROFILE_SUCCESS, types.UPDATE_ACCOUNT_PROFILE_ERROR],
    `/account/v2/profile`,
    'patch',
    { ...accountProfileProperties }
  );

export function assignAccountToGroup(groupInstanceId: string) {
  return createApiAction(
    [types.ASSIGN_ACCOUNT_TO_GROUP, types.ASSIGN_ACCOUNT_TO_GROUP_SUCCESS, types.ASSIGN_ACCOUNT_TO_GROUP_ERROR],
    '/account/assign-to-group',
    'post',
    { groupInstanceId }
  );
}

export function findMatchingGroupsByDiscovery() {
  return createApiAction(
    [
      types.FIND_MATCHING_GROUPS_BY_DISCOVERY,
      types.FIND_MATCHING_GROUPS_BY_DISCOVERY_SUCCESS,
      types.FIND_MATCHING_GROUPS_BY_DISCOVERY_ERROR,
    ],
    '/account/find-matching-groups-by-discovery',
    'get',
    null
  );
}

export function getDiscovery(id) {
  return createApiAction(
    [types.GET_DISCOVERY, types.GET_DISCOVERY_SUCCESS, types.GET_DISCOVERY_ERROR],
    `/account/${id}/discovery`,
    'get',
    null
  );
}

export function getHasSeenDownloadAppPopup() {
  return createApiAction(
    [
      types.GET_HAS_SEEN_DOWNLOAD_APP_POPUP,
      types.GET_HAS_SEEN_DOWNLOAD_APP_POPUP_SUCCESS,
      types.GET_HAS_SEEN_DOWNLOAD_APP_POPUP_ERROR,
    ],
    '/account/seen-download-app',
    'get'
  );
}

export function markHasSeenDownloadAppPopup() {
  return createApiAction(
    [
      types.MARK_HAS_SEEN_DOWNLOAD_APP_POPUP,
      types.MARK_HAS_SEEN_DOWNLOAD_APP_POPUP_SUCCESS,
      types.MARK_HAS_SEEN_DOWNLOAD_APP_POPUP_ERROR,
    ],
    '/account/seen-download-app',
    'post'
  );
}

// Reducer
const initialState: Omit<AccountStore, 'accountId'> = {
  isAuth: false,
  id: null,
  name: null,
  nickname: null,
  fullName: null,
  phoneNumber: null,
  role: null,
  email: null,
  picture: null,
  imageUrl: null,
  status: null,
  lastUpdated: null,
  meta: null,
  isPayment: false,
  cancelPaymentRequest: null,
  firstSession: null,
  privateGroups: {},
  isFirstLogin: false,
  whatBringsMe: null,
  intro: null,
  events: [],
  oneTimeCircle: null,
  passions: [],
  descriptionTags: [],
  defaultEventProps: {},
  paymentTimestamp: null,
  paymentProvider: null,
  subscriptionStatus: null,
  trialEnd: null,
  pickAGroupUrl: '',
  segment: null,
  //It's set to true, cause initially we don't want to show the popup
  hasSeenDownloadApp: true,
};

const handlers = {
  [types.GET_DISCOVERY_SUCCESS]: (state, { data }) => ({
    ...state,
    meta: {
      ...state.meta,
      discovery: data,
    },
  }),
  [types.GET_ACCOUNT_SUCCESS]: (state, { data }) => ({
    ...state,
    ...data,
    isAuth: true,
  }),
  [purchaseTypes.GET_PURCHASE_INFO_SUCCESS]: (state, { data }) => ({
    ...state,
    ...data,
  }),
  [types.GET_ACCOUNT_GENERAL_CONTENT_SUCCESS]: (state, { data }) => ({
    ...state,
    ...data,
  }),
  [types.GET_ACCOUNT_PROFILE_SUCCESS]: (state, { data }) => ({
    ...state,
    ...data,
  }),
  [types.UPDATE_ACCOUNT_SUCCESS]: (state, { data }) => ({
    ...state,
    ...data,
  }),
  [types.UPDATE_ACCOUNT_PROFILE_SUCCESS]: (state, { data }) => ({
    ...state,
    ...data,
  }),
  [authTypes.TOKEN_LOGIN_SUCCESS]: (state, { data }) => ({
    ...state,
    ...data,
    isAuth: true,
  }),
  [authTypes.LOGIN_WITH_FIREBASE_SUCCESS]: (state, { data }) => ({
    ...state,
    ...data,
    isAuth: true,
  }),
  [authTypes.LOGOUT_SUCCESS]: () => ({
    ...initialState,
    isAuth: false,
  }),
  [paymentTypes.PAYMENT_METHOD_SUCCESS]: (state) => ({
    ...state,
    isPayment: true,
  }),
  [purchaseTypes.UPDATE_PURCHASE_PAYMENT_SUCCESS]: (state) => ({
    ...state,
    isVoicesPurchase: true,
  }),
  [paymentTypes.CANCEL_SUBSCRIPTION_REQUEST_SUCCESS]: (state, { data }) => ({
    ...state,
    cancelPaymentRequest: data.cancelPayment,
  }),
  [purchaseTypes.CANCEL_PURCHASE_SUBSCRIPTION_REQUEST_SUCCESS]: (state, { data }) => ({
    ...state,
    voicesPurchaseCancelPaymentRequest: data.cancelPayment,
  }),
  [paymentTypes.REENABLE_SUBSCRIPTION_SUCCESS]: (state, { data }) => ({
    ...state,
    cancelPaymentRequest: data.cancelPayment,
  }),
  [purchaseTypes.REENABLE_PURCHASE_SUBSCRIPTION_SUCCESS]: (state, { data }) => ({
    ...state,
    voicesPurchaseCancelPaymentRequest: data.cancelPayment,
  }),
  [paymentTypes.CANCEL_SUBSCRIPTION_NEXT_MONTH_FREE_SUCCESS]: (state, { data }) => ({
    ...state,
    meta: data.meta,
  }),
  [authTypes.PING_SUCCESS]: (state, { data }) => {
    if (data.privateGroups) {
      return {
        ...state,
        privateGroups: data.privateGroups,
      };
    }
    return state;
  },
  [types.GET_PICK_A_GROUP_URL_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      // data.page is discovery-repeat or onboarding/document/pick-a-group
      pickAGroupUrl: data.page,
    };
  },
  [types.GET_HAS_SEEN_DOWNLOAD_APP_POPUP_SUCCESS]: (state, { data }) => {
    return {
      ...state,
      hasSeenDownloadApp: data.hasSeenDownloadApp,
    };
  },
  [types.MARK_HAS_SEEN_DOWNLOAD_APP_POPUP_SUCCESS]: (state) => {
    return { ...state, hasSeenDownloadApp: true };
  },
};

export default createReducer(initialState, handlers);
