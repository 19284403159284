import { Theme } from '@material-ui/core';

export interface HeaderProps {
  text: string;
}

export const baseStyles = (theme: Theme): Record<'content' | 'root', Record<string, string | number | Record<string, string | number>>> => ({
  content: {
    display: 'flex',
    maxWidth: '920px',
    padding: '0 24px',

    [theme.breakpoints.down('xs')]: {
      padding: '0 16px',
    },
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100vw',
  },
});
