import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { openExerciseBreakModal } from '../dialogs/mixedDialog/mixedDialogContent';
import { setDialogProps } from '../../store/ducks/root';
import getPaymentStatus from '../../utils/payment';
import {
  setExercise,
  setOpenExercise,
  createProgress,
  submitAnswer,
  reviewQuestion,
  addQuestionCache,
  updateAnswer,
  getSummaryInfo,
} from '../../store/ducks/exercises';
import {sendGTMEvent, trackEvent} from '../../utils/analyticsEvent';
import progressStatus from './progressStatus';
import questionTypes from './questionTypes';
import Intro from './Intro';
import PartIntro from './PartIntro';
import Question from './Question';
import FinalScreen from './FinalScreen';
import { OLD_COLORS, OLD_SIZES } from '../../styles/appConsts';
import { setPaymentPopUpData } from '../../store/ducks/root';
import {storage} from "../../utils/localStorage";

const MODAL_WIDTH = 375;

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1111,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  content: {
    position: 'relative',
    display: 'flex',
    height: 667,
    width: MODAL_WIDTH,
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      width: '100%',
    },
  },
  closeIconDiv: {
    position: 'absolute',
    top: 4,
    right: 4,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  closeIcon: {
    color: OLD_COLORS.WHITE,
  },
  betaDiv: {
    marginTop: OLD_SIZES.xxl,
    backgroundColor: 'rgba(255, 255, 255, 0.61)',
    borderRadius: OLD_SIZES.xs,
    padding: `${OLD_SIZES.xs}px ${OLD_SIZES.s}px`,
  },
  betaText: {
    fontSize: 12,
  },
}));

function Main({
  exercise,
  paymentRequired,
  questionsCache,
  setExercise,
  setOpenExercise,
  createProgress,
  submitAnswer,
  reviewQuestion,
  addQuestionCache,
  updateAnswer,
  getSummaryInfo,
  setDialogProps,
}) {
  const classes = useStyles();

  const { id, intro, parts, questionsId } = exercise;

  const [nextQuestion, setNextQuestion] = useState(exercise.nextQuestion);
  const [progress, setProgress] = useState(exercise.progress);

  const [submitQuestionData, setSubmitQuestionData] = useState(null);

  const { status, part: partIndex = 0, question: questionIndex = 0 } = progress;
  const { question, answers, totalAnswers, answered } = nextQuestion;

  const [displayPartIntro, setDisplayPartIntro] = useState(questionIndex === 0);
  const [reviewMode, setReviewMode] = useState(answered);
  const [displayFinalScreen, setDisplayFinalScreen] = useState(status === progressStatus.COMPLETED);

  const [isLoading, setIsLoading] = useState(false);
  const [switchQuestionSpinner, setSwitchQuestionSpinner] = useState(false);

  const [summaryInfo, setSummaryInfo] = useState([]);

  useEffect(() => {
    const { progress: p } = exercise;

    const [prevPartIdx, prevQuestionIdx] = getPrevIndex(p.part, p.question);
    const qId = questionsId[prevPartIdx]?.[prevQuestionIdx];

    // when mount, if need to set payment we go to prev question
    async function goToPrevQuestion() {
      let prevQuestion = questionsCache[qId];
      if (!prevQuestion) {
        setSwitchQuestionSpinner(true);
        prevQuestion = await reviewQuestion(id, qId)
          .then(({ payload: { data } }) => {
            setSwitchQuestionSpinner(false);
            return {
              question: data.question,
              answers: data.answers,
              answered: true,
              totalAnswers: data.answers.length,
            };
          })
          .catch(() => setSwitchQuestionSpinner(false));
        addQuestionCache({ id: prevQuestion.question.id, question: prevQuestion });
      }
      setProgress({ ...progress, part: prevPartIdx, question: prevQuestionIdx });
      setNextQuestion(prevQuestion);
    }

    if (paymentRequired && qId) {
      goToPrevQuestion();
    }
  }, []);

  const loadSummaryInfo = () => {
    return getSummaryInfo(id).then(({ payload: { data } }) => {
      let info = [];
      data.forEach((part, i) => {
        part.forEach((question) => {
          if (question.type !== questionTypes.TEXT) {
            return;
          }
          info.push({
            questionId: question.questionId,
            question: question.title,
            title: parts[i].title,
            description: parts[i].description,
            partColor: parts[i].color,
            part: i + 1,
          });
          info = info.concat(question.answers);
        });
      });
      setSummaryInfo(info);
    });
  };

  const reviewNextQuestion = async (qId) => {
    trackEvent('click review exercise question next');
    // we reach to the stage of where we are, stop reviewing
    if (qId === exercise.nextQuestion.question.id) {
      setReviewMode(false);
      setProgress(exercise.progress);
      setNextQuestion(exercise.nextQuestion);
      setDisplayPartIntro(exercise.progress.question === 0);
      return;
    }

    // no next questionId, means we reach to end of exercise
    if (!qId) {
      setDisplayFinalScreen(true);
      return;
    }

    let nq = questionsCache[qId];
    if (!nq) {
      nq = await reviewQuestion(id, qId).then(({ payload: { data } }) => {
        return {
          question: data.question,
          answers: data.answers,
          answered: true,
          totalAnswers: data.answers.length,
        };
      });
      addQuestionCache({ id: nq.question.id, question: nq });
    }
    setProgress({ ...progress, part: nextPIdx, question: nextQIdx });
    setNextQuestion(nq);
    setDisplayPartIntro(nextQIdx === 0);
  };

  const onQuestionUpdate = (ansId, data) => {
    setIsLoading(true);
    updateAnswer(id, question.id, ansId, data)
      .then(({ payload: { data } }) => {
        const { newAnswer } = data;
        const updatedAnswers = answers.map((ans) => {
          if (ans.id === newAnswer.id) {
            return { ...ans, ...newAnswer };
          }
          return ans;
        });
        const updatedNextQuestion = { ...nextQuestion, answers: updatedAnswers };
        // check if need to update cache in store
        if (questionsCache[question.id]) {
          addQuestionCache({
            id: question.id,
            question: updatedNextQuestion,
          });
        }
        // check if need to update store
        if (exercise.nextQuestion.question.id === question.id) {
          setExercise({ ...exercise, nextQuestion: updatedNextQuestion });
        }
        // update local stage
        setNextQuestion(updatedNextQuestion);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onQuestionSubmit = (data) => {
    let answer;
    if (!data) {
      answer = 'no answer'
    } else {
      answer = typeof data === "string" ? 'free text' : data
    }
    trackEvent('click submit exercise answer', {
      part: partIndex + 1,
      question: questionIndex + 1,
      questionId: question.id,
    });
    sendGTMEvent({
      GA_event_category: 'reflection',
      GA_event_action: 'reflection question answered',
      GA_event_label: question.title,
      GA_event_detail: answer,
      reflection: intro ? intro.title : 'NA'
    });
    setIsLoading(true);
    submitAnswer(id, question.id, data)
      .then(({ payload: { data } }) => {
        setSubmitQuestionData({ nextQuestion: data.nextQuestion, progress: data.progress });
        const updatedQuestion = {
          ...nextQuestion,
          answers: data.question.answers,
          answered: true,
          totalAnswers: data.question.answers.length,
        };
        setNextQuestion(updatedQuestion);
        addQuestionCache({ id: question.id, question: updatedQuestion });
        setIsLoading(false);
      })
      .catch((err) => {
        const status = err.error?.response?.status;
        if (status === 402) {
          openPaymentPopUpLocal();
        }
        setIsLoading(false);
      });
  };

  const onQuestionNext = () => {
    if (isPaymentRequired) {
      openPaymentPopUpLocal();
      return;
    }
    if (reviewMode) {
      reviewNextQuestion(nextQuestionId);
      return;
    }
    if (submitQuestionData.progress.status !== progressStatus.COMPLETED) {
      trackEvent('click next exercise question', {
        part: submitQuestionData.progress.part + 1,
        question: submitQuestionData.progress.question + 1,
        questionId: submitQuestionData.nextQuestion.question.id,
      });
      setDisplayPartIntro(submitQuestionData.progress.question === 0);
      setProgress(submitQuestionData.progress);
      setNextQuestion(submitQuestionData.nextQuestion);
    } else {
      trackEvent('click finish exercise');
      setDisplayFinalScreen(true);
    }
  };

  const onNavBack = async (qId) => {
    trackEvent('click review exercise question back');
    let prevQuestion = questionsCache[qId];
    if (!prevQuestion) {
      prevQuestion = await reviewQuestion(id, qId).then(({ payload: { data } }) => {
        return {
          question: data.question,
          answers: data.answers,
          answered: true,
          totalAnswers: data.answers.length,
        };
      });
      addQuestionCache({ id: prevQuestion.question.id, question: prevQuestion });
    }
    setReviewMode(true);
    setProgress({ ...progress, part: prevPIdx, question: prevQIdx });
    setNextQuestion(prevQuestion);
  };

  const openPaymentPopUpLocal = () => {
    setPaymentPopUpData({
      open: true,
      addPaymentCallback: (action) => {
        if (action !== 'skip') {
          setOpenExercise(false);
        }
      },
    });
  };

  const getPrevIndex = (pIdx, qIdx) => {
    if (pIdx === 0 && qIdx === 0) {
      return [null, null];
    }
    if (qIdx === 0) {
      return [pIdx - 1, questionsId[pIdx - 1]?.length - 1];
    }
    return [pIdx, qIdx - 1];
  };

  const getNextIndex = (pIdx, qIdx) => {
    if (qIdx + 1 === questionsId[pIdx]?.length) {
      return pIdx + 1 === questionsId.length ? [null, null] : [pIdx + 1, 0];
    }
    return [pIdx, qIdx + 1];
  };

  const [prevPIdx, prevQIdx] = getPrevIndex(partIndex, questionIndex);
  const [nextPIdx, nextQIdx] = getNextIndex(partIndex, questionIndex);
  const prevQuestionId = questionsId[prevPIdx]?.[prevQIdx];
  const nextQuestionId = questionsId[nextPIdx]?.[nextQIdx];

  const isPaymentRequired = paymentRequired && nextQuestionId === exercise.nextQuestion.question.id;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {status === progressStatus.NEW ? (
          <Intro
            {...intro}
            onSubmit={() => {
              trackEvent('click submit exercise intro');
              sendGTMEvent({
                GA_event_category: 'reflection',
                GA_event_action: 'reflection started',
                GA_event_label: 'reflection started',
                GA_event_detail: 'NA',
                reflection: intro ? intro.title : 'NA'
              });
              createProgress(id).then(({ payload: { data } }) => {
                setProgress(data.progress);
              });
            }}
          />
        ) : null}
        {status !== progressStatus.NEW && displayPartIntro && !displayFinalScreen ? (
          <PartIntro
            parts={parts}
            currentPart={partIndex}
            reviewMode={reviewMode}
            onSubmit={() => setDisplayPartIntro(false)}
          />
        ) : null}
        {status !== progressStatus.NEW && !displayPartIntro && !displayFinalScreen ? (
          <Question
            switchQuestionSpinner={switchQuestionSpinner}
            questionId={question.id}
            prevQuestionId={prevQuestionId}
            part={parts[partIndex]}
            currentPart={partIndex}
            currentQuestion={questionIndex}
            totalQuestions={questionsId[partIndex]?.length}
            question={question.title}
            type={question.type}
            optionalAnswers={question.answers}
            additionalContent={question.content}
            totalAnswers={totalAnswers}
            answered={answered}
            answers={answers}
            paymentRequired={isPaymentRequired}
            isLoading={isLoading}
            onUpdate={onQuestionUpdate}
            onSubmit={onQuestionSubmit}
            onNext={onQuestionNext}
            onBack={onNavBack}
          />
        ) : null}
        {displayFinalScreen ? (
          <FinalScreen
            loadSummaryInfo={loadSummaryInfo}
            summaryInfo={summaryInfo}
            onSubmit={() => {
              sendGTMEvent({
                GA_event_category: 'reflection',
                GA_event_action: 'reflection completed',
                GA_event_label: 'reflection completed',
                GA_event_detail: 'NA',
                reflection: intro ? intro.title : 'NA'
              });
              setOpenExercise(false)
            }}
            onEditAnswer={async (questionId) => {
              let question = questionsCache[questionId];
              if (!question) {
                question = await reviewQuestion(id, questionId).then(({ payload: { data } }) => {
                  return {
                    question: data.question,
                    answers: data.answers,
                    answered: true,
                    totalAnswers: data.answers.length,
                  };
                });
                addQuestionCache({ id: question.question.id, question: question });
              }
              let partIdx = 0,
                questionIdx = 0;
              exercise.questionsId?.forEach((part, pIdx) => {
                part.forEach((qId, qIdx) => {
                  if (qId === questionId) {
                    partIdx = pIdx;
                    questionIdx = qIdx;
                  }
                });
              });
              setReviewMode(true);
              setProgress({ ...progress, part: partIdx, question: questionIdx });
              setNextQuestion(question);
              setDisplayPartIntro(false);
              setDisplayFinalScreen(false);
            }}
          />
        ) : null}
        <div
          className={classes.closeIconDiv}
          onClick={() => {
            trackEvent('click close exercise', { status, part: partIndex + 1, question: questionIndex + 1 });
            if (status === progressStatus.COMPLETED || paymentRequired) {
              setOpenExercise(false);
            } else {
              openExerciseBreakModal(setDialogProps, { onClose: () => setOpenExercise(false) });
            }
          }}
        >
          <CloseIcon className={classes.closeIcon} />
        </div>
      </div>
    </div>
  );
}

function mapState({ exercises }) {
  const { shallPay } = getPaymentStatus();
  return {
    exercise: exercises.current,
    paymentRequired: exercises.current?.paymentRequired && shallPay,
    questionsCache: exercises.questionsCache,
  };
}

export default connect(mapState, {
  setExercise,
  setOpenExercise,
  createProgress,
  submitAnswer,
  reviewQuestion,
  addQuestionCache,
  updateAnswer,
  getSummaryInfo,
  setDialogProps,
  setPaymentPopUpData,
})(Main);
