import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon, Text } from '@7chairs/frontend';
import { useStyles } from './sharedStyles';
import { trackEvent } from '../../../utils/analyticsEvent';
import { navigateToPickAGroupPage } from '../../../utils/pickAGroup';

interface MeetingCardNoCircleProps {
  redirectUrl: string;
}

export const MeetingCardNoCircle = ({ redirectUrl }: MeetingCardNoCircleProps) => {
  // @ts-ignore
  const isPayment: boolean = useSelector((state) => state.account.isPayment);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.titleWrapper}>
        <div className={classes.title}>
          <Text variant={'titleH3'} color={'BLACK_100'}>
            {isPayment ? 'Weekly video meetings' : 'Join weekly group meetings'}
          </Text>
        </div>
        <Icon.Creative.Video size={64} />
      </div>
      <div className={classes.body}>
        <Text variant={'body'} color={'BLACK_80'}>
          {isPayment
            ? 'Find your circle to participate in online weekly video meetings'
            : 'Connect with people who get what you’re going through & find support'}
        </Text>
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          className={`${classes.button} ${classes.buttonFindYourCircle}`}
          iconLeft={Icon.Outline.Lock}
          onClick={() => {
            trackEvent('click join group');
            navigateToPickAGroupPage({ targetUrl: redirectUrl, isPayment });
          }}
          size="big"
          variant="primary"
        >
          {isPayment ? 'Find your circle' : 'Find your group'}
        </Button>
      </div>
    </div>
  );
};
