import React, { useContext, useEffect, useState } from 'react';
import queryString from 'query-string';
import AvatarSexStep from './AvatarSexStep';
import { useHistory } from 'react-router-dom';
import AvatarTabsStep from './AvatarTabsStep';
import { connect } from 'react-redux';
import { updateAccount } from '../../../../store/ducks/account';
import { NavigationContext } from '../../../../components/navigator';
import { storage } from '../../../../utils/localStorage';
import { getPersonalGroups } from '../../../../store/ducks/groups';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '95vh',
    overflow: 'scroll',
  },
  help: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
}));

const AvatarBuilder = ({ updateAccount, myGroups, isFirstLogin, getPersonalGroups }) => {
  const classes = useStyles();
  const [queryParams, setQueryParams] = useState(queryString.parse(location.search));
  const history = useHistory();
  const [isMobileWebview] = useState(window.navigator.userAgent.indexOf('circles-mobile-app') > -1);
  const navCtx = useContext(NavigationContext);
  const [group, setGroup] = useState(undefined);
  const [data, setData] = useState(storage?.avatar || {});
  React.useEffect(() => {
    if (isFirstLogin) {
      storage.avatar = {
        ...data,
        isFirstLogin: true,
      };
      setData({
        ...data,
        isFirstLogin: true,
      });
    }
    if (myGroups.length === 0) {
      getPersonalGroups()
        .then(({ payload: { data } }) => setGroup(data[0]))
        .catch(console.error);
    }
  }, []);

  React.useEffect(() => {
    const groupId = storage.currentGroupInstanceId || myGroups[0]?.groupInstanceId;
    let group = myGroups.find((group) => group.groupInstanceId === groupId);
    if (!group && myGroups.length > 0) {
      group = myGroups[0];
    }
    setGroup(group);
  }, [myGroups]);

  useEffect(() => {
    return history.listen((location) => {
      setQueryParams(queryString.parse(location.search));
    });
  }, [history]);

  const redirect = () => {
    if (!isMobileWebview && storage?.avatar?.isFirstLogin) {
      storage.avatar = null;
      navCtx.pushScreen(`/group/${group?.groupInstanceId}`);
    } else {
      storage.avatar = null;
      navCtx.pushScreen('/profile');
    }
  };

  const nextHandler = (newData) => {
    if (newData) {
      storage.avatar = {
        ...data,
        ...newData,
      };
      setData({
        ...data,
        ...newData,
      });
    }
    history.push({
      search: queryString.stringify({ step: parseInt(queryParams.step || 0) + 1 }),
    });
  };

  const renderStep = (step) => {
    switch (step) {
      case '0':
        return <AvatarSexStep next={nextHandler} redirect={redirect} />;
      case '1':
        return (
          <AvatarTabsStep
            data={data}
            onDone={(data, link) => {
              updateAccount({ picture: link });
              redirect();
            }}
          />
        );
      default:
        return <AvatarSexStep redirect={redirect} next={nextHandler} />;
    }
  };
  return <div className={classes.root}>{renderStep(queryParams.step)}</div>;
};

function mapState({ account, groups, strapi }) {
  return {
    nickname: account.name,
    picture: account.picture,
    isFirstLogin: account.isFirstLogin,
    myGroups: groups.myGroups,
  };
}

export default connect(mapState, { updateAccount, getPersonalGroups })(AvatarBuilder);
