import { AccountRole } from '@7chairs/types';
import { extractAnalyticsCookies } from './analyticsEvent';
import { PAYMENT_PROVIDERS, STRIPE_SUBSCRIPTION_STATUS } from './consts';

const status = (shallPay = true, canSkip = false) => ({ shallPay, canSkip }); // defaults must be the most strict

const getPaymentStatus = () => {
  const { role = AccountRole.Member, isPayment } = extractAnalyticsCookies();

  if (isPayment || role !== AccountRole.Member) {
    return status(false);
  }

  return status();
};

export default getPaymentStatus;

export const isUnpaidUser = ({ provider, subscriptionStatus, unpaidSubscriptionId }) => {
  return (
    provider === PAYMENT_PROVIDERS.STRIPE &&
    subscriptionStatus === STRIPE_SUBSCRIPTION_STATUS.UNPAID &&
    !!unpaidSubscriptionId
  );
};

export const paymentPageUrl = () => {
  return `${PAYMENT_URL}/?backUrl=${window.location.href}`;
};

export const navigateToPaymentPage = () => {
  window.location = paymentPageUrl();
};
