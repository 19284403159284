import React from 'react';
import {Header, Label1} from '../../../components/Text';
import { OLD_COLORS, OLD_SIZES } from '../../../styles/appConsts';
import PhoneInput from '../../../components/form/PhoneInput';
import { CtaButton } from '../../../components/buttons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  header: {
    textAlign: 'center',
    color: OLD_COLORS.BLACK,
    marginBottom: 24
  },
  title: {
    textAlign: 'center',
    color: OLD_COLORS.GRAY_500
  },
  inputLabelWrapper: {
    paddingTop: OLD_SIZES.l,
    paddingLeft: OLD_SIZES.m,
  },
  inputWrapper: {
    paddingTop: OLD_SIZES.s,
  },
  ctaBtn: {
    marginTop: OLD_SIZES.xxl,
    '&:disabled': {
      backgroundColor: OLD_COLORS.BRAND_PURPLE_2,
      color: OLD_COLORS.WHITE,
    },
  },
}));

interface PhoneNumberProps {
  phoneNumber: string;
  isError: boolean;
  handlePhoneNumberChange: (phoneNumber: string) => void;
  handleErrorChange: (isError: boolean) => void;
  onSubmit: (phoneNumber: string) => void;
}

export const PhoneNumber = ({
  phoneNumber,
  isError,
  handlePhoneNumberChange,
  handleErrorChange,
  onSubmit,
}: PhoneNumberProps) => {
  const classes = useStyles();
  return (
    <>
      <div>
        <Header className={classes.header}>
          Login to get our Black Friday offer
        </Header>
        <Label1 className={classes.title}>
          We will send you one time SMS with the code. Carrier rates may apply
        </Label1>
        <div className={classes.inputLabelWrapper}>
          <Label1 color={isError ? OLD_COLORS.RED_500 : OLD_COLORS.GRAY_800}>Phone Number</Label1>
        </div>
        <div className={classes.inputWrapper}>
          <PhoneInput
            borderColor={isError ? OLD_COLORS.RED_500 : OLD_COLORS.GRAY_800}
            onChange={(phoneNumber) => {
              handlePhoneNumberChange(phoneNumber);
              handleErrorChange(false);
            }}
            onSubmit={onSubmit}
          />
        </div>
      </div>
      <CtaButton className={classes.ctaBtn} disabled={!phoneNumber} onClick={onSubmit}>
        Continue
      </CtaButton>
    </>
  );
};
