import React from 'react';
import { Box, Button, ButtonGroup, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { OLD_COLORS } from '../../../../styles/appConsts';
import clsx from 'clsx';

interface StyleProps {
  colorLeft: string;
  colorRight: string;
  itemSelected: 'left' | 'right';
}

const useStyles = makeStyles<Theme, StyleProps>({
  button: {
    borderColor: OLD_COLORS.BLACK,
    padding: '10px 40px',
  },
  buttonLeft: {
    backgroundColor: (props) => (props.itemSelected === 'left' ? props.colorLeft : OLD_COLORS.WHITE),
    borderBottomLeftRadius: '4px',
    borderTopLeftRadius: '4px',
    '&:hover': {
      backgroundColor: (props) => props.colorLeft,
    },
  },
  buttonRight: {
    backgroundColor: (props) => (props.itemSelected === 'right' ? props.colorRight : OLD_COLORS.WHITE),
    borderBottomRightRadius: '4px',
    borderTopRightRadius: '4px',
    '&:hover': {
      backgroundColor: (props) => props.colorRight,
    },
  },
});

interface ButtonGroupOfTwoProps {
  actions: [() => void, () => void];
  colors: [string, string];
  itemSelected: 'left' | 'right';
  texts: [string, string];
}

export const ButtonGroupOfTwo = ({ actions, colors, itemSelected, texts }: ButtonGroupOfTwoProps) => {
  const [actionLeft, actionRight] = actions;
  const [colorLeft, colorRight] = colors;
  const [textLeft, textRight] = texts;

  const styleProps = { colorLeft, colorRight, itemSelected };
  const classes = useStyles(styleProps);

  return (
    <Box>
      <ButtonGroup disableElevation fullWidth>
        <Button
          className={clsx(classes.button, classes.buttonLeft, {
            [classes.backgroundColorLeft]: itemSelected === 'left',
          })}
          onClick={actionLeft}
          variant='outlined'
        >
          {textLeft}
        </Button>
        <Button
          className={clsx(classes.button, classes.buttonRight, {
            [classes.backgroundColorRight]: itemSelected === 'right',
          })}
          onClick={actionRight}
          variant='outlined'
        >
          {textRight}
        </Button>
      </ButtonGroup>
    </Box>
  );
};
