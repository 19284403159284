import React, { useState } from 'react';
import {
  FirstMeetingQuestionnaireSingleQuestionAnswer,
  GroupLeaderInfo,
  ThirdQuestionConfiguration,
} from '../../types';
import { makeStyles } from '@material-ui/core/styles';
import { OLD_COLORS } from '../../../../styles/appConsts';
import { Label1, Label2 } from '../../../../components/Text';
import { Box } from '@material-ui/core';
import { CtaButton } from '../../../../components/buttons';
import { ThirdQuestionPositiveAnswered } from './ThridQuestionPositiveAnswered';
import { ThirdQuestionNegativeAnswered } from './ThridQuestionNegativeAnswered';
import RankStar from '../../../../components/svgImages/common/RankStar';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '50px',
  },
  title: {
    fontSize: '22px',
    fontWeight: 500,
    color: OLD_COLORS.BLACK,
    textAlign: 'center',
  },
  buttons: {
    maxHeight: '55px',
    display: 'flex',
  },
  positiveAnswerButton: {
    width: '150px',
    minWidth: '150px',
    maxWidth: '150px',
    borderRadius: '5px 3px 3px 5px',
    background: OLD_COLORS.VIBRANT_BLUE_2,
    '&:hover': {
      background: OLD_COLORS.VIBRANT_BLUE_2,
    },
  },
  positiveAnswerButtonLabel: {
    color: OLD_COLORS.WHITE,
    textTransform: 'uppercase',
  },
  negativeAnswerButton: {
    width: '150px',
    minWidth: '150px',
    maxWidth: '150px',
    borderRadius: '3px 5px 5px 3px',
    background: OLD_COLORS.GRAY_200,
    '&:hover': {
      background: OLD_COLORS.GRAY_200,
    },
  },
  negativeAnswerButtonLabel: {
    color: OLD_COLORS.VIBRANT_BLUE_2,
    textTransform: 'uppercase',
  },
  testimonial: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    marginTop: '50px',
    maxWidth: '350px',
  },
  testimonialText: {
    fontSize: '14px',
    fontStyle: 'italic',
    textAlign: 'center',
  },
  testimonialStars: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 8,
  },
  testimonialAuthor: {
    fontSize: '12px',
    fontWeight: 300,
    color: OLD_COLORS.GRAY_500,
  },
}));

interface ThirdQuestionProps {
  configuration: ThirdQuestionConfiguration;
  groupLeaderInfo: GroupLeaderInfo;
  addAnswer: (answer: FirstMeetingQuestionnaireSingleQuestionAnswer) => void;
  finishQuestionnaire: (showGroupChatOnWebOrCloseOnMobileWebview: boolean, showThankYou: boolean) => void;
}

export const ThirdQuestion = ({
  configuration: {
    type,
    title,
    subTitle,
    positiveAnswerLabel,
    positiveAnswerValue,
    positiveAnswer,
    negativeAnswerLabel,
    negativeAnswerValue,
    negativeAnswer,
    testimonial,
  },
  groupLeaderInfo,
  addAnswer,
  finishQuestionnaire,
}: ThirdQuestionProps) => {
  const classes = useStyles();

  const [answer, setAnswer] = useState<string | null>(null);

  const handleSetAnswer = (value: string) => {
    addAnswer({ type, value });
    setAnswer(value);
  };

  const starArray = [
    { odd: 1, even: 2 },
    { odd: 3, even: 4 },
    { odd: 5, even: 6 },
    { odd: 7, even: 8 },
    { odd: 9, even: 10 },
  ];

  return (
    <>
      {!answer ? (
        <Box className={classes.wrapper}>
          <Label2 className={classes.title}>{title}</Label2>
          <Label2 className={classes.title}>{subTitle}</Label2>
          <Box className={classes.buttons}>
            <CtaButton className={classes.positiveAnswerButton} onClick={() => handleSetAnswer(positiveAnswerValue)}>
              <Label1 className={classes.positiveAnswerButtonLabel}>{positiveAnswerLabel}</Label1>
            </CtaButton>
            <CtaButton className={classes.negativeAnswerButton} onClick={() => handleSetAnswer(negativeAnswerValue)}>
              <Label1 className={classes.negativeAnswerButtonLabel}>{negativeAnswerLabel}</Label1>
            </CtaButton>
          </Box>
          <Box className={classes.testimonial}>
            <Label2 className={classes.testimonialText}>{testimonial.text}</Label2>
            <div className={classes.testimonialStars}>
              {starArray.map((star, index) => (
                <RankStar
                  key={'RankStar_' + index}
                  style={index < 4 ? { marginRight: 10 } : {}}
                  colorleft={testimonial.rank >= star.odd ? OLD_COLORS.LIGHT_ORANGE : OLD_COLORS.GRAY_400}
                  colorright={testimonial.rank >= star.even ? OLD_COLORS.LIGHT_ORANGE : OLD_COLORS.GRAY_400}
                />
              ))}
            </div>
            <Label2 className={classes.testimonialAuthor}>{testimonial.author}</Label2>
          </Box>
        </Box>
      ) : null}
      {answer === positiveAnswerValue ? (
        <ThirdQuestionPositiveAnswered
          groupLeaderInfo={groupLeaderInfo}
          title={positiveAnswer.title}
          subTitle={positiveAnswer.subTitle}
          buttonLabel={positiveAnswer.buttonLabel}
          finishQuestionnaire={finishQuestionnaire}
        />
      ) : null}
      {answer === negativeAnswerValue ? (
        <ThirdQuestionNegativeAnswered
          groupLeaderInfo={groupLeaderInfo}
          title={negativeAnswer.title}
          subTitle1={negativeAnswer.subTitle1}
          subTitle2={negativeAnswer.subTitle2}
          upperButtonLabel={negativeAnswer.upperButtonLabel}
          lowerButtonLabel={negativeAnswer.lowerButtonLabel}
          finishQuestionnaire={finishQuestionnaire}
        />
      ) : null}
    </>
  );
};
