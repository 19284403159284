import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  spinner: {
    visibility: (props) => props.hidden ? 'hidden' : 'visible',
    width: (props) => props.size,
    textAlign: 'center',
    margin: '0 auto',
    '& > div': {
      width: (props) => `calc(${props.size}/3 - 2px)`,
      height: (props) => `calc(${props.size}/3 - 2px)`,
      backgroundColor: 'grey',
      borderRadius: '100%',
      display: 'inline-block',
      animation: '$sk-bouncedelay 1.4s infinite ease-in-out both',
    }
  },
  bounce1: {
    animationDelay: '-0.32s !important',
  },
  bounce2: {
    animationDelay: '-0.16s !important',
  },
  '@keyframes sk-bouncedelay': {
    '0%, 80%, 100%': {
      transform: 'scale(0)',
    },
    '40%': {
      transform: 'scale(1.0)',
    }
  }
}));

function ThreeDots(props) {
  const cls = useStyles(props);
  return (
    <div className={clsx(cls.spinner, props.className)}>
      <div className={cls.bounce1}></div>
      <div className={cls.bounce2}></div>
      <div></div>
    </div>)
};

ThreeDots.propTypes = {
  size: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
};

export default ThreeDots;
