import { createAction, createApiAction, createReducer } from '../../utils';
import { nextSession as getNext } from '../../utils/sessionTime';

// Types
export const types = {
  GET_GROUP_INFO: 'groupSpace/GET_GROUP_INFO',
  GET_GROUP_INFO_SUCCESS: 'groupSpace/GET_GROUP_INFO_SUCCESS',
  GET_GROUP_INFO_ERROR: 'groupSpace/GET_GROUP_INFO_ERROR',
  GET_SURVEY_DATA: 'groupSpace/GET_SURVEY_DATA',
  GET_SURVEY_DATA_SUCCESS: 'groupSpace/GET_SURVEY_DATA_SUCCESS',
  GET_SURVEY_DATA_ERROR: 'groupSpace/GET_SURVEY_DATA_ERROR',
  POST_SURVEY_DATA: 'groupSpace/POST_SURVEY_DATA',
  POST_SURVEY_DATA_SUCCESS: 'groupSpace/POST_SURVEY_DATA_SUCCESS',
  POST_SURVEY_DATA_ERROR: 'groupSpace/POST_SURVEY_DATA_ERROR',

  POST_COMMITMENT_ANSWER: 'groupSpace/POST_COMMITMENT_ANSWER',
  POST_COMMITMENT_ANSWER_SUCCESS: 'groupSpace/POST_COMMITMENT_ANSWER_SUCCESS',
  POST_COMMITMENT_ANSWER_ERROR: 'groupSpace/POST_COMMITMENT_ANSWER_ERROR',

  // group-leader-info start
  GET_GROUP_LEADER_INFO: 'groupSpace/GET_GROUP_LEADER_INFO',
  GET_GROUP_LEADER_INFO_SUCCESS: 'groupSpace/GET_GROUP_LEADER_INFO_SUCCESS',
  GET_GROUP_LEADER_INFO_ERROR: 'groupSpace/GET_GROUP_LEADER_INFO_ERROR',
  // group-leader-info end

  // first-meeting-questionnaire start
  GET_FIRST_MEETING_QUESTIONNAIRE_CONFIGURATION: 'groupSpace/GET_FIRST_MEETING_QUESTIONNAIRE_CONFIGURATION',
  GET_FIRST_MEETING_QUESTIONNAIRE_CONFIGURATION_SUCCESS:
    'groupSpace/GET_FIRST_MEETING_QUESTIONNAIRE_CONFIGURATION_SUCCESS',
  GET_FIRST_MEETING_QUESTIONNAIRE_CONFIGURATION_ERROR: 'groupSpace/GET_FIRST_MEETING_QUESTIONNAIRE_CONFIGURATION_ERROR',

  POST_FIRST_MEETING_QUESTIONNAIRE_ANSWERS: 'groupSpace/POST_FIRST_MEETING_QUESTIONNAIRE_ANSWERS',
  POST_FIRST_MEETING_QUESTIONNAIRE_ANSWERS_SUCCESS: 'groupSpace/POST_FIRST_MEETING_QUESTIONNAIRE_ANSWERS_SUCCESS',
  POST_FIRST_MEETING_QUESTIONNAIRE_ANSWERS_ERROR: 'groupSpace/POST_FIRST_MEETING_QUESTIONNAIRE_ANSWERS_ERROR',
  // first-meeting-questionnaire end

  GET_MEMBERS_INTRO: 'groupSpace/GET_MEMBERS_INTRO',
  GET_MEMBERS_INTRO_SUCCESS: 'groupSpace/GET_MEMBERS_INTRO_SUCCESS',
  GET_MEMBERS_INTRO_ERROR: 'groupSpace/GET_MEMBERS_INTRO_ERROR',
  SET_MEMBER_INTRO: 'groupSpace/SET_MEMBER_INTRO',
  SET_MEMBER_INTRO_SUCCESS: 'groupSpace/SET_MEMBER_INTRO_SUCCESS',
  SET_MEMBER_INTRO_ERROR: 'groupSpace/SET_MEMBER_INTRO_ERROR',
  UPDATE_NEXT_SESSION_TIME: 'groupSpace/UPDATE_NEXT_SESSION_TIME',
  RESET_GROUP_SPACE: 'groupSpace/RESET_GROUP_SPACE',
};

// Actions
export function getPersonalGroupGeneralInfo(id, limit) {
  return createApiAction(
    [types.GET_GROUP_INFO, types.GET_GROUP_INFO_SUCCESS, types.GET_GROUP_INFO_ERROR],
    `/groupspace/v2/${id}?limit=${limit}`,
    'get',
    undefined
  );
}

export function getSurveyData({ groupId }) {
  return createApiAction(
    [types.GET_SURVEY_DATA, types.GET_SURVEY_DATA_SUCCESS, types.GET_SURVEY_DATA_ERROR],
    `/groupspace/${groupId}/group-belonging-survey`,
    'get',
    undefined
  );
}

export function postSurveyData({ groupId, pieceId, data }) {
  return createApiAction(
    [types.POST_SURVEY_DATA, types.POST_SURVEY_DATA_SUCCESS, types.POST_SURVEY_DATA_ERROR],
    `/groupspace/${groupId}/group-belonging-survey/${pieceId}`,
    'post',
    data
  );
}

export function postCommitmentAnswer({ groupId, answer }) {
  return createApiAction(
    [types.POST_COMMITMENT_ANSWER, types.POST_COMMITMENT_ANSWER_SUCCESS, types.POST_COMMITMENT_ANSWER_ERROR],
    `/groupspace/${groupId}/commitment-answer`,
    'post',
    { answer }
  );
}

export function getFirstMeetingQuestionnaireConfiguration(groupId) {
  return createApiAction(
    [
      types.GET_FIRST_MEETING_QUESTIONNAIRE_CONFIGURATION,
      types.GET_FIRST_MEETING_QUESTIONNAIRE_CONFIGURATION_SUCCESS,
      types.GET_FIRST_MEETING_QUESTIONNAIRE_CONFIGURATION_ERROR,
    ],
    `groupspace/${groupId}/first-meeting-questionnaire`,
    'get',
    undefined
  );
}

export function postFirstMeetingQuestionnaireAnswers(groupId, pieceId, data) {
  return createApiAction(
    [
      types.POST_FIRST_MEETING_QUESTIONNAIRE_ANSWERS,
      types.POST_FIRST_MEETING_QUESTIONNAIRE_ANSWERS_SUCCESS,
      types.POST_FIRST_MEETING_QUESTIONNAIRE_ANSWERS_ERROR,
    ],
    `/groupspace/${groupId}/first-meeting-questionnaire/${pieceId}`,
    'post',
    data
  );
}

export function getGroupLeaderInfo(groupId) {
  return createApiAction(
    [types.GET_GROUP_LEADER_INFO, types.GET_GROUP_LEADER_INFO_SUCCESS, types.GET_GROUP_LEADER_INFO_ERROR],
    `groupspace/${groupId}/leader`,
    'get',
    undefined
  );
}

export function setGroupMemberIntro(id, data) {
  return createApiAction(
    [types.SET_MEMBER_INTRO, types.SET_MEMBER_INTRO_SUCCESS, types.SET_MEMBER_INTRO_ERROR],
    `/groupspace/${id}/intro`,
    'post',
    data
  );
}

export const updateNextSessionTime = () => createAction(types.UPDATE_NEXT_SESSION_TIME);
export const resetGroupSpace = () => createAction(types.RESET_GROUP_SPACE);

// Reducer
const initialState = {
  groupInfo: {},
  nextSession: null,
};

const handlers = {
  [types.RESET_GROUP_SPACE]: () => ({
    ...initialState,
  }),
  [types.GET_GROUP_INFO_SUCCESS]: (state, { data }) => ({
    ...state,
    groupInfo: data,
    nextSession: data.schedule ? getNext(data.schedule, data.startFrom)[0] : null,
  }),
  [types.GET_MEMBERS_INTRO_SUCCESS]: (state, { data }) => ({
    ...state,
    membersIntro: data,
  }),
  [types.SET_MEMBER_INTRO_SUCCESS]: (state, { data }) => {
    const members = state.groupInfo.members.map((m) => {
      if (m.id === data.id) {
        return {
          ...m,
          intro: data.intro,
        };
      }
      return m;
    });
    return {
      ...state,
      groupInfo: {
        ...state.groupInfo,
        members,
      },
    };
  },
  [types.UPDATE_NEXT_SESSION_TIME]: (state) => ({
    ...state,
    nextSession: state.groupInfo.schedule ? getNext(state.groupInfo.schedule, state.groupInfo.startFrom)[0] : null,
  }),
};

export default createReducer(initialState, handlers);
