import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Switch } from '@material-ui/core';
import { trackEvent } from '../../utils/analyticsEvent';
import { Label1, SubHeader } from '../../components/Text';
import { OLD_COLORS, OLD_SIZES } from '../../styles/appConsts';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '60%',
    maxWidth: 732,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  titleGrid: {
    padding: `${OLD_SIZES.l}px ${OLD_SIZES.xl}px`,
    borderBottom: `1px solid ${OLD_COLORS.GRAY_300}`,
  },
  itemGrid: {
    padding: OLD_SIZES.m,
    borderBottom: `1px solid ${OLD_COLORS.GRAY_300}`,
  },
}));

function NotificationsSettings({ subscriptionGroups, additionalSubscriptions, getSettings, updateSettings }) {
  const classes = useStyles();
  const [submitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    getSettings();
  }, []);

  return (
    <Grid className={classes.root} container direction='column' alignItems='center'>
      <Grid item className={classes.titleGrid}>
        <SubHeader>Here you can set up and manage message notifications for the Circles platform</SubHeader>
      </Grid>
      {subscriptionGroups.map((subscription, index) => {
        const { id, name, active } = subscription;
        return (
          <Grid
            item
            container
            key={index}
            className={classes.itemGrid}
            justifyContent='space-between'
            alignItems='center'
          >
            <Label1 color={OLD_COLORS.GRAY_800}>{name}</Label1>
            <Switch
              checked={active}
              color='primary'
              onChange={() => {
                if (!submitting) {
                  setSubmitting(true);
                  const status = active ? 'inactive' : 'active';
                  trackEvent(`click ${active ? 'disable' : 'enable'} subscription`, { name });
                  updateSettings('groups', id, status)
                    .then(() => setSubmitting(false))
                    .catch(() => setSubmitting(false));
                }
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default NotificationsSettings;
