import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccountsTag } from '@7chairs/types';
import { Tag, Text, Button, getColor, IconButton, Icon } from '@7chairs/frontend';

import { AccountStore, getAccountProfile, updateAccountProfile } from '../../../../store/ducks/account';
import { getAccountsTags, ProfileStore } from '../../../../store/ducks/profile';

import { trackEvent } from '../../../../utils/analyticsEvent';
import { toggleItemInArray } from '../../../../utils/array';

import { NavigationContext } from '../../../../components/navigator';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '231px',
  },
  optionsContainer: {
    marginBottom: '85px',
    maxWidth: '550px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '342px',
    },
  },
  saveContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      backgroundColor: getColor('WHITE_100'),
      bottom: 0,
      boxShadow: `0px 3px 8px ${getColor('BLACK_100')}4D`,
      flexDirection: 'row',
      height: '80px',
      justifyContent: 'space-between',
      padding: '12px 16px',
      position: 'absolute',
      width: '100%',
    },
  },
  textDescription: {
    marginBottom: '40px',
    textAlign: 'center',
    width: '420px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'start',
      width: '327px',
      marginBottom: '28px',
    },
  },
  textSelected: {
    marginBottom: '18px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
      marginLeft: '8px',
    },
  },
  textTitle: {
    marginBottom: '8px',
    textAlign: 'center',
    width: '420px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'start',
      width: '327px',
    },
  },
  wrapper: {
    alignItems: 'center',
    backgroundColor: getColor('YELLOW_BG'),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'scroll',
    paddingTop: '78px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '30px',
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    columnGap: 10,
    rowGap: 12,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'start',
    },
  },
}));

const ICON_BUTTON_SIZE = 48;

export enum TagOptionsMode {
  DescriptionTags = 'description-tags',
  Passions = 'passions',
}

const TEXTS_PRESETS = {
  [TagOptionsMode.DescriptionTags]: {
    title: 'Which of these best describes you?',
    description: 'Select up to 5',
  },
  [TagOptionsMode.Passions]: {
    title: 'What are your passions?',
    description: 'Select up to 5 topics that matter a whole lot to you.',
  },
};

export const ProfileTagOptions = () => {
  const classes = useStyles();
  const navCtx = useContext(NavigationContext);
  const dispatch = useDispatch();

  const isMobileWebview = useMemo(() => window.navigator.userAgent.indexOf('circles-mobile-app') > -1, []);

  // TODO: enrich DefaultRootState with account: AccountStore and groups
  // @ts-ignore
  const account: AccountStore = useSelector((state) => state.account);
  // @ts-ignore
  const profileStore: ProfileStore = useSelector((state) => state.profile);

  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get('mode') || TagOptionsMode.Passions;

  const [passions, setPassions] = useState<AccountsTag[]>(profileStore.passions);
  const [descriptionTags, setDescriptionTags] = useState<AccountsTag[]>(profileStore.descriptions);
  const [activePassions, setActivePassions] = useState<string[]>([]);
  const [activeDescriptionTags, setActiveDescriptionTags] = useState<string[]>([]);

  const onSave = () => {
    trackEvent(`Click save ${mode === TagOptionsMode.Passions ? 'passions' : 'My description'}`);
    dispatch(
      updateAccountProfile(account.id, {
        descriptionTags: activeDescriptionTags,
        passions: activePassions,
      })
    ).then(() => {
      // @ts-ignore
      if (isMobileWebview && window.ReactNativeWebView) {
        // @ts-ignore
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            action: 'updateMemberProfile',
            update: { descriptionTags: activeDescriptionTags, passions: activePassions },
          })
        );
      }
      navCtx.popScreen();
    });
  };

  const toggleActiveTags = (mode, option: string) => {
    const activeTagLength = mode === TagOptionsMode.Passions ? activePassions.length : activeDescriptionTags.length;
    if (activeTagLength < 5) {
      mode === TagOptionsMode.Passions
        ? setActivePassions((prevState) => toggleItemInArray(option, prevState))
        : setActiveDescriptionTags((prevState) => toggleItemInArray(option, prevState));
    }
  };

  useEffect(() => {
    dispatch(getAccountProfile(account.id)).then((response) => {
      const responsePayloadData = response.payload.data;
      setActiveDescriptionTags(responsePayloadData.descriptionTags || []);
      setActivePassions(responsePayloadData.passions || []);
    });
    dispatch(getAccountsTags()).then((response) => {
      const responsePayloadData = response.payload.data;
      setPassions(responsePayloadData.filter((obj) => obj.type === 'passion'));
      setDescriptionTags(responsePayloadData.filter((obj) => obj.type === 'description'));
    });
  }, []);

  return (
    <Box className={classes.wrapper}>
      <Text variant='titleH3' className={classes.textTitle}>
        {TEXTS_PRESETS[mode].title}
      </Text>
      <Text variant='body' className={classes.textDescription}>
        {TEXTS_PRESETS[mode].description}
      </Text>
      <Box className={classes.optionsContainer}>
        {mode === TagOptionsMode.Passions ? (
          <Box className={classes.container}>
            {passions.map((option) => {
              return (
                <Tag
                  key={option.key}
                  label={option.label}
                  isSelected={activePassions.includes(option.key)}
                  onClick={() => toggleActiveTags(mode, option.key)}
                  value={option.key}
                  variant='dark'
                />
              );
            })}
          </Box>
        ) : (
          <Box className={classes.container}>
            {descriptionTags.map((option) => {
              return (
                <Tag
                  label={option.label}
                  isSelected={activeDescriptionTags.includes(option.key)}
                  onClick={() => toggleActiveTags(mode, option.key)}
                  value={option.key}
                  variant='dark'
                />
              );
            })}
          </Box>
        )}
      </Box>
      <Box className={classes.saveContainer}>
        <Text variant='small' className={classes.textSelected}>
          {mode === TagOptionsMode.Passions ? activePassions.length : activeDescriptionTags.length}/5 Selected
        </Text>
        {!isMobileWebview ? (
          <Button variant='primary' size='big' className={classes.button} onClick={onSave}>
            Next
          </Button>
        ) : (
          <IconButton
            icon={<Icon.Outline.ArrowLeft />}
            onClick={onSave}
            stylesProps={{
              backgroundColor: 'PURPLE_100',
              color: 'WHITE_100',
              size: ICON_BUTTON_SIZE,
              withShadow: false,
            }}
          />
        )}
      </Box>
    </Box>
  );
};
