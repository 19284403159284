import React from 'react';

const brandIcons = {
  visa: 'visa.svg',
  mastercard: 'mastercard.svg',
  amex: 'american-express.svg',
  discover: 'discover.svg',
  diners: 'diners.svg',
  jcb: 'jcb.svg',
  unionpay: 'unionpay.svg',
  unknown: 'credit-card-gray.svg',
};

export default ({ brand }) => <img src={`${ASSETS_BASE_DIR}/creditCards/${brandIcons[brand] || brandIcons.unknown}`} />;
