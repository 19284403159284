import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  extractFromUserCredential,
  firebaseSignOut,
  getFirebaseAuth,
  THIRD_PARTY_AUTH_PROVIDERS,
} from '../../../utils/firebase';
import { signInWithPopup } from 'firebase/auth';
import { sendGTMEvent, trackEvent } from '../../../utils/analyticsEvent';
import { Paragraph } from '../../../components/Text';
import { OLD_SIZES } from '../../../styles/appConsts';
import { useDispatch } from 'react-redux';
import { loginWithFirebase } from '../../../store/ducks/auth';
import { AuthProvider } from '@7chairs/types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 540,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: OLD_SIZES.xl,
    paddingRight: OLD_SIZES.xl,
    paddingBottom: OLD_SIZES.xl,
  },
  items: {
    marginTop: OLD_SIZES.l,
    display: 'flex',
    justifyContent: 'center',
  },
  item: {
    width: 96,
    [theme.breakpoints.down('sm')]: {
      width: 64,
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export const ThirdPartyAuth = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loginByProvider = (provider: AuthProvider) =>
    signInWithPopup(getFirebaseAuth(), THIRD_PARTY_AUTH_PROVIDERS[provider]()).then(async (result) => {
      const { idToken, email } = await extractFromUserCredential(result);
      dispatch(
        loginWithFirebase(idToken, {
          provider,
          email,
        })
      )
        .then(() => {
          trackEvent('login with provider success', { provider });
          sendGTMEvent({
            GA_event_category: 'login',
            GA_event_action: 'login success',
            GA_event_label: provider,
            GA_event_detail: 'NA',
          });
        })
        .catch(() => {
          firebaseSignOut();
          trackEvent('login with provider fail', { provider });
        });
    });

  // const facebookSignIn = () => {
  //   const provider = AuthProvider.Facebook;
  //   trackEvent('click login with provider', { provider });
  //   loginByProvider(provider).catch(() => {
  //     trackEvent('click cancel login with provider', { provider });
  //   });
  // };

  const googleSignIn = () => {
    const provider = AuthProvider.Google;
    trackEvent('click login with provider', { provider });
    loginByProvider(provider).catch(() => {
      trackEvent('click cancel login with provider', { provider });
    });
  };

  const yahooSignIn = () => {
    const provider = AuthProvider.Yahoo;
    trackEvent('click login with provider', { provider });
    loginByProvider(provider).catch(() => {
      trackEvent('click cancel login with provider', { provider });
    });
  };

  return (
    <div className={classes.root}>
      <Paragraph align="center">Or sign in with</Paragraph>
      <div className={classes.items}>
        {/* <div className={classes.item} onClick={facebookSignIn}>
          <img src={`${ASSETS_BASE_DIR}/facebook.svg`} alt={'facebook logo'} />
        </div> */}
        <div className={classes.item} onClick={googleSignIn}>
          <img src={`${ASSETS_BASE_DIR}/google.svg`} alt={'google logo'} />
        </div>
        <div className={classes.item} onClick={yahooSignIn}>
          <img src={`${ASSETS_BASE_DIR}/yahoo.svg`} alt={'yahoo logo'} />
        </div>
      </div>
    </div>
  );
};
