import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MAX_MEMBERS_TO_FETCH_WITH_GROUP_INFO_DATA } from '../../screens/groupSpace/GroupSpaceContainer';
import { setInAppLinkData } from '../../store/ducks/root';
import { getPersonalGroupGeneralInfo } from '../../store/ducks/groupSpace';
import { MemberProfilePopup } from '../groupSpace/members/MemberProfilePopup';
import { GroupSpaceMember } from '../../screens/groupSpace/GroupSpace';

export interface InAppLink {
  type: string;
  memberAccountId: string;
  text: string;
}

export const InAppLinkHandler = () => {
  const dispatch = useDispatch();

  // @ts-ignore
  const accountId: string = useSelector((state) => state.account.id);
  // @ts-ignore
  const groupInstanceId: string | null = useSelector((state) => state.groupSpace.groupInfo.id);
  // @ts-ignore
  const inAppLinkData: InAppLink | null = useSelector((state) => state.root.inAppLinkData);
  // @ts-ignore
  const groupMembers: GroupSpaceMember[] | null = useSelector((state) => state.groupSpace.groupInfo.members);

  useEffect(() => {
    if (inAppLinkData && !groupMembers?.some(({ id }) => id === inAppLinkData.memberAccountId)) {
      dispatch(getPersonalGroupGeneralInfo(groupInstanceId, MAX_MEMBERS_TO_FETCH_WITH_GROUP_INFO_DATA));
    }
  }, [inAppLinkData, groupMembers]);

  const member: GroupSpaceMember | null = useMemo(() => {
    if (inAppLinkData && groupMembers?.length) {
      return groupMembers.find(({ id }) => id === inAppLinkData.memberAccountId) ?? null;
    }
    return null;
  }, [inAppLinkData, groupMembers]);

  if (member && inAppLinkData.type === 'openMemberProfile') {
    return (
      <MemberProfilePopup
        isOpen={true}
        member={member}
        isUser={member.id === accountId}
        onClose={() => dispatch(setInAppLinkData(null))}
      />
    );
  }

  return null;
};
