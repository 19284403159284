import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { MailOutlineOutlined } from '@material-ui/icons';
import { OLD_COLORS, OLD_SIZES } from '../styles/appConsts';
import { Label1 } from './Text';

const useStyles = makeStyles(() => ({
  appBar: {
    backgroundColor: OLD_COLORS.WHITE,
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.1)', // to have same box shadow as new "white" header
    position: 'relative',
  },
  toolbar: {
    textAlign: 'left',
  },
  sideElement: {
    width: 104,
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  centerElement: {
    display: 'flex',
    width: '100%',
  },
  newMessageWrapper: {
    position: 'absolute',
    bottom: -32,
    left: 0,
    right: 0,
    height: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  newMessageDiv: {
    backgroundColor: OLD_COLORS.GREEN_500,
    display: 'flex',
    alignItems: 'center',
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
    padding: `${OLD_SIZES.s}px ${OLD_SIZES.m}px`,
  },
  newMessageIcon: {
    color: OLD_COLORS.WHITE,
    marginRight: OLD_SIZES.xs,
  },
}));

function Header({ center, right = [], left = null, displayNewMessageAlert }) {
  const classes = useStyles();

  const headerRef = useRef();

  return (
    <AppBar ref={headerRef} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.sideElement}>{left}</div>
        <div className={classes.centerElement}>{center}</div>
        <div className={clsx(classes.sideElement, classes.right)}>
          {right.map((node, idx) => {
            const style = idx === 0 ? {} : { marginLeft: OLD_SIZES.s };
            return (
              <div key={`header-right-${idx}`} style={style}>
                {node}
              </div>
            );
          })}
        </div>
        {displayNewMessageAlert && (
          <div className={classes.newMessageWrapper}>
            <div className={classes.newMessageDiv}>
              <MailOutlineOutlined className={classes.newMessageIcon} />
              <Label1 color={OLD_COLORS.WHITE}>You’ve got a new message!</Label1>
            </div>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  center: PropTypes.node,
  left: PropTypes.node,
  right: PropTypes.node,
};

export default Header;
