import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Label1 } from '../../Text';
import { OLD_SIZES, OLD_COLORS } from '../../../styles/appConsts';

const styles = () => ({
  container: {
    marginTop: OLD_SIZES.m,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  additionalContentText: {
    fontSize: 16,
    fontWeight: '500',
    color: OLD_COLORS.BLACK,
  },
  answersContainer: {
    marginTop: OLD_SIZES.m,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'wrap',
  },
  answerDiv: {
    borderRadius: 4,
    marginRight: OLD_SIZES.s,
    marginBottom: OLD_SIZES.s,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  answerContent: {
    padding: `${OLD_SIZES.xs}px ${OLD_SIZES.s}px`,
  },
  selectedAnswer: {
    borderRadius: 2,
  },
  unselectedAnswer: {
    borderRadius: 4,
    opacity: 0.5,
  },
  answerText: {
    fontSize: 14,
    fontWeight: '500',
    textAlign: 'center',
    color: OLD_COLORS.WHITE,
  },
});

export default withStyles(styles)((props) => {
  const { classes, selectedAnswers, optionalAnswers, additionalContent, color, onChange } = props;

  const answers = selectedAnswers || [];

  return (
    <div className={classes.container}>
      {additionalContent?.subtitle && (
        <Label1 className={classes.additionalContentText}>{additionalContent.subtitle}</Label1>
      )}
      {additionalContent?.description && (
        <Label1 className={classes.additionalContentText}>{additionalContent.description}</Label1>
      )}
      <div className={classes.answersContainer}>
        {optionalAnswers.map((ans, idx) => {
          const selected = answers.includes(ans.value);
          return (
            <div
              key={`${ans.value}-${idx}`}
              className={classes.answerDiv}
              style={{ border: selected ? `3px solid ${color}80` : '3px solid transparent' }}
              onClick={() => {
                if (selected) {
                  onChange(answers.filter((val) => val !== ans.value));
                } else {
                  onChange([...answers, ans.value]);
                }
              }}
            >
              <div
                className={clsx(classes.answerContent, selected ? classes.selectedAnswer : classes.unselectedAnswer)}
                style={{ backgroundColor: color }}
              >
                <Label1 className={classes.answerText}>{ans.label}</Label1>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});
