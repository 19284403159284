import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TopBar from '../../components/TopBarContainer';
import {
  getFirstMeetingQuestionnaireConfiguration,
  getGroupLeaderInfo,
  postFirstMeetingQuestionnaireAnswers,
} from '../../store/ducks/groupSpace';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import {
  FirstMeetingQuestionnaireAnswers,
  FirstMeetingQuestionnaireConfiguration,
  FirstMeetingQuestionnaireSingleQuestionAnswer,
  GroupLeaderInfo,
} from './types';
import { FirstQuestion } from './components/firstQuestion/FirstQuestion';
import { SecondQuestion } from './components/secondQuestion/SecondQuestion';
import { ThirdQuestion } from './components/thirdQuestion/ThirdQuestion';
import { ThankYou } from './components/thankYou/ThankYou';
import { NavigationContext } from '../../components/navigator';
import { trackEvent } from '../../utils/analyticsEvent';
import { Footer } from './components/footer/Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '70px',
      alignItems: 'flex-start',
      height: 'calc(100% - 70px)',
      width: '80%',
    },
  },
}));

export const FirstMeetingQuestionnaire = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const navCtx = useContext(NavigationContext);

  const { groupId } = useParams();

  // @ts-ignore
  const accountId = useSelector((state) => state.account.id);

  const [pieceId, setPieceId] = useState<string | null>(null);

  const [thankYouVisible, setThankYouVisible] = useState(false);

  const [firstMeetingQuestionnaireConfiguration, setFirstMeetingQuestionnaireConfiguration] =
    useState<FirstMeetingQuestionnaireConfiguration | null>(null);

  const [groupLeaderInfo, setGroupLeaderInfo] = useState<GroupLeaderInfo | null>(null);

  const [currentQuestionNumber, setCurrentQuestionNumber] = useState<number>(1);

  const [answers, setAnswers] = useState<FirstMeetingQuestionnaireAnswers>([]);

  const isMobileWebview = useMemo(
    () => window.navigator.userAgent.indexOf('circles-mobile-app') > -1,
    [window.navigator.userAgent]
  );

  useEffect(() => {
    trackEvent('view first meeting questionnaire', { accountId, groupId });
  }, []);

  useEffect(() => {
    dispatch(getFirstMeetingQuestionnaireConfiguration(groupId)).then(
      ({
        payload: {
          data: { id, body },
        },
      }) => {
        setPieceId(id);
        setFirstMeetingQuestionnaireConfiguration(body);
      }
    );
  }, [dispatch, groupId]);

  useEffect(() => {
    dispatch(getGroupLeaderInfo(groupId)).then(({ payload: { data } }) => {
      setGroupLeaderInfo(data);
    });
  }, [dispatch, groupId]);

  const addAnswer = (answer: FirstMeetingQuestionnaireSingleQuestionAnswer) => {
    const { type, value } = answer;
    trackEvent('click first meeting questionnaire add answer', {
      accountId,
      groupId,
      type,
      value,
    });
    setAnswers([...answers, answer]);
  };

  const moveToNextQuestion = () => setCurrentQuestionNumber(currentQuestionNumber + 1);

  const navigateToGroupChatOnWebOrCloseOnMobileWebview = () => {
    if (isMobileWebview) {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'close' }));
    } else {
      navCtx.replaceScreen(`/chat/${groupId}`);
    }
  };

  const finishQuestionnaire = (showGroupChatOnWebOrCloseOnMobileWebview: boolean, showThankYou: boolean) => {
    if (showGroupChatOnWebOrCloseOnMobileWebview && showThankYou) {
      throw new Error('You can either show group chat or show thank you screen, not both!');
    }
    dispatch(
      postFirstMeetingQuestionnaireAnswers(groupId, pieceId, {
        groupLeaderId: groupLeaderInfo.id,
        answers,
      })
    ).then(() => {
      trackEvent('click first meeting questionnaire submit', { accountId, groupId });
    });
    if (showGroupChatOnWebOrCloseOnMobileWebview) {
      navigateToGroupChatOnWebOrCloseOnMobileWebview();
    }
    if (showThankYou) {
      setThankYouVisible(true);
    }
  };

  return (
    <div className={classes.root}>
      <TopBar isMobileWebview={isMobileWebview} title={firstMeetingQuestionnaireConfiguration?.title ?? ''} />
      {firstMeetingQuestionnaireConfiguration && groupLeaderInfo ? (
        <Box className={classes.content}>
          {thankYouVisible ? (
            <ThankYou
              groupLeaderPicture={groupLeaderInfo.picture}
              title={firstMeetingQuestionnaireConfiguration.thankYou.title}
              buttonLabel={firstMeetingQuestionnaireConfiguration.thankYou.buttonLabel}
              navigateToGroupChatOnWebOrCloseOnMobileWebview={navigateToGroupChatOnWebOrCloseOnMobileWebview}
            />
          ) : (
            <>
              {currentQuestionNumber === 1 ? (
                <FirstQuestion
                  configuration={firstMeetingQuestionnaireConfiguration.questions[0]}
                  addAnswer={addAnswer}
                  moveToNextQuestion={moveToNextQuestion}
                />
              ) : null}
              {currentQuestionNumber === 2 ? (
                <SecondQuestion
                  configuration={firstMeetingQuestionnaireConfiguration.questions[1]}
                  addAnswer={addAnswer}
                  moveToNextQuestion={moveToNextQuestion}
                />
              ) : null}
              {currentQuestionNumber === 3 ? (
                <ThirdQuestion
                  configuration={firstMeetingQuestionnaireConfiguration.questions[2]}
                  groupLeaderInfo={groupLeaderInfo}
                  addAnswer={addAnswer}
                  finishQuestionnaire={finishQuestionnaire}
                />
              ) : null}
            </>
          )}
        </Box>
      ) : null}
      <Footer imageVisible={thankYouVisible} />
    </div>
  );
};
