import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getColor } from '@7chairs/frontend';
import { getPersonalGroups } from '../../store/ducks/groups';
import { getPersonalGroupGeneralInfo } from '../../store/ducks/groupSpace';
import { getPickAGroupUrl } from '../../store/ducks/account';
import { RootState } from '../../store/ducks/index';
import { groupEvent } from '../../utils/analyticsEvent';
import { storage } from '../../utils/localStorage';
import { NavigationContext } from '../../components/navigator';
import { Header } from '../../components/designSystem';
import { GroupSpace } from './GroupSpace';
import { Tabs } from './Tabs';
import { StreamChat } from '../../components/streamChat/StreamChat';

const TABS_HEIGHT = 88;

const useStyles = makeStyles((theme) => ({
  root: {
    height: `calc(100vh - ${TABS_HEIGHT}px)`,
    overflow: 'auto',
  },
  streamChatWrapper: {
    height: 'calc(100% - 176px)', // 176px is a height of a header
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100% - 212px)', // 212px is a height of a header
    },
  },
  tabs: {
    backgroundColor: getColor('WHITE_100'),
    borderRadius: '5px',
    bottom: 0,
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    height: `${TABS_HEIGHT}px`,
    justifyContent: 'center',
    left: 0,
    padding: '14px 0px',
    position: 'absolute',
    width: '100%',
  },
}));

export const MAX_MEMBERS_TO_FETCH_WITH_GROUP_INFO_DATA = 12;
const DEFAULT_MGS_HEADER_TITLE = 'Welcome to circles community';

const getGroupInstanceIdFromParams = (groupInstanceIdFromParams: string) => {
  return groupInstanceIdFromParams !== 'undefined' ? groupInstanceIdFromParams : '';
};

export enum GroupSpaceTab {
  GroupSpace = 'groupSpace',
  Conversations = 'conversations',
}

export const updateStorageAndReplaceScreen = (navCtx: any, storageValue: string | null, replaceScreenValue: string) => {
  storage.currentGroupInstanceId = storageValue;
  navCtx.replaceScreen(`/group/${replaceScreenValue}`);
};

interface GroupSpaceContainerProps {
  tab: GroupSpaceTab;
}

export const GroupSpaceContainer = ({ tab }: GroupSpaceContainerProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navCtx = useContext(NavigationContext);

  const { groupInstanceId: groupInstanceIdFromParams } = useParams();

  const groupTitle = useSelector((state: RootState) => state.groupSpace.groupInfo.title);
  const userPicture = useSelector((state: RootState) => state.account.picture);
  const userGroups = useSelector((state: RootState) => state.groups.myGroups);
  const pickAGroupUrl = useSelector((state: RootState) => state.account.pickAGroupUrl);

  const [groupInstanceId, setGroupInstanceId] = useState<string>(
    getGroupInstanceIdFromParams(groupInstanceIdFromParams)
  );

  const [isGroupInstanceIdValidated, setGroupInstanceIdValidated] = useState(false);

  useEffect(() => {
    dispatch(getPersonalGroups()).then(({ payload: { data: groups } }) => {
      if (groups.length === 0) {
        updateStorageAndReplaceScreen(navCtx, null, 'undefined');
        setGroupInstanceIdValidated(true);
        return;
      }

      if (!groupInstanceId) {
        // groupInstanceId not specified, so we attempt to load default one
        let currentGroupInstanceId = storage.currentGroupInstanceId || groups[0].groupInstanceId;
        const groupExists = groups.some((group) => group.groupInstanceId === currentGroupInstanceId);
        if (!groupExists) {
          currentGroupInstanceId = groups[0].groupInstanceId;
        }
        updateStorageAndReplaceScreen(navCtx, currentGroupInstanceId, currentGroupInstanceId);
        setGroupInstanceIdValidated(true);
        return;
      }

      // check if that member is still assigned to the group with given id
      const groupExists = groups.some((group) => group.groupInstanceId === groupInstanceId);
      if (!groupExists) {
        updateStorageAndReplaceScreen(navCtx, groups[0].groupInstanceId, groups[0].groupInstanceId);
      }
      setGroupInstanceIdValidated(true);
    });
  }, []);

  useEffect(() => {
    setGroupInstanceId(getGroupInstanceIdFromParams(groupInstanceIdFromParams));
  }, [groupInstanceIdFromParams]);

  useEffect(() => {
    if (groupInstanceId && isGroupInstanceIdValidated) {
      groupEvent(groupInstanceId);
      dispatch(getPersonalGroupGeneralInfo(groupInstanceId, MAX_MEMBERS_TO_FETCH_WITH_GROUP_INFO_DATA));
    }
    if (!groupInstanceId && !pickAGroupUrl) {
      dispatch(getPickAGroupUrl());
    }
  }, [groupInstanceId, isGroupInstanceIdValidated]);

  return (
    <div className={classes.root}>
      <Header.MGS
        text={groupTitle || DEFAULT_MGS_HEADER_TITLE}
        userData={{ groups: userGroups, picture: userPicture }}
      />
      {isGroupInstanceIdValidated ? (
        <>
          <>
            {tab === GroupSpaceTab.GroupSpace ? <GroupSpace groupInstanceId={groupInstanceId} /> : null}
            {tab === GroupSpaceTab.Conversations ? (
              <div className={classes.streamChatWrapper}>
                <StreamChat groupId={groupInstanceId} />
              </div>
            ) : null}
          </>
          <div className={classes.tabs}>
            <Tabs activeTab={tab} groupInstanceId={groupInstanceId} />
          </div>
        </>
      ) : null}
    </div>
  );
};
