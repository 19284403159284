import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { OLD_SIZES } from '../../../../styles/appConsts';
import { CtaButton } from '../../../buttons';

const styles = () => ({
  ctaButton: {
    marginTop: OLD_SIZES.s,
    padding: OLD_SIZES.m,
  },
});

export default withStyles(styles)((props) => {
  const { classes, content, onClick, style = {} } = props;
  return (
    <CtaButton disableElevation className={classes.ctaButton} style={style} onClick={onClick}>
      {content}
    </CtaButton>
  );
});
