import React, { useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { getColor, Button, Text } from '@7chairs/frontend';

import { trackEvent } from '../../../utils/analyticsEvent';
import { OLD_SIZES } from '../../../styles/appConsts';

import { NavigationContext } from '../../../components/navigator/index';
import { scheduleMeetingWithCS } from '../../../components/dialogs/mixedDialog/mixedDialogContent';
import { nextMonthFreeOffer } from '../../../components/dialogs/mixedDialog/mixedDialogContent';
import { nextMonthFreeConrifmation } from '../../../components/dialogs/mixedDialog/mixedDialogContent';
import { Header } from '../../../components/Text';
import { CancellationReason } from '@7chairs/types';

import { setDialogProps } from '../../../store/ducks/root';
import { cancelSubscriptionContactUserRequest, cancelSubscriptionNextMonthFree } from '../../../store/ducks/payment';

import { closeIfWebview } from './utils';
import { useCancelSubscriptionModal } from './hooks/useCancelSubscriptionModal';

import { usePickAGroupVariant } from './hooks/usePickAGroupUrl';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: OLD_SIZES.l,
  },
  title: {
    marginBottom: OLD_SIZES.m,
  },
  subtitle: {
    marginBottom: OLD_SIZES.xl,
    width: '290px',
    textAlign: 'center',
  },
  tile: {
    backgroundColor: getColor('GRAY_BG'),
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    marginBottom: OLD_SIZES.m,
    padding: `${OLD_SIZES.l}px 0`,
    textAlign: 'center',
    width: '290px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tileContainer: {
    width: '290px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '290px',
  },
  button: {
    marginBottom: '16px',
    width: '100%',
  },
}));

interface AssistanceMenuComponentProps {
  reasons: CancellationReason[];
  cancelSubscriptionContactUserRequest: any;
  cancelSubscriptionNextMonthFree: any;
  setDialogProps: any;
}

function AssistanceMenuComponent({
  cancelSubscriptionContactUserRequest,
  cancelSubscriptionNextMonthFree,
  setDialogProps,
  reasons,
}: AssistanceMenuComponentProps) {
  const classes = useStyles();

  const navCtx = useContext(NavigationContext);

  // @ts-ignore
  const fullName = useSelector((state) => state.account.fullName);
  // @ts-ignore
  const email = useSelector((state) => state.account.email);
  // @ts-ignore
  const nextMonthFreeAvailable = useSelector((state) => state.payment.nextMonthFreeAvailable);
  // @ts-ignore
  const cancelPaymentRequest = useSelector((state) => state.account.cancelPaymentRequest);

  const cancelSubscriptionModal = useCancelSubscriptionModal();

  const onMeetingWithCS = () => {
    const option = 'meetingWithCS';
    trackEvent('click cancel subscription option', { option });
    const meetingUrl = `https://meetings.hubspot.com/hello-circles?email=${email}&firstName=${
      fullName.split(/\s/)[0]
    }&lastName=${fullName.split(/\s/)[1] ?? ''}`;
    scheduleMeetingWithCS(setDialogProps, {
      onApprove: () => {
        cancelSubscriptionContactUserRequest(option);
        if (closeIfWebview()) {
          return;
        }
        navCtx.replaceScreen('/profile');
      },
      onClose: () => {
        cancelSubscriptionContactUserRequest(option);
        if (closeIfWebview()) {
          return;
        }
        navCtx.replaceScreen('/profile');
      },
      meetingUrl,
    });
  };

  const onFulfillNextMonthFreeOffer = () => {
    if (closeIfWebview()) {
      return;
    }

    navCtx.replaceScreen('/profile');
  };

  const onCancelSubscription = () => {
    trackEvent('click cancel subsription proceed');
    if (cancelPaymentRequest?.status === 'new') {
      navCtx.pushScreen('/payment');
      return;
    }
    if (!nextMonthFreeAvailable) {
      navCtx.pushScreen('/payment/cancel');
      return;
    }
    trackEvent('view next month free offer popup');
    nextMonthFreeOffer(setDialogProps, {
      onApprove: () => {
        trackEvent('click get next month free option');
        cancelSubscriptionNextMonthFree().then(() => {
          nextMonthFreeConrifmation(setDialogProps, {
            onConfirm: onFulfillNextMonthFreeOffer,
            onClose: onFulfillNextMonthFreeOffer,
          });
        });
      },
      onUnsubscribe: () => {
        trackEvent('click cancel subsription skip next month free');
        navCtx.pushScreen('/payment/cancel');
      },
      onClose: () => {},
    });
  };

  const onOtherReasons = () => navCtx.pushScreen('/payment/explain-problem');

  const pickAGroupVariant = usePickAGroupVariant();
  const buttonByOption: { [reason in CancellationReason]: { text: string; action: () => void } } = {
    switch: {
      text: 'I want to change groups',
      action: () => {
        // @ts-ignore
        window.location.href = `${WEBSITE_URL}/${pickAGroupVariant}/?accessToken=${
          window.localStorage.authToken
          // @ts-ignore
        }&backUrl=${encodeURIComponent(WEB_APP_URL)}`;
      },
    },
    discount: {
      text: 'Billing Inquiries',
      action: () => cancelSubscriptionModal('discount'),
    },
    techIssue: {
      text: 'I’m having technical issues',
      action: () => cancelSubscriptionModal('techIssue'),
    },
    meetingWithCS: {
      text: 'Call me and I’ll explain',
      action: onMeetingWithCS,
    },
    cancel: {
      text: 'Cancel my subscription',
      action: onCancelSubscription,
    },
    other: {
      text: 'Other',
      action: onOtherReasons,
    },
  };

  return (
    <Grid container direction="column" alignItems="center" className={classes.root}>
      <Header className={classes.title}>Let us know how can we help</Header>
      <div className={classes.subtitle}>
        <Text variant="subtitle">
          We want you to make the most out of your experience, let us know what is bothering you
        </Text>
      </div>

      <div className={classes.buttonContainer}>
        {reasons.map((reason) => (
          <ReasonButton key={reason} onClick={buttonByOption[reason].action} text={buttonByOption[reason].text} />
        ))}
      </div>
    </Grid>
  );
}

interface ReasonButtonProps {
  text: string;
  onClick: () => void;
}

function ReasonButton({ text, onClick }: ReasonButtonProps) {
  const classes = useStyles();
  return (
    <div className={classes.button}>
      <Button variant="primary" size="big" onClick={onClick}>
        {text}
      </Button>
    </div>
  );
}

export const AssistanceMenu = connect(() => ({}), {
  cancelSubscriptionContactUserRequest,
  cancelSubscriptionNextMonthFree,
  setDialogProps,
})(AssistanceMenuComponent);
