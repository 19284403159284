import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import { Label1 } from '../../Text';
import { OLD_SIZES, OLD_COLORS } from '../../../styles/appConsts';

const pictureAnswerStyles = () => ({
  root: {
    minHeight: 130,
    marginBottom: OLD_SIZES.l,
    borderRadius: 8,
    marginRight: OLD_SIZES.m,
    width: '45%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  label: {
    fontSize: 14,
    fontWeight: '500',
    color: OLD_COLORS.BLACK,
    marginBottom: OLD_SIZES.s,
  },
  content: {
    position: 'relative',
    width: '100%',
  },
  image: {
    height: 110,
    width: '100%',
    borderRadius: 8,
  },
  selectedWrapper: {
    position: 'absolute',
    top: -4,
    left: -4,
    bottom: 0,
    right: -4,
    borderRadius: 8,
    backgroundColor: OLD_COLORS.BLACK,
    border: `4px solid ${OLD_COLORS.BLACK}`,
    opacity: 0.7,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkIcon: {
    color: OLD_COLORS.WHITE,
    fontSize: 40,
  },
});

const PictureAnswer = withStyles(pictureAnswerStyles)((props) => {
  const { classes, text, picture, selected, onClick, index } = props;
  return (
    <div className={classes.root} onClick={onClick}>
      <Label1 className={classes.label}>{`${index}. ${text}`}</Label1>
      <div className={classes.content}>
        {selected && (
          <div className={classes.selectedWrapper}>
            <CheckIcon className={classes.checkIcon} />
          </div>
        )}
        <img src={picture} className={classes.image} />
      </div>
    </div>
  );
});

const textAnswerStyles = (theme) => ({
  root: {
    minHeight: 110,
    marginBottom: OLD_SIZES.xxl,
    borderRadius: 8,
    marginRight: OLD_SIZES.m,
    width: '45%',
    backgroundColor: OLD_COLORS.GRAY_400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  text: {
    fontSize: 16,
    fontWeight: '500',
    textAlign: 'center',
  },
  selectedWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    borderRadius: 8,
    backgroundColor: OLD_COLORS.BLACK,
    opacity: 0.3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkIcon: {
    color: OLD_COLORS.WHITE,
    fontSize: 40,
  },
});

const TextAnswer = withStyles(textAnswerStyles)((props) => {
  const { classes, color, text, selected, onClick } = props;
  return (
    <div className={classes.root} onClick={onClick}>
      <Label1 className={classes.text} color={color}>
        {text}
      </Label1>
      {selected && (
        <div className={classes.selectedWrapper}>
          <CheckIcon className={classes.checkIcon} />
        </div>
      )}
    </div>
  );
});

const styles = (theme) => ({
  container: {
    marginTop: OLD_SIZES.m,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  additionalContentText: {
    fontSize: 12,
    color: OLD_COLORS.WHITE,
    opacity: 0.5,
  },
  answersContainer: {
    marginTop: OLD_SIZES.m,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexFlow: 'wrap',
  },
});

export default withStyles(styles)((props) => {
  const { classes, selectedAnswer, optionalAnswers, additionalContent, color, onChange } = props;

  return (
    <div className={classes.container}>
      {additionalContent?.subtitle && (
        <Label1 className={classes.additionalContentText}>{additionalContent.subtitle}</Label1>
      )}
      {additionalContent?.description && (
        <Label1 className={classes.additionalContentText}>{additionalContent.description}</Label1>
      )}
      <div className={classes.answersContainer}>
        {optionalAnswers.map((ans, idx) => {
          const selected = selectedAnswer === ans.value;
          const onClick = () => {
            if (selected) {
              onChange(null);
            } else {
              onChange(ans.value);
            }
          };
          return ans.picture ? (
            <PictureAnswer
              key={`${ans.value}-${idx}`}
              picture={ans.picture}
              index={idx + 1}
              text={ans.label}
              selected={selected}
              onClick={onClick}
            />
          ) : (
            <TextAnswer
              key={`${ans.value}-${idx}`}
              color={color}
              text={ans.label}
              selected={selected}
              onClick={onClick}
            />
          );
        })}
      </div>
    </div>
  );
});
