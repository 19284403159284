import React from 'react';
import questionTypes from '../questionTypes';
import TextAnswer from './Text';
import RankAnswer from './Rank';
import MultiChoiceAnswer from './MultiChoice';
import SingleChoiceAnswer from './SingleChoice';

const components = {
  [questionTypes.TEXT]: TextAnswer,
  [questionTypes.RANK]: RankAnswer,
  [questionTypes.MULTI_CHOICE]: MultiChoiceAnswer,
  [questionTypes.SINGLE_CHOICE]: SingleChoiceAnswer,
};

export default function ({ questionType, placeholder, value, optionalAnswers, additionalContent, color, setAnswer }) {
  const compProps = {
    [questionTypes.TEXT]: { placeholder, value, onChange: (e) => setAnswer(e.target.value) },
    [questionTypes.RANK]: {
      settings: optionalAnswers?.[0],
      value,
      color,
      onChange: (val) => setAnswer(val),
    },
    [questionTypes.MULTI_CHOICE]: {
      selectedAnswers: value,
      optionalAnswers,
      additionalContent,
      color,
      onChange: (val) => setAnswer(val),
    },
    [questionTypes.SINGLE_CHOICE]: {
      selectedAnswer: value,
      optionalAnswers,
      additionalContent,
      color,
      onChange: (val) => setAnswer(val),
    },
  };

  const Comp = components[questionType];

  return <Comp {...compProps[questionType]} />;
}
