import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  div: {},
});

export default withStyles(styles)((props) => {
  const { renderComponent, components, onClick, style = {} } = props;
  return (
    <div style={style} onClick={onClick}>
      {components.map((c, i) => renderComponent(c, i))}
    </div>
  );
});
