import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import TopBar from '../TopBarContainer';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  blobs: {
    position: 'relative',
    marginTop: 100,
    width: 186,
    height: 198,
  },
  blob1: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    animation: '$spin1 3s infinite linear',
    zIndex: 999,
  },
  blob2: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    animation: '$spin2 5s infinite linear',
  },
  blob3: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    animation: '$spin3 6s infinite linear',
  },
  '@keyframes spin1': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes spin2': {
    from: {
      transform: 'rotate(360deg)',
    },
    to: {
      transform: 'rotate(0deg)',
    },
  },
  '@keyframes spin3': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  children: {
    fontSize: 16,
    maxWidth: 350,
    marginTop: 30,
    textAlign: 'center',
  },
}));

interface Props {
  children?: React.ReactNode;
}

export const Loader = ({ children }: Props) => {
  const classes = useStyles();

  const isMobileWebview = useMemo(() => window.navigator.userAgent.indexOf('circles-mobile-app') > -1, [
    window.navigator.userAgent,
  ]);

  return (
    <div className={classes.root}>
      <TopBar isMobileWebview={isMobileWebview} mainLogo />
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' flexWrap='nowrap'>
        <div className={classes.blobs}>
          <img src={`${ASSETS_BASE_DIR}/loader/blob1.svg`} className={classes.blob1} alt='' />
          <img src={`${ASSETS_BASE_DIR}/loader/blob2.svg`} className={classes.blob2} alt='' />
          <img src={`${ASSETS_BASE_DIR}/loader/blob3.svg`} className={classes.blob3} alt='' />
        </div>
        {children ? <div className={classes.children}>{children}</div> : null}
      </Box>
    </div>
  );
};
